export default ({cardsArray, sessionID, deckName}) => {
  return {
    cardsArray,
    inProgress: false,
    nextTestDate: "",
    sessionID,
    startDate: Date.now(),
    testCount: 0,
    sessionSnapshot: "",
    deckName
  };
};
