import {testOne} from "./reiCards"
import {testTwo} from "./2"
import {testThree} from "./3"
import {testFour} from "./4"
import {testFive} from "./5"

export default {
    testOne,
    testTwo,
    testThree,
    testFour,
    testFive
}