import React, { PureComponent, useState, useEffect, useRef } from "react";
import "./LiningPractice.css";

/* class LiningPractice extends React.Component {
    state = {  }

    componentDidMount(){
        
        console.log(document.querySelector('.line-container').scrollHeight % window.innerHeight / 10)
    }
    render() {
         
        return ( 
        <div className="note-taker">
            <div className="line-container">
                <div className="pad-line" styles={{height: window.innerHeight / 10}}>

                </div>
            </div>
            
            
        </div> );
    }
} */

const NoteBlock = ({ width, index }) => {
  const [state, setState] = useState({ line: "", selected: false });
  let { selected, line } = state;

  const inputHandler = (e) => {
    console.log(e.target.value);
    setState({ ...state, line: e.target.value });
  };

  useEffect(() => {}, []);

  return (
    <>
      <div
        className={`line-block ${index} ${selected ? "" : "hov"}`}
        onDragOverCapture
        onClick={() => setState({ ...state, selected: !selected })}
        style={{ width }}
      >
        <input
          /* autofocus={`${selected}`} */
          className={`line-input ${selected ? "active" : ""}`}
          value={state.line}
          onChange={inputHandler}
        />
        {!selected ? line : null}
      </div>
    </>
  );
};

const LiningPractice = () => {
  let [state, setState] = useState({ rowHeight: 5 });

  useEffect(() => {
    let windowHeight = window.innerHeight;
    let rowHeight = document.querySelector(".line-container").scrollHeight / 15;
    let noteBlockWidth = document.querySelector(".pad-row").scrollWidth / 2;

    setState({
      ...state,
      rowHeight,
      noteBlockWidth,
    });
  }, []);
  return (
    <div className="note-taker">
      <div className="pad-container">
        <div className="line-container">
          {Array(15).fill(
            <div className="pad-row" style={{ height: state.rowHeight }}>
              {Array(2)
                .fill("")
                .map((cp, index) => {
                  return (
                    <NoteBlock index={index} width={state.noteBlockWidth} />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LiningPractice;
