import React, { useState, useEffect, useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import Card from "../Card";
import Icon from "antd/lib/icon";
import "./TestComponent.css";

const testIntervals = {
  /* This object maps the key (corresponding to the 'testCount'[i.e has it been tested once, twice, etc] 
        value of the session) to its appropriate interval of time. */
  0: 60 * 60 * 12 * 1000,
  1: 60 * 60 * 48 * 1000,
  2: 60 * 60 * 24 * 5 * 1000,
  3: 60 * 60 * 24 * 7 * 1000
};

const TestComponent = ({testsArray}) => {
  let [state, setState] = useState({
    currentCard: "",
    testArray: [],
    cardsArray: [],
    remembered: [],
    forgotten: []
  });

  const history = useHistory();
  useMemo(() => {
    //change the line below to be the testArray prop
   //let testsArray = JSON.parse(localStorage.getItem("testsArray")) || [];
   console.log('TESTS ARRAY TEST COMPONENT',testsArray)

    const shuffleArray = array => {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)),
          temp = array[i];
        [array[i], array[j]] = [array[j], array[i]];
        array[i].key = i;
        array[j].key = j;
      }
      return array;
    };

    let test = shuffleArray(testsArray);
    let cardsArray = [...test];
    let currentCard = test.pop()
    
    setState({
      ...state,
      testArray: testsArray,
      cardsArray,
      currentCard
    })

    console.log('CC', currentCard)
  }, []);

  let rotateCards = status => {
    let clickedCard = { ...state.currentCard };
    // let nextCard = state.testArray.pop();
    //console.log('nextCard', nextCard);
    state[status].push(clickedCard);
    state.currentCard = state.testArray.pop();

    setState({
      ...state
    });
  };

  let saveQuit = () => {
    let sessionsArray = localStorage.getItem("sessionsArray");
    let sessionsInTest = {}; // this is meant to group the items that were just studied back into
    //their respective sessions

    if (sessionsArray !== "undefined") {
      //set the forgotten session's cards array to an empty array to refill it later with the cards in the test that were forgotten
      sessionsArray = JSON.parse(sessionsArray);

      if (!sessionsArray.filter(session => session.sessionID === 0).length) {
        console.log("Sessions on line 111 filtered");
        sessionsArray = [
          ...sessionsArray,
          {
            sessionID: 0,
            cardsArray: []
          }
        ];
      }
    }
    let sessionArray = [...sessionsArray];

    sessionsInTest[0] = sessionsArray.filter(
      session => session.sessionID === 0
    )[0].cardsArray;

    state.remembered.forEach(item => {
      if (!sessionsInTest[item.sessionId]) {
        sessionsInTest[item.sessionId] = [{ ...item }];
      } else {
        sessionsInTest[item.sessionId] = [
          ...sessionsInTest[item.sessionId],
          { ...item }
        ];
      }
    });
    state.forgotten.forEach(item => {
      item.sessionId = 0;

      if (!sessionsInTest[item.sessionId]) {
        sessionsInTest[item.sessionId] = [{ ...item }];
      } else {
        sessionsInTest[item.sessionId] = [
          ...sessionsInTest[item.sessionId],
          { ...item }
        ];
      }
    });
   
    let idArray = Object.keys(sessionsInTest);
    //console.log('1',idArray, sessionsInTest)
    let newSessionsArray;
    //for each session ID related to all test items, loop through all sessions and
    //if the session id is equal, the new cardArray for that session will be updated.
    idArray.forEach(id => {
      newSessionsArray = sessionArray.map(session => {
        if (parseInt(id) === session.sessionID) {
          if (session.testCount < 4) {
            session.testCount = session.testCount + 1;
          }
          if (session.testCount >= 4) {
            // As a hotfix for the testdate glitch this is reverting unproperly
            //updated words back to a previous date. In the future this should add them to a 'remembered'status :UDPATE:

            session.testCount = 3;
          }

          session.cardsArray = sessionsInTest[id];
          //console.log('sessionbeforeTestUpdate', session, session.nextTestDate + testIntervals[session.testCount] )
          //console.log('nextdate',session.nextTestDate, 'testintervals', testIntervals[session.testCount])

          session.nextTestDate = Date.now() + testIntervals[session.testCount];
        }
        return session;
      });
    });
    
    localStorage.setItem("sessionsArray", JSON.stringify(newSessionsArray));
    history.push("/dashboard/");
  };

  return (
    <>
      <div className="main-test-section">
        <div className="left-section">
          <div className="test-results-container">
            <div className="test-result-box">
              <div className="rememebered-count-container">Remembered</div>
              {state.remembered.length}
            </div>
            <div className="test-result-box">
              <div className="forgotten-count-container">Forgotten</div>
              {state.forgotten.length}
            </div>
          </div>
        </div>
        {state.currentCard ? (
          state.cardsArray.map((card, index) => {
            console.log(index, card.key)
            return (
              <div className="test-card-container">
                <div
                  className={`card ${
                    index === state.currentCard.key ? "" : "hidden"
                  } 
                             ${state.flipped ? "flip" : ""} `}
                  onClick={() =>
                    setState({ ...state, flipped: !state.flipped })
                  }
                >
                  <div className="front">
                    <div>{card.word}</div>
                  </div>

                  <div className="back">{card.translation}</div>
                </div>
                <div
                  className={`btn-container  ${
                    index === state.currentCard.key ? "" : "hidden"
                  }`}
                >
                  <div
                    className="cardBtn green"
                    onClick={() => rotateCards("remembered")}
                  >
                    <Icon type="check" style={{ fill: "white" }} />
                  </div>
                  <div
                    className="cardBtn red"
                    onClick={() => rotateCards("forgotten")}
                  >
                    <Icon type="stop" style={{ fill: "white" }} />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="general-text">
            You've finished your test!
            <div className="save-quit-button" onClick={() => saveQuit()}>
              Save & Quit
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TestComponent;
