//import AWS from "aws-sdk";
const AWS = require('aws-sdk')


// Initialize the Amazon Cognito credentials provider
AWS.config.region = "us-east-2"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "us-east-2:0f4c8b71-d03c-49f8-b2ec-a4d5616e3e45"
});

const polly = new AWS.Polly({ apiVersion: "2016-06-10" });

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: "mnemozen-audio" }
});

const wordArray = [
    {
        "word": "آلة",
        "translation": "machine",
        "language": "ar",
        "sentences": [],
        "learned": false,
        "rememberedStatus": 0,
        "sessionId": null,
        "topic": "",
        "question": "آلة",
        "answers": [],
        "correctAnswer": "machine",
        "explanation": ""
    },{
    "word": "آخر",
    "translation": "post",
    "language": "ar",
    "sentences": [],
    "learned": false,
    "rememberedStatus": 0,
    "sessionId": null,
    "topic": "",
    "question": "آخر",
    "answers": [],
    "correctAnswer": "post",
    "explanation": "",
"audio": ""
}]
const createAudio = async (card) => {
    var word = card
        var speechParams = {
          OutputFormat: "mp3",
          SampleRate: "16000",
          Text: `<speak><prosody rate="slow">${card.word}</prosody></speak>`,
          TextType: "ssml",
          VoiceId: "Zeina"
        };
    return new Promise( async (resolve, reject) => {
        try {
            await polly.synthesizeSpeech(speechParams, function(
              error,
              data
            ) {
              if (error) {
                console.log(error);
              } else {
                var putParams = {
                  Body: data.AudioStream,
                  ContentType: "application/octet-stream",
                  Key: `${card.word}.mp3`, 
                  ACL: "public-read"
                };
                s3.upload(putParams, function(err, data) {
                  if (err) console.log(err, err.stack);
                  // an error occurred
                  else {
                    console.log("upload success");
                    console.dir(data); // successful response
                    
                    word.audio = data.Location
                    console.log(word);
                    resolve(word)
                  }
                });
                
  
                /* let audio = new Audio(`${url}`);
                console.log(audio);
                let example = document.querySelector(`.card${order}Ex${index}`);
                let playFunc = () => audio.play();
                example.onclick = playFunc;
                console.log(example); */
                
              }
            });
           
          } catch (err) {
            console.log(err);
            reject(err)
          }
    })

    
       
        
        
      
      
}

const createDeckAudio = async (cardArray, user) => {
    let requests = []
    cardArray.forEach((card)=> {
        requests.push(createAudio(card))
    })
    let response = await Promise.all(requests).then(res => {
      console.log("REQS", res)
      return res
    }).catch(err => console.log(err))

    return response

}

export default createDeckAudio