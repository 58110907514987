export const testTwo = [
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You are planning on using the Amazon RDS facility for Fault tolerance for your application. How does Amazon RDS multi Availability Zone model work?",
    answers: [
      "A. A second, standby database is deployed and maintained in a different availability zone from master, using synchronous replication.",
      "B. A second, standby database is deployed and maintained in a different availability zone from master using asynchronous replication.",
      "C. A second, standby database is deployed and maintained in a different region from master using asynchronous replication.",
      "D. A second, standby database is deployed and maintained in a different region from master using synchronous replication."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "Amazon RDS Multi-AZ deployments provide enhanced availability and durability for Database (DB) Instances, making them a natural fit for production database workloads. When you provision a Multi-AZ DB Instance, Amazon RDS automatically creates a primary DB Instance and synchronously replicates the data to a standby instance in a different Availability Zone (AZ). Each AZ runs on its own physically distinct, independent infrastructure, and is engineered to be highly reliable. In case of an infrastructure failure, Amazon RDS performs an automatic failover to the standby (or to a read replica in the case of Amazon Aurora), so that you can resume database operations as soon as the failover is complete."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your team has maintained a Jenkins server installed in an AWS EC2 instance. The Jenkins server is mainly used to build the artifacts for a Java application. At the moment, there is no disaster recovery strategy for this Jenkins server. Your team needs to design a new disaster recovery plan with both RTO and RPO set as 24 hours. Which disaster recovery strategy should the team choose?       ",
    answers: [
      "A. Backup & Restore strategy. For example, backup the Jenkins configuration files every day to a S3 bucket. Use CloudFormation templates to provision the necessary AWS resources",
      "B. Pilot Light strategy. Use an AMI to launch an EC2 instance in another region and stop the instance to save cost. Start the Pilot Light instance when there is an outage in the original Jenkins server.",
      "C. Warm Standby strategy. Launch a smaller size EC2 instance in the same region but different VPC as a standby.",
      "D. Hot Standby (Multi Site) strategy. Launch a fully operational EC2 instance for the Jenkins server. Suspend its tasks unless a failover happens."
    ],
    correctAnswer: "Correct Answer – A",
    explanation:
      "In this case, the EC2 has a Jenkins server installed which is used as a CI/CD server. It is not an application in production so its impact is quite low. Other than that, as both RPO and RTO are 24 hours, there is plenty of time to recover the server using a backup when there is an outage. The Backup & Restore strategy is enough for this scenario. There are several methods to back the server, such as the Jenkins configuration files, AMI for the EC2 instance, EBS daily snapshots, etc."
  },
  {
    topic: "Incident and Event Response",
    question:
      "Your system automatically provisions EIPs to EC2 instances in a VPC on boot. The system provisions the whole VPC and stack at once. You have two of them per VPC. On your new AWS account, your attempt to create a Development environment failed, after successfully creating Staging and Production environments in the same region. What happened?",
    answers: [
      "A. You didn’t choose the Development version of the AMI you are using.",
      "B. You didn’t set the Development flag to true when deploying EC2 instances.",
      "C. You hit the soft limit of 5 EIPs per region and requested a 6th.",
      "D. You hit the soft limit of 2 VPCs per region and requested a 3rd."
    ],
    correctAnswer: "Answer - C",
    explanation:
      "The most likely cause is the fact you have hit the maximum of 5 Elastic IP’s per region."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are designing a service that aggregates clickstream data in batch and delivers reports to subscribers via email only once per week. Data is extremely spikey, geographically distributed, high-scale, and unpredictable. How should you design this system?",
    answers: [
      "A. Use a large RedShift cluster to perform the analysis, and a fleet of Lambdas to perform record inserts into the RedShift tables. Lambda will scale rapidly enough for the traffic spikes.",
      "B. Use a CloudFront distribution with access log delivery to S3. Clicks should be recorded as querystring GETs to the distribution. Reports are built and sent by periodically running EMR jobs over the access logs in S3.",
      "C. Use API Gateway invoking Lambdas which PutRecords into Kinesis, and EMR running Spark performing GetRecords on Kinesis to scale with spikes. Spark on EMR outputs the analysis to S3, which are sent out via email.",
      "D. Use AWS Elasticsearch service and EC2 Auto Scaling groups. The Autoscaling groups scale based on click throughput and stream into the Elasticsearch domain, which is also scalable. Use Kibana to generate reports periodically."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "When you look at building reports or analyzing data from a large data set, you need to consider EMR because this service is built on the Hadoop framework which is used to processes large data sets."
  },
  {
    topic: "SDLC Automation",
    question:
      "You want to pass queue messages that are 1 GB each. How should you achieve this?",
    answers: [
      "A. Use Kinesis as a buffer stream for message bodies. Store the checkpoint id for the placement in the Kinesis Stream in SQS.",
      "B. Use the Amazon SQS Extended Client Library for Java and Amazon S3 as a storage mechanism for message bodies.",
      "C. Use SQS’s support for message partitioning and multi-part uploads on Amazon S3.",
      "D. Use AWS EFS as a shared pool storage medium. Store filesystem pointers to the files on disk in the SQS message bodies."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "You can manage Amazon SQS messages with Amazon S3. This is especially useful for storing and consuming messages with a message size of up to 2 GB. To manage Amazon SQS messages with Amazon S3, use the Amazon SQS Extended Client Library for Java. Specifically, you use this library to:"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "A software company recently migrated one of its applications to AWS platform. The application is deployed in region ap-southeast-1. For the disaster recovery strategy, the DevOps team has used a warm-standby solution and created another functional environment in region ap-southeast-2. Which of the following steps can be regarded as appropriate for recovery? (Select TWO.)",
    answers: [
      "A. Create custom AMIs and start the application in Amazon EC2 instances.",
      "B. Adjust Auto Scaling groups to accommodate the increased traffic.",
      "C. Use EBS Lifecycle Manager to regularly create EBS snapshots.",
      "D. Select tools to backup data into AWS S3. Enable encryption for sensitive data.",
      "E. Check Amazon Route 53 to make sure that all traffic is routed to region ap-southeast-2."
    ],
    correctAnswer: "Correct Answer – B, E",
    explanation:
      "Refer to https://media.amazonwebservices.com/AWS_Disaster_Recovery.pdf for the AWS disaster recovery blueprint. It should be noted that the warm standby solution is an extension to pilot light. The standby is fully functional however the environment uses a minimum-size of servers to save cost."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "Your team is investigating existing AWS tools to help them better understand where and how the sensitive information is stored and accessed in AWS. You found that AWS Macie can meet the need as it uses machine learning to analyze, classify and protect data. It also provides a dashboard to show several key interest points such as high-risk S3 objects and total user session. Which AWS services are the data sources for AWS Macie? (Select TWO.)",
    answers: [
      "A. AWS CloudWatch",
      "B. AWS CloudTrail",
      "C. Amazon S3 bucket",
      "D. AWS Config",
      "E. AWS EBS volume"
    ],
    correctAnswer: "Correct Answer – B, C",
    explanation:
      "Amazon Macie uses machine learning to protect data stored in Amazon S3. About the introduction for AWS Macie, please refer to https://docs.aws.amazon.com/macie/latest/userguide/macie-concepts.html."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You are working for a large supermarket chain as a DevOps engineer. Your company has stored a huge amount of data in different AWS and on-premise sources. The data includes daily transactions, customer data, etc. You have a task to build up an AWS QuickSight service which can use machine learning technologies to help other teams to analyze data, visualize data through dashboards and discover hidden trends. Which data can NOT be provided to QuickSight for analysis?  ",
    answers: [
      "A. An AWS Redshift cluster.",
      "B. A MySQL 5.1 database in the customer’s data center which is internet accessible.",
      "C. A MariaDB RDS instance.",
      "D. YAML files stored in S3 buckets."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "AWS QuickSight can use various relational data stores as data sources to create an analysis via machine learning technologies."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You are building out a layer in a software stack on AWS that needs to be able to scale out to react to increased demand as fast as possible. You are running the code on EC2 instances in an Auto Scaling Group behind an ELB. Which application code deployment method should you use?",
    answers: [
      "A. SSH into new instances that come online, and deploy new code onto the system by pulling it from an S3 bucket, which is populated  by code that you refresh from source control on new pushes.",
      "B. Bake an AMI when deploying new versions of code, and use that AMI for the Auto Scaling Launch Configuration.",
      "C. Create a Dockerfile when preparing to deploy a new version to production and publish it to S3. Use UserData in the Auto Scaling Launch configuration to pull down the Dockerfile from S3 and run it when new instances launch.",
      "D. Create a new Auto Scaling Launch Configuration with UserData scripts configured to pull the latest code at all times."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "Since the time required to spin up an instance is required to be fast , its better to create an AMI rather than use User Data. When you use User Data , the script will be run during boot up , and hence this will be slower."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You have provided AWS consulting service to a financial company which just migrated its major on-premise products to AWS. Last week, there was an outage of  AWS hardware which impacted one of EBS volumes. This failure was alerted in the AWS Personal Health Dashboard however it took the team several hours to be aware of that. The company asks for a solution to notify the team when there is an open issue happening in AWS Personal Health Dashboard. Which approach should you recommend?",
    answers: [
      "A. Create a CloudWatch Event which monitors AWS Health Event and trigger a SNS notification when there is a new event.",
      "B. In AWS Personal Health Dashboard console, configure a SNS notification when specific open issues appear.",
      "C. Configure a Lambda function which periodically checks open issues via AWS Health API and triggers a SNS notification if a new issue has been found.",
      "D. Create a new CloudWatch Event which monitors Trusted Advisor service and trigger a SNS notification when there is a new issue."
    ],
    correctAnswer: "Correct Answer – A",
    explanation:
      "AWS Personal Health Dashboard is able to show AWS information such as open issues, scheduled changes and event logs that may be relevant to your AWS resources:"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "An application is installed in EC2 and sends customized metrics to AWS CloudWatch. You have configured lots of AWS CloudWatch alarms based on these metric reports data. You have found that there are several CloudWatch alarms which have the state of “INSUFFICIENT_DATA” at times. You confirmed with developers that certain metric data is only intermittently generated by design. What should you perform to ignore these “INSUFFICIENT_DATA” alarms?      \n\n                                                           ",
    answers: [
      "A. Configure the CloudWatch alarms to change the state of “INSUFFICIENT_DATA” to “OK” after 5 minutes.",
      "B. Configure these CloudWatch alarms to treat missing data points as “ignore” so that “INSUFFICIENT_DATA” does not show up.",
      "C. Create a Lambda function which calls CloudWatch Alarm API to check the reason of “INSUFFICIENT_DATA” and modifies the alarm state to “OK” if there is no data received from EC2 instance during the time.",
      "D. Create a CloudWatch Event. When an “INSUFFICIENT_DATA” CloudWatch alarm appears, use a SNS to notify the team to react accordingly."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "Refer to https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/AlarmThatSendsEmail.html on how to configure CloudWatch alarms when missing data happens."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You have customized some system and application logs in EC2 instances and delivered them to several Log Groups in CloudWatch Logs. You find that it is very difficult to discover useful logs only through AWS CloudWatch Logs console. You prefer to stream the logs out to a downstream processing system, which can provide more accurate and important information to the operations team. To which services can you configure CloudWatch Logs and stream the data out? (Select TWO.)",
    answers: [
      "A. AWS S3",
      "B. AWS Lambda function",
      "C. AWS Elasticsearch",
      "D. AWS CloudTrail",
      "E. AWS DynamoDB"
    ],
    correctAnswer: "Correct Answer – B, C",
    explanation:
      "In CloudWatch Logs, subscriptions can be used to deliver log events to other services in real time. About the details and supported services, please refer to https://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/Subscriptions.html."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You have created a new Auto Scaling group for a new application which sets the minimum capacity as 1 and the maximum capacity as 20. The ASG is running well for two weeks however recently there were cases that some instances were not terminated successfully. You need to work out a way to notify the team via email whenever any instance fails to terminate. What is the simplest way to implement this?",
    answers: [
      "A. Configure the Auto Scaling group to send a notification to a SNS topic whenever instances fail to terminate.",
      "B. Configure the related Auto Scaling configuration to send a notification to a SNS topic whenever instances fail to terminate.",
      "C. Create a CloudWatch Event. When an event of “Terminate Unsuccessful” happens, invoke a Lambda function to notify the team.",
      "D. Create a CloudWatch alarm based on the metric of “Terminate Failed”. Send a notification to a SNS topic when the alarm state is “ALARM”."
    ],
    correctAnswer: "Correct Answer – A",
    explanation:
      "Auto Scaling group can be easily configured to send a SNS notification whenever it scales. The details can be found in https://docs.aws.amazon.com/autoscaling/ec2/userguide/ASGettingNotifications.html."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "A financial company has an application deployed in an AWS Auto Scaling Group. In most cases, the traffic of the application is very stable and the ASG does not scale in or out. However, since the latest release last week, EC2 instances may fail the health check in Elastic Load Balancer and then be terminated by ASG. The development team has created an ASG lifecycle hook so that they can access the instance and troubleshoot the issue. After the instance goes to the state of “Terminating:Wait”, which AWS service can be configured to receive a notification automatically? (Select TWO.)           ",
    answers: [
      "A. AWS CloudTrail",
      "B. AWS Config",
      "C. AWS CloudWatch Event",
      "D. AWS SNS",
      "E. AWS Step Functions"
    ],
    correctAnswer: "Correct Answer – C, D",
    explanation:
      "Auto Scaling lifecycle hook has provided users a chance to suspend the scaling process. The lifecycle hook state is illustrated as below:"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are developing a Java application which is deployed in AWS by an Auto Scaling Group. The EC2 instances belong to m5.large type. Recently, due to several newly added features, the response time of the application increased because of high CPU usage rate. You need to modify the EC2 instance type to c5.large to remedy this issue. What is the best way to implement this?",
    answers: [
      "A. In AWS console, edit the Auto Scaling Group by modifying the instance type from m5.large to c5.large.",
      "B. Select the relevant launch configuration and choose “Actions -> Copy launch configuration”. Modify the instance type accordingly in the new configuration. Select the new launch configuration for the Auto Scaling Group.",
      "C. In AWS EC2 console, stop the EC2 instances, modify the instance type to c5.large in “Instance Settings -> Change Instance Type” and then start the instances.",
      "D. Select the Auto Scaling launch configuration and choose “Actions -> Change Instance Type”. Modify the instance type to c5.large accordingly."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "One main feature for Auto Scaling configuration is that it cannot be modified after being created. To change the launch configuration, the best practice is to use an existing one as the basis for a new configuration and then update the ASG to use the new one."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "A large group-buying website is deployed in AWS by a classic Load Balancer and an Auto Scaling Group. The ELB is configured with the below health check:\n\n\n\tPing Target: HTTPS:443/healthcheck.htm\n\tTimeout: 20 seconds\n\tInterval: 30 seconds\n\tUnhealthy threshold: 3\n\tHealthy threshold: 3\n\n\nAuto Scaling Group also uses ELB as its health check type. Last weekend, there was a very high traffic due to a promotional event. However, after new instances were launched by ASG, they failed the health check in ELB because of the traffic congestion and were eventually terminated by ASG again. As a result, new instances were very difficult to launch, which made the matter worse. What approach can help resolve this issue under high traffic? (Select TWO.)",
    answers: [
      "A. Increase the Timeout to 25 seconds.",
      "B. Increase the Healthy threshold to 5.",
      "C. Decrease the Unhealthy threshold to 2.",
      "D. Increase the Interval to 35 seconds.",
      "E. Modify Ping target to use HTTP instead of HTTPS."
    ],
    correctAnswer: "Correct Answer – A, D",
    explanation:
      "Check https://docs.aws.amazon.com/elasticloadbalancing/latest/classic/elb-healthchecks.html for the configurations of Health Checks for Classic Load Balancer. Health Check parameters can be easily adjusted in AWS console:"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      'You are building a "game high score" table in DynamoDB which has many games. For similar usage levels and the number of players, the highest score for the respective games would be stored ( the table includes some columns such as "Name of the game", "Name of the user", "UserID" etc ). The highest score for any game, user-wise,  should be retrieved. What would be the table\'s key structure in DynamoDB?',
    answers: [
      "A. HighestScore as the hash / only key.",
      "B. UserID as the hash key, HighestScore as the range key.",
      "C. UserID as the hash / only key.",
      "D. UserID as the range / only key."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "It always best to choose the hash key as the column that will have a wide range of values. This is also given in the AWS documentation"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You need to perform ad-hoc business analytics queries on well-structured, peta bytes of data. Data comes in constantly at a high velocity. Your business intelligence team can understand SQL. What AWS service(s) should you look to first?",
    answers: [
      "A. Kinesis Firehose + RDS",
      "B. Kinesis Firehose + RedShift",
      "C. EMR using Hive",
      "D. EMR running Apache Spark"
    ],
    correctAnswer: "Answer – B",
    explanation:
      "Amazon Kinesis Firehose is the easiest way to load streaming data into AWS. It can capture, transform, and load streaming data into Amazon Kinesis Analytics, Amazon S3, Amazon Redshift, and Amazon Elasticsearch Service, enabling near real-time analytics with existing business intelligence tools and dashboards you’re already using today. It is a fully managed service that automatically scales to match the throughput of your data and requires no ongoing administration. It can also batch, compress, and encrypt the data before loading it, minimizing the amount of storage used at the destination and increasing security."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your web application consists of 10% writes and 90% reads. You currently service all requests through a Route53 Alias Record directed towards an AWS ELB, which sits in front of an EC2 Auto Scaling Group. Your system is getting very expensive when there are large traffic spikes during certain news events, during which many more people access your web application, all at the same time. What is the simplest and cheapest way to reduce costs and scale with spikes like this?",
    answers: [
      "A. Create an S3 bucket and asynchronously replicate common requests responses into S3 objects. When a request comes in for a precomputed response, redirect to AWS S3.",
      "B. Create another ELB and Auto Scaling Group layer mounted on top of the other system, adding a tier to the system. Serve most read requests out of the top layer.",
      "C. Create a CloudFront Distribution and direct Route53 to the Distribution.  Use the ELB as an Origin and specify Cache Behaviours to proxy cache requests which can be served late.",
      "D. Create a Memcached cluster in AWS ElastiCache. Create cache logic to serve requests which can be served late from the in-memory cache for increased performance."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "Use Cloudfront distribution for distributing the heavy reads for your application. You can create a zone apex record to point to the Cloudfront distribution."
  },
  {
    topic: "Incident and Event Response",
    question:
      "If you’re trying to configure an AWS Elastic Beanstalk worker tier for easy debugging if there are problems finishing queue jobs, Which of the following worker environment feature will help us to debug issues with unprocessed jobs?",
    answers: [
      "A. Rolling Deployments.",
      "B. Enhanced Health Reporting",
      "C. Blue-Green Deployments.",
      "D. Dead Letter Queue"
    ],
    correctAnswer: "Answer – D",
    explanation:
      "Elastic Beanstalk worker environments support Amazon Simple Queue Service (SQS) dead letter queues. A dead letter queue is a queue where other (source) queues can send messages that for some reason could not be successfully processed. A primary benefit of using a dead letter queue is the ability to sideline and isolate the unsuccessfully processed messages. You can then analyze any messages sent to the dead letter queue to try to determine why they were not successfully processed."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are a DevOps engineer working in a small IT company. It has limited operation budget for AWS resources so spot EC2 instances are always suggested. There is a new application which will be deployed via a Load Balancer and Auto Scaling Group. However, as this application controls the authentication for all other products of the company, it is inappropriate to use only spot instances for this. The best way is to use a combination of On-Demand and Spot instances for the Auto Scaling Group if possible. What is the best way to implement this?",
    answers: [
      "A. Create two ASGs. One for On-Demand instances and one for Spot instances.",
      "B. Create the ASG by a Launch Template and configure the On-Demand/Spot percentage accordingly.",
      "C. In Auto Scaling Launch Configuration, configure a suitable On-Demand/Spot percentage and then create the ASG with this Launch Configuration.",
      "D. It is impossible to create a combination of On-Demand and Spot instances for this case. Only one type is allowed for an ASG configuration."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "There are two methods to configure an Auto Scaling Group - a Launch Configuration or a Launch Template. Please refer to https://docs.aws.amazon.com/autoscaling/ec2/userguide/create-asg-launch-template.html and https://docs.aws.amazon.com/autoscaling/ec2/userguide/create-asg.html for details:"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You need the absolute highest possible network performance for a cluster computing application. You already selected homogeneous instance types supporting 10 gigabit enhanced networking, made sure that your workload was network bound, and put the instances in a placement group. What is the last optimization you can make?",
    answers: [
      "A. Use 9001 MTU instead of 1500 for Jumbo Frames, to raise packet body to packet overhead ratios.",
      "B. Segregate the instances into different peered VPCs while keeping them all in a placement group, so each one has its own Internet Gateway.",
      "C. Bake an AMI for the instances and relaunch, so the instances are fresh in the placement group and do not have noisy neighbors.",
      "D. Turn off SYN/ACK on your TCP stack or begin using UDP for higher throughput."
    ],
    correctAnswer: "Answer - A",
    explanation:
      "Jumbo frames allow more than 1500 bytes of data by increasing the payload size per packet, and thus increasing the percentage of the packet that is not packet overhead. Fewer packets are needed to send the same amount of usable data. However, outside of a given AWS region (EC2-Classic), a single VPC, or a VPC peering connection, you will experience a maximum path of 1500 MTU. VPN connections and traffic sent over an Internet gateway are limited to 1500 MTU. If packets are over 1500 bytes, they are fragmented, or they are dropped if the Don't Fragment flag is set in the IP header."
  },
  {
    topic: "Incident and Event Response",
    question:
      "Your application’s Auto Scaling Group scales up too quickly, too much, and stays scaled when traffic decreases. What should you do to fix this? ",
    answers: [
      "A. Set a longer cooldown period on the Group, so the system stops overshooting the target capacity. The issue is that the scaling system doesn’t allow enough time for new instances to begin servicing requests before measuring aggregate load again.",
      "B. Calculate the bottleneck or constraint on the compute layer, then select that as the new metric, and set the metric thresholds to the bounding values that begin to affect response latency.",
      "C. Raise the CloudWatch Alarms threshold associated with your autoscaling group, so the scaling takes more of an increase in demand before beginning.",
      "D. Use larger instances instead of lots of smaller ones, so the Group stops scaling out so much and wasting resources as the OS level, since the OS uses a higher proportion of resources on smaller instances."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "The ideal case is that the right metric is not being used for the scale up and down."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Your company needs to automate 3 layers of a large cloud deployment. You want to be able to track this deployment’s evolution as it changes over time, and carefully control any alterations. What is a good way to automate a stack to meet these requirements?",
    answers: [
      "A. Use OpsWorks Stacks with three layers to model the layering in your stack.",
      "B. Use CloudFormation Nested Stack Templates, with three child stacks to represent the three logical layers of your cloud.",
      "C. Use AWS Config to declare a configuration set that AWS should roll out to your cloud.",
      "D. Use Elastic Beanstalk Linked Applications, passing the important DNS entires between layers using the metadata interface."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "As your infrastructure grows, common patterns can emerge in which you declare the same components in each of your templates. You can separate out these common components and create dedicated templates for them. That way, you can mix and match different templates but use nested stacks to create a single, unified stack. Nested stacks are stacks that create other stacks. To create nested stacks, use the AWS::CloudFormation::Stackresource in your template to reference other templates."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "A DynamoDB table called “Global-Temperature” was created by your team which is used to track the highest/lowest temperatures of cities in different countries. The items in the table were identified by a partition key (CountryId) and there was no sort key. The application is recently improved with more features and some queries need to be based on a new partition key (CityId) and sort key (HighestTemperature). How should you implement this?",
    answers: [
      "A. Add a Local Secondary Index with a partition key as CityId and a sort key as HighestTemperature.",
      "B. Modify the existing primary index with partition key as CityId and sort key as HighestTemperature.",
      "C. Add a Global Secondary Index with a partition key as CityId and a sort key as HighestTemperature.",
      "D. Add a Global Secondary Index with partition key as CityId and another Global Secondary Index with partition key as HighestTemperature. Because the primary index only uses a simple primary key (partition key), Secondary Index can only have one partition key as well."
    ],
    correctAnswer: "Correct Answer – C",
    explanation:
      "It is common that applications might need to perform various queries via different attributes as query criteria. In this case, more global secondary indexes are required. Please refer to https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/GSI.html for its introductions."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You migrated an on-premise MySQL database to AWS RDS MySQL in region ap-southeast-1. The database has stored key information such as customers’ phone number, address and date of birth. In order to decrease the read load on the master DB instance, a Read Replica has been created in region ap-southeast-2. This Read Replica can also be promoted as a data recovery scheme if the source DB instance fails. In which scenarios can the data be successfully recovered by the Read Replica? (Select TWO.)         ",
    answers: [
      "A. Data has been deleted mistakenly by a bug in application code.",
      "B. A RDS hardware failure on the master instance.",
      "C. AWS account is compromised.",
      "D. The master database instance has been mistakenly deleted.",
      "E. An AWS regional failure happening in both ap-southeast-1 and ap-southeast-2."
    ],
    correctAnswer: "Correct Answer – B, D",
    explanation:
      "When creating a Read Replica, user can select another region so that updates made to the source DB instance are copied to the Read Replica in this new region:"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "An RDS MySQL database is created for a new feature that your team is currently working on. The feature has used the database to save email transaction data for each customer. For the database, automated snapshots have been configured and are working properly. Recently, due to business requirements, the newest snapshot needs to be shared and used in another AWS account. How can you share this automated RDS snapshot?   ",
    answers: [
      "A. First make a copy of the snapshot, which turns it into a manual version. Then you share the copy with the other account.",
      "B. Select the automated snapshot in AWS console. Share the snapshot by “Actions -> Share Snapshot”.",
      "C. Create a manual snapshot for the RDS database and make sure that it is encrypted. Then share the encrypted snapshot to another account via AWS console or CLI.",
      "D. There is no way to share snapshots to another account for automated ones."
    ],
    correctAnswer: "Correct Answer – A",
    explanation:
      "Automated RDS snapshots cannot be shared directly with other accounts. The action of “Share Snapshot” cannot be selected:"
  },
  {
    topic: "Incident and Event Response",
    question:
      "There is a very serious outage at AWS. EC2 is not affected, but your EC2 instance deployment scripts stopped working in the region with the outage. What might be the issue?",
    answers: [
      "A. The AWS Console is down, so your CLI commands do not work.",
      "B. S3 is unavailable, so you can’t create EBS volumes from a snapshot you use to deploy new volumes.",
      "C. AWS turns off the DeployCode API call when there are major outages, to protect from system floods.",
      "D. None of the other answers make sense. If EC2 is not affected, it must be some other issue."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "The EBS Snapshots are stored in S3 , so if you have an scripts which deploy EC2 Instances , the EBS volumes need to be constructed from snapshots stored in S3."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Your company wants to understand where cost is coming from in the company’s production AWS account. There are a number of applications and services running at any given time. Without expending too much initial development time,how best can you give the business a good understanding of which applications cost the most per month to operate?",
    answers: [
      "A. Create an automation script which periodically creates AWS Support tickets requesting detailed intra-month information about your bill.",
      "B. Use custom CloudWatch Metrics in your system, and put a metric data point whenever cost is incurred.",
      "C. Use AWS Cost Allocation Tagging for all resources which support it. Use the Cost Explorer to analyze costs throughout the month.",
      "D. Use the AWS Price API and constantly running resource inventory scripts to calculate total price based on multiplication of consumed  resources over time."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "A tag is a label that you or AWS assigns to an AWS resource. Each tag consists of a key and a value. A key can have more than one value. You can use tags to organize your resources, and cost allocation tags to track your AWS costs on a detailed level. After you activate cost allocation tags, AWS uses the cost allocation tags to organize your resource costs on your cost allocation report, to make it easier for you to categorize and track your AWS costs. AWS provides two types of cost allocation tags, an AWS-generated tag and user-defined tags. AWS defines, creates, and applies the AWS-generated tag for you, and you define, create, and apply user-defined tags. You must activate both types of tags separately before they can appear in Cost Explorer or on a cost allocation report."
  },
  {
    topic: "Incident and Event Response",
    question:
      "You have an asynchronous processing application using an Auto Scaling Group and an SQS Queue. The Auto Scaling Group scales according to the depth of the job queue. The completion velocity of the jobs has gone down, the Auto Scaling Group size has maxed out, but the inbound job velocity did not increase. What is a possible issue?",
    answers: [
      "A. Some of the new jobs coming in are malformed and unprocessable.",
      "B. The routing tables changed and none of the workers can process events anymore.",
      "C. Someone changed the IAM Role Policy on the instances in the worker group and broke permissions to access the queue.",
      "D. The scaling metric is not functioning correctly."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "This question is more on the grounds of validating each option"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You need your API backed by DynamoDB to stay online during a total regional AWS failure. You can tolerate a couple minutes of lag or slowness during a large failure event, but the system should recover with normal operation after those few minutes. What is a good approach?",
    answers: [
      "A. Set up DynamoDB cross-region replication in a master-standby configuration, with a single standby in another region. Create an Auto Scaling Group behind an ELB in each of the two regions for your application layer in which DynamoDB is running in. Add a Route53 Latency routing using the ELBs in the two regions as the resource records.",
      "B. Set up a DynamoDB Global table. Create an Auto Scaling Group behind an ELB in each of the two regions for your application layer in which the DynamoDB is running in. Add a Route53 Latency DNS Record with DNS Failover, using the ELBs in the two regions as the resource records.",
      "C. Set up a DynamoDB Multi-Region table. Create a cross-region ELB pointing to a cross-region Auto Scaling Group, and direct a Route53 Latency DNS Record with DNS Failover to the cross-region ELB.",
      "D. Set up DynamoDB cross-region replication in a master-standby configuration, with a single standby in another region. Create a crossregion ELB pointing to a cross-region Auto Scaling Group, and direct a Route53 Latency DNS Record with DNS Failover to the cross- region ELB."
    ],
    correctAnswer: "Answer – B",
    explanation: "#Updated based on latest AWS updates"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You need to create an audit log of all changes to customer banking data. You use DynamoDB to store this customer banking data. It’s important not to lose any information due to server failures. What is an elegant way to accomplish this?",
    answers: [
      "A. Use a DynamoDB Stream Specification and stream all changes to AWS Lambda. Log the changes to AWS CloudWatch Logs, removing sensitive information before logging.",
      "B. Before writing to DynamoDB, do a pre-write acknoledgment to disk on the application server, removing sensitive information before logging. Periodically rotate these log files into S3.",
      "C. Use a DynamoDB Stream Specification and periodically flush to an EC2 instance store, removing sensitive information before putting the objects. Periodically flush these batches to S3.",
      "D. Before writing to DynamoDB, do a pre-write acknowledgment to disk on the application server, removing sensitive information before logging. Periodically pipe these files into CloudWatch Logs."
    ],
    correctAnswer: "Answer-A",
    explanation:
      "You can use the DynamoDB table streams as triggers to execute the lambda functions. Triggers are custom actions you take in response to updates made to the DynamoDB table. To create a trigger, first you enable Amazon DynamoDB Streams for your table. Then, you write a Lambda function to process the updates published to the stream."
  },
  {
    topic: "SDLC Automation",
    question:
      "If I want CloudFormation stack status updates to show up in a continuous delivery system in as close to real time as possible, how should I achieve this?",
    answers: [
      "A. Use a long-poll on the Resources object in your CloudFormation stack and display those state changes in the UI for the system.",
      "B. Use a long-poll on the ListStacksAPI call for your CloudFormation stack and display those state changes in the UI for the system.",
      "C. Subscribe your continuous delivery system to an SNS topic that you also tell your CloudFormation stack to publish events into.",
      "D. Subscribe your continuous delivery system to an SQS queue that you also tell your CloudFormation stack to publish events into."
    ],
    correctAnswer: "Answer - C",
    explanation:
      "You can monitor the progress of a stack update by viewing the stack's events. The console's Events tab displays each major step in the creation and update of the stack sorted by the time of each event with latest events on top. The start of the stack update process is marked with an UPDATE_IN_PROGRESS event for the stack"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "What is required to achieve gigabit network throughput on EC2? You already selected cluster-compute, 10GB instances with enhanced networking, and your workload is already network-bound, but you are not seeing 10 gigabit speeds.",
    answers: [
      "A. Enable biplex networking on your servers, so packets are non-blocking in both directions and there’s no switching overhead.",
      "B. Ensure the instances are in different VPCs so you don’t saturate the Internet Gateway on any one VPC.",
      "C. Select PIOPS for your drives and mount several, so you can provision sufficient disk throughput.",
      "D. Use a placement group for your instances so the instances are physically near each other in the same Availability Zone."
    ],
    correctAnswer: "Answer – D",
    explanation:
      "A placement group is a logical grouping of instances within a single Availability Zone. Placement groups are recommended for applications that benefit from low network latency, high network throughput, or both. To provide the lowest latency, and the highest packet-per-second network performance for your placement group, choose an instance type that supports enhanced networking."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You need to deploy a new application version to production. Because the deployment is high-risk, you need to roll the new version out to users over a number of hours, to make sure everything is working correctly. You need to be able to control the proportion of users seeing the new version of the application down to the percentage point. You use ELB and EC2 with Auto Scaling Groups and custom AMIs with your code pre-installed assigned to Launch Configurations. There are no database-level changes during your deployment. You also need to be able to switch back to the original version of code quickly if something goes wrong. What is the best way to meet these requirements?",
    answers: [
      "A. Create a second ELB, Auto Scaling Launch Configuration, and Auto Scaling Group using the Launch Configuration. Create AMIs with all code pre-installed. Assign the new AMI to the second Auto Scaling Launch Configuration. Use Route53 Weighted Round Robin Records to adjust the proportion of traffic hitting the two ELBs.",
      "B. Use the Blue-Green deployment method to enable the fastest possible rollback if needed. Create a full second stack of instances and cut the DNS over to the new stack of instances, and change the DNS back if a rollback is needed.",
      "C. Create AMIs with all code pre-installed. Assign the new AMI to the Auto Scaling Launch Configuration, to replace the old one. Gradually terminate instances running the old code (launched with the old Launch Configuration) and allow the new AMIs to boot to adjust the traffic balance to the new code. On rollback, reverse the process by doing the same thing, but changing the AMI on the Launch Config back to the original code.",
      "D. Migrate to use AWS Elastic Beanstalk. Use the established and well-tested Rolling Deployment setting AWS provides on the new Application Environment, publishing a zip bundle of the new code and adjusting the wait period to spread the deployment over time. Re-deploy the old code bundle to rollback if needed."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "Weighted Routing\n\nWeighted routing lets you associate multiple resources with a single domain name (example.com) or subdomain name (acme.example.com) and choose how much traffic is routed to each resource. This can be useful for a variety of purposes, including load balancing and testing new versions of software. To configure weighted routing, you create records that have the same name and type for each of your resources. You assign each record a relative weight that corresponds with how much traffic you want to send to each resource. Amazon Route 53 sends traffic to a resource based on the weight that you assign to the record as a proportion of the total weight for all records in the group\n\nFor more information refer to page 265 of the below link"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Your CTO has asked you to make sure that you know what all users of your AWS account are doing to change resources at all times. She wants a report of who is doing what over time, reported to her once per week, for as broad a resource type group as possible. How should you do this?",
    answers: [
      "A. Create a global AWS CloudTrail Trail. Configure a script to aggregate the log data delivered to S3 once per week and deliver this to the CTO.",
      "B. Use CloudWatch Events Rules with an SNS topic subscribed to all AWS API calls. Subscribe the CTO to an email type delivery on this SNS Topic.",
      "C. Use AWS IAM credential reports to deliver a CSV of all uses of IAM User Tokens over time to the CTO.",
      "D. Use AWS Config with an SNS subscription on a Lambda, and insert these changes over time into a DynamoDB table. Generate reports based on the contents of this table."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "AWS CloudTrail is an AWS service that helps you enable governance, compliance, and operational and risk auditing of your AWS account. Actions taken by a user, role, or an AWS service are recorded as events in CloudTrail. Events include actions taken in the AWS Management Console, AWS Command Line Interface, and AWS SDKs and APIs."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are building a mobile app for consumers to post cat pictures online. You will be storing the images in AWS S3. You want to run the system very cheaply and simply. Which one of these options allows you to build a photo sharing application with the right authentication/authorization implementation.",
    answers: [
      "A. Build the application out using AWS Cognito and web identity federation to allow users to log in using Facebook or Google Accounts. Once they are logged in, the secret token passed to that user is used to directly access resources on AWS, like AWS S3.",
      "B. Use JWT or SAML compliant systems to build authorization policies.  Users log in with a username and password, and are given a token they can use indefinitely to make calls against the photo infrastructure.",
      "C. Use AWS API Gateway with a constantly rotating API Key to allow access from the client-side.  Construct a custom build of the SDK and include S3 access in it.",
      "D. Create an AWS oAuth Service Domain ad grant public signup and access to the domain.  During setup, add at least one major social media site as a trusted Identity Provider for users."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "Amazon Cognito lets you easily add user sign-up and sign-in and manage permissions for your mobile and web apps. You can create your own user directory within Amazon Cognito. You can also choose to authenticate users through social identity providers such as Facebook, Twitter, or Amazon; with SAML identity solutions; or by using your own identity system. In addition, Amazon Cognito enables you to save data locally on users' devices, allowing your applications to work even when the devices are offline. You can then synchronize data across users' devices so that their app experience remains consistent regardless of the device they use."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "A DevOps engineer has used a CloudFormation template to create a RDS resource for a new web application. The RDS database has used the engine of PostgreSQL and the encryption is not enabled. However, for certain security considerations, the database needs to be updated to enable the encryption. The CloudFormation template is updated accordingly (StorageEncrypted is true). To prevent data loss during the update of CloudFormation stack, the data should be restored from the latest DB snapshot. Which two steps should be taken in combination to meet this requirement? (Select TWO.)",
    answers: [
      "A. Make sure that automated snapshots are working properly and record the last snapshot ARN ID.",
      "B. Deactivate any applications that are using the DB instance and then create a manual snapshot.",
      "C. Add a Stack Policy in the CloudFormation stack to prevent the DB resource from being deleted.",
      "D. Add the DBSnapshotIdentifier property with the ID of the used DB snapshot.",
      "E. Add a DeletionPolicy of Snapshot in the CloudFormation template."
    ],
    correctAnswer: "Correct Answer – B, D",
    explanation:
      "When StorageEncrypted is modified and the CloudFormation is updated, the DB instance is deleted and replaced with a new one. Check https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/aws-properties-rds-database-instance.html#cfn-rds-dbinstance-dbsnapshotidentifier for details."
  },
  {
    topic: "SDLC Automation",
    question:
      "Your team wants to begin practicing continuous delivery using CloudFormation, to enable automated builds and deploys of whole, versioned stacks or stack layers. You have a 3-tier, mission-critical system. Which of the following is NOT a best practice for using CloudFormation in a continuous delivery environment?",
    answers: [
      "A. Use the AWS CloudFormation ValidateTemplate call before publishing changes to AWS.",
      "B. Model your stack in one template, so you can leverage CloudFormation’s state management and dependency resolution to propagate all changes.",
      "C. Use CloudFormation to create brand new infrastructure for all stateless resources on each push, and run integration tests on that set of infrastructure.",
      "D. Parametrize the template and use Mappings to ensure your template works in multiple Regions."
    ],
    correctAnswer: "Answer – B",
    explanation: "Some of the best practices for Cloudformation are"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You run a 2000-engineer organization. You are about to begin using AWS at a large scale for the first time. You want to integrate with your existing identity management system running on Microsoft Active Directory, because your organization is a power-user of Active Directory. How should you manage your AWS identities in the most simple manner?",
    answers: [
      "A. Use AWS Directory Service Simple AD.",
      "B. Use AWS Directory Service AD Connector.",
      "C. Use an Sync Domain running on AWS Directory Service.",
      "D. Use an AWS Directory Sync Domain running on AWS Lambda."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "AD Connector is a directory gateway with which you can redirect directory requests to your on-premises Microsoft Active Directory without caching any information in the cloud. "
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You need to deploy an AWS stack in a repeatable manner across multiple environments. You have selected CloudFormation as the right tool to accomplish this, but have found that there is a resource type you need to create and model, but is unsupported by CloudFormation. How should you overcome this challenge?",
    answers: [
      "A. Use a CloudFormation Custom Resource Template by selecting an API call to proxy for create, update, and delete actions.  CloudFormation will use the AWS SDK, CLI, or API method of your choosing as the state transition function for the resource type you are modeling.",
      "B. Submit a ticket to the AWS Forums. AWS extends CloudFormation Resource Types by releasing tooling to the AWS Labs organization on GitHub. Their response time is usually 1 day, and they complete requests within a week or two.",
      "C. Instead of depending on CloudFormation, use Chef, Puppet, or Ansible to author Heat templates, which are declarative stack resource definitions that operate over the OpenStack hypervisor and cloud environment.",
      "D. Create a CloudFormation Custom Resource Type by implementing create, update, and delete functionality, either by subscribing a Custom Resource Provider to an SNS topic, or by implementing the logic in AWS Lambda."
    ],
    correctAnswer: "Answer – D",
    explanation:
      "Custom resources enable you to write custom provisioning logic in templates that AWS CloudFormation runs anytime you create, update (if you changed the custom resource), or delete stacks. For example, you might want to include resources that aren't available as AWS CloudFormation resource types. You can include those resources by using custom resources. That way you can still manage all your related resources in a single stack."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your API requires the ability to stay online during AWS regional failures. Your API does not store any state, it only aggregates data from other sources – you do not have a database. What is a simple but effective way to achieve this uptime goal?",
    answers: [
      "A. Use a CloudFront distribution to serve up your API. Even if the region your API is in goes down, the edge locations CloudFront uses will be fine.",
      "B. Use an ELB and a cross-zone ELB deployment to create redundancy across datacenters. Even if a region fails, the other AZ will stay online.",
      "C. Create a Route53 Weighted Round Robin record, and if one region goes down, have that region redirect to the other region.",
      "D. Create a Route53 Latency Based Routing Record with Failover and point it to two identical deployments of your stateless API in two different regions. Make sure both regions use Auto Scaling Groups behind ELBs."
    ],
    correctAnswer: "Answer – D",
    explanation:
      "Failover routing lets you route traffic to a resource when the resource is healthy or to a different resource when the first resource is unhealthy. The primary and secondary resource record sets can route traffic to anything from an Amazon S3 bucket that is configured as a website to a complex tree of records."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You meet once per month with your operations team to review the past month’s data. During the meeting, you realize that 3 weeks ago, your monitoring system which pings over HTTP from outside AWS recorded a large spike in latency on your 3-tier web service API. You use DynamoDB for the database layer, ELB, EBS, and EC2 for the business logic tier, and SQS, ELB, and EC2 for the presentation layer. Which of the following techniques will NOT help you figure out what happened?",
    answers: [
      "A. Check your CloudTrail logs for any API calls that caused slowness",
      "B. Review CloudWatch Metrics for one-minute interval graphs to determine which component(s) slowed the system down.",
      "C. Review your ELB access logs in S3 to see if any ELBs in your system saw the latency.",
      "D. Analyze your logs to detect bursts in traffic at that time."
    ],
    correctAnswer: "Answer – B",
    explanation: "The Cloudwatch metric retention is as follows."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are responsible to create an AWS Elastic Beanstalk environment as soon as possible by using CLI commands. It is a php application installed in linux server. Besides, in the new environment, a cron job should be set up in all instances. The file named as cron-linux is prepared for this and uploaded together with other application source code in a S3 bucket. Which kind of config file and CLI command are correct to create this Elastic Beanstalk environment? ",
    answers: ["A. ", "B. ", "C. ", "D. "],
    correctAnswer: "Correct Answer – B",
    explanation:
      "Configuration (.config) files for Elastic Beanstalk are put in the .ebextensions folder together with other application code. Please check https://docs.aws.amazon.com/elasticbeanstalk/latest/dg/environment-configuration-methods-during.html#configuration-options-during-console-ebextensions for the details of setting configurations during environment creation."
  },
  {
    topic: "Incident and Event Response",
    question:
      "Your serverless architecture using AWS API Gateway, AWS Lambda, and AWS DynamoDB experienced a large increase in traffic to a sustained 3000 requests per second, and dramatically increased in failure rates. Your requests, during normal operation, last 500 milliseconds on average. Your DynamoDB table did not exceed 50% of provisioned throughput, and Table primary keys are designed correctly. What is the most likely issue?",
    answers: [
      "A. Your API Gateway deployment is throttling your requests.",
      "B. Your AWS API Gateway Deployment is bottlenecking on request (de)serialization.",
      "C. You did not request a limit increase on concurrent Lambda function executions.",
      "D. You used Consistent Read requests on DynamoDB and are experiencing semaphore lock."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "Every Lambda function is allocated with a fixed amount of specific resources regardless of the memory allocation, and each function is allocated with a fixed amount of code storage per function and per account."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You need to grant permission to a vendor who has an AWS account to access your AWS account. They need to be able to read protected messages in a private S3 bucket at their leisure. They also use AWS. What is the best way to accomplish this?",
    answers: [
      "A. Create an IAM User with API Access Keys. Grant the User permissions to access the bucket. Give the vendor the AWS Access Key ID and AWS Secret Access Key for the User.",
      "B. Create an EC2 Instance Profile on your account. Grant the associated IAM role full access to the bucket. Start an EC2 instance with this Profile and give SSH access to the instance to the vendor.",
      "C. Create a cross-account IAM Role with permission to access the bucket, and grant permission to use the Role to the vendor AWS account.",
      "D. Generate a signed S3 PUT URL and a signed S3 PUT URL, both with wildcard values and 2 year durations. Pass the URLs to the vendor."
    ],
    correctAnswer: "Answer C",
    explanation:
      "You can use AWS Identity and Access Management (IAM) roles and AWS Security Token Service (STS) to set up cross-account access between AWS accounts. When you assume an IAM role in another AWS account to obtain cross-account access to services and resources in that account, AWS CloudTrail logs the cross-account activity"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You need your CI to build AMIs with code pre-installed on the images on every new code push. You need to do this as cheaply as possible. How do you do this?",
    answers: [
      "A. Create a spot instance request to include the maximum price that you are willing to pay per hour per instance just above the spot price as soon as new commits come in, perform all instance configuration and setup, then create an AMI based on the spot instance.",
      "B. Have the CI launch a new on-demand EC2 instance when new commits come in, perform all instance configuration and setup, then create an AMI based on the on-demand instance.",
      "C. Purchase a Light Utilization Reserved Instance to save money on the continuous integration machine.  Use these credits whenever your create AMIs on instances.",
      "D. When the CI instance receives commits, attach a new EBS volume to the CI machine. Perform all setup on this EBS volume so you don’t need a new EC2 instance to create the AMI."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "Amazon EC2 Spot instances allow you to make use of spare Amazon EC2 computing capacity. Since Spot instances are often available at a discount compared to On-Demand pricing, you can significantly reduce the cost of running your applications, grow your application’s compute capacity and throughput for the same budget, and enable new types of cloud computing applications."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have deployed an application to AWS which makes use of Autoscaling to launch new instances. You now want to change the instance type for the new instances. Using minimal resources, how can you achieve this deployment?",
    answers: [
      "A. Use Elastic Beanstalk to deploy the new application with the new instance type",
      "B. Use Cloudformation to deploy the new application with the new instance type",
      "C. Create a new launch configuration with the new instance type, attach it to the existing autoscaling group and deploy the application",
      "D. Create new EC2 instances with the new instance type and attach it to the Autoscaling Group"
    ],
    correctAnswer: "Answer - C",
    explanation:
      "The ideal way is to create a new launch configuration , attach it to the existing Auto Scaling group, and terminate the running instances."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "The operations team and the development team want a single place to view both operating system and application logs. How should you implement this using AWS services? Choose two from the options below",
    answers: [
      "A. Using AWS CloudFormation, create a CloudWatch Logs LogGroup and send the operating system and application logs of interest using the CloudWatch Logs Agent.",
      "B. Using AWS CloudFormation and configuration management, set up remote logging to send events via UDP packets to CloudTrail.",
      "C. Using configuration management, set up remote logging to send events to Amazon Kinesis and insert these into Amazon CloudSearch or Amazon Redshift, depending on available analytic tools.",
      "D. Using AWS CloudFormation, merge the application logs with the operating system logs, and use IAM Roles to allow both teams to have access to view console output from Amazon EC2."
    ],
    correctAnswer: "Answer – A and C",
    explanation:
      "\n\tOption B is invalid because Cloudtrail is not designed specifically to take in UDP packets\n\tOption D is invalid because there are already Cloudwatch logs available, so there is no need to have specific logs designed for this.\n"
  },
  {
    topic: "SDLC Automation",
    question:
      "You have a large number of web servers in an Auto Scaling group behind a load balancer. On an hourly basis, you want to filter and process the logs to collect data on unique visitors, and then put that data in a durable data store in order to run reports. Web servers in the Auto Scaling group are constantly launching and terminating based on your scaling policies, but you do not want to lose any of the log data from these servers during a stop/termination initiated by a user or by Auto Scaling. What two approaches will meet these requirements? Choose two answers from the options given below.",
    answers: [
      "A. Install an Amazon Cloudwatch Logs Agent on every web server during the bootstrap process.  Create a CloudWatch log group and define Metric Filters to create custom metrics that track unique visitors from the streaming web server logs. Create a scheduled task on an Amazon EC2 instance that runs every hour to generate a new report based on the Cloudwatch custom metrics.",
      "B. On the web servers, create a scheduled task that executes a script to rotate and transmit the logs to Amazon Glacier. Ensure that the operating system shutdown procedure triggers a logs transmission when the Amazon EC2 instance is stopped/terminated.  Use Amazon Data Pipeline to process the data in Amazon Glacier and run reports every hour.",
      "C. On the web servers, create a scheduled task that executes a script to rotate and transmit the logs to an Amazon S3 bucket. Ensure that the operating system shutdown procedure triggers a logs transmission when the Amazon EC2 instance is stopped/terminated.  Use AWS Data Pipeline to move log data from the Amazon S3 bucket to Amazon Redshift In order to process and run reports every hour.",
      "D. Install an AWS Data Pipeline Logs Agent on every web server during the bootstrap process.  Create a log group object in AWS Data Pipeline, and define Metric Filters to move processed log data directly from the web servers to Amazon Redshift and run reports every hour."
    ],
    correctAnswer: "Answer – A and C",
    explanation:
      "You can use the CloudWatch Logs agent installer on an existing EC2 instance to install and configure the CloudWatch Logs agent."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "Your development team is using access keys to develop an application that has access to S3 and DynamoDB. A new security policy has been outlined that the credentials that are nearing the 2 months expiry period should be rotated. How can you achieve this?",
    answers: [
      "A. Use the application to rotate the keys in every 2 months via the console",
      "B. Use a script which will query the date the keys are created. If the keys are nearing the 2 months expiry period , delete them and recreate new keys",
      "C. Delete the user associated with the keys after every 2 months. Then recreate the user again.",
      "D. Delete the IAM Role associated with the keys after every 2 months. Then recreate the IAM Role again."
    ],
    correctAnswer: "Answer - B",
    explanation:
      'One can use the CLI command list-access-keys to get the access keys. This command also returns the "CreateDate" of the keys. If the CreateDate is older than 2 months , then the keys can be deleted.'
  },
  {
    topic: "Incident and Event Response",
    question:
      "You have an application hosted in AWS , which sits on EC2 Instances behind an Elastic Load Balancer. You have added a new feature to your application and are now receving complaints from users that the site has a slow response. Which of the below actions can you carry out to help you pinpoint the issue",
    answers: [
      "A. Use Cloudtrail to log all the API calls , and then traverse the log files to locate the issue",
      "B. Use Cloudwatch , monitor the CPU utilization to see the times when the CPU peaked",
      "C. Review the Elastic Load Balancer logs",
      "D. Create some custom Cloudwatch metrics which are pertinent to the key features of your application"
    ],
    correctAnswer: "Answer - D",
    explanation:
      "Since the issue is occuring after the new feature has been added , it could be relevant to the new feature."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You currently have an application deployed via Elastic Beanstalk. You are now deploying a new application and have ensured that Elastic beanstalk has detached the current instances and deployed and reattached new instances. But the new instances are still not receiving any sort of traffic. Why is this the case.",
    answers: [
      "A. The instances are of the wrong AMI , hence they are not being detected by the ELB.",
      "B. It takes time for the ELB to register the instances , hence there is a small time frame before your instances can start receiving traffic",
      "C. You need to create a new Elastic Beanstalk application , because you cannot detach and then reattach instances to an ELB within an Elastic Beanstalk application",
      "D. The instances needed to be reattached before the new application version was deployed"
    ],
    correctAnswer: "Answer - B",
    explanation:
      "Before the EC2 Instances can start receiving traffic , they will be checked via the health checks of the ELB. Once the health checks are successful , the EC2 Instance will change its state to InService and then the EC2 Instances can start receiving traffic."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "A DevOps engineer has used Elastic Beanstalk to create a new Java environment with a Classic Load Balancer and an Auto Scaling Group. In the Load Balancer, a health check has been configured with a URL as the Health check path. It also has a Rolling Deployment policy. The development team just released a new feature which had several big changes to the application architecture. During the deployment of the new feature, it has failed and rolled back as the health check did not pass. However, the health check issue is known and as expected. Which approach can be taken so that the deployment can be successful regardless of health status?         ",
    answers: [
      "A. In Deployment configurations, enlarge the Health Check Command timeout such as from 600s to 1200s.",
      "B. Lower down the Healthy threshold from OK to Degraded so that the health check can pass.",
      "C. Modify the deployment policy from Rolling to All at once.",
      "D. In Deployment preferences, specify the Ignore health check option."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "Refer to https://docs.aws.amazon.com/elasticbeanstalk/latest/dg/using-features.rolling-version-deploy.html for the health check related settings in Elastic Beanstalk deployment policies."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You currently have EC2 Instances hosting an application. These instances are part of an Autoscaling Group created using Cloudformation. You now want to change the instance type of the EC2 Instances. How can you manage the deployment with the least amount of downtime",
    answers: [
      "A. Terminate the existing Auto Scaling group. Create a new launch configuration with the new Instance type. Attach that to the new Autoscaing Group.",
      "B. Use the AutoScalingRollingUpdate policy on CloudFormation Template Auto Scaling group",
      "C. Use the Rolling Update feature which is available for EC2 Instances.",
      "D. Manually terminate the instances, launch new instances with the new instance type and attach them to the Autoscaling group"
    ],
    correctAnswer: "Answer - B",
    explanation:
      "The AWS::AutoScaling::AutoScalingGroup resource supports an UpdatePolicy attribute. This is used to define how an Auto Scaling group resource is updated when an update to the CloudFormation stack occurs. A common approach to updating an Auto Scaling group is to perform a rolling update, which is done by specifying the AutoScalingRollingUpdate policy. This retains the same Auto Scaling group and replaces old instances with new ones, according to the parameters specified."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You are helping another team to create an AWS Aurora Database for a new application. The users of the application mainly come from Europe and North America. You propose to configure an Aurora Global Database. The primary database is located in eu-west-1 region and the secondary database is located in us-east-1 region. Which benefits can this Amazon Aurora Global Database bring? (Select Three)     ",
    answers: [
      "A. The cluster in the secondary region us-east-1 enables low-latency reads.",
      "B. Aurora Global Database is available for both Aurora MySQL and Aurora PostgreSQL.",
      "C. The clusters in both primary and secondary regions have the same read & write configured capacities.",
      "D. There is no charge for the replicated write I/Os between the primary region and each secondary region.",
      "E. For disaster recovery, the secondary cluster can be easily promoted to allow full read and write operations."
    ],
    correctAnswer: "Correct Answer – A, B and E",
    explanation:
      "Refer to https://docs.aws.amazon.com/AmazonRDS/latest/AuroraUserGuide/aurora-global-database.html for the descriptions of Aurora Global Database."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "In order to improve the security of applications deployed on your company’s AWS platform, you are configuring AWS Inspector to continually assess EC2 instances (both Linux and Windows) to see if there are security related vulnerabilities and then get the potential issues fixed in time.\n\nWhen you defined a new assessment target in AWS Inspector console, you chose all EC2 instances in that AWS account and region. You also selected the option to install AWS Inspector agents in all instances. What conditions must be met in order to generate the Inspector assessment report? (Select TWO.)",
    answers: [
      "A. The security group in the EC2 instances should allow SSH port 22.",
      "B. All EC2 instances need to have the AWS Systems Manager (SSM) Agent installed.",
      "C. All EC2 instances should have AWS CLI commands preinstalled.",
      "D. The EC2 instances need to configure an IAM role to have the AWS Inspector full access.",
      "E. The EC2 instances should have a role to allow SSM Run Command."
    ],
    correctAnswer: "Correct Answer – B, E",
    explanation:
      "Amazon Inspector is an AWS tool to perform security assessments of Amazon EC2 instances by using AWS managed rules packages. Refer to https://aws.amazon.com/inspector/ for its details."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "As a DevOps engineer, you are supporting development team to deploy a new e-commerce software. You have used AWS CodeDeploy to deploy the latest software release to several AWS EC2 instances. In this CodeDeploy application, there are two Deployment groups called Stage and Prod. At the moment, the only difference between Stage and Prod is the logging level which can be configured in a file. What is the most efficient way to implement the different logging levels for Deployment groups Stage and Prod?          ",
    answers: [
      "A. Create two source versions of script files in BeforeInstall hook. One version has its logging level setting for Deployment Group “Stage”. And the other one has its logging level setting for “Prod”. Choose the relevant source files when creating a new deployment.",
      "B. For the script file in BeforeInstall hook, use the environment variable DEPLOYMENT_GROUP_NAME to determine the Deployment Group. Then modify the logging level accordingly in the script.",
      "C. In the hook script file, use the environment variable DEPLOYMENT_ID to determine which Deployment Group it is. Then modify the logging level accordingly in the script.",
      "D. Create two script files. One version has its logging level setting for “Stage”. And the other one has its logging level setting for “Prod”. Modify the Deployment Group configurations to use the correct script file."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "Since the only difference between Deployment Groups is logging level, the same set of sources files should be used with an environment variable to determine the Deployment Group."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Your DevOps team has maintained a large number of EC2 instances in several AWS accounts. There are some instances that were launched for testing purposes and no longer used. To save cost, you need to work out an approach to quickly identify the EC2 instances that have a low utilization rate such as daily CPU utilization is 10% or less for several days. Which method is the best that you should choose?   ",
    answers: [
      "A. In CloudWatch Metrics, for each instance, create an alarm and trigger a SNS notification when CPU utilization is below 10%.",
      "B. Create a Lambda function that checks the CPU utilization for each instance and triggers a SNS notification if average CPU utilization rate is low.",
      "C. In CloudWatch Logs, configure a Filter to check the usage rate of EC2 instances. Create an alarm if the utilization is low.",
      "D. In Trusted Advisor, check the status of Low Utilization Amazon EC2 Instances which is part of Cost Optimization Checks."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "The question asks for the method that can quickly identify the EC2 instances with low utilization. Therefore it should be avoided to repeatedly perform configurations or checks for each instance."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You just joined a startup IT company as an AWS DevOps engineer. Your company recently started to use AWS platform and its AWS account has used a Basic support plan. As the company has limited budget for AWS resources, your manager asked you to design a solution to alert him when EC2 resources have a low utilization rate. You plan to use AWS CloudWatch Events to identify these resources with a lambda function as the target. The Lambda function is able to provide customized text and notify your manager. Which two options should you take in a combination to meet the need? (Select TWO.)      ",
    answers: [
      "A. Upgrade the AWS account support plan to “Developer” to access all Trusted Advisor features.",
      "B. Upgrade the AWS account support plan to “Business” to access all Trusted Advisor checks.",
      "C. Upgrade the AWS account support plan to “Enterprise” to activate all cost related features.",
      "D. Create a new CloudWatch Events rule with the event source as Trusted Advisor. The event type is “Check Item Refresh Status”. Select the Lambda function as the target.",
      "E. Create a CloudWatch rule with the event source as Trusted Advisor. The event type is “All Events”. Select the Lambda function as the target."
    ],
    correctAnswer: "Correct Answer – B, D",
    explanation:
      "In order to use the Cost Optimization Checks in Trusted Advisor, the support plan of Business and Enterprise should be used. Details refer to https://console.aws.amazon.com/support/plans/home#/."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have an Autoscaling Group configured to launch EC2 Instances for your application. But you notice that the Autoscaling Group is not launching instances in the right proportion. In fact instances are being launched too fast. What can you do to mitigate this issue? Choose 2 answers from the options given below",
    answers: [
      "A. Adjust the cooldown period set for the Autoscaling Group",
      "B. Set a custom metric which monitors a key application functionality for the scale-in and scale-out process.",
      "C. Adjust the CPU threshold set for the Autoscaling scale-in and scale-out process.",
      "D. Adjust the Memory threshold set for the Autoscaling scale-in and scale-out process.."
    ],
    correctAnswer: "Answer – A and B",
    explanation:
      "The Auto Scaling cooldown period is a configurable setting for your Auto Scaling group that helps to ensure that Auto Scaling doesn't launch or terminate additional instances before the previous scaling activity takes effect. "
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are responsible to maintain dozens of CloudFormation templates. Most of them use mappings to manage AMI IDs for different regions and instance types. However, the AMI IDs can change regularly, such as when there are software updates. In that case, you have to modify all related CloudFormation templates, which is very time consuming. You are considering an automated method to query and get the correct AMI IDs for CloudFormation. Which two approaches in together can help you achieve this? (Select TWO.)",
    answers: [
      "A. Create a Lambda function to get the latest AMIs for a given region and instance type.",
      "B. Prepare a shell script to fetch latest AMIs for any region and instance type by using AWS CLI command such as ec2 describe-images.",
      "C. In the CloudFormation template, create a custom resource type to invoke and send input values to a Lambda function in order to get the correct AMIs. After the custom resource gets a proper response, the stack proceeds with other resources.",
      "D. Use a CloudWatch Event rule to execute a Lambda function every day to get the latest AMI IDs.",
      "E. Add a new AMI ID parameter in the CloudFormation templates. When creating CloudFormation stacks, input the latest AMI ID parameter."
    ],
    correctAnswer: "Correct Answer – A, C",
    explanation:
      "The question asks for an automated method to fetch the AMI IDs for EC2 resources in CloudFormation template. The manual methods should be avoided. Refer to https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/walkthrough-custom-resources-lambda-lookup-amiids.html for the suggested approach."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      'A CloudFormation stack has included several AWS resources including EC2 instances. Previously, it used a mapping table to manage AMI IDs for different regions and instance types. During the creation of EC2 resource, the AMI ID is received from the function of "Fn::FindInMap". However, for this approach, you have to correct the mapping table whenever there is a new AMI ID available. You are looking at other better approaches to automatically fetch the updated AMIs. A Lambda function is already deployed by you which can get the latest AMI with the region and instance type as the input. What is the best way to use the Lambda function to achieve this requirement?  ',
    answers: [
      "A. Before updating/creating the CloudFormation stack, use a shell script to run the Lambda function to get the correct AMI ID. Use the ID as a parameter for the CloudFormation template.",
      "B. Create a Custom resource in the CloudFormation template. Associate the Lambda with the Custom resource by specifying the Amazon Resource Name (ARN) of the Lambda function for the ServiceToken property,",
      "C. Create a Custom resource in the CloudFormation template. In its RequestId property, specify the name of Lambda function to associate it with the Custom resource.",
      "D. Manually trigger the Lambda function and store its output in the Systems Manager Parameter Store. Modify the CloudFormation template to get the latest AMI ID from Parameter store."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "This case asks for the best approach to use the Lambda which means that it is possible several options may work in theory. However, we need to find out the option that needs less manual involvement and is more straightforward to implement."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "An IOT company has a hybrid environment to maintain. It owns several EC2 instances in AWS and also dozens of Raspberry Pi devices. The DevOps team starts using AWS Systems Manager to manage all servers such as apply a patching strategy in both on-premises and cloud environment. The Systems Manager agents are installed successfully in all the servers. What is the best way to tell which servers are Raspberry Pi devices in the Managed Instances console of Systems Manager?     ",
    answers: [
      "A. The machines that have the platform name as CentOS Linux are Raspberry Pi devices.",
      'B. The machines prefixed with "mi-" are Raspberry Pi devices.',
      "C. The Raspberry Pi instances have the IP addresses that are not within the VPC IP range.",
      'D. The machines prefixed with "i-" are Raspberry Pi devices.'
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "AWS Systems Manager is able to configure EC2 instances or on-premises servers in a hybrid environment. Various distributions of Linux, including Raspberry Pi devices, and Microsoft Windows Server are supported. Check https://docs.aws.amazon.com/systems-manager/latest/userguide/managed_instances.html for the supported instances of AWS Systems Manager."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "In your team, AWS Systems Manager is used to maintain EC2 instances. For example, you can run a command to execute a shell script for instances that have a tag of “QA”. However,  for security concerns, you want to limit the usage of “Run Command” feature for some IAM users. For these specific users, you need an IAM policy to only allow them to run command for instances that have the “department” tag of “dev1” or “dev2”. Which IAM policy can help you to achieve this requirement?",
    answers: [
      'A. {\n\n   "Version":"2012-10-17",\n\n   "Statement": [\n\n      {\n\n         "Effect":"Allow",\n\n         "Action":[\n\n            "*"\n\n         ],\n\n         "Resource":"*",\n\n         "Condition":{\n\n            "StringLike":{\n\n               "ssm:resourceTag/department":[\n\n                  "dev1"\n\n               ],\n\n               "ssm:resourceTag/department":[\n\n                  "dev2"\n\n               ]\n\n            }\n\n         }\n\n      }\n\n  ]\n\n}',
      'B. {\n\n   "Version":"2012-10-17",\n\n   "Statement": [\n\n      {\n\n         "Effect":"Allow",\n\n         "Action":[\n\n            "ssm:SendCommand"\n\n         ],\n\n         "Resource":"*",\n\n         "Condition":{\n\n            "StringNotEquals":{\n\n               "ssm:resourceTag/department":[\n\n                  "dev1", "dev2"\n\n               ]\n\n            }\n\n         }\n\n      }\n\n  ]\n\n}',
      'C. {\n\n   "Version":"2012-10-17",\n\n   "Statement": [\n\n      {\n\n         "Effect":"Allow",\n\n         "Action":[\n\n            "ssm:SendCommand"\n\n         ],\n\n         "Resource":"*",\n\n         "Condition":{\n\n            "StringLike":{\n\n               "ssm:resourceTag/department":[\n\n                  "dev1"\n\n               ],\n\n               "ssm:resourceTag/department":[\n\n                  "dev2"\n\n               ]\n\n            }\n\n         }\n\n      }\n\n  ]\n\n}',
      'D. {\n\n   "Version":"2012-10-17",\n\n   "Statement": [\n\n      {\n\n         "Effect":"Allow",\n\n         "Action":[\n\n            "ssm:RunCommand"\n\n         ],\n\n         "Resource":"*",\n\n         "Condition":{\n\n            "StringLike":{\n\n               "ssm:department":[\n\n                  "dev1"\n\n               ],\n\n               "ssm:department":[\n\n                  "dev2"\n\n               ]\n\n            }\n\n         }\n\n      }\n\n  ]\n\n}'
    ],
    correctAnswer: "Correct Answer – C",
    explanation:
      "If a restriction is required for Systems Manager Run Command, it is a best practice to put a suitable IAM policy for IAM users or groups. Details on how to use the IAM policy for “Run Command” can be found in the below links:"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You work in the security team and you need to ensure that all EC2 instances have installed certain latest security patches in time. However, the requirement is that the patches are installed in the dev and test environments for a week before they are installed in production instances. All EC2 instances can be differentiated via Tags. What is the best way to implement this using AWS Systems Manager?",
    answers: [
      "A. Create a customized Patch Baseline. Create several Patch Groups for dev, test, and production instances. Associate the Patch Groups with the new Patch Baseline. Schedule the patching in a maintenance window as required.",
      "B. Tag the instances using dev, test, and production. In Systems Manager, run the command of “AWS-InstallPatches” based on the tags.",
      "C. Use a predefined default Patch Baseline. Add tags of dev, test, and production to relevant EC2 instances. Associate the Patch Baseline with EC2 instances via tags.",
      "D. Use Systems Manager session manager to configure the patching for EC2 instances. Apply required patches accordingly after you remote login into the server."
    ],
    correctAnswer: "Correct Answer – A",
    explanation:
      "The best tool to consider for applying patches should be the Patch Manager in Systems Manager. Since in this case, certain patches need to be selected, it is highly possible that a customized patch baseline should be created firstly."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "In your company, AWS ECS is being used to manage Docker containers for a Java application. Recently, to meet the market need, several important features are under development and the team is under pressure to deliver new releases. Your manager asks you to notify the team in both Slack channel and email whenever the ECS task enters into the Stopped status so that relevant personnel can take actions immediately. What is the best way for you to implement that?",
    answers: [
      'A. Create the following CloudWatch Event rule. Add a target of Lambda function to send a notification in Slack and another target of SNS to send the email.\n\n{\n\n"source": [\n\n  "aws.ecs"\n\n],\n\n"detail-type": [\n\n  "ECS Task State Change"\n\n],\n\n"detail": {\n\n  "lastStatus": [\n\n    "STOPPED"\n\n  ]\n\n}\n\n}',
      "B. Configure the ECS cluster instances to send log files in /var/log/ecs/ to CloudWatch Logs. Create a Metric Filter to search for the keyword “STOPPED”. Create an alarm to trigger a Lambda function whenever there is a match. The Lambda function sends customised notifications to Slack channel and email list.",
      'C. Create a CloudWatch Event rule as below. Use a Lambda function as the target to send notifications to both Slack channel and the relevant email list.\n\n{\n\n"source": [\n\n  "aws.ecs"\n\n],\n\n"detail-type": [\n\n  "ECS Container Instance State Change"\n\n],\n\n"detail": {\n\n  "desireStatus": [\n\n    "STOPPED"\n\n  ]\n\n}\n\n}',
      "D. Configure the ECS cluster instances to send log files in /var/log/docker/ to CloudWatch Logs. Create a Metric Filter to search for the keyword “STOPPED”. Create an alarm to trigger a SNS notification whenever there is a match. Subscribe a Lambda function to the SNS topic which sends a message to Slack channel and email list."
    ],
    correctAnswer: "Correct Answer – A",
    explanation:
      "CloudWatch Events can integrate with Amazon ECS to provide immediate notifications. When there is a state change in ECS, CloudWatch Events is able to capture the event and notify the target. Refer to"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "AWS ECS has been used by a large IT company to deploy dozens of Microservices. There is a new requirement from management to report any state change of either ECS container instances or ECS tasks to a group channel in Microsoft Teams. The notification messages should be nicely readable and sent immediately whenever a status change happens. Which two methods in combinations can achieve this requirement? (Select TWO)",
    answers: [
      "A. Create a T2 micro instance to handle with the customized notifications. Integrate with Microsoft Teams via its Incoming Webhook.",
      "B. Add a new CloudWatch Events rule for status change of ECS. Add a Lambda function as the target when an event is triggered.",
      "C. Configure a new SNS topic called ECS_Status_Change. Register the SNS as the trigger of a Lambda function to generate custom readable alarms.",
      "D. Set up alarms in ECS CloudWatch Metrics. When the state of either ECS instance or ECS task changes, generate an alarm to trigger a SNS topic.",
      "E. Create a Lambda function to analyze the state change event in ECS and then provide an appropriate message to the channel in Microsoft Teams."
    ],
    correctAnswer: "Correct Answer – B, E",
    explanation:
      "In this case, notifications are expected to be sent immediately when there is a state change for ECS. CloudWatch Events should be considered as it integrates with ECS and can trigger a CloudWatch Event for its target. About the examples of Amazon ECS CloudWatch events, please refer to"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "A Cloud Hybrid environment is run by a large financial company. Previously, separate deployment tools are used for on-premise and AWS servers. There is an increasing need to manage the deployment with the same tool for all the instances. You proposed to use AWS CodeDeploy for this requirement. Given that all existing on-premise machines are running on Ubuntu 16.04 LTS operating system, which prerequisites must be met in order to configure on-premises instances with AWS CodeDeploy? (Select TWO.)      ",
    answers: [
      "A. The local account used to configure the on-premises instance must be able to run either as sudo or root.",
      "B. The on-premises instance must open port 80 for the outbound traffic to connect to public AWS service endpoints.",
      "C. Java 8 should be installed in order for CodeDeploy agent to work properly.",
      "D. The IAM identity to register the on-premises instance in CodeDeploy service must be granted proper permissions.",
      "E. A VPN connection or a Direct Connect should be established between on-premise environment and AWS VPC."
    ],
    correctAnswer: "Correct Answer – A, D",
    explanation:
      "AWS CodeDeploy can help to manage the deployment of both on-premise and EC2 instances. However there are some prerequisites that need to be met for on-premise servers. Refer to"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "A bank has adopted strict IT process to deploy new software releases. It uses AWS CodeDeploy to manage several deployment stages including Feature Test, System Integration Test and Production. Each stage has a Deployment Group in CodeDeploy. And relevant targets are added into each group using Tags. For example, the servers that contain the Production tag will only be deployed when a new deployment is created for the Production Deployment Group. Which targets can NOT be added to Deployment Group by Tags or list in AWS CodeDeploy? (Select TWO.)",
    answers: [
      "A. ECS Cluster with an Amazon Linux AMI",
      "B. EC2 Auto Scaling groups",
      "C. Lambda function written in Python 3.6",
      "D. EC2 instance (Microsoft Windows Server 2016)",
      "E. Red Hat Enterprise Linux (RHEL) on-premises instances"
    ],
    correctAnswer: "Correct Answer – A. C",
    explanation:
      "While creating Deployment Group In CodeDeploy, user can select the targets via Tags and lists. The targets can be any combination of Amazon EC2 Auto Scaling groups, Amazon EC2 instances or On-premises instances:"
  },
  {
    topic: "SDLC Automation",
    question:
      "Which of the following is the right sequence of initial steps in the deployment of application revisions using Code Deploy\n\n1) Specify deployment configuration\n\n2) Upload revision\n\n3) Create application\n\n4) Specify deployment group",
    answers: [
      "A. 3,2,1 and 4",
      "B. 3,1,2 and 4",
      "C. 3,4,1 and 2",
      "D. 3,4,2 and 1"
    ],
    correctAnswer: "Answer - C",
    explanation:
      "The below diagram from the AWS documentation shows the deployment steps"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "Your DevOps team manages dozens of AWS accounts. Some of them belong to certain AWS Organizations and some do not. There is a new requirement to set up a CloudWatch rule in the master account whenever there is a new event of EC2 termination for any of the other accounts. You plan to setup a CloudWatch Events rule in each account and get the event sent to the default Event bus in the master account. Which condition is NOT mandatory for this solution to work?",
    answers: [
      "A. Master account should permit the CloudWatch events to be received from other accounts.",
      "B. All accounts should belong to certain AWS Organization.",
      "C. A proper IAM role is needed for the sender account to send events to the Event bus in the master account.",
      "D. In CloudWatch Events, in order to send the event to the master account, the target should be configured as “Event bus in another AWS account”."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "CloudWatch Event bus is a service to help send/receive CloudWatch events to/from other AWS accounts:"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have an Opswork stack setup in AWS. You want to install some updates to the Linux instances in the stack. Which of the following can be used to publish those updates. Choose 2 answers from the options given below",
    answers: [
      "A. Create and start new instances programmatically to replace your current online instances. Then delete the current instances.",
      "B. Use Auto-scaling to launch new instances and then delete the older instances",
      "C. On Linux-based instances in Chef 11.10 or older stacks, run the Update Dependencies stack command",
      "D. Delete the stack and create a new stack with the instances and their relavant updates"
    ],
    correctAnswer: "Answer - A and C",
    explanation:
      "As per AWS documentation,\n\nBy default, AWS OpsWorks Stacks automatically installs the latest updates during setup, after an instance finishes booting. AWS OpsWorks Stacks does not automatically install updates after an instance is online, to avoid interruptions such as restarting application servers. Instead, you manage updates to your online instances yourself, so you can minimize any disruptions.\n\nWe recommend that you use one of the following to update your online instances."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "Your company has a DevOps team to manage AWS accounts used by all the other teams within the company. In the master AWS account, several CloudWatch Events Rules are established where the CloudWatch Events happening in other AWS accounts can be sent to. In order to do this, permissions have been configured in the default CloudWatch Event Bus of the master account in region ap-southeast-2. Which entities can be added so that they are permitted to send events to CloudWatch Event Bus in the master account? (Select TWO.) ",
    answers: [
      "A. Certain IAM users in the same AWS Organization.",
      "B. Another AWS Organization.",
      "C. Everybody (all AWS accounts).",
      "D. An AWS account which creates a CloudWatch Events rule in region ap-south-1 and sends events to the master account.",
      "E. IAM groups."
    ],
    correctAnswer: "Correct Answer – B, C",
    explanation: "Please refer to"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You are a DevOps engineer for a company. You have been requested to create a rolling deployment solution that is cost-effective with minimal downtime. How should you achieve this? Choose two answers from the options below",
    answers: [
      "A. Re-deploy your application using a CloudFormation template",
      "B. Re-deploy with a CloudFormation template, define update policies on Auto Scaling groups in your CloudFormation template",
      "C. Use UpdatePolicy attribute with AutoScalingRollingUpdate policy on CloudFormation to deploy new code",
      "D. After each stack is deployed, tear down the old stack"
    ],
    correctAnswer: "Answer - B and C",
    explanation:
      "The AWS::AutoScaling::AutoScalingGroup resource supports an UpdatePolicy attribute. This is used to define how an Auto Scaling group resource is updated when an update to the CloudFormation stack occurs. A common approach to updating an Auto Scaling group is to perform a rolling update, which is done by specifying the AutoScalingRollingUpdate policy. This retains the same Auto Scaling group and replaces old instances with new ones, according to the parameters specified."
  },
  {
    topic: "SDLC Automation",
    question:
      "A software development team has 5 developers and you are helping them to configure AWS CodeCommit repositories. They prefer using their existing SSH keys to access CodeCommit to pull or push code however two developers told you that they were NOT able to successfully operate on CodeCommit repositories from their own Linux machines. How would you troubleshoot on this issue? (Select TWO.)",
    answers: [
      "A. Check if the IAM user has activated MFA.",
      "B. In the IAM Security Credential tab, check if the user’s private SSH key has been uploaded.",
      "C. Check if the IAM user has a proper policy to access CodeCommit resource.",
      "D. If GIT is used, check if the IAM users are able to access AWS CodeCommit using their AWS credentials",
      "E. Check whether the public SSH key has been uploaded to IAM Security Credential tab."
    ],
    correctAnswer: "Correct Answer – C, E",
    explanation:
      "There are several methods to access CodeCommit: HTTPS, SSH and AWS access keys. Refer to"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You have been requested to use CloudFormation to maintain version control and achieve automation for the applications in your organization. How can you best use CloudFormation to keep everything agile and maintain multiple environments while keeping cost down?",
    answers: [
      "A. Create separate templates based on functionality, create nested stacks with CloudFormation.",
      "B. Use CloudFormation custom resources to handle dependencies between stacks",
      "C. Create multiple templates in one CloudFormation stack.",
      "D. Combine all resources into one template for version control and automation."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "As your infrastructure grows, common patterns can emerge in which you declare the same components in each of your templates. You can separate out these common components and create dedicated templates for them. That way, you can mix and match different templates but use nested stacks to create a single, unified stack. Nested stacks are stacks that create other stacks. To create nested stacks, use the AWS::CloudFormation::Stackresource in your template to reference other templates."
  },
  {
    topic: "SDLC Automation",
    question:
      "A new employee joined a company where AWS CodeCommit is used to manage code repositories in several AWS regions. In order to connect with CodeCommit by SSH, he installed the latest version of GIT in a Linux machine, created a key pair via ssh-keygen and uploaded the public key to the security credentials of his IAM user. However he still cannot git clone any repositories using SSH. Which config file in ~/.ssh/ does he need to create in his Linux machine?",
    answers: [
      "A. Host *\n\n  User IAMUSREIDEXAMPLE\n\n  IdentityFile ~/.ssh/id_rsa",
      "B. Host git-codecommit.ap-south-1.amazonaws.com\n\n  User APKAXXXXXXXXXEXAMPLE\n\n  IdentityFile ~/.ssh/id_rsa",
      "C. Host git-codecommit.*.amazonaws.com\n\n  User APKAXXXXXXXXXEXAMPLE\n\n  IdentityFile ~/.ssh/id_rsa",
      "D. Host git-codecommit.*.amazonaws.com\n\n  User IAMUSREIDEXAMPLE\n\n  IdentityFile ~/.ssh/id_rsa.pub"
    ],
    correctAnswer: "Correct Answer – C",
    explanation:
      "GIT SSH access is a commonly used method to connect with AWS CodeCommit from a remote server. Check"
  },
  {
    topic: "SDLC Automation",
    question:
      "You work for a very large company that has multiple applications which are very different and built on different programming languages. How can you deploy applications as quickly as possible?",
    answers: [
      "A. Develop all apps in one Docker container and deploy using ElasticBeanstalk",
      "B. Create a Lambda function deployment package consisting of code and any dependencies",
      "C. Develop each app in a separate Docker container and deploy using Elastic Beanstalk",
      "D. Develop each app in a separate Docker containers and deploy using CloudFormation"
    ],
    correctAnswer: "Answer – C",
    explanation:
      "Elastic Beanstalk supports the deployment of web applications from Docker containers. With Docker containers, you can define your own runtime environment. You can choose your own platform, programming language, and any application dependencies (such as package managers or tools), that aren't supported by other platforms. Docker containers are self-contained and include all the configuration information and software your web application requires to run."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You work in a startup company and your team is developing a new Android App. Whenever a user has uploaded a photo in the App, a notification will be sent to his friends immediately. An AWS DynamoDB table has been created to save the photo information per user and the DynamoDB Stream is enabled to capture the item modifications. Which service can integrate with DynamoDB Stream, process its events and send notifications?",
    answers: [
      "A. A Lambda function.",
      "B. A SNS notification.",
      "C. A CloudWatch Events rule.",
      "D. A S3 bucket."
    ],
    correctAnswer: "Correct Answer – A",
    explanation: "The Stream feature can be enabled in DynamoDB as below:"
  }
];
