export const testFive = [
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "What is the amount of time that Opswork stacks services waits for a response from an underlying instance before deeming it as a failed instance?",
    answers: [
      "A. 1 minute.",
      "B. 5 minutes.",
      "C. 20 minutes.",
      "D. 60 minutes"
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are a Devops Engineer for your company. You have been instructed to create a continuous integrated and continuous delivery model for the application in your organization. Which of the below services could be used for this purpose. Choose 2 answers from the options given below",
    answers: [
      "A. AWS CodeDeploy",
      "B. AWS CodePipeline",
      "C. AWS SQS",
      "D. AWS IAM"
    ],
    correctAnswer: "Answer – A and B",
    explanation: "The AWS Documentation mentions the below"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have just been assigned to take care of the Automated resources which have been setup by your company in AWS. You are looking at integrating some of the company’s chef recipes to be used for the existing Opswork stacks already setup in AWS. But when you go to the recipes section, you cannot see the option to add any recipes. What could be the reason for this?",
    answers: [
      "A. Once you create a stack, you cannot assign custom recipe’s, this needs to be done when the stack is created.",
      "B. Once you create layers in the stack, you cannot assign custom recipe’s , this needs to be done when the layers are created.",
      "C. The stack layers were created without the custom cookbooks option. Just change the layer settings accordingly.",
      "D. The stacks were created without the custom cookbooks option. Just change the stack settings accordingly."
    ],
    correctAnswer: "Answer - D",
    explanation: "The AWS Documentation mentions the below"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are a Devops Engineer and are designing an Opswork stack in AWS. The company has some custom recipes that are part of their on-premise Chef configuration. These same recipes need to be run whenever an instance is launched in Opsworks. Which of the following steps need to be carried out to to ensure this requirement gets fulfilled. Choose 2 answers from the options given below",
    answers: [
      "A. Ensure the custom cookbooks option is set in Opswork stack.",
      "B. Ensure the custom cookbooks option is set in Opswork layer.",
      "C. Ensure the recipe is placed as part of the Setup Lifecycle event as part of the Layer setting.",
      "D. Ensure the recipe is placed as part of the Setup Lifecycle event as part of the Stack setting."
    ],
    correctAnswer: "Answer – A and C",
    explanation: "The AWS Documentation mentions the below"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Which of the following file needs to be included along with your source code binaries when your application uses the EC2/On-Premises compute platform, and deploy it using the AWS Code Deploy service.",
    answers: [
      "A. appspec.yml",
      "B. appconfig.yml",
      "C. appspec.json",
      "D. appconfig.json"
    ],
    correctAnswer: "Answer – A",
    explanation: "The AWS Documentation mentions the below"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "When using EC2 instances with the Code Deploy service, which of the following are some of the pre-requisites to ensure that the EC2 instances can work with Code Deploy. Choose 2 answers from the options given below",
    answers: [
      "A. Ensure an IAM role is attached to the instance so that it can work with the Code Deploy Service.",
      "B. Ensure the EC2 Instance is configured with Enhanced Networking",
      "C. Ensure the EC2 Instance is placed in the default VPC",
      "D. Ensure that the CodeDeploy agent is installed on the EC2 Instance"
    ],
    correctAnswer: "Answer – A and D",
    explanation: "This is mentioned in the AWS documentation"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You are a Devops Engineer for your company. The company has a number of Cloudformation templates in AWS. There is a concern from the IT Security department and they want to know who all use the Cloudformation stacks in the company’s AWS account. Which of the following can be done to take care of this security concern?",
    answers: [
      "A. Enable Cloudwatch events for each cloudformation stack to track the resource creation events.",
      "B. Enable Cloudtrail logs so that the API calls can be recorded",
      "C. Enable Cloudwatch logs for each cloudformation stack to track the resource creation events.",
      "D. Connect SQS and Cloudformation so that a message is published for each resource created in the Cloudformation stack."
    ],
    correctAnswer: "Answer - B",
    explanation: "This is given as a best practice in the AWS documentation"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Your company has a number of Cloudformation stacks defined in AWS. As part of the routine housekeeping activity, a number of stacks have been targeted for deletion. But a few of the stacks are not getting deleted and are failing when you are trying to delete them. Which of the following could be valid reasons for this? Choose 2 answers from the options given below",
    answers: [
      "A. The stacks were created with the wrong template version. Since the standard template version is now higher , it is preventing the deletion of the stacks. You need to contact AWS support.",
      "B. The stack has an S3 bucket defined which has objects present in it.",
      "C. The stack has a EC2 Security Group which has EC2 Instances attached to it.",
      "D. The stack consists of an EC2 resource which was created with a custom AMI."
    ],
    correctAnswer: "Answer – B and C",
    explanation: "The AWS documentation mentions the below point"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "Which of the following is a reliable and durable logging solution to track changes made to your AWS resources?",
    answers: [
      "A. Create a new CloudTrail trail with one new S3 bucket to store the logs and with the global services option selected. Use IAM roles S3 bucket policies and Multi Factor Authentication (MFA) Delete on the S3 bucket that stores your logs.",
      "B. Create a new CloudTrail with one new S3 bucket to store the logs. Configure SNS to send log file delivery notifications to your management system. Use IAM roles and S3 bucket policies on the S3 bucket that stores your logs.",
      "C. Create a new CloudTrail trail with an existing S3 bucket to store the logs and with the global services option selected. Use S3 ACLs and Multi Factor Authentication (MFA) Delete on the S3 bucket that stores your logs.",
      "D. Create three new CloudTrail trails with three new S3 buckets to store the logs one for the AWS Management console, one for AWS SDKs and one for command line tools. Use IAM roles and S3 bucket policies on the S3 buckets that store your logs."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "AWS Identity and Access Management (IAM) is integrated with AWS CloudTrail, a service that logs AWS events made by or on behalf of your AWS account. CloudTrail logs authenticated AWS API calls and also AWS sign-in events, and collects this event information in files that are delivered to Amazon S3 buckets. You need to ensure that all services are included. Hence option B is partially correct."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have decided you need to change the instance type of your instances in production which are running as part of an Autoscaling Group. You currently have 4 instances in production. You cannot have any interruption in service and need to ensure 2 instances are always running during the update. Which of the below options can be chosen for this?",
    answers: [
      "A. AutoScalingRollingUpdate",
      "B. AutoScalingScheduledAction",
      "C. AutoScalingReplacingUpdate",
      "D. AutoScalingIntegrationUpdate"
    ],
    correctAnswer: "Answer – A",
    explanation:
      "The AWS::AutoScaling::AutoScalingGroup resource supports an UpdatePolicy attribute. This is used to define how an Auto Scaling group resource is updated when an update to the CloudFormation stack occurs. A common approach to updating an Auto Scaling group is to perform a rolling update, which is done by specifying the AutoScalingRollingUpdate policy. This retains the same Auto Scaling group and replaces old instances with new ones, according to the parameters specified."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have a development team that is planning for continuous release cycles for their application. They want to use the AWS services available to be able to deploy a web application and also ensure they can rollback to previous versions fairly quickly. Which of the following options can be used to achieve this requirement. Choose 2 answers from the options given below",
    answers: [
      "A. Use the Elastic beanstalk service. Use Application versions and upload the revisions of your application. Deploy the revisions accordingly and rollback to prior versions accordingly.",
      "B. Use the Elastic beanstalk service. Create separate environments for each application revision. Revert back to an environment incase the new environment does not work.",
      "C. Use the Opswork service to deploy the web instances. Deploy the app to the Opswork web layer. Rollback using the Deploy app in Opswork.",
      "D. Use the Cloudformation service. Create separate templates for each application revision and deploy them accordingly."
    ],
    correctAnswer: "Answer – A and C",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Which of the following are ways to secure data at rest and in transit in AWS. Choose 3 answers from the options given below",
    answers: [
      "A. Encrypt all EBS volumes attached to EC2 Instances",
      "B. Use server side encryption for S3",
      "C. Use SSL/HTTPS when using the Elastic Load Balancer",
      "D. Use IOPS volumes when working with EBS volumes on EC2 Instances"
    ],
    correctAnswer: "Answer – A,B and C",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Which of the following commands for the elastic beanstalk CLI can be used to create the current application into the specified environment?",
    answers: ["A. eb create", "B. eb start", "C. en env", "D. en app"],
    correctAnswer: "Answer – A",
    explanation: "Differences from Version 3 of EB CLI"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are a Devops engineer for your company. You have been instructed to deploy docker containers using the Opswork service. How could you achieve this? Choose 2 answers from the options given below",
    answers: [
      "A. Use custom cookbooks for your Opswork stack and provide the Git repository which has the chef recipes for the Docker containers.",
      "B. Use Elastic beanstalk to deploy docker containers since this is not possible in Opswork. Then attach the elastic beanstalk environment as a layer in Opswork.",
      "C. Use Cloudformation to deploy docker containers since this is not possible in Opswork. Then attach the Cloudformation resources as a layer in Opswork.",
      "D. In the App for Opswork deployment, specify the git url for the recipes which will deploy the applications in the docker environment."
    ],
    correctAnswer: "Answer – A and D",
    explanation: "This is mentioned in the AWS documentation"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are building out a layer in a software stack on AWS that needs to be able to scale out to react to increased demand as fast as possible. You are running the code on EC2 instances in an Auto Scaling Group behind an ELB. Which application code deployment method should you use?",
    answers: [
      "A. SSH into new instances that come online, and deploy new code onto the system by pulling it from an S3 bucket, which is populated  by code that you refresh from source control on new pushes.",
      "B. Bake an AMI when deploying new versions of code, and use that AMI for the Auto Scaling Launch Configuration.",
      "C. Create a Dockerfile when preparing to deploy a new version to production and publish it to S3. Use UserData in the Auto Scaling Launch configuration to pull down the Dockerfile from S3 and run it when new instances launch.",
      "D. Create a new Auto Scaling Launch Configuration with UserData scripts configured to pull the latest code at all times."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "Since the time required to spin up an instance is required to be fast , its better to create an AMI rather than use User Data. When you use User Data , the script will be run during boot up , and hence this will be slower."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Your development team use .Net to code their web application. They want to deploy it to AWS for the purpose of continuous integration and deployment. The application code is hosted in a Git repository. Which of the following combination of steps can be used to fulfil this requirement. Choose 2 answers from the options given below",
    answers: [
      "A. Use the Elastic beanstalk service to provision an IIS platform web environment to host the application.",
      "B. Use the Code Pipeline service to provision an IIS environment to host the application.",
      "C. Create a source bundle for the .Net code and upload it as an application revision.",
      "D. Use a chef recipe to deploy the code and attach it to the Elastic beanstalk environment."
    ],
    correctAnswer: "Answer – A and C",
    explanation:
      "When you provision an environment using the Elastic beanstalk service, you can choose the IIS platform to host the .Net based application as shown below."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Your company is planning on using the available services in AWS to completely automate their integration, build and deployment process. They are planning on using AWS CodeBuild to build their artifacts. When using CodeBuild, which of the following files specifies a collection of build commands that can be used by the service during the build process.",
    answers: [
      "A. appspec.yml",
      "B. buildspec.yml",
      "C. buildspec.xml",
      "D. appspec.json"
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Which of the following tools from AWS allows the automatic collection of software inventory from EC2 instances and helps apply OS patches.",
    answers: [
      "A. AWS Code Deploy",
      "B. Amazon EC2 Systems Manager",
      "C. EC2 AMI's",
      "D. AWS Code Pipeline"
    ],
    correctAnswer: "Answer - B",
    explanation:
      "The Amazon EC2 Systems Manager helps you automatically collect software inventory, apply OS patches, create system images, and configure Windows and Linux operating systems. These capabilities enable automated configuration and ongoing management of systems at scale and help maintain software compliance for instances running in Amazon EC2 or on-premises."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Which of the following can be used in Cloudformation to coordinate the creation of stack resources. Choose 2 answers from the options given below",
    answers: [
      "A. AWS::CloudFormation::HoldCondition",
      "B. AWS::CloudFormation::WaitCondition",
      "C. HoldPolicy attribute",
      "D. CreationPolicy attribute"
    ],
    correctAnswer: "Answer – B and D",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Which of the following resource is used in Cloudformation to create nested stacks",
    answers: [
      "A. AWS::CloudFormation::Stack",
      "B. AWS::CloudFormation::Nested",
      "C. AWS::CloudFormation::NestedStack",
      "D. AWS::CloudFormation::StackNest"
    ],
    correctAnswer: "Answer - A",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You have a set of web servers hosted in AWS which host a web application used by a section of users. You want to monitor the number of errors which occur when using the web application. Which of the below options can be used for this purpose. Choose 3 answers from the options given below.",
    answers: [
      "A. Send the logs from the instances onto Cloudwatch logs.",
      "B. Search for the keyword “ERROR” in the log files on the server.",
      "C. Search for the keyword “ERROR” in Cloudwatch logs.",
      "D. Increment a metric filter in Cloudwatch whenever the pattern is matched."
    ],
    correctAnswer: "Answer – A,C and D",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "SDLC Automation",
    question:
      "Your company is supporting a number of applications that need to be moved to AWS. Initially they thought of moving these applications to the Elastic beanstalk service. When going to the Elastic beanstalk service , you can see that the underlying platform service is not an option in the Elastic beanstalk service. Which of the following options can be used to port your application onto Elastic beanstalk",
    answers: [
      "A. Use the Opswork service to create a stack. In the stack , create a separate custom layer. Deploy the application to this layer and then attach the layer to Elastic beanstalk",
      "B. Use custom chef recipe’s to deploy your application in Elastic beanstalk.",
      "C. Use custom Cloudformation templates to deploy the application into Elastic beanstalk",
      "D. Create a docker container for the custom application and then deploy it to Elastic beanstalk."
    ],
    correctAnswer: "Answer - D",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Which of the following are ways to ensure that data is secured while in transit when using the AWS Elastic load balancer? Choose 2 answers from the options given below",
    answers: [
      "A. Use a TCP front end listener for your ELB",
      "B. Use an SSL front end listener for your ELB",
      "C. Use an HTTP front end listener for your ELB",
      "D. Use an HTTPS front end listener for your ELB"
    ],
    correctAnswer: "Answer – B and D",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Which of the following services from AWS can be integrated with the Jenkins continuous integration tool.",
    answers: [
      "A. Amazon EC2",
      "B. Amazon ECS",
      "C. Amazon Elastic beanstalk",
      "D. All of the above"
    ],
    correctAnswer: "Answer - D",
    explanation: "The following AWS services can be integrated with Jenkins"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Your company has an application hosted on an Elastic beanstalk environment. You have been instructed that whenever application changes occur and new versions need to be deployed that the fastest deployment approach is employed. Which of the following deployment mechanisms will fulfil this requirement?",
    answers: [
      "A. All at once",
      "B. Rolling",
      "C. Immutable",
      "D. Rolling with batch"
    ],
    correctAnswer: "Answer - A",
    explanation:
      "The following table from the AWS documentation shows the deployment time for each deployment methods."
  },
  {
    topic: "SDLC Automation",
    question:
      "You have a web application hosted on EC2 instances. There are application changes which happen to the web application on a quarterly basis. Which of the following are example of Blue Green deployments which can be applied to the application? Choose 2 answers from the options given below",
    answers: [
      "A. Deploy the application to an elastic beanstalk environment. Have a secondary elastic beanstalk environment in place with the updated application code. Use the swap URL’s feature to switch onto the new environment.",
      "B. Place the EC2 instances behind an ELB. Have a secondary environment with EC2 Instances and ELB in another region. Use Route53 with geo-location to route requests and switch over to the secondary environment.",
      "C. Deploy the application using Opswork stacks. Have a secondary stack for the new application deployment. Use Route53 to switch over to the new stack for the new application update.",
      "D. Deploy the application to an elastic beanstalk environment. Use the Rolling updates feature to perform a Blue Green deployment."
    ],
    correctAnswer: "Answer – A and C",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "SDLC Automation",
    question:
      "Which of the following services can be used to detect the application health in a Blue Green deployment in AWS.",
    answers: [
      "A. AWS Code Commit",
      "B. AWS Code Pipeline",
      "C. AWS CloudTrail",
      "D. AWS Cloudwatch"
    ],
    correctAnswer: "Answer - D",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "Your company has an application hosted in AWS which makes use of DynamoDB. There is a requirement from the IT security department to ensure that all source IP addresses which make calls to the DynamoDB tables are recorded. Which of the following services can be used to ensure this requirement is fulfilled.",
    answers: [
      "A. AWS Code Commit",
      "B. AWS Code Pipeline",
      "C. AWS CloudTrail",
      "D. AWS Cloudwatch"
    ],
    correctAnswer: "Answer - C",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are in charge of designing a Cloudformation template which deploys a LAMP stack. After deploying a stack , you see that the status of the stack is showing as CREATE_COMPLETE , but the apache server is still not up and running and is experiencing issues while starting up. You want to ensure that the stack creation only shows the status of CREATE_COMPLETE after all resources defined in the stack are up and running. How can you achieve this? Choose 2 answers from the options given below.",
    answers: [
      "A. Define a stack policy which defines that all underlying resources should be up and running before showing a status of CREATE_COMPLETE.",
      "B. Use lifecycle hooks to mark the completion of the creation and configuration of the underlying resource.",
      "C. Associate the CreationPolicy with an EC2 instance. This association tells the CloudFormation, as to what resource to wait on",
      "D. Use the CFN helper scripts to signal once the resource configuration is complete."
    ],
    correctAnswer: "Answer – C and D",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "SDLC Automation",
    question:
      "You have a web application composed of an Auto Scaling group of web servers behind a load balancer, and create a new AMI for each application version for deployment. You have a new version to release, and you want to use the Blue-Green deployment technique to migrate users over in a controlled manner while the size of the fleet remains constant over a period of 6 hours, to ensure that the new version is performing well. What option should you choose to enable this technique while being able to roll back easily? Choose 2 answers from the options given below. Each answer presents part of the solution",
    answers: [
      "A. Create an Auto Scaling launch configuration with the new AMI to use the new launch configuration and to register instances with the new load balancer",
      "B. Create an Auto Scaling launch configuration with the new AMI to use the new launch configuration and to register instances with the existing load balancer",
      "C. Use Amazon Route53 weighted Round Robin to vary the proportion of requests sent to the load balancers.",
      "D. Configure Elastic Load Balancing to vary the proportion of requests sent to instances running the two application versions."
    ],
    correctAnswer: "Answer – A and C",
    explanation:
      "The AWS documentation gives this example of a Blue Green deployment"
  },
  {
    topic: "Incident and Event Response",
    question:
      "Your company currently has a set of EC2 Instances running a web application which sits behind an Elastic Load Balancer. You also have an Amazon RDS instance which is used by the web application. You have been asked to ensure that this architecture is self healing in nature and cost effective. Which of the following would fulfill this requirement. Choose 2 answers from the option given below",
    answers: [
      "A. Use Cloudwatch metrics to check the utilization of the web layer. Use Autoscaling Group to scale the web instances accordingly based on the cloudwatch metrics.",
      "B. Use Cloudwatch metrics to check the utilization of the database servers. Use Autoscaling Group to scale the database instances accordingly based on the cloudwatch metrics.",
      "C. Utilize the Read Replica feature for the Amazon RDS layer",
      "D. Utilize the Multi-AZ feature for the Amazon RDS layer"
    ],
    correctAnswer: "Answer - A and D",
    explanation:
      "The following diagram from AWS showcases a self-healing architecture where you have a set of EC2 servers as Web server being launched by an Autoscaling Group."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Your company has a set of EC2 Instances that access data objects stored in an S3 bucket. Your IT Security department is concerned about the security of this architecture and wants you to implement the following\n\n1) Ensure that the EC2 Instance securely accesses the data objects stored in the S3 bucket\n\n2) Ensure that the integrity of the objects stored in S3 is maintained.\n\nWhich of the following would help fulfil the requirements of the IT Security department. Choose 2 answers from the options given below",
    answers: [
      "A. Create an IAM user and ensure the EC2 Instances uses the IAM user credentials to access the data in the bucket.",
      "B. Create an IAM Role and ensure the EC2 Instances uses the IAM Role to access the data in the bucket.",
      "C. Use S3 Cross Region replication to replicate the objects so that the integrity of data is maintained.",
      "D. Use an S3 bucket policy that ensures that MFA Delete is set on the objects in the bucket"
    ],
    correctAnswer: "Answer - B and D",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "Your company uses an application hosted in AWS which consists of EC2 Instances. The logs of the EC2 instances need to be processed and analyzed in real time , since this is a requirement from the IT Security department. Which of the following can be used to process the logs in real time?",
    answers: [
      "A. Use Cloudwatch logs to process and analyze the logs in real time",
      "B. Use Amazon Glacier to store the logs and then use Amazon Kinesis to process and analyze the logs in real time",
      "C. Stream the logs to Kinesis Firehose and send the output to Kinesis analytics to process and analyze the logs in real time",
      "D. Use another EC2 Instance with a larger instance type to process the logs"
    ],
    correctAnswer: "Answer – C",
    explanation: "The AWS Documentation mentions the below"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You are a Devops engineer for your company. The company hosts a web application that is hosted on a single EC2 Instance. The end users are complaining of slow response times for the application. Which of the following can be used to effectively scale the application?",
    answers: [
      "A. Use Autoscaling Groups to launch multiple instances and place them behind an ELB.",
      "B. Use Autoscaling launch configurations to launch multiple instances and place them behing an ELB.",
      "C. Use Amazon RDS with the Multi-AZ feature.",
      "D. Use Cloudformation to deploy the app again with an Amazon RDS with the Multi-AZ feature."
    ],
    correctAnswer: "Answer - A",
    explanation: "The AWS Documentation mentions the below"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You have a set of EC2 Instances hosting an nginx server and a web application that is used by a set of users in your organization. After a recent application version upgrade, the instance runs into technical issues and needs an immediate restart. This does not give you enough time to inspect the cause of the issue on the server.  Which of the following options if implemented prior to the incident would have assisted in detecting the underlying cause of the issue?",
    answers: [
      "A. Enable detailed monitoring and check the Cloudwatch metrics to see the cause of the issue.",
      "B. Create a snapshot of the EBS volume after restart, attach it to another instance as a volume and then diagnose the issue.",
      "C. Stream all the data to Amazon Kinesis and then analyze the data in real time.",
      "D. Install Cloudwatch logs agent on the instance and send all the logs to Cloudwatch logs."
    ],
    correctAnswer: "Answer – D",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "The AWS Code Deploy service can be used to deploy code from which of the below mentioned source repositories. Choose 3 answers from the options given below",
    answers: [
      "A. S3 Buckets",
      "B. GitHub repositories",
      "C. Subversion repositories",
      "D. Bitbucket repositories"
    ],
    correctAnswer: "Answer – A,B and D",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "In AWS Code Deploy which of the following deployment types are available. Choose 2 answers from the options given below",
    answers: [
      "A. In-place deployments",
      "B. Rolling deployments",
      "C. Immutable deployments",
      "D. Blue/Green deployments"
    ],
    correctAnswer: "Answer – A and D",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are designing a cloudformation template to install a set of web servers on EC2 Instances. The following User data needs to be passed to the EC2 Instances\n\n#!/bin/bash\n\nsudo apt-get update\n\nsudo apt-get install -y nginx\n\nWhere in the cloudformation template would you ideally pass this User Data",
    answers: [
      "A. In the properties section of the EC2 Instance in the resources section",
      "B. In the properties section of the EC2 Instance in the Output section",
      "C. In the Metadata section of the EC2 Instance in the resources section",
      "D. In the Metadata section of the EC2 Instance in the Output section"
    ],
    correctAnswer: "Answer - A",
    explanation:
      "Below is an example on how the user data can be passed in a cloudformation template"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You need to deploy a multi-container Docker environment on to Elastic beanstalk. Which of the following files can be used to deploy a set of Docker containers to Elastic beanstalk",
    answers: [
      "A. Dockerfile",
      "B. DockerMultifile",
      "C. Dockerrun.aws.json",
      "D. Dockerrun"
    ],
    correctAnswer: "Answer – C",
    explanation: "The AWS Documentation specifies"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have the requirement to get a snapshot of the current configuration of the resources in your AWS Account. Which of the following services can be used for this purpose",
    answers: [
      "A. AWS CodeDeploy",
      "B. AWS Trusted Advisor",
      "C. AWS Config",
      "D. AWS IAM"
    ],
    correctAnswer: "Answer - C",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have a set of EC2 Instances in an Autoscaling Group that processes messages from an SQS queue. The messages contain the location in S3 from where video's need to be processed by the EC2 Instances. When a scale in happens, it is noticed that an at times that the EC2 Instance is still in a state of processing a video when the instance is terminated. How can you implement a solution which will ensure this does not happen?",
    answers: [
      "A. Change the CoolDown property for the Autoscaling Group.",
      "B. Suspend the AZRebalance termination policy",
      "C. Use lifecycle hooks to ensure the processing is complete before the termination occurs",
      "D. Increase the minimum and maximum size for the Auto Scaling group, and change the scaling policies so they scale less dynamically."
    ],
    correctAnswer: "Answer - C",
    explanation:
      "This is a case where lifecycle policies can be used. The lifecycle policy can be used to put the instance in a state of Terminating:Wait , complete the processing and then send a signal to complete the termination"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your company is hosting an application in AWS. The application consists of a set of web servers and AWS RDS. The application is a read intensive application. It has been noticed that the response time of the application decreases due to the load on the AWS RDS instance. Which of the following measures can be taken to scale the data tier. Choose 2 answers from the options given below",
    answers: [
      "A. Create Amazon DB Read Replica's. Configure the application layer to query the readreplica's for query needs.",
      "B. Use Autoscaling to scale out and scale in the database tier",
      "C. Use SQS to cache the database queries",
      "D. Use ElastiCache in front of your Amazon RDS DB to cache common queries."
    ],
    correctAnswer: "Answer - A and D",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "SDLC Automation",
    question:
      "You are using Jenkins as your continuous integration systems for the application hosted in AWS. The builds are then placed on newly launched EC2 Instances. You want to ensure that the overall cost of the entire continuous integration and deployment pipeline is minimized. Which of the below options would meet these requirements? Choose 2 answers from the options given below",
    answers: [
      "A. Ensure that all build tests are conducted using Jenkins before deploying the build to newly launched EC2 Instances.",
      "B. Ensure that all build tests are conducted on the newly launched EC2 Instances.",
      "C. Ensure the Instances are launched only when the build tests are completed.",
      "D. Ensure the Instances are created beforehand for faster turnaround time for the application builds to be placed."
    ],
    correctAnswer: "Answer - A and C",
    explanation:
      "To ensure low cost , one can carry out the build tests on the Jenkins server itself. Once the build tests are completed , the build can then be transferred onto newly launched EC2 Instances."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are using Elastic beanstalk to deploy an application that consists of a web and application server. There is a requirement to run some python scripts before the application version is deployed to the web server. Which of the following can be used to achieve this?",
    answers: [
      "A. Make use of container commands",
      "B. Make use of Docker containers",
      "C. Make use of custom resources",
      "D. Make use of multiple elastic beanstalk environments"
    ],
    correctAnswer: "Answer - A",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "SDLC Automation",
    question:
      "Your company has an application sitting on EC2 Instances behind an Elastic Load balancer. The EC2 Instances are being launched as part of an Autoscaling Group. Which of the following is an example of Blue Green Deployments in AWS?",
    answers: [
      "A. Use a Cloudformation stack to deploy your resources. Use 2 Cloudformation stacks. Whenever you want to switch over, deploy and use the resources in the second Cloudformation stack.",
      "B. Use the Elastic beanstalk service to deploy your resources. Use 2 Elastic beanstalk environments. Use Rolling deployments to switch between the environments.",
      "C. Re-deploy your application behind a load balancer that uses Auto Scaling groups, create a new Auto Scaling group, and associate it to the load balancer. After the new environment is up and running, set the desired number of instances on the old Auto Scaling group to zero, and when all instances have terminated, delete the old Auto Scaling group.",
      "D. Use the Opsworks service to deploy your resources. Use 2 Opswork layers to deploy 2 versions of your application. When the time comes for the switch, change to the alternate layer in the Opswork stack"
    ],
    correctAnswer: "Answer – C",
    explanation: "This deployment technique is given in the AWS Whitepaper"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You have a requirement to automate the creation of EBS Snapshots. Which of the following can be used to achieve this in the best way possible?",
    answers: [
      "A. Create a powershell script which uses the AWS CLI to get the volumes and then run the script as a cron job.",
      "B. Use the AWSConfig service to create a snapshot of the AWS Volumes",
      "C. Use the AWS CodeDeploy service to create a snapshot of the AWS Volumes",
      "D. Use Cloudwatch Events to trigger the snapshots of EBS Volumes"
    ],
    correctAnswer: "Answer – D",
    explanation:
      "The best is to use the inbuilt service from Cloudwatch , as Cloudwatch Events to automate the creation of EBS Snapshots. With Option A , you would be restricted to running the powrshell script on Windows machines and maintaining the script itself. And then you have the overhead of having a separate instance just to run that script."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Your company has a set of resources hosted in AWS. Your IT Supervisor is concerned with the costs being incurred with the current set of AWS resources and wants to monitor the cost usage. Which of the following mechanisms can be used to monitor the costs of the AWS resources and also look at the possibility of cost optimization. Choose 3 answers from the options given below",
    answers: [
      "A. Use the Cost Explorer to see the costs of AWS resources",
      "B. Create budgets in billing section so that budgets are set before hand",
      "C. Send all logs to Cloudwatch logs and inspect the logs for billing details",
      "D. Consider using the Trusted Advisor"
    ],
    correctAnswer: "Answer - A,B and D",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "When you add lifecycle hooks to an Autoscaling Group , what are the wait states that occur during the scale in and scale out process. Choose 2 answers from the options given below",
    answers: [
      "A. Launching:Wait",
      "B. Exiting:Wait",
      "C. Pending:Wait",
      "D. Terminating:Wait"
    ],
    correctAnswer: "Answer - C and D",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your company has a set of EC2 instances sitting behind an Elastic Load Balancer. There is a requirement to create an OpsWorks stack to host the newer version of this application. The Opsworks stack has been created, but the current ELB is being utilized to host the newer version of the application. The current application is not responding to requests now. What could be the cause ?",
    answers: [
      "A. This is because the Opsworks stack is utilizing the instances of the current application after the ELB was attached to it.",
      "B. You have configured the Opsworks stack to deploy the instances of the new version of the application in the same domain as that of the old instances.",
      "C. After the ELB is attached to the Opsworks stack, it would have de-registered the the instances of the current application and therefore the current application is not responding to requests.",
      "D. This is because the Opsworks web layer is utilizing the instances of the current application after the ELB was attached to the Opsworks layer."
    ],
    correctAnswer: "Answer – C",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Your company has an on-premise Active Directory setup in place. The company has extended their footprint on AWS , but still want to have the ability to use their on-premise Active Directory for authentication. Which of the following AWS services can be used to ensure that AWS resources such as AWS Workspaces can continue to use the existing credentials stored in the on-premise Active Directory.",
    answers: [
      "A. Use the Active Directory service on AWS",
      "B. Use the AWS Simple AD service",
      "C. Use the Active Directory connector service on AWS",
      "D. Use the ClassicLink feature on AWS"
    ],
    correctAnswer: "Answer – C",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You are a Devops Engineer for your company. Your company is using Opswork stack to rollout a collection of web instances. When the instances are launched , a configuration file need to be setup prior to the launching of the web application hosted on these instances. Which of the following steps would you carry out to ensure this requirement gets fulfilled. Choose 2 answers from the options given below",
    answers: [
      "A. Ensure that the Opswork stack is changed to use the AWS specific cookbooks",
      "B. Ensure that the Opswork stack is changed to use custom cookbooks",
      "C. Configure a recipe which sets the configuration file and add it to the Configure LifeCycle Event of the specific web layer.",
      "D. Configure a recipe which sets the configuration file and add it to the Deploy LifeCycle Event of the specific web layer."
    ],
    correctAnswer: "Answer - B and C",
    explanation: "This is mentioned in the AWS documentation"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Which of the following items are required to allow an application deployed on an EC2 instance to write data to a DynamoDB table? Assume that no security keys are allowed to be stored on the EC2 instance. Choose 2 answers from the options below",
    answers: [
      "A. Create an IAM Role that allows write access to the DynamoDB table.",
      "B. Add an IAM Role to a running EC2 instance.",
      "C. Create an IAM User that allows write access to the DynamoDB table.",
      "D. Add an IAM User to a running EC2 instance."
    ],
    correctAnswer: "Answer – A and B",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You have a set of EC2 Instances running behind an ELB. These EC2 Instances are launched via an Autoscaling Group. There is a requirement to ensure that the logs from the server are stored in a durable storage layer. This is so that log data can be analyzed by staff in the future. Which of the following steps can be implemented to ensure this requirement is fulfilled. Choose 2 answers from the options given below",
    answers: [
      "A. On the web servers, create a scheduled task that executes a script to rotate and transmit the logs to an Amazon S3 bucket.",
      "B. Use AWS Data Pipeline to move log data from the Amazon S3 bucket to Amazon Redshift in order to process and run reports",
      "C. On the web servers, create a scheduled task that executes a script to rotate and transmit the logs to Amazon Glacier.",
      "D. Use AWS Data Pipeline to move log data from the Amazon S3 bucket to Amazon SQS in order to process and run reports"
    ],
    correctAnswer: "Answer - A and B",
    explanation:
      "Amazon S3 is the perfect option for durable storage. The AWS Documentation mentions the following on S3 Storage"
  },
  {
    topic: "SDLC Automation",
    question:
      "Your company has a set of development teams that work in a variety of programming languages. They develop applications which have a lot of different application dependencies. There is a move from the company to move these development environments onto AWS. Which of the below is the best option to make this happen.",
    answers: [
      "A. Use the Cloudformation service to create docker containers for each type of application",
      "B. Use the Elastic beanstalk service and use Docker containers to host each application environment for the developer community",
      "C. Use the Opswork service, create a stack and create separate layers for each application environment for the developer community",
      "D. Launch separate EC2 Instances to host each application type for the developer community"
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your company wants to understand where cost is coming from in the company’s production AWS account. There are a number of applications and services running at any given time. Without expending too much initial development time,how best can you give the business a good understanding of which applications cost the most per month to operate?",
    answers: [
      "A. Create an automation script which periodically creates AWS Support tickets requesting detailed intra-month information about your bill.",
      "B. Use custom CloudWatch Metrics in your system, and put a metric data point whenever cost is incurred.",
      "C. Use AWS Cost Allocation Tagging for all resources which support it. Use the Cost Explorer to analyze costs throughout the month.",
      "D. Use the AWS Price API and constantly running resource inventory scripts to calculate total price based on multiplication of consumed resources over time."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "A tag is a label that you or AWS assigns to an AWS resource. Each tag consists of a key and a value. A key can have more than one value. You can use tags to organize your resources, and cost allocation tags to track your AWS costs on a detailed level. After you activate cost allocation tags, AWS uses the cost allocation tags to organize your resource costs on your cost allocation report, to make it easier for you to categorize and track your AWS costs. AWS provides two types of cost allocation tags, an AWS-generated tag and user-defined tags. AWS defines, creates, and applies the AWS-generated tag for you, and you define, create, and apply user-defined tags. You must activate both types of tags separately before they can appear in Cost Explorer or on a cost allocation report."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You've created a Cloudformation template as per your team's request which is required for testing an application. The requirement is that when there is a request for stack deletion, the database should still be preserved for future reference.How can you achieve this using Cloudformation?",
    answers: [
      "A. Ensure that the RDS is created with Read Replica's so that the Read Replica remains after the stack is torn down.",
      "B. In the AWS CloudFormation template, set the DeletionPolicy of theAWS::RDS::DBInstance’s DeletionPolicy property to “Retain.”",
      "C. In the AWS CloudFormation template, set the WaitPolicy of the AWS::RDS::DBInstance’s WaitPolicy property to “Retain.”",
      "D. In the AWS CloudFormation template, set the AWS::RDS::DBInstance’s DBlnstanceClass property to be read-only."
    ],
    correctAnswer: "Answer - B",
    explanation:
      "With the DeletionPolicy attribute you can preserve or (in some cases) backup a resource when its stack is deleted. You specify a DeletionPolicy attribute for each resource that you want to control. If a resource has no DeletionPolicy attribute, AWS CloudFormation deletes the resource by default. Note that this capability also applies to update operations that lead to resources being removed."
  },
  {
    topic: "SDLC Automation",
    question:
      "Your team is responsible for an AWS Elastic Beanstalk application. The business requires that you move to a continuous deployment model, releasing updates to the application multiple times per day with zero downtime. What should you do to enable this and still be able to roll back almost immediately in an emergency to the previous version?",
    answers: [
      "A. Enable rolling updates in the Elastic Beanstalk environment, setting an appropriate pause time for application startup.",
      "B. Create a second Elastic Beanstalk environment running the new application version, and swap the environment CNAMEs.",
      "C. Develop the application to poll for a new application version in your code repository; download and install to each running Elastic Beanstalk instance.",
      "D. Create a second Elastic Beanstalk environment with the new application version, and configure the old environment to redirect clients, using the HTTP 301 response code, to the new environment."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "Since the requirement calls for zero downtime and for the ability roll back quickly, we need to implement Blue green deployments using the Elastic beanstalk service. For this, we can use the SWAP url feature which is available with Elastic beanstalk"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "Your current log analysis application takes more than four hours to generate a report of the top 10 users of your web application. You have been asked to implement a system for processing of the information in real time. Choose the option that is cost-effective and can fulfill the requirements.",
    answers: [
      "A. Publish your data to CloudWatch Logs, and configure your application to autoscale to handle the load on demand.",
      "B. Publish your log data to an Amazon S3 bucket. Use AWS CloudFormation to create an Auto Scaling group to scale your post-processing application which is configured to pull down your log files stored in Amazon S3.",
      "C. Post your log data to an Amazon Kinesis data stream, and subscribe your log-processing application so that is configured to process your logging data.",
      "D. Configure an Auto Scaling group to increase the size of your Amazon EMR cluster.",
      "E. Create a multi-AZ Amazon RDS MySQL cluster, post the logging data to MySQL, and run a map reduce job to retrieve the required information on user counts."
    ],
    correctAnswer: "Answer – C",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are building an application based on the Go programming language for internal, non-production use which uses MySQL as a database. You want developers without very much AWS experience to be able to deploy new code with a single command line push. You also want to set this up as simply as possible. Which tool is ideal for this setup?",
    answers: [
      "A. AWS CloudFormation",
      "B. AWS OpsWorks",
      "C. AWS ELB + EC2",
      "D. AWS Elastic Beanstalk"
    ],
    correctAnswer: "Answer - D",
    explanation:
      "With Elastic Beanstalk, you can quickly deploy and manage applications in the AWS Cloud without worrying about the infrastructure that runs those applications. AWS Elastic Beanstalk reduces management complexity without restricting choice or control. You simply upload your application, and Elastic Beanstalk automatically handles the details of capacity provisioning, load balancing, scaling, and application health monitoring"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You need to grant a vendor access to your AWS account. They need to be able to read protected messages in a private S3 bucket at their leisure. They also use AWS. What is the best way to accomplish this?",
    answers: [
      "A. Create an IAM User with API Access Keys. Grant the User permissions to access the bucket. Give the vendor the AWS Access Key ID and AWS Secret Access Key for the User.",
      "B. Create an EC2 Instance Profile on your account. Grant the associated IAM role full access to the bucket. Start an EC2 instance with this Profile and give SSH access to the instance to the vendor.",
      "C. Create a cross-account IAM Role with permission to access the bucket, and grant permission to use the Role to the vendor AWS account.",
      "D. Generate a signed S3 PUT URL and a signed S3 PUT URL, both with wildcard values and 2 year durations. Pass the URLs to the vendor."
    ],
    correctAnswer: "Answer - C",
    explanation:
      "You can use AWS Identity and Access Management (IAM) roles and AWS Security Token Service (STS) to set up cross-account access between AWS accounts. When you assume an IAM role in another AWS account to obtain cross-account access to services and resources in that account, AWS CloudTrail logs the cross-account activity"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You have a set of applications hosted in AWS. There is a requirement to store the logs from this application onto durable storage. After a period of 3 months, the logs can be placed in archival storage. Which of the following steps would you carry out to achieve this requirement. Choose 2 answers from the options given below",
    answers: [
      "A. Store the log files as they emitted from the application on to Amazon Glacier",
      "B. Store the log files as they emitted from the application on to Amazon Simple Storage service",
      "C. Use Lifecycle policies to move the data onto Amazon Glacier after a period of 3 months",
      "D. Use Lifecycle policies to move the data onto Amazon Simple Storage service after a period of 3 months"
    ],
    correctAnswer: "Answer - B and C",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "SDLC Automation",
    question:
      "Your development team is developing a mobile application that access resources in AWS. The users accessing this application will be logging in via Facebook and Google. Which of the following AWS mechanisms would you use to authenticate users for the application that needs to access AWS resources",
    answers: [
      "A. Use separate IAM users that correspond to each Facebook and Google user",
      "B. Use separate IAM Roles that correspond to each Facebook and Google user",
      "C. Use Web identity federation to authenticate the users",
      "D. Use AWS Policies to authenticate the users"
    ],
    correctAnswer: "Answer – C",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You are a Devops Engineer for your company. You are responsible for creating Cloudformation templates for your company. There is a requirement to ensure that an S3 bucket is created for all resources in development for logging purposes. How would you achieve this?",
    answers: [
      "A. Create separate Cloudformation templates for Development and production.",
      "B. Create a parameter in the Cloudformation template and then use the Condition clause in the template to create an S3 bucket if the parameter has a value of development",
      "C. Create an S3 bucket from before and then just provide access based on the tag value mentioned in the Cloudformation template",
      "D. Use the metadata section in the Cloudformation template to decide on whether to create the S3 bucket or not."
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "In reviewing the Auto-Scaling events for your application you notice that your application is scaling out and in multiple times in the same hour. What design choice could you make to optimize for costs while preserving elasticity?\n\nChoose 2 options from the choices given below",
    answers: [
      "A. Modify the Auto Scaling policy to use scheduled scaling actions",
      "B. Modify the Auto Scaling Group cool down timers",
      "C. Modify the Amazon Cloudwatch alarm period that triggers your AutoScaling scale down policy.",
      "D. Modify the Auto Scaling group termination policy to terminate the newest instance first."
    ],
    correctAnswer: "Answer - B and C",
    explanation:
      "The Auto Scaling cooldown period is a configurable setting for your Auto Scaling group that helps to ensure that Auto Scaling doesn't launch or terminate additional instances before the previous scaling activity takes effect. After the Auto Scaling group dynamically scales using a simple scaling policy, Auto Scaling waits for the cooldown period to complete before resuming scaling activities. When you manually scale your Auto Scaling group, the default is not to wait for the cooldown period, but you can override the default and honor the cooldown period. Note that if an instance becomes unhealthy, Auto Scaling does not wait for the cooldown period to complete before replacing the unhealthy instance."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You have an application running in us-west-2 that requires 6 EC2 instances running at all times. With 3 AZ available in that region, which of the following deployments provides 100% fault tolerance if any single AZ in us-west-2 becomes unavailable. Choose 2 answers from the options below",
    answers: [
      "A. us-west-2a with 2 instances, us-west-2b with 2 instances, us-west-2c with 2 instances",
      "B. us-west-2a with 3 instances, us-west-2b with 3 instances, us-west-2c with 0 instances",
      "C. us-west-2a with 4 instances, us-west-2b with 2 instances, us-west-2c with 2 instances",
      "D. us-west-2a with 6 instances, us-west-2b with 6 instances, us-west-2c with 0 instances",
      "E. us-west-2a with 3 instances, us-west-2b with 3 instances, us-west-2c with 3 instances"
    ],
    correctAnswer: "Answer - D and E",
    explanation:
      "Since we need 6 instances running at all times, only D and E fulfil this option."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You are a Devops Engineer for your company. There is a requirement to log each time an Instance is scaled in or scaled out from an existing Autoscaling Group. Which of the following steps can be implemented to fulfil this requirement. Each step forms part of the solution.",
    answers: [
      "A. Create a Lambda function which will write the event to Cloudwatch logs",
      "B. Create a Cloudwatch event which will trigger the Lambda function.",
      "C. Create an SQS queue which will write the event to Cloudwatch logs",
      "D. Create a Cloudwatch event which will trigger the SQS queue."
    ],
    correctAnswer: "Answer - A and B",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have written a CloudFormation template that creates 1 elastic load balancer fronting 2 EC2 instances. Which section of the template should you edit so that the DNS of the load balancer is returned upon creation of the stack?",
    answers: ["A. Resources", "B. Parameters", "C. Outputs", "D. Mappings"],
    correctAnswer: "Answer – C",
    explanation:
      "The below example shows a simple CloudFormation template. It creates an EC2 instance based on the AMI - ami-d6f32ab5. When the instance is created, it will output the AZ in which it is created."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "There is a company website that is going to be launched in the coming weeks. There is a probability that the traffic will be quite high in the first couple of weeks. In the event of a load failure, how can you set up DNS failover to a static website? Choose the correct answer from the options given below.",
    answers: [
      "A. Duplicate the exact application architecture in another region and configure DNS weight-based routing",
      "B. Enable failover to an on-premise data center to the application hosted there.",
      "C. Use Route 53 with the failover option to failover to a static S3 website bucket or CloudFront distribution.",
      "D. Add more servers in case the application fails."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "Amazon Route 53 health checks monitor the health and performance of your web applications, web servers, and other resources."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "One of your EC2 instance is reporting an unhealthy system status check. However, this is not something you should have to monitor and repair on your own. How might you automate the repair of the system status check failure in an AWS environment? Choose the correct answer from the options given below",
    answers: [
      "A. Create CloudWatch alarms for StatuscheckFailed_System metrics and select EC2 action-Recover the EC2 instance",
      "B. Write a script that queries the EC2 API for each EC2 instance status check",
      "C. Write a script that periodically shuts down and starts EC2 instances based on certain stats.",
      "D. Implement a third party monitoring tool."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "Using Amazon CloudWatch alarm actions, you can create alarms that automatically stop, terminate, reboot, or recover your EC2 instances. You can use the stop or terminate actions to help you save money when you no longer need an instance to be running. You can use the reboot and recover actions to automatically reboot those instances or recover them onto new hardware if a system impairment occurs."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your development team is using an Elastic beanstalk environment. After a week, the environment was torn down and a new one was created. When the development team tried to access the data on the older environment, it was not available. Why is this the case?",
    answers: [
      "A. This is because the underlying EC2 Instances are created with encrypted storage and cannot be accessed once the environment has been terminated.",
      "B. This is because the underlying EC2 Instances are created with IOPS volumes and cannot be accessed once the environment has been terminated.",
      "C. This is because before the environment termination, Elastic beanstalk copies the data to DynamoDB, and hence the data is not present in the EBS volumes",
      "D. This is because the underlying EC2 Instances are created with no persistent local storage"
    ],
    correctAnswer: "Answer - D",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Incident and Event Response",
    question:
      "You have a setup in AWS which consists of EC2 Instances sitting behind and ELB. The launching and termination of the Instances are controlled via an Autoscaling Group. The architecture consists of a MySQL AWS RDS database. Which of the following can be used to induce one more step towards a self-healing architecture for this design?",
    answers: [
      "A. Enable Read Replica's for the AWS RDS database.",
      "B. Enable Multi-AZ feature for the AWS RDS database.",
      "C. Create one more ELB in another region for fault tolerance",
      "D. Create one more Autoscaling Group in another region for fault tolerance"
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Incident and Event Response",
    question:
      "You are setting up cloudformation templates for your organization. The cloudformation template consists of creating EC2 Instances for both your development and production environments in the same region. Each of these instances will have an Elastic IP and a security group attached to them which will be done via Cloudformation. Your cloudformation stack for the development environment gets successfully created, but then the production cloudformation stack fails. Which of the below could be a reason for this.",
    answers: [
      "A. You have chosen the wrong tags when creating the instances in both environments.",
      "B. You hit the soft limit of 5 EIPs per region when creating the development environment.",
      "C. You hit the soft limit for security groups when creating the development environment.",
      "D. You didn’t choose the Production version of the AMI you are using when creating the production stack."
    ],
    correctAnswer: "Answer - B",
    explanation:
      "The most viable reason could be that you reached the limit for the number of Elastic IP's in the region."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "A company is running three production web server reserved EC2 instances with EBS-backed root volumes. These instances have a consistent CPU load of 80%. Traffic is being distributed to these instances by an Elastic Load Balancer. They also have their corresponding production and development Multi-AZ RDS MySQL databases. What recommendation would you make to reduce cost in this environment without affecting availability of mission-critical systems? Choose the correct answer from the options given below",
    answers: [
      "A. Consider using on-demand instances instead of reserved EC2 instances",
      "B. Consider not using a Multi-AZ RDS deployment for the development database",
      "C. Consider using spot instances instead of reserved EC2 instances",
      "D. Consider removing the Elastic Load Balancer"
    ],
    correctAnswer: "Answer – B",
    explanation:
      "Multi-AZ databases is better for production environments rather than for development environments, so you can reduce costs by not using this for development environments"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "A company has EC2 instances running in AWS. The EC2 instances are running via an Autoscaling solution. There is a lot of requests being lost because of the load on the servers. The Autoscaling solution is launching new instances to take the load but there are still some requests which are being lost. Which of the following is likely to provide the most cost-effective solution to avoid losing recently submitted requests? Choose the correct answer from the options given below",
    answers: [
      "A. Use an SQS queue to decouple the application components",
      "B. Keep one extra EC2 instance always powered on in case a spike occurs",
      "C. Use larger instances for your application",
      "D. Pre-warm your Elastic Load Balancer"
    ],
    correctAnswer: "Answer - A",
    explanation:
      "Amazon Simple Queue Service (SQS) is a fully-managed message queuing service for reliably communicating among distributed software components and microservices - at any scale. Building applications from individual components that each perform a discrete function improves scalability and reliability, and is best practice design for modern applications"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "When storing sensitive data on the cloud which of the below options should be carried out on AWS. Choose 3 answers from the options given below.",
    answers: [
      "A. With AWS you do not need to worry about encryption",
      "B. Enable EBS Encryption",
      "C. Encrypt the file system on an EBS volume using Linux tools",
      "D. Enable S3 Encryption"
    ],
    correctAnswer: "Answer – B,C and D",
    explanation:
      "Amazon EBS encryption offers you a simple encryption solution for your EBS volumes without the need for you to build, maintain, and secure your own key management infrastructure. When you create an encrypted EBS volume and attach it to a supported instance type, the following types of data are encrypted:"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You've been tasked with building out a duplicate environment in another region for disaster recovery purposes. Part of your environment relies on EC2 instances with preconfigured software. What steps would you take to configure the instances in another region? Choose the correct answer from the options below",
    answers: [
      "A. Create an AMI of the EC2 instance",
      "B. Create an AMI of the EC2 instance and copy the AMI to the desired region",
      "C. Make the EC2 instance shareable among other regions through IAM permissions",
      "D. None of the above"
    ],
    correctAnswer: "Answer – B",
    explanation:
      "You can copy an Amazon Machine Image (AMI) within or across an AWS region using the AWS Management Console, the AWS command line tools or SDKs, or the Amazon EC2 API, all of which support the CopyImage action. You can copy both Amazon EBS-backed AMIs and instance store-backed AMIs. You can copy AMIs with encrypted snapshots and encrypted AMIs."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You have instances running on your VPC. You have test, production and development based instances running in the VPC. You want to ensure that people who are responsible for the development instances don’t have the access to work on the production instances to ensure better security. Using policies, which of the following would be the best way to accomplish this? Choose the correct answer from the options given below",
    answers: [
      "A. Launch the test and production instances in separate VPC's and use VPC peering",
      "B. Create an IAM policy with a condition which allows access to only instances that are used for production or development",
      "C. Launch the test and production instances in different Availability Zones and use Multi Factor Authentication",
      "D. Define the tags on the test and production servers and add a condition to the IAM policy which allows access to specific tags"
    ],
    correctAnswer: "Answer – D",
    explanation:
      "You can easily add tags which define which instances are production and which are development instances and then ensure these tags are used when controlling access via an IAM policy."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Your company is concerned with EBS volume backup on Amazon EC2 and wants to ensure they have proper backups and that the data is durable. What solution would you implement and why? Choose the correct answer from the options below",
    answers: [
      "A. Configure Amazon Storage Gateway with EBS volumes as the data source and store the backups on premise through the storage gateway",
      "B. Write a cronjob on the server that compresses the data that needs to be backed up using gzip compression, then use AWS CLI to copy the data into an S3 bucket for durability",
      "C. Use a lifecycle policy to back up EBS volumes stored on Amazon S3 for durability",
      "D. Use Amazon Data Lifecycle Manager (Amazon DLM) to automate the creation of snapshots taken to back up your Amazon EBS volumes.. The data is durable because EBS snapshots are stored on the Amazon S3 standard storage class"
    ],
    correctAnswer: "Answer – D",
    explanation:
      "You can use Amazon Data Lifecycle Manager (Amazon DLM) to automate the creation, retention, and deletion of snapshots taken to back up your Amazon EBS volumes. The snapshots are automatically stored on S3 for durability."
  },
  {
    topic: "SDLC Automation",
    question:
      "You are working for a startup company that is building an application that receives large amounts of data. Unfortunately, current funding has left the start-up short on cash, cannot afford to purchase thousands of dollars of storage hardware, and has opted to use AWS. Which services would you implement in order to store a virtually unlimited amount of data without any effort to scale when demand unexpectedly increases? Choose the correct answer from the options below",
    answers: [
      "A. Amazon S3, because it provides unlimited amounts of storage data, scales automatically, is highly available, and durable",
      "B. Amazon Glacier, to keep costs low for storage and scale infinitely",
      "C. Amazon Import/Export, because Amazon assists in migrating large amounts of data to Amazon S3",
      "D. Amazon EC2, because EBS volumes can scale to hold any amount of data and, when used with Auto Scaling, can be designed for fault tolerance and high availability"
    ],
    correctAnswer: "Answer – A",
    explanation:
      "The best option is to use S3 because you can host a large amount of data in S3 and is the best storage option provided by AWS."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You have a web application running on six Amazon EC2 instances, consuming about 45% of resources on each instance. You are using auto-scaling to make sure that six instances are running at all times. The number of requests this application processes is consistent and does not experience spikes. The application is critical to your business and you want high availability at all times. You want the load to be distributed evenly between all instances. You also want to use the same Amazon Machine Image (AMI) for all instances. Which of the following architectural choices should you make?",
    answers: [
      "A. Deploy 6 EC2 instances in one availability zone and use Amazon Elastic Load Balancer.",
      "B. Deploy 3 EC2 instances in one region and 3 in another region and use Amazon Elastic Load Balancer.",
      "C. Deploy 3 EC2 instances in one availability zone and 3 in another availability zone and use Amazon Elastic Load Balancer.",
      "D. Deploy 2 EC2 instances in three regions and use Amazon Elastic Load Balancer."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "Option A is automatically incorrect because remember that the question asks for high availability. For option A, if the AZ goes down then the entire application fails."
  }
];
