import React from 'react';
import './StarredWord.css';
import Icon from 'antd/lib/icon'
import {Spring, config} from 'react-spring';


class StarredWord extends React.Component {
    state = { hoverStar: false }

    hover = () =>{
        this.setState({
            hoverStar: !this.state.hoverStar
        })
    }
    render() { 
        return ( 
            <div className='exHolder'>
                    <div className='exWordContainer'>
                      <Icon type="caret-right" />
                        {this.props.card.word}
                      <div 
                      className="exStar" 
                      onMouseOver={this.hover}
                      onMouseOut={this.hover}>
                        
                      <Icon 
                      type="star" 
                      onClick={() => this.props.toggleStarred(this.props.card)}
                      
                      style={{fill: 'rgb(255, 91, 69)'}} className={`exampleStar`} />
                      </div>  
                      
                      {this.state.hoverStar ? 
                        <Spring config={config.gentle} from={{opacity: 0, marginLeft: '-10px'}} to={{opacity: 1, marginLeft: '5px'}}>
                        {props => 
                        
                        <div styles={props} className='deleteExText'>DELETE</div>
                        
                        }
                        </Spring>
                        : null
                      }
                    
                    </div>
                    
                    <div className='exDefinitionContainer'>
                      <div className='exDefinition'>
                         {this.props.card.translation}
                      </div> 
                    </div>
              
            </div>

         );
    }
}
 
export default StarredWord;