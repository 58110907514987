import React, { useState, useEffect } from "react";
import Icon from "antd/es/icon";
import "./LearnSentenceContainer.css";
import QuizSentenceHolder from './QuizSentenceHolder'

export default ({currentCard}) => {
    console.log("CURRENT CARD",currentCard)
  return (

    <div className="learn-sentence-container">
      <div className="learn-sentence-header">
        <div className="sentence-header-tab">Sentences</div>
        <div className={`sentence-header-tab`}>Conjugations</div>
      </div>
      <div className="examples-container">
        {currentCard.sentences
          ? currentCard.sentences.map(sentences => (
              <QuizSentenceHolder sentences={sentences} />
            ))
          : "There are no sentences available for this word."}
      </div>
    </div>
  );
};
