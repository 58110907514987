import React, {
    useState,
    useEffect,
    useMemo,
    createContext,
    useContext,
  } from "react";
  import { useHistory, Link, useParams } from "react-router-dom";
  import "./TestTemplate.css";

const BasicCard = ({card, cardStyles = {}, boxEdgeSize}) => {
    let [state, setState] = useState({
      flipped: false,
      card,
    });
  
    let sideStyles = {position: 'relative', width: '100%'}
    return ( <div
      
      className={`basic-card`}
      style={{height: 200,minWidth: 200, maxWidth: 200}}                   
      onClick={() => setState({ ...state, flipped: !state.flipped })}
    >
      {
        !state.flipped ? <div style={sideStyles}>
        <div style={{fontSize: 14}}>
          {card.front}
        </div>
      </div>
        :
  
  <div style={sideStyles}>
        {card.img ? <img style={{transform: 'rotate(90deg)', width: '60%'}} alt="" src={`${process.env.PUBLIC_URL + card.img}`/* `../../images/cytosine-c.png` *//* card.img */} /> : null}
        {!card.img && card.back}
        
      </div>
      }
  
      
      
    </div>)
  }

  export default BasicCard