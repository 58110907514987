import React, { useState, useEffect, useMemo } from "react";
import './CardReview.css';

const CardReview = () => {

    const [state, setState] = useState({
        sessionsArray: [],
        cardsShown: '',
        flipped: false
    })


   useEffect(()=> {
        /* let sessionsArray = JSON.parse(localStorage.getItem('sessionsArray'));
        setState({
            sessionsArray
        }) */
        console.log("CARDSSHOWN:",state.cardsShown)
        
    })
 
    useMemo(()=> {
        let sessionsArray = JSON.parse(localStorage.getItem('sessionsArray')) || [];
    
        setState({
            sessionsArray
        })
        
    }, [])

    let openCard = (cardsArray) => {

        setState({
            ...state,
            cardsShown: cardsArray
        })
        
    }

    let closeBox = () => {
        setState({
            ...state,
            cardsShown: ''
        })
    }

    
    return (
        <div className="card-review-container">
            <div className=''>

            </div>
            {
                !state.cardsShown && state.sessionsArray.length ? state.sessionsArray.map(session => {
                        let weekFrom = 60 * 60 * 24 * 7 * 1000
                        console.log("SESSION",new Date(session.startDate).getDay(), "n\ Next Date: ", new Date(session.startDate + weekFrom).getDay())
                    return (
                        <div className="session-card-container" onClick={() => openCard(session.cardsArray)}>
                            Review Session {`${session.sessionID}`}
                        </div>
                    )
                }) : <div> You have no study sessions to review at this time. <br/> Start One Now! </div>
            }
            {
                state.cardsShown ? (
                <div className="cards-viewer">
                    <div className="exit" onClick={() => closeBox() }>x</div>
                    {
                        state.cardsShown.map(card => {

                            const CardReviewCard = () => {
                                let [state2,setState] = useState({
                                    flipped: false
                                })
                                let flipCard = () => {

                                    setState({
                                        ...state2,
                                        flipped: !state2.flipped
                                    })
                                }
                                return (
                                <div className="card-review-card" onMouseOver={flipCard} onMouseOut={flipCard}>
                                    {!state2.flipped ? card.word : card.translation}
                                </div>   
                                )
                            }
                            
                            return (
                                <CardReviewCard />
                            )
                        })
                    }
                </div>
                ) :
                null
            }

        </div>
    )
}

export default CardReview;

