import React, { useState, useEffect, useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import TestComponent from "./TestComponent";
import { createTestData } from '../../helpers/createTestData';
import "./TestsPage.css";
import TestBox from "./TestBox";

const TestsPage = () => {
  let history = useHistory();
  let [state, setState] = useState({
    allTestsArray: [],
    sessionBegun: false,
    wordsDue: 0,
    selectedSessions: [],
    finalArray: []
  });
  useMemo(() => {
    
    let {testsArray} = createTestData();
    let allTestsArray = testsArray;
    //set the total number of words due
    let wordsDue = 0;
    allTestsArray.forEach(sessn => {
        sessn.cardsArray.forEach(card => {
            wordsDue++;
        })
    })

    console.log("WORDSDUE",wordsDue);
    setState({
        ...state,
        allTestsArray,
        wordsDue
    })
    
  }, []);

  let addAndDeleteSessions = (clicked, session) => {

    if(!clicked){
        setState({
            ...state,
            selectedSessions: [...state.selectedSessions].filter(sessn => sessn.sessionID !== session.sessionID)
        })
    }
    else{
        setState({
            ...state,
            selectedSessions: [...state.selectedSessions, {...session}]
        })
    }
  }

  let beginTest = (session = false) => {
    if(state.selectedSessions.length){
        let finalArray = [];
        state.selectedSessions.forEach(sessn => {
            sessn.cardsArray.forEach(card => {
                finalArray.push(card)
            })
        })
        setState({
            ...state,
            sessionBegun: true,
            finalArray
        }) 
    }
    else{  
        setState({
            ...state,
            sessionBegun: true,
            finalArray: state.allTestsArray
        }) 
    }
    
  };

  
  return (
    <div className="tests-page-container">
      <div className="sections-container">
        {!state.sessionBegun ? (
          <div className="tests-sec1 test-button">
            <h1> <span className="words-due">{state.wordsDue}</span> Words Due</h1>
            <button className="test-all-btn" onClick={() => beginTest()}>
              Test All
            </button>
            <div className="test-selection-count">
                <span>OR</span>
                <h2>
                   Select Your Test Sessions 
                </h2>
                <button className="btn" onClick={() => beginTest()}>Begin</button>
                <div>
                    {state.selectedSessions.length} sessions selected
                </div>
            </div>

            <div className="test-session-container">
              {state.allTestsArray.map(session => {
                return <TestBox addAndDeleteSessions={addAndDeleteSessions} session={session} />;
              })}
            </div>
          </div>
        ) : (
          <TestComponent testsArray={state.finalArray} />
        )}
      </div>
    </div>
  );
};

export default TestsPage;
