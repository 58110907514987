import React, {
    useState,
    useEffect,
    useMemo,
    createContext,
    useContext,
  } from "react";
  import { useHistory, Link, useParams } from "react-router-dom";
  import "./TestTemplate.css";
  import BasicCard from './BasicCard'

const CardYard = ({cards, }) => { 

    let cardStyles = {}
    
    return (<div className="card-yard-container" style={{}}>
      
      {cards.map(card => {
        return <BasicCard card={card} />
      })}
    
    </div>);
  
  
  }

export default CardYard