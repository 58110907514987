import React, { useState, useEffect, useMemo, useParams } from "react";
import { Link } from 'react-router-dom';
import "./CustomDecks.css";
import createDeckAudio from "../helpers/createDeckAudio";

var decksObject = {
  ar: { dictionary: {}, sessions: [] },
  sp: { dictionary: {}, sessions: [] },
  customDecks: [],
};
/* 

var sessionsObject = {"sessionID":2,"inProgress":true,"cardsArray":[],"startDate":1617491945758,"nextTestDate":1617535145758,"testCount":0}

*/

var defaultUser = {
  decks: {
    customDecks: {},
    nativeDecks: {},
  },
};

var defaultEditorText = `{\n"deckName": "",\n"sessions": {"list": []},\n"dictionary": {},\n"deckArray": [],\n"isLanguage": true,\n} `;

const CustomDecks = (props) => {
  const [state, setState] = useState({
    customDecks: [],
    sessionsArray: [],
    cardsShown: "",
    flipped: false,
  });

  useEffect(() => {
    
  });

  useMemo(() => {
    
    console.log('P')
    let user = JSON.parse(localStorage.getItem("user"))  || {
      decks: { customDecks: {}, nativeDecks: {} },
    };
    let { decks } = user
    

    setState({
      customDecks:
        Object.keys(decks.customDecks).map((key) => decks.customDecks[key]) || [],
      user
    });
  }, []);

  const generateDeckAudio =async (wordArray, deckName) => {
    let deckArray = await createDeckAudio(wordArray)
    let user = state.user
    user.decks.customDecks[deckName].deckArray = deckArray
    
    localStorage.setItem("user", JSON.stringify({...state.user, deckArray}))

  }
  const addDeck = (e) => {
    let editor = document.querySelector("#editor");
    let user = JSON.parse(localStorage.getItem("user")) || defaultUser;
    console.log(user);
    console.log(typeof JSON.parse(editor.value));
    let newDeck = JSON.parse(editor.value) ? JSON.parse(editor.value) : {};
    console.log(newDeck, !user.decks.customDecks[newDeck.deckName]);
    if (
      newDeck.deckName &&
      newDeck.dictionary &&
      !user.decks.customDecks[newDeck.deckName]
    ) {
      user.decks.customDecks[newDeck.deckName] = newDeck;

      localStorage.setItem("user", JSON.stringify({ ...user }));

      setState({
        ...state,
        customDecks: [...state.customDecks, newDeck],
      });

      editor.value = defaultEditorText;
    }
  };

  return (
    <div
      className={``}
      style={{
        height: "100vh",
        width: "100vw",
        background: "darkgrey",
        paddingTop: "60px",
      }}
    >
      <div className="centered-row">
        {state.customDecks.map((deck) => {
          console.log(deck)
          let {deckArray, sessions } = deck
          let studiedWords = 0;
          sessions.list.forEach(session => {
            studiedWords += session.cardsArray.length
          })
          return (
          <div
            className={`deck-icon`}  style={{}} 
          >
            {deck.deckName}
            <button>
              <Link to="/dashboard/practice/sentences">Study</Link>
            </button>
            <button onClick={()=> generateDeckAudio(Object.keys(deck.dictionary).map(word => deck.dictionary[word]), deck.deckName) }>
            Generate Deck Audio
            </button>
            <div>{`Total Words: ${deckArray.length}`}</div>
            <div>{`Total Studied: ${studiedWords}`}</div>
            <div>
              {deck.sessions.list.map(session => {
                let {cardsArray} = session

                return (
                  <div className="centered-row">
                    {cardsArray.map(card => {
                      return (
                      <div>{card.word}</div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
            <button >
               <Link to="/dashboard/practice/sentences?practice">Practice All</Link>
              
              </button>
          </div>
        )})}
      </div>
      <div className="centered-column">
        <div className="title-icon">Add Deck JSON</div>
        <textarea id="editor" style={{ height: "50%", width: "80%" }}>
          {`{\n"deckName": "",\n"sessions": {"list": []},\n"dictionary": {},\n"deckArray": [],\n"isLanguage": true,\n} `}
        </textarea>
        <div className="-button" onClick={() => addDeck()}>
          Add Deck
        </div>
      </div>
    </div>
  );
};

export default CustomDecks;
