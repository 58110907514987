import React, { useState, useEffect, useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import "./AddWordsPage.css";
const client = new WebSocket('ws://localhost:9898', ["soap", "wamp"]);

client.onopen = ()=> {
    console.log('CLIENT: Connected to the server.')
    client.send('CLIENT: This is the client!')

}

client.onmessage = (data) => {
    console.log("FROM SERVER:",data)
}

client.onerror = (data) => {
    console.log(data);
}


const AddWordsPage = ({}) =>{  
    let [state, setState] = useState({
        clicked: false,
        inputValue: ''
    })
   
    useMemo(()=> {

    })

    const write = (e) => {
        
        setState({
            ...state,
            inputValue: e.target.value
        })
        client.send(e.target.value)
    }


    return (
        <div className="add-words-container">
           
            <div className="word-create-container">
            Word Container
            <div className="src-output-container">
                <div className="input-container">
                    <input type="text" value={state.inputValue} onChange={write}/>
                </div>
                <div className="input-container">
                    <input type="text" />
                </div>
            </div>
            </div>
            <button className="test-button" onClick={write}>Add +</button>
        </div>
    )
}

export default AddWordsPage;