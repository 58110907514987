import React, { useState, useEffect } from "react";
import Icon from "antd/es/icon";
import "./QuizSentenceHolder.css";

export default ({sentences}) => {
    let [sentenceState, setSentenceState] = useState({
      hover: false
    });
    
    return (
      <div
        className="sentences"
        onMouseOut={() =>
          setSentenceState({ hover: !sentenceState.hover })
        }
        onMouseOver={() =>
          setSentenceState({ hover: !sentenceState.hover })
        }
      >
        {!sentenceState.hover
          ? sentences.native
          : sentences.translation}
          
      </div>
    );
  }