import React, { useState, useEffect, useMemo } from "react";
import "./TestButtons.css";
import Icon from "antd/lib/icon";

const TestButtons = ({
  index,
  currentCard,
  card = {},
  frontKey = "",
  backKey = "",
  frontMultiData = [],
  backMultiData = [],
  rotateCards
}) => {
  let [state, setState] = useState({
    current: "",
    card,
    currentCard
  });

  let remembered = () => {};
  useEffect(() => {
    setState({
      ...state,
      card,
      currentCard
    });
  }, []);

  useEffect(() => {

    window.document.addEventListener("keydown", e => {
      if (e.key === "ArrowLeft") {
        rotateCards("remembered")
      } else if (e.key === "ArrowRight") {
        rotateCards("forgotten")
      } else {
        console.log(e.key);
      }
    });
  }, [])
  

  return (
    <div
      className={`btn-container `}
    >
      <div className="cardBtn green" onClick={() => rotateCards("remembered")}>
        <Icon type="check" style={{ fill: "white" }} />
      </div>
      <div className="cardBtn red" onClick={() => rotateCards("forgotten")}>
        <Icon type="stop" style={{ fill: "white" }} />
      </div>
    </div>
  );
};

export default TestButtons;
