export const testFour = [
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are in charge of designing a number of Cloudformation templates for your organization. You need to ensure that no one can accidentally update the production based resources on the stack during a stack update. How can this be achieved in the most efficient way?",
    answers: [
      "A. Create tags for the resources and then create IAM policies to protect the resources.",
      "B. Use a Stack based policy to protect the production based resources.",
      "C. Use S3 bucket policies to protect the resources.",
      "D. Use MFA to protect the resources"
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are in charge of designing a Cloudformation template which deploys a LAMP stack. After deploying a stack , you see that the status of the stack is showing as CREATE_COMPLETE , but the apache server is still not up and running and is experiencing issues while starting up. You want to ensure that the stack creation only shows the status of CREATE_COMPLETE after all resources defined in the stack are up and running. How can you achieve this?\n\nChoose 2 answers from the options given below.",
    answers: [
      "A. Define a stack policy which defines that all underlying resources should be up and running before showing a status of CREATE_COMPLETE.",
      "B. Use lifecycle hooks to mark the completion of the creation and configuration of the underlying resource.",
      "C. Use the CreationPolicy to ensure it is associated with the EC2 Instance resource.",
      "D. Use the CFN helper scripts to signal once the resource configuration is complete."
    ],
    correctAnswer: "Answer – C and D",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "A big supermarket has maintained lots of applications in AWS developed with CloudFormation stacks. In the company, there are many development teams with various roles such as Developer, UI, QA, etc. Different roles should have different access to create, modify and delete those stacks. The DevOps team needs a centralized AWS tool to manage all these CloudFormation stacks as products. And the team can also manage the product provisioning by granting access to IAM users and groups. Which AWS tool should the team use?",
    answers: [
      "A. Trusted Advisor",
      "B. Systems Manager",
      "C. AWS Service Catalog",
      "D. CloudFormation"
    ],
    correctAnswer: "Correct Answer – C",
    explanation: "There are some key concepts in AWS Service Catalog:"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are Devops Engineer for a large organization. The company wants to start using Cloudformation templates to start building their resources in AWS. You are getting requirements for the templates from various departments, such as the networking, security, application etc. What is the best way to architect these Cloudformation templates.",
    answers: [
      "A. Use a single Cloudformation template, since this would reduce the maintenance overhead on the templates itself.",
      "B. Create separate logical templates, for example, a separate template for networking, security, application etc. Then nest the relevant templates.",
      "C. Consider using Elastic beanstalk to create your environments since Cloudformation is not built for such customization.",
      "D. Consider using Opsworks to create your environments since Cloudformation is not built for such customization."
    ],
    correctAnswer: "Answer - B",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "SDLC Automation",
    question:
      "You have created a DynamoDB table for an application that needs to support thousands of users. You need to ensure that each user can only access their own data in a particular table. Many users already have accounts with a third-party identity provider, such as Facebook, Google, or Login with Amazon. How would you implement this requirement?\n\nChoose 2 answers from the options given below.",
    answers: [
      "A. Create an IAM User for all users so that they can access the application.",
      "B. Use Web identity federation and register your application with a third-party identity provider such as Google, Amazon, or Facebook.",
      "C. Create an IAM role which has specific access to the DynamoDB table.",
      "D. Use a third-party identity provider such as Google, Facebook or Amazon so users can become an AWS IAM User with access to the application."
    ],
    correctAnswer: "Answer - B and C",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "SDLC Automation",
    question:
      "You are designing a cloudformation stack which involves the creation of a web server and a database server. You need to ensure that the web server in the stack gets created after the database server is created. How can you achieve this?",
    answers: [
      "A. Ensure that the database server is defined first and before the web server in the cloudformation template. The stack creation normally goes in order to create the resources.",
      "B. Ensure that the database server is defined as a child of the web server in the cloudformation template.",
      "C. Ensure that the web server is defined as a child of the database server in the cloudformation template.",
      "D. Use the DependsOn attribute to ensure that the database server is created before the web server."
    ],
    correctAnswer: "Answer – D",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are in charge of designing a number of Cloudformation templates for your organization. You are required to make changes to the stack resources every now and then based on the requirement. How can you check the impact of the change to resources in a cloudformation stack before deploying changes to the stack?",
    answers: [
      "A. There is no way to control this. You need to check for the impact beforehand.",
      "B. Use Cloudformation change sets to check for the impact to the changes.",
      "C. Use Cloudformation Stack Policies to check for the impact to the changes.",
      "D. Use Cloudformation Rolling Updates to check for the impact to the changes."
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Company ABC has started to use AWS Service Catalog to manage its CloudFormation stacks within the company. In Service Catalog, several portfolios have been created with relevant products configured. It is supposed to assign access to different users or teams for these different products. For example, for one important payment service, only DevOps team can create, modify or delete the product. In Service Catalog, how should you manage the user access?",
    answers: [
      "A. Make sure AWS Organization is created. Manage Organization Service Control Policies in Service Catalog to control the access.",
      "B. Assign AWS Cognito User Pools to manage the access to different products.",
      "C. In Service Catalog, use AWS CLI or console to configure ACL policies to manage user access.",
      "D. Assign permissions to IAM users, groups, and roles."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "For Service Catalog, it can grant user access to a portfolio which means that the user can browse the portfolio or launch the products in it. Check details in"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Which of the following are true with regard to Opsworks stack Instances?\n\nChoose 3 answers from the options given below.",
    answers: [
      "A. A stacks instances can be a combination of both Linux and Windows based operating systems.",
      "B. You can use EC2 Instances that were created outisde the boundary of Opswork.",
      "C. You can use instances running on your own hardware.",
      "D. You can start and stop instances manually."
    ],
    correctAnswer: "Answer - B,C and D",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You currently have a set of instances running on your Opswork stacks. You need to install security updates on these servers. What does AWS recommend in terms of how the security updates should be deployed?\n\nChoose 2 answers from the options given below.",
    answers: [
      "A. Create and start new instances to replace your current online instances. Then delete the current instances.",
      "B. Create a new Opswork stack with the new instances.",
      "C. On Linux-based instances in Chef 11.10 or older stacks, run the Update Dependencies stack command.",
      "D. Create a cloudformation template which can be used to replace the instances."
    ],
    correctAnswer: "Answer – A and C",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "SDLC Automation",
    question:
      "A team has an application which is a website. The team wants to test different versions of the website to the users without redirecting or changing the browser URL. In order to perform this kind of A/B testing, the team does not plan to modify any front-end or back-end code with additional logic. And it is supposed to put this logic to CDN network. What is the best place/service to implement the switching logic?",
    answers: [
      "A. Weighted routing at Route53",
      "B. Lambda@Edge",
      "C. Global accelerator at CloudFront",
      "D. Lambda and API Gateway"
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "Lambda@Edge can help perform A/B testing without the need to redirect or change the browser URL. For details, please refer to use cases of Lambda@Edge in"
  },
  {
    topic: "SDLC Automation",
    question:
      "The DevOps team is using Lambda@Edge to perform the A/B testing strategy for a Web application. By Lambda@Edge, logic can be added in the CloudFront CDN in order to choose which content to deliver without the need to touch the application code. Two versions of application contents can be easily tested and analyzed. Lambda functions can change CloudFront contents at below points:\n\n\n\nWhich one can be configured to add the logic to choose different versions of applications for A/B testing? (Select Three.)",
    answers: [
      "A. CloudFront cache",
      "B. Viewer request",
      "C. Origin request",
      "D. Origin response",
      "E. Viewer response"
    ],
    correctAnswer: "Correct Answer – B, C and D",
    explanation: "Refer to"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "A fintech startup has a successful web product which is built using Node.js on EC2 c4.xlarge instance with an Elastic Load Balancer. And its database is put into a RDS PostgreSQL m5.4xlarge instance. All the AWS services are built in AWS region ap-southeast-1. Your manager asked you how to design a disaster recovery system since the product did not have such a system yet. In order to determine the right services for the DR system, which questions should you ask your manager first? (Select TWO.)",
    answers: [
      "A. Which instance type of EC2 does it need?",
      "B. What is the budget for the disaster recovery system?",
      "C. What are the RTO and RPO for the product?",
      "D. Whether another engine type of RDS is required?",
      "E. Which type of Elastic Load Balancer does the product use?"
    ],
    correctAnswer: "Correct Answer – B, C",
    explanation: "Check the AWS disaster recovery whitepaper is"
  },
  {
    topic: "SDLC Automation",
    question:
      "You are creating a cloudformation templates which takes in a database password as a parameter. How can you ensure that the password is not visible when anybody tries to describes the stack",
    answers: [
      "A. Use the password attribute for the resource",
      "B. Use the NoEcho property for the parameter value",
      "C. Use the hidden property for the parameter value",
      "D. Set the hidden attribute for the Cloudformation resource."
    ],
    correctAnswer: "Answer - B",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are creating a Cloudformation template in which Userdata is going to be passed to underlying EC2 Instance. Which of the below functions is normally used to pass data to the UserData section in the Cloudformation template?",
    answers: [
      'A. "UserData": {    "Fn::Base64": {',
      'B. "UserData": {         "Fn::Ref": {',
      'C. "UserData": {         "Fn::GetAtt": {',
      'D. "UserData": {         "Fn::FindInMap": {'
    ],
    correctAnswer: "Answer – A",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "A big fintech company has provided services to customers such as banks and governments. One of the applications is built in AWS EC2 instance and RDS MySQL. Its Recovery time objective (RTO) and Recovery point objective (RPO) are 30 minutes. At the moment, it has used a warm standby disaster recovery strategy. Which AWS services can help achieve these RTO and RPO targets? (Select TWO.)",
    answers: [
      "A. Route53",
      "B. AWS Storage Gateway",
      "C. RDS MySQL Severless feature",
      "D. S3 Glacier",
      "E. Auto Scaling Group"
    ],
    correctAnswer: "Correct Answer – A, E",
    explanation:
      "There are several Disaster Recovery Scenarios with AWS according to"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "A startup IT company just migrated its most important web application from on-premises to AWS. There is a strong need to design a disaster recovery system in AWS as soon as possible otherwise any outage may result in a huge impact to the company’s reputation and cash flow. However the company is running out of budget and has to control any operation cost. Which AWS tools can help the company to control the cost while designing a disaster recovery system? (Select TWO.)",
    answers: [
      "A. Create more spot instances in the hot standby system.",
      "B. Use a suitable Auto Scaling Group to control the number of running instances.",
      "C. Use Trusted Advisor to monitor the EC2 instances that have low utilization rate in the standby system. Terminate those instances to save cost.",
      "D. Use a S3 lifecycle policy to move all stored data in S3 to Glacier after one day to lower down the cost.",
      "E. Configure an EBS Lifecycle Policy to delete old EBS snapshots."
    ],
    correctAnswer: "Correct Answer – B, E",
    explanation:
      "While considering the services to lower down the cost, it is also necessary to make sure that the system can recover as normal and meet the given RPO and RTO."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Which of the following can be used to debug the creation of Cloudformation stack resources? Choose 2 answers from the options below",
    answers: [
      "A. Use Cloudtrail to debug all the API call’s sent by the Cloudformation stack.",
      "B. Use the AWS CloudFormation console to view the status of your stack.",
      "C. See the logs in the /var/log directory for Linux instances",
      "D. Use AWSConfig to debug all the API call’s sent by the Cloudformation stack."
    ],
    correctAnswer: "Answer – B and C",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "SDLC Automation",
    question:
      "A company has developed a book reading application deployed in AWS. As the number of users grows, there is an increasing need to add a search feature for the application. The team needs to create this feature as soon as possible under an independent pipeline in AWS CodePipeline. In the new pipeline, the source stage is a CodeCommit repository which contains the JSON format data used for the search service. The build stage has used AWS CodeBuild to configure the data. What is the best way to implement the search feature in AWS?",
    answers: [
      "A. Use AWS CloudSearch to implement this search function. The build stage builds a CloudFormation template. Add another deploy stage to deploy the CloudFormation stack for the CloudSearch search domain.",
      "B. Use AWS EC2 to provision and manage a cluster of servers running SOLR applications for the search feature. The build stage is responsible for baking an AMI.",
      "C. Use AWS CloudSearch to implement this feature. The build stage uses AWS CLI to configure the data in the search domain of CloudSearch.",
      "D. Use AWS Lambda to manage an EC2 Elasticsearch application for the search feature. The build stage is responsible for building the Lambda function."
    ],
    correctAnswer: "Correct Answer – C",
    explanation:
      "In this question, it mentions that a solution needs to be implemented as soon as possible. AWS CloudSearch should be considered first as it is a fully managed AWS service and users can easily set up and manage a search solution for a website or application. Check"
  },
  {
    topic: "SDLC Automation",
    question:
      "A large company uses AWS CodeCommit to manage its source code. There are over a hundred of repositories and many teams are working on different projects. As a DevOps engineer, you need to allocate suitable access to different users. For example, development team should not access the repositories that contain sensitive data. How should you manage this?",
    answers: [
      "A. Tag repositories in AWS CodeCommit. Create policies in IAM that allow or deny actions on repositories based on the tags associated with repositories.",
      "B. In AWS CodeCommit console, create CodeCommit policies to IAM groups that allow or deny actions on repositories.",
      "C. Configure Git tags in AWS CodeCommit repositories. Create policies in IAM that allow or deny actions on repositories based on the Git tags.",
      "D. In IAM, for each IAM user, add an IAM policy that allows or denies actions on repositories based on repository names."
    ],
    correctAnswer: "Correct Answer – A",
    explanation:
      "In July 2019, CodeCommit has a new feature to support adding tags in its repositories. For details refer to https://aws.amazon.com/about-aws/whats-new/2019/07/aws-codecommit-now-supports-resource-tagging/."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your application is having a very high traffic, so you have enabled autoscaling in multi availability zone to suffice the needs of your application but you observe that one of the availability zone is not receiving any traffic. What can be wrong here?",
    answers: [
      "A. Autoscaling only works for single availability zone",
      "B. Autoscaling can be enabled for multi AZ only in north Virginia region",
      "C. Availability zone is not added to Elastic load balancer",
      "D. Instances need to manually added to availability zone"
    ],
    correctAnswer: "Answer – C",
    explanation:
      "When you add an Availability Zone to your load balancer, Elastic Load Balancing creates a load balancer node in the Availability Zone. Load balancer nodes accept traffic from clients and forward requests to the healthy registered instances in one or more Availability Zones."
  },
  {
    topic: "SDLC Automation",
    question:
      "You have just developed a new mobile application that handles analytics workloads on large scale datasets that are stored on Amazon Redshift. Consequently, the application needs to access Amazon Redshift tables. Which of the below methods would be the best, both practically and security-wise, to access the tables? Choose the correct answer from the options below",
    answers: [
      "A. Create an IAM user and generate encryption keys for that user. Create a policy for RedShift read-only access. Embed the keys in the application.",
      "B. Create a HSM client certificate in Redshift and authenticate using this certificate.",
      "C. Create a RedShift read-only access policy in IAM and embed those credentials in the application.",
      "D. Use roles that allow a web identity federated user to assume a role that allows access to the RedShift table by providing temporary credentials."
    ],
    correctAnswer: "Answer – D",
    explanation:
      "For access to any AWS service, the ideal approach for any application is to use Roles. This is the first preference. Hence option A and C are wrong."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "The company you work for has a huge amount of infrastructure built on AWS. However there has been some concerns recently about the security of this infrastructure, and an external auditor has been given the task of running a thorough check of all of your company's AWS assets. The auditor will be in the USA while your company's infrastructure resides in the Asia Pacific (Sydney) region on AWS. Initially, he needs to check all of your VPC assets, specifically, security groups and NACLs You have been assigned the task of providing the auditor with a login to be able to do this. Which of the following would be the best and most secure solution to provide the auditor with so he can begin his initial investigations? Choose the correct answer from the options below",
    answers: [
      "A. Create an IAM user tied to an administrator role. Also provide an additional level of security with MFA.",
      "B. Give him root access to your AWS Infrastructure, because he is an auditor he will need access to every service.",
      "C. Create an IAM user who will have read-only access to your AWS VPC infrastructure and provide the auditor with those credentials.",
      "D. Create an IAM user with full VPC access but set a condition that will not allow him to modify anything if the request is from any IP other than his own."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "Generally you should refrain from giving high level permissions and give only the required permissions. In this case option C fits well by just providing the relevant access which is required."
  },
  {
    topic: "SDLC Automation",
    question:
      "A big IT company started using AWS CodeCommit to manage its source code. There are hundreds of developers and new Git repositories are added in CodeCommit frequently. The DevOps team was told to design an approach to create a new repository by running a script and after the script has run, some init code such as template and README can be added into the repository automatically. Which approach is the best to be managed?",
    answers: [
      "A. Design a shell script using Git commands to create a new repository and submit the init code.",
      "B. Put the code into S3 bucket. Design a CloudFormation template to create a repository with the resource type AWS::CodeCommit::Repository. Add a property to include the code. Create a CloudFormation stack when needed.",
      "C. Design a CloudFormation template to create a repository with the resource type AWS::CodeCommit::Repository. Design a Lambda function to commit the initial code to the new repository.",
      "D. CodeCommit does not support this. Use GitHub instead."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "AWS CodeCommit supports including code when creating a repository with AWS CloudFormation. Check the document in"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You work for a startup that has developed a new photo-sharing application for mobile devices. Over recent months your application has increased in popularity; this has resulted in a decrease in the performance of the application due to the increased load. Your application has a two-tier architecture that is composed of an Auto Scaling PHP application tier and a MySQL RDS instance initially deployed with AWS CloudFormation. Your Auto Scaling group has a min value of 4 and a max value of 8. The desired capacity is now at 8 due to the high CPU utilization of the instances. After some analysis, you are confident that the performance issues stem from a constraint in CPU capacity, while memory utilization remains low. You therefore decide to move from the general-purpose M3 instances to the compute-optimized C3 instances.\n\nHow would you deploy this change while minimizing any interruption to your end users?",
    answers: [
      "A. Sign into the AWS Management Console, copy the old launch configuration, and create a new launch configuration that specifies the C3 instances. Update the AutoScaling group with the new launch configuration. Auto Scaling will then update the instance type of all running instances",
      "B. Sign into the AWS Management Console and update the existing launch configuration with the new C3 instance type. Add an UpdatePolicy attribute to your AutoScaling group that specifies an AutoScaling RollingUpdate.",
      "C. Update the launch configuration specified in the AWS CloudFormation template with the new C3 instance type. Run a stack update with the new template. Auto Scaling will then update the instances with the new instance type.",
      "D. Update the launch configuration specified in the AWS CloudFormation template with the new C3 instance type. Also add an UpdatePolicy attribute to your Auto Scalinggroup that specifies an AutoScalingRollingUpdate. Run a stack update with the new template"
    ],
    correctAnswer: "Answer – D",
    explanation: "The AWS Documentation mentions the below"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have implemented a system to automate deployments of your configuration and application dynamically after an Amazon EC2 instance in an Auto Scaling group is launched. Your system uses a configuration management tool that works in a standalone configuration, where there is no master node. Due to the volatility of application load, new instances must be brought into service within three minutes of the launch of the instance operating system. The deployment stages take the following times to complete:\n\n1) Installing configuration management agent: 2mins\n\n2) Configuring instance using artifacts: 4mins\n\n3) Installing application framework: 15mins\n\n4)  Deploying application code: 1min\n\nWhat process should you use to automate the deployment using this type of standalone agent\n\nconfiguration?",
    answers: [
      "A. Configure your Auto Scaling launch configuration with an Amazon EC2 UserData script to install the agent, pull configuration artifacts and application code from an Amazon S3 bucket, and then execute the agent to configure the infrastructure and application.",
      "B. Build a custom Amazon Machine Image that includes all components pre-installed, including an agent, configuration artifacts, application frameworks, and code. Create a startup script that executes the agent to configure the system onstartup.",
      "C. Build a custom Amazon Machine Image that includes the configuration management agent and application framework pre-installed. Configure your Auto Scaling launch configuration with an Amazon EC2 UserData script to pull configuration artifacts and application code from an Amazon S3 bucket, and then execute the agent to configure the system.",
      "D. Create a web service that polls the Amazon EC2 API to check for new instances that are launched in an Auto Scaling group. When it recognizes a new instance, execute a remote script via SSH to install the agent, SCP the configuration artifacts and application code, and finally execute the agent to configure the system"
    ],
    correctAnswer: "Answer – B",
    explanation:
      "Since the new instances need to be brought up in 3 minutes , hence the best option is to pre-bake all the components into an AMI. If you try to user the User Data option , it will just take time , based on the time mentioned in the question to install and configure the various components."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "As part of your continuous deployment process, your application undergoes an I/O load performance test before it is deployed to production using new AMIs. The application uses one Amazon EBS PIOPS volume per instance and requires consistent I/O performance.\n\nWhich of the following must be carried out to ensure that I/O load performance tests yield the correct results in a repeatable manner?",
    answers: [
      "A. Ensure that the I/O block sizes for the test are randomly selected.",
      "B. Ensure that the Amazon EBS volumes have been pre-warmed by reading all the blocks before the test.",
      "C. Ensure that snapshots of the Amazon EBS volumes are created as a backup.",
      "D. Ensure that the Amazon EBS volume is encrypted."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "Always ensure that the volume is pre-warmed before it is set for the load test."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your team is responsible for an AWS Elastic Beanstalk application. The business requires that you move to a continuous deployment model, releasing updates to the application multiple times per day with zero downtime. What should you do to enable this and still be able to roll back almost immediately in an emergency to the previous version?",
    answers: [
      "A. Enable rolling updates in the Elastic Beanstalk environment, setting an appropriate pause time for application startup.",
      "B. Create a second Elastic Beanstalk environment running the new application version, and swap the environment CNAMEs.",
      "C. Develop the application to poll for a new application version in your code repository; download and install to each running Elastic Beanstalk instance.",
      "D. Create a second Elastic Beanstalk environment with the new application version, and configure the old environment to redirect clients, using the HTTP 301 response code, to the new environment"
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS Documentation mentions the below"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Your current log analysis application takes more than four hours to generate a report of the top 10 users of your web application. You have been asked to implement a system that can report this information in real time and also ensure that the report is always up to date. Choose the option that is cost-effective and can fulfill the requirements.",
    answers: [
      "A. Publish your data to CloudWatch Logs, and configure your application to autoscale to handle the load on demand.",
      "B. Publish your log data to an Amazon S3 bucket. Use AWS CloudFormation to create an AutoScaling group to scale your post-processing application which is configured to pull down your log files stored in Amazon S3.",
      "C. Post your log data to an Amazon Kinesis data stream, and subscribe your log-processing application so that is configured to process your logging data.",
      "D. Configure an Auto Scaling group to increase the size of your Amazon EMR cluster"
    ],
    correctAnswer: "Answer – C",
    explanation: "The AWS Documentation mentions the below"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Your social media marketing application has a component written in Ruby running on AWS Elastic Beanstalk. This application component posts messages to social media sites in support of various marketing campaigns. Your management now requires you to record replies to these social media messages to analyze the effectiveness of the marketing campaign in comparison to past and future efforts. You’ve already developed a new application component to interface with the social media site APIs in order to read the replies. Which process should you use to record the social media replies in a durable data store that can be accessed at any time for analytics of historical data?",
    answers: [
      "A. Deploy the new application component in an Auto Scaling group of Amazon EC2 instances, read the data from the social media sites, store it with Amazon Elastic Block Store, and use AWS Data Pipeline to publish it to Amazon Kinesis for analytics.",
      "B. Deploy the new application component as an Elastic Beanstalk application, read the data from the social media sites, store it and process it in DynamoDB, and use Apache Hive with Amazon Elastic MapReduce for analytics.",
      "C. Deploy the new application component in an Auto Scaling group of Amazon EC2 instances, read the data from the social media sites, store it in Amazon Glacier, and use AWS Data Pipeline to publish it to Amazon RedShift for analytics.",
      "D. Deploy the new application component as an Amazon Elastic Beanstalk application, read the data from the social media site, store it with Amazon Elastic Block store,and use Amazon Kinesis to stream the data to Amazon CloudWatch for analytics"
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS Documentation mentions the below"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are managing an application that acts as the front end and you use MongoDB for document management which is hosted on a relevant Web server. You pre-bake AMI's with the latest version of the Web server, then used the User Data section to setup the application. You now have a change to the underlying Operating system version and need to deploy that accordingly. How can this be done in the easiest way possible ?",
    answers: [
      "A. Create a new EBS Volume with the relevant OS patches and attach it to the EC2 Instance.",
      "B. Create a Cloudformation stack with the new AMI and then deploy the application accordingly.",
      "C. Create a new pre-baked AMI with the new OS and use the User Data section to deploy the application.",
      "D. Create an Opswork stack with the new AMI and then deploy the application accordingly."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "The best way in this scenario is to continue the same deployment process which was being used and create a new AMI and then use the User Data section to deploy the application."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You are managing the development of an application that uses DynamoDB to store JSON data. You have already set the Read and Write capacity of the DynamoDB table. You are unsure of the amount of the traffic that will be received by the application during the deployment time. How can you ensure that the DynamoDB is not highly throttled and does not become a bottleneck for the application? Choose 2 answers from the options below.",
    answers: [
      "A. Monitor the ConsumedReadCapacityUnits and ConsumedWriteCapacityUnits metric using Cloudwatch.",
      "B. Monitor the SystemErrors metric using Cloudwatch",
      "C. Create a Cloudwatch alarm which would then send a trigger to AWS Lambda to increase the Read and Write capacity of the DynamoDB table.",
      "D. Create a Cloudwatch alarm which would then send a trigger to AWS Lambda to create a new DynamoDB table."
    ],
    correctAnswer: "Answer – A and C",
    explanation:
      "Refer to the following AWS Documentation that specifies what should be monitored for a DynamoDB table."
  },
  {
    topic: "SDLC Automation",
    question:
      "The DevOps team is evaluating AWS CodeBuild to see if it is suitable for the new applications which are under development. In order to build artifacts for these applications, different images or environments are required. In AWS CodeBuild, environment image needs to be selected in which the build project will run. Which environment image is a valid one for CodeBuild? (Select TWO.)",
    answers: [
      "A. A RedHat Linux image managed by AWS CodeBuild.",
      "B. A Suse Linux image managed by the user.",
      "C. A Windows server image managed by AWS CodeBuild.",
      "D. A Centos Linux image from an AMI created by the user.",
      "E. A custom docker image which is hosted in an external Docker registry."
    ],
    correctAnswer: "Correct Answer – C, E",
    explanation:
      "Check https://docs.aws.amazon.com/codebuild/latest/userguide/build-env-ref.html"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are using Elastic Beanstalk for your development team. You are responsible for deploying multiple versions of your application. How can you ensure, in an ideal way, that you don’t cross the application version limit in Elastic beanstalk?",
    answers: [
      "A. Create a lambda function to delete the older versions.",
      "B. Create a script to delete the older versions.",
      "C. Use AWSConfig to delete the older versions",
      "D. Use lifecyle policies in Elastic beanstalk"
    ],
    correctAnswer: "Answer - D",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "When deploying applications to Elastic Beanstalk , which of the following statements is false with regards to application deployment",
    answers: [
      "A. The application can be bundled in a zip file",
      "B. Can include parent directories",
      "C. Should not exceed 512 MB in size",
      "D. Can be a war file which can be deployed to the application server"
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "SDLC Automation",
    question:
      "Your organization owns several AWS accounts. The AWS operation team creates several base docker images in AWS ECR. Another development team is working on a new project in which the build phase needs to use AWS CodeBuild to build artifacts. One requirement is that the environment image of CodeBuild must use an ECR docker image owned by the operation team. However, the ECR docker image is located in different AWS account. How would you resolve this and create the CodeBuild project?",
    answers: [
      "A. Select AWS managed Ubuntu image. In the image, pull the ECR docker image from another account.",
      "B. CodeBuild does not support cross account ECR image. Copy the image to the ECR registry in the same account first.",
      "C. Select custom image and choose ECR image registry. Enter the full ECR repository URI for the repository in the other account.",
      "D. Select custom image and choose Other registry for an external Docker registry. In external registry URI, enter the ECR repository URI of the other account."
    ],
    correctAnswer: "Correct Answer – C",
    explanation:
      "AWS CodeBuild supports accessing cross-account ECR images according to"
  },
  {
    topic: "Incident and Event Response",
    question:
      "Company ABC used AWS CodeDeploy to deploy one web application to AWS services including EC2. CodeDeploy is running well however your manager wants to get in time notifications in Slack channel when a new deployment has succeeded or failed. You were told to implement this in AWS CloudWatch Events. The target of the Event rule would be a Lambda function that applies only to deployments. Which event pattern is correct for the rule? ",
    answers: [
      'A. {\n\n  "source": [\n\n    "aws.codedeploy"\n\n  ],\n\n  "detail-type": [\n\n    "CodeDeploy Deployment State-change Notification"\n\n  ],\n\n  "detail": {\n\n    "state": [\n\n      "FAILURE",\n\n      "SUCCESS"\n\n    ]\n\n  }\n\n}',
      'B. {\n\n  "source": [\n\n    "aws.codedeploy"\n\n  ],\n\n  "detail-type": [\n\n    "CodeDeploy Instance State-change Notification"\n\n  ],\n\n  "detail": {\n\n    "state": [\n\n      "FAILURE",\n\n      "SUCCESS",\n\n      "READY",\n\n      "START"\n\n    ]\n\n  }\n\n}',
      'C. {\n\n  "source": [\n\n    "aws.codedeploy"\n\n  ],\n\n  "detail-type": [\n\n    "AWS API Call via CloudTrail"\n\n  ],\n\n  "detail": {\n\n    "state": [\n\n      "FAILURE",\n\n      "SUCCESS"\n\n    ]\n\n  }\n\n}',
      'D. {\n\n  "source": [\n\n    "aws.codedeploy"\n\n  ],\n\n  "detail-type": [\n\n    "CodeDeploy Instance State-change Notification"\n\n  ],\n\n  "detail": {\n\n    "state": [\n\n      "FAILURE",\n\n      "START"\n\n    ]\n\n  }\n\n}'
    ],
    correctAnswer: "Correct Answer – A",
    explanation:
      "CloudWatch Events rule is great to help monitor the status of CodeDeploy. Details can be found in"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Your application has an Auto Scaling group of three EC2 instances behind an Elastic Load Balancer. Your Auto Scaling group was updated with a new launch configuration that refers to an updated AMI. During the deployment, customers complained that they were receiving several errors even though all instances passed the ELB health checks. How can you prevent this from happening again?",
    answers: [
      "A. Create a new ELB and attach the Autoscaling Group to the ELB",
      "B. Create a new launch configuration with the updated AMI and associate it with the AutoScaling group. Increase the size of the group to six and when instances become healthy revert to three.",
      "C. Manually terminate the instances with the older launch configuration.",
      "D. Update the launch configuration instead of updating the Autoscaling Group"
    ],
    correctAnswer: "Answer - B",
    explanation:
      "An Auto Scaling group is associated with one launch configuration at a time, and you can't modify a launch configuration after you've created it. To change the launch configuration for an Auto Scaling group, you can use an existing launch configuration as the basis for a new launch configuration and then update the Auto Scaling group to use the new launch configuration."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You are a Devops Engineer for your company. You are in charge of an application that uses EC2, ELB and Autoscaling. You have been requested to get the ELB access logs. When you try to access the logs , you can see that nothing has been recorded in S3. Why is this the case?",
    answers: [
      "A. You don’t have the necessary access to the logs generated by ELB.",
      "B. By default ELB access logs are disabled.",
      "C. The Autoscaling service is not sending the required logs to ELB",
      "D. The EC2 Instances are not sending the required logs to ELB"
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "SDLC Automation",
    question:
      "Your team has created a new AWS CodeDeploy application to automate deployments to Amazon EC2 instances under an autoscaling group. It is possible that a deployment activity has failed due to software issues. Your manager asked you to configure automatic rollback for the deployment group in the production environment. In terms of the automatic rollback configuration of CodeDeploy, which statement is correct?",
    answers: [
      "A. Users can configure SNS notifications for deployment activities. Rollback can be triggered whenever SNS topics receive notifications.",
      "B. There is no automatic rollback for CodeDeploy deployment group. However, users can trigger rollback manually.",
      "C. Automatic rollback can only be configured for EC2 instances. On-premises instances do not have this feature.",
      "D. Automatic rollback can be triggered when alarm thresholds are met."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "Check https://docs.aws.amazon.com/codedeploy/latest/userguide/deployments-rollback-and-redeploy.html"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You are a Devops Engineer for your company. You are planning on using Cloudwatch for monitoring the resources hosted in AWS. Which of the following can you do with Cloudwatch logs ideally. Choose 3 answers from the options given below",
    answers: [
      "A. Stream the log data to Amazon Kinesis for further processing",
      "B. Send the log data to AWS Lambda for custom processing",
      "C. Stream the log data into Amazon Elasticsearch for any search analysis required.",
      "D. Send the data to SQS for further processing."
    ],
    correctAnswer: "Answer - A,B and C",
    explanation:
      "Amazon Kinesis can be used for rapid and continuous data intake and aggregation. The type of data used includes IT infrastructure log data, application logs, social media, market data feeds, and web clickstream data"
  },
  {
    topic: "SDLC Automation",
    question:
      "Your company has used on-premises Jenkins server as the CI/CD tool for several years. Recently, as most of its products already migrated to AWS platform. The DevOps team is considering the possibilities to use AWS CodePipeline as the new tool to replace Jenkins server. You were told to make an initial analysis and report to your manager. What are the advantages of CodePipeline when compared to Jenkins server? (Select TWO.)",
    answers: [
      "A. Similar to Jenkins, CodePipeline also provides a large number of plugins in AWS marketplace.",
      "B. AWS CodePipeline integrates well with other AWS services such as CodeCommit and CodeBuild. It is easily configured for users of the AWS ecosystem.",
      "C. AWS CodePipeline is open-sourced just as Jenkins so that users can contribute to the community.",
      "D. IAM policies can be configured to control the access to CodePipeline resources.",
      "E. AWS CodePipeline is totally free and you only need to pay related resources generated in the pipeline such as EC2."
    ],
    correctAnswer: "Correct Answer – B, D",
    explanation:
      "\n\tOption A is incorrect: Unlike Jenkins, CodePipeline is a service managed by AWS. It does not have plugins in AWS marketplace.\n\tOption B is CORRECT: Because CodePipeline integrates with various tools in different stages. Details can be found in\n"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "You are running a multi-tier architecture on AWS with webserver instances running Nginx. Your users are getting errors when they use the web application. How can you diagnose the errors quickly and efficiently?",
    answers: [
      "A. Install the CloudWatch Logs agent and send Nginx access log data to CloudWatch. From there, pipe the log data through to a third party logging and graphing tool.",
      "B. Install the CloudWatch Logs agent and send Nginx access log data to CloudWatch. Then, filter the log streams for searching the relevant errors.",
      "C. Send all the errors to AWS Lambda for processing.",
      "D. Send all the errors to AWS Config for processing"
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You have configured the following AWS services in your organization - Auto Scaling group, Elastic Load Balancer, and EC2 instances. You have been requested to terminate an instance from the Autoscaling Group when the CPU utilization is less than 30%. How can you achieve this.",
    answers: [
      "A. Create a CloudWatch alarm to send a notification to SQS. SQS can then remove one instance from the Autoscaling Group.",
      "B. Create a CloudWatch alarm to send a notification to the Auto Scaling group when the aggregated CPU utilization is less than 30% and configure the Auto Scaling policy to remove one instance.",
      "C. Create a CloudWatch alarm to send a notification to the ELB. The ELB can then remove one instance from the Autoscaling Group.",
      "D. Create a CloudWatch alarm to send a notification to the admin team. The admin team can then manually terminate an instance from the Autoscaling Group."
    ],
    correctAnswer: "Answer - B",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "Which of the following is a reliable and durable logging solution to track changes made to your AWS resources?",
    answers: [
      "A. Create a new CloudTrail trail with one new S3 bucket to store the logs and with the global services option selected. Use IAM roles,  S3 bucket policies and Multi Factor Authentication (MFA) Delete on the S3 bucket that stores your logs.",
      "B. Create a new CloudTrail with one new S3 bucket to store the logs. Configure SNS to send log file delivery notifications to your management system. Use IAM roles and S3 bucket policies on the S3 bucket that stores your logs.",
      "C. Create a new CloudTrail trail with an existing S3 bucket to store the logs and with the global services option selected. Use S3 ACLs and Multi Factor Authentication (MFA) Delete on the S3 bucket that stores your logs.",
      "D. Create three new CloudTrail trails with three new S3 buckets to store the logs one for the AWS Management console, one for AWS SDKs and one for command line tools. Use IAM roles and S3 bucket policies on the S3 buckets that store your logs."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "AWS Identity and Access Management (IAM) is integrated with AWS CloudTrail, a service that logs AWS events made by or on behalf of your AWS account. CloudTrail logs authenticated AWS API calls and also AWS sign-in events, and collects this event information in files that are delivered to Amazon S3 buckets. You need to ensure that all services are included. Hence option B is partially correct."
  },
  {
    topic: "SDLC Automation",
    question:
      "You were assigned a new task to create a pipeline in AWS CodePipeline service for a new Android App. In the pipeline, the source stage is GitHub and the build stage uses CodeBuild to build the App. During the build stage, it uses a buildspec file to generate artifacts which are stored in a S3 bucket. Another stage needs to be added in order to test the new version of App in AWS Device Farm. A test project in AWS Device Farm was already created by QA team. How should you configure this new stage in AWS CodePipeline?",
    answers: [
      "A. Add a new deploy stage and add AWS CloudFormation as the action provider. The CloudFormation stack will create and initiate the AWS Device Farm project.",
      "B. Add a new test stage and add AWS Device Farm as the action provider. Configure the AWS Device Farm project ID and device pool ARN in the stage.",
      "C. Add a new stage and add an action provider using Lambda function. The Lambda function is responsible to trigger the AWS Device Farm project.",
      "D. Add a new stage and add an SNS topic as the action provider. The SNS topic will trigger the AWS Device Farm project to execute the test."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "About how to configure a stage to run the test in AWS Device Farm, please check the tutorial in"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Which of the following is not a supported platform for the Elastic beanstalk service",
    answers: ["A. Java", "B. AngularJS", "C. PHP", "D. .Net"],
    correctAnswer: "Answer - B",
    explanation: "Below are the supported platforms for Elastic beanstalk"
  },
  {
    topic: "SDLC Automation",
    question:
      "A team is developing a new web application which will be deployed in AWS EC2. Within the company, Atlassian JIRA Software is commonly used to manage many existing projects. For this new project, the team lead expected to manage using a unified project management dashboard where there is information such as JIRA stories/issues, continuous deployment status, application endpoints, etc. What is the best way to achieve this requirement?",
    answers: [
      "A. Use CloudFormation stack to create CloudWatch dashboards to manage the project including the JIRA URL.",
      "B. Use AWS CodePipeline to manage the source, build and deployment. Add a stage with the action provider as Jenkins. In the Jenkins server, configure a JIRA plugin to integrate with Atlassian JIRA.",
      "C. Create an AWS CodeStar project. Configure the connection to JIRA in the CodeStar project. Customize the project dashboard as required.",
      "D. Create an AWS CodeStar project. Customize the project dashboard as required with a link to the JIRA server URL."
    ],
    correctAnswer: "Correct Answer – C",
    explanation:
      "AWS CodeStar has provided the integration with Atlassian JIRA according to"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are a Devops engineer for your company.There is a requirement to host a custom application which has custom dependencies for a development team. This needs to be done using AWS service. Which of the following is the ideal way to fulfil this requirement.",
    answers: [
      "A. Package the application and dependencies with Docker, and deploy the Docker container with CloudFormation.",
      "B. Package the application and dependencies with Docker, and deploy the Docker container with Elastic Beanstalk.",
      "C. Package the application and dependencies in an S3 file, and deploy the Docker container with Elastic Beanstalk.",
      "D. Package the application and dependencies with in Elastic Beanstalk, and deploy with Elastic Beanstalk"
    ],
    correctAnswer: "Answer - B",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "SDLC Automation",
    question:
      "Your team uses AWS CodeStar to manage a Java project. The source code is put in AWS CodeCommit. The build stage is managed by AWS CodeBuild and then the deployment stage uses CloudFormation to deploy a stack including resources such as Lambda function. The team lead has added several team members into the CodeStar project including Jason (Owner), Tony (Viewer) and Eric (Contributor). Different roles should have different permissions in the project. How are the permissions managed in CodeStar?",
    answers: [
      "A. For different team members, users need to create appropriate IAM policies first and then assign these policies to the IAM users.",
      "B. Users need to create IAM service roles with suitable IAM policies. Then assign these service roles to different team members in CodeStar depending on their roles.",
      "C. In CodeStar console, configure the team member roles by assigning different read and write permissions to stages such as build or deploy.",
      "D. Different team member roles have relevant IAM policies allocated automatically by AWS. CodeStar users only need to make sure the correct roles are assigned to team members."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "AWS CodeStar is able to automatically create customer managed IAM policies on your behalf when a project is created. These policies are used to manage access levels for team members in CodeStar project. Details can be found in https://docs.aws.amazon.com/codestar/latest/userguide/adh-policy-examples.html#adh-policy-service-role."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "A large corporation owns a huge amount of data which is located in AWS S3. There are applications that keep reading or writing data in these S3 buckets. The security auditor was worried that there may be some sensitive data that was exposed in S3. For example, certain applications may store some text files which contain customers’ PII information. The auditor asked for a solution to quickly scan potential security related issues in these S3 buckets. Which solution is the best?",
    answers: [
      "A. Configure AWS Inspector in S3. It is able to use machine learning to search for security issues in S3 and provide CloudWatch alarms to the admin users.",
      "B. Enable Amazon Macie as it can scan security issues in S3 and generate alerts based on the level of risks.",
      "C. Enable AWS GuardDuty as it can analyze all the application data in S3 and generate security findings.",
      "D. Configure Amazon Athena in S3 and create Athena SQL tables. Query security issues by using SQL commands."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "When talking about PII security issues in S3, the first service to be considered should be Amazon Macie. Check out"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You just joined an IT security team in a large financial company. Your company is a heavy user of AWS and owns a large number of AWS resources including EC2, S3, RDS, etc. In order to monitor the services from security perspective, your team is required to create a continuous monitoring service in AWS. For example, the service should be able to identify potential risks if an EC2 instance is compromised and is used to perform a Denial of Service (DoS) attack using UDP protocol. Which approach should you take?",
    answers: [
      "A. Use AWS GuardDuty to continuously monitor AWS services. GuardDuty can detect security issues and generate findings in console or through Amazon CloudWatch events.",
      "B. Enable AWS Macie to continuously scan AWS security risks in resources such as EC2. It can identify potential issues and provide alarms such as if an EC2 instance is compromised.",
      "C. Enable AWS Enterprise support plan and activate full features of Trusted Advisor which can quickly provide alarms for security related issue.",
      "D. Activate VPC Flow Logs, AWS CloudTrail event logs, and DNS logs and transfer the logs to a dedicated S3 bucket. Configure Athena to query the logs to identify potential security problems."
    ],
    correctAnswer: "Correct Answer – A",
    explanation: "According to"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You have defined a Linux based instance stack in Opswork. You now want to attach a database to the Opswork stack. Which of the below is an important step to ensure that the application on the Linux instances can communicate with the database",
    answers: [
      "A. Add another stack with the database layer and attach it to the application stack.",
      "B. Configure SSL so that the instance can communicate with the database",
      "C. Add the appropriate driver packages to ensure the application can work with the database",
      "D. Configure database tags for the Opswork application layer"
    ],
    correctAnswer: "Answer – C",
    explanation: "The AWS documentation mentions the below point"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You have an Opswork stack defined with Linux instances. You have executed a recipe , but the execution has failed. What is one of the ways that you can use to diagnose what was the reason why the recipe did not execute correctly.",
    answers: [
      "A. Use AWS Cloudtrail and check the Opswork logs to diagnose the error",
      "B. Use AWS Config and check the Opswork logs to diagnose the error",
      "C. Log into the instance and check if the recipe was properly configured.",
      "D. Deregister the instance and check the EC2 Logs"
    ],
    correctAnswer: "Answer - C",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "A financial company recently encountered an IT security incident for one of its AWS EC2 instances. An attacker used Kali Linux penetration testing tool to scan the company’s EC2 resources, found an EC2 configuration weaknesses and then gained unauthorized access. You need to work out a plan to make sure that all EC2 instances always meet patch compliance. A monitoring tool is also required for these kinds of potential security risks. Which approaches should you take in together to meet the requirements? (Select TWO.)",
    answers: [
      "A. Use AWS Systems Manager Run Command to apply necessary patches every 30 days to ensure all EC2 instances are always patch compliant.",
      "B. Configure patch baselines in AWS Systems Manager and use Patch Manager to apply patches in a maintenance window.",
      "C. Configure AWS Macie to continuously monitor security issues for AWS resources. Configure SNS notifications based on Macie alarms in CloudWatch Events.",
      "D. Configure monitoring dashboard in AWS QuickSight which uses machine learning skills to discover security incidents that are happening.",
      "E. Enable AWS GuardDuty to monitor potential security incidents. Create CloudWatch Event rules based on the findings and trigger SNS notifications."
    ],
    correctAnswer: "Correct Answer – B, E",
    explanation:
      "\n\tOption A is incorrect: To meet patch compliance, the best tool is Patch Manager rather than Run Command. Option B should be chozen.\n\tOption B is CORRECT: Because Patch Manager can easily apply necessary patches in EC2 instances according to established patch baselines.\n\tOption C is incorrect: AWS Macie is a tool to scan security issues in S3 only. It can not discover issues mentioned in the question.\n\tOption D is incorrect: Because QuickSight is a tool to create and publish interactive dashboards which can not monitor security risks.\n\tOption E is CORRECT: Because AWS GuardDuty is appropriate to monitor the mentioned security issues. PenTest:IAMUser/KaliLinux is also a finding type of GuardDuty. GuardDuty will report this risk when a machine running Kali Linux is making API calls using credentials of your AWS account.\n"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You were assigned a task to create a security monitoring dashboard in AWS. The dashboard should be able to identify whether EC2 instances are exposed to common vulnerabilities and exposures (CVEs). For example, if an EC2 instance does not install certain patch and is exposed to a known CVE, this incident should be discovered. Which approach is the best one to implement this?",
    answers: [
      "A. Enable AWS GuardDuty and include CVE rule package in the GuardDuty template. Monitor CVE findings in the console.",
      "B. In AWS Systems Manager, include CVE patches in patch baselines. Use patch manager to apply system patches to all EC2 instances.",
      "C. Enable AWS Inspector and make sure all EC2 instances have the Inspector agents installed properly. Include CVE rule package in the assessment template.",
      "D. Configure AWS Macie and include CVE rule package in the assessment template."
    ],
    correctAnswer: "Correct Answer – C",
    explanation:
      "Common vulnerabilities and exposures (CVEs) belong to one of the rule packages that AWS Inspector can configure. Refer to https://docs.aws.amazon.com/inspector/latest/userguide/inspector_cves.html."
  },
  {
    topic: "SDLC Automation",
    question:
      "Your company uses AWS platform to host most of its applications and services. As an AWS administrator, you have managed a large number of AWS resources to meet the business needs. Sometimes, you have to create, update or delete similar resources in many AWS regions and accounts. For example, you need to configure an AWS Config rule to determine if CloudTrail was enabled in all accounts. Which way is the best for you to implement such kind of configurations across multiple regions or accounts in a single operation?",
    answers: [
      "A. Prepare CloudFormation templates and create CloudFormation stacks using the templates in various regions and accounts.",
      "B. Create Lambda functions to use AWS SDK to create AWS resources. Run the Lambda functions in different regions and accounts.",
      "C. Configure an AWS CodePipeline to deploy AWS resources in a deployment stage via CodeDeploy. Execute the pipeline in different regions and accounts.",
      "D. Create CloudFormation StackSets using templates. Specify regions and accounts depending on the requirements for the StackSets."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "The key of the question is that AWS resources need to be configured or maintained across different regions and accounts in a single operation. CloudFormation StackSets should be considered first as it can easily provision CloudFormation stacks for selected target accounts across specified regions. Refer to the document in https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/what-is-cfnstacksets.html."
  },
  {
    topic: "SDLC Automation",
    question:
      "You are in charge of maintaining several AWS CloudFormation StackSets in a large organization. One StackSets was created to configure infrastructure resources such as IAM roles and security groups in several regions for a web application. Recently, due to certain new features of the application, one parameter value of the CloudFormation StackSets needs to be updated. However, this update is only required for two regions and the other regions should not be changed for the parameter. How should you implement this?",
    answers: [
      "A. Parameters of StackSets can only be modified for all regions. This operation cannot be done unless the CloudFormation stacks in these two regions are removed from the StackSets.",
      "B. Parameters of StackSets can not be modified for selected regions. Deregister these two regions from the StackSets and then create a new StackSet with new parameters in these two regions.",
      "C. Choose Override StackSet parameters from the Actions menu. Specify the two regions that you want to modify and then override the StackSet parameters for these regions.",
      "D. Choose Edit StackSet Details from the Actions menu. Specify the two regions that you want to change and then modify the StackSet parameters for only these two regions."
    ],
    correctAnswer: "Correct Answer – C",
    explanation:
      "In this case, stack instances in two regions need to have different property values than the specified ones when the StackSets was created. This can be done by overriding parameter values for existing stack instances. The detailed explanation can be found in https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/stackinstances-override.html."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "One application of your company was configured to send all application logs to a Kinesis Stream and the logs can exist in the Stream shards for 24 hours. A recent company strategy is to use Splunk Enterprise to search, monitor and analyze application logs and a Splunk server has already been deployed in an EC2 instance. Which approach is the best to load the streaming data in Kinesis Stream to the Splunk instance?",
    answers: [
      "A. Configure the Kinesis Stream to auto deliver the received logs to the Splunk destination for the server to index the logs.",
      "B. Use Amazon Kinesis Data Firehose as a fully managed service to deliver real-time streaming data in Kinesis Stream to the Splunk instance.",
      "C. Use Amazon Kinesis analytics to analyze and transfer real-time streaming data in Kinesis Stream to the destination which is the Splunk instance.",
      "D. Use AWS SDK in an EC2 instance to get the records from Kinesis Stream and forward the records to the Splunk instance."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "Amazon Kinesis Data Firehose is a suitable data consumer for the data producer which is the Kinesis Stream in this case. According to"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "A financial company has used Kinesis Stream to store system logs in real time from a busy application. Then the data in Kinesis Stream is sent to a Kinesis Firehose delivery stream which delivers data to the final S3 bucket destination. The input data format is RFC3163 Syslog however it is required to convert the format to JSON in Kinesis Firehose before the data is delivered. How should you implement this?",
    answers: [
      "A. Create a Lambda function for data transformation using a blueprint. Kinesis Data Firehose can invoke the Lambda function to transform incoming source data.",
      "B. In Kinesis Data Firehose, invoke AWS Glue to create a schema in order to modify the format of incoming source data.",
      "C. Configure Kinesis Data Firehose to use third party JSON deserializer tool Apache Hive JSON SerDe to convert the data to JSON format.",
      "D. Kinesis Firehose cannot transform the data format inside of it. Instead it has to be done in Kinesis Stream."
    ],
    correctAnswer: "Correct Answer – A",
    explanation: "Refer to"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You are a site reliable engineer in a company. Your company has a new application deployed in AWS that has three tiers including frontend, backend and database. Various AWS services are being used such as EC2, ELB, Auto Scaling, Route53, RDS, etc. For the whole application, the RTO (recovery time objective) is set to 1 hour. Which approach can help you the most to achieve this target?",
    answers: [
      "A. In RDS, configure each database to create regular automated snapshots every hour. Copy the snapshots to another region.",
      "B. Create a warm standby in another region. Use Route53 failover routing policy to route to the standby if the active application has an outage.",
      "C. Create regular EBS snapshots every hour using EBS lifecycle manager.",
      "D. Create a Jenkins pipeline to automatically create AMIs for EC2 instances. Execute the pipeline every hour."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "The question asks for the approach that can help the most to achieve the target. The application contains several components and all of them should be considered."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You recently encountered a major bug in your web application during a deployment cycle. During this failed deployment, it took the team four hours to roll back to a previously working state, which left customers with a poor user experience. During the post-mortem, you team discussed the need to provide a quicker, more robust way to roll back failed deployments. You currently run your web application on Amazon EC2 and use Elastic Load Balancing for your load balancing needs.\n\nWhich technique should you use to solve this problem?",
    answers: [
      "A. Create deployable versioned bundles of your application. Store the bundle on Amazon S3. Re-deploy your web application on Elastic Beanstalk and enable the Elastic Beanstalk auto – rollback feature tied to CloudWatch metrics that define failure.",
      "B. Use an AWS OpsWorks stack to re-deploy your web application and use AWS OpsWorks DeploymentCommand to initiate a rollback during failures.",
      "C. Create deployable versioned bundles of your application. Store the bundle on Amazon S3. Use an AWS OpsWorks stack to redeploy your web application and use AWS OpsWorks application versioning to initiate a rollback during failures.",
      "D. Using Elastic BeanStalk redeploy your web application and use the Elastic BeanStalk API to trigger a FailedDeployment API call to initiate a rollback to the previous version."
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "SDLC Automation",
    question:
      "You are designing an application that contains protected health information. Security and compliance requirements for your application mandate that all protected health information in the application use encryption at rest and in transit. The application uses a three-tier architecture where data flows through the load balancer and is stored on Amazon EBS volumes for processing and the results are stored in Amazon S3 using the AWS SDK.\n\nWhich of the following two options satisfy the security requirements? (Select two)",
    answers: [
      "A. Use SSL termination on the load balancer, Amazon EBS encryption on Amazon EC2 instances and Amazon S3 with server- side encryption.",
      "B. Use SSL termination with a SAN SSL certificate on the load balancer. Amazon EC2 with all Amazon EBS volumes using Amazon EBS encryption, and Amazon S3 with server-side encryption with customer-managed keys.",
      "C. Use TCP load balancing on the load balancer. SSL termination on the Amazon EC2 instances. OS-level disk encryption on the Amazon EBS volumes and Amazon S3 with server-side encryption.",
      "D. Use TCP load balancing on the load balancer. SSL termination on the Amazon EC2 instances and Amazon S3 with server-side encryption.",
      "E. Use SSL termination on the load balancer an SSL listener on the Amazon EC2 instances, Amazon EBS encryption on EBS volumes containing PHI and Amazon S3 with server-side encryption."
    ],
    correctAnswer: " ",
    explanation: "Answer – C and E"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have an AWS OpsWorks Stack running Chef Version 11.10. Your company hosts its own proprietary cookbook on Amazon S3, and this is specified as a custom cookbook in the stack. You want to use an open-source cookbook located in an external Git repository. What tasks should you perform to enable the use of both custom cookbooks?",
    answers: [
      "A. In the AWS OpsWorks stack settings, enable Berkshelf. Create a new cookbook with a Berksfile that specifies the other two cookbooks. Configure the stack to use this new cookbook.",
      "B. In the OpsWorks stack settings add the open source project’s cookbook details in addition to your cookbook.",
      "C. Contact the open source project’s maintainers and request that they pull your cookbook into theirs. Update the stack to use their cookbook.",
      "D. In your cookbook create an S3 symlink object that points to the open source project’s cookbook."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "To use an external cookbook on an instance, you need a way to install it and manage any dependencies. The preferred approach is to implement a cookbook that supports a dependency manager named Berkshelf. Berkshelf works on Amazon EC2 instances, including AWS OpsWorks Stacks instances, but it is also designed to work with Test Kitchen and Vagrant. "
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You are responsible for an application that leverages the Amazon SDK for storing and retrieving data from Amazon S3, accessing multiple DynamoDB tables, and exchanging message with Amazon SQS queues. Your VP of Compliance is concerned that you are not following security best practices for securing all of this access. He has asked you to verify that the application’s AWS access keys are not older than six months. Which of the below options will provide your VP with the requested information?",
    answers: [
      "A. Create a script to query the IAM list-access keys API to get your application access key creation date and create a batch process to periodically create a compliance report for your VP.",
      "B. Provide your VP with a link to IAM AWS documentation to address the VP’s key rotation concerns.",
      "C. Update your application to log changes to its AWS access key credential file and use a periodic Amazon EMR job to create a compliance report for your VP",
      "D. Create a new set of instructions for your configuration management tool that will periodically create and rotate the application’s existing access keys and provide a compliance report to your VP."
    ],
    correctAnswer: "Answer – A",
    explanation:
      'Use the "iam:ListAccessKeys" API to know when the access keys were created. You can use this information to find which keys are older than six months. Run a batch process to generate the compliance report as per the requirement of the VP of the compliance department'
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "One of your engineers has written a web application in the Go Programming language and has asked your DevOps team to deploy it to AWS. The application code is hosted on a Git repository.\n\nWhat are your options?  (Select Two)",
    answers: [
      'A. Create a new AWS Elastic Beanstalk application and configure a Go environment to host your application, Using Git check out the latest version of the code, once the local repository for Elastic Beanstalk is configured use "eb create" command to create an environment and then use "eb deploy" command to deploy the application.',
      "B. Write a Dockerfile that installs the Go base image and uses Git to fetch your application. Create a new AWS OpsWorks stack that contains a Docker layer that uses the Dockerrun.aws.json file to deploy your container and then use the Dockerfile to automate the deployment.",
      "C. Write a Dockerfile that installs the Go base image and fetches your application using Git, Create a new AWS Elastic Beanstalk application and use this Dockerfile to automate the deployment.",
      "D. Write a Dockerfile that installs the Go base image and fetches your application using Git, Create an AWS CloudFormation template that creates and associates an AWS::EC2::Instance resource type with an AWS::EC2::Container resource type."
    ],
    correctAnswer: "Answer – A and C",
    explanation:
      "\n\tOpsworks works with Chef recipes and not with Docker containers so Option B is invalid.\n\tThere is no AWS::EC2::Container resource for Cloudformation so Option D is invalid.\n\tBelow is the documentation on Elastic beanstalk and Docker\n"
  },
  {
    topic: "SDLC Automation",
    question:
      "How would you test a new feature on an AWS service from inside of a staging VPC with the least turnaround time?",
    answers: [
      "A. Launch an Amazon Elastic Compute Cloud (EC2) instance in the staging VPC in response to a development request, and use configuration management to set up the application. Run any testing harnesses to verify application functionality and then use Amazon Simple Notification Service (SNS) to notify the development team of the results.",
      "B. Use an Amazon EC2 instance that frequently polls the version control system to detect the new feature, use AWS CloudFormation and Amazon EC2 user data to run any testing harnesses to verify application functionality and then use Amazon SNS to notify the development team of the results.",
      "C. Use an Elastic Beanstalk application that polls the version control system to detect the new feature, use AWS CloudFormation and Amazon EC2 user data to run any testing harnesses to verify application functionality and then use Amazon Kinesis to notify the development team of the results.",
      "D. Use AWS CloudFormation to launch an Amazon EC2 instance use Amazon EC2 user data to run any testing harnesses to verify application functionality and then use Amazon Kinesis to notify the development team of the results."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "Using Amazon Kinesis would just take more time in setup and would not be ideal to notify the relevant team in the shortest time possible."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "A group of developers in your organization want to migrate their existing application into Elastic Beanstalk and want to use Elastic load Balancing and Amazon SQS. They are currently using a custom application server.\n\nHow would you deploy their system to Elastic Beanstalk?",
    answers: [
      "A. Configure an Elastic Beanstalk platform using AWS OpsWorks deploy it to Elastic Beanstalk and run a script that creates a load balancer and an Amazon SQS queue.",
      "B. Use a Docker container that has the third party application server installed on it and create an application source bundle that produces the load balancer and an Amazon SQS queue.",
      "C. Create a custom Elastic Beanstalk platform that contains the third party application server and runs a script that creates a load balancer and an Amazon SQS queue.",
      "D. Configure an AWS OpsWorks stack that installs the third party application server and creates a load balancer and an Amazon SQS queue and then deploys it to Elastic Beanstalk."
    ],
    correctAnswer: "Answer – B",
    explanation: "Below is the documentation on Elastic beanstalk and Docker"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You have a large multi-tier architecture creating using cloudformation, that serves public facing web traffic through a load balancer and is backed by a web tier that is contained within an Amazon EC2 Auto Scaling group. During a peak in traffic, you discover that your web tier is adding new instances disproportionately compared to the amount of incoming traffic and the Auto Scaling policy that was set up.\n\nWhat should you do in order to stop the Auto Scaling group from scaling incorrectly in response to incoming traffic?",
    answers: [
      "A. Using CloudWatch and the instance BootTime metric, increase the PauseTime and CoolDown property on the Auto Scaling group to be over the value of the metric.",
      "B. Using a custom CloudWatch metric insert the elapsed time since the instance launch to the time the instance responds to an Elastic Load Balancing health check, and periodically adjust the Pause Time and the CoolDown property on the AutoScaling group to be over the value of the metric.",
      "C. Using a custom CloudWatch metric insert the elapsed time since the instance launch to the time the instance responds to an Elastic Load Balancing health check, and periodically adjust the Pause Time of the UpdatePolicy and reduce the Scaling Adjustment property by 50%",
      "D. Using a third-party configuration management tool and the AWS SDK suspend all ScheduledActions of the Auto Scaling group until after the traffic peak and then resume all scheduledActions."
    ],
    correctAnswer: "Answer : B",
    explanation:
      "The question focuses on adding instances depending up on traffic using an Auto Scaling Group.\n\nYou can add an UpdatePolicy attribute to your Auto Scaling group to control how rolling updates are performed when a change has been made to the Auto Scaling group's launch configuration. This is mainly used alongside CloudFormation Templates which use AutoScalingGroup Resource.\n\nIn the UpdatePolicy attribute of AutoScalingGroup we use PauseTime.\nPerforming a rolling update on an Auto Scaling group can result in unexpected behavior if you do not have the right settings configured. \n\nPauseTime indicates the amount of time that AWS CloudFormation pauses after making a change to a batch of instances to give those instances time to start software applications. For example, you might need to specify PauseTime when scaling up the number of instances in an Auto Scaling group.\n\nFor more information please visit the link:"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You have a web application that is currently running on a three M3 EC2 instances in three different AZs. You have an Auto Scaling group configured to scale from three to thirty instances. When reviewing your CloudWatch metrics, you see that sometimes your Auto Scaling group is hosting fifteen instances. The web application is reading and writing to a DynamoDB-configured backend, configured with 800 Write Capacity Units and 800 Read Capacity Units. Your DynamoDB Primary Key is the Company ID. You are hosting 25 TB of data in your web application.\n\nYou have customers that are complaining of long load times when their staff arrives at the office at 9:00 AM and load the website. Your website consists of content that is pulled from DynamoDB. Choose the answer that will ensure high availability (with an acceptable downtime) and reduce the applicaiton's load time.",
    answers: [
      "A. Add a caching layer in front of your web application by choosing ElastiCache Memcached instances in one of the AZs.",
      "B. Double the number of Read Capacity Units in your DynamoDB instance because the instance is probably being throttled when the customer accesses the website and your web application.",
      "C. Change your Auto Scaling group configuration to use Amazon C3 instance types, because the web application layer is probably running out of compute capacity.",
      "D. Implement an Amazon SQS queue between your DynamoDB database layer and the web application layer to minimize the large burst in traffic the customer generates when everyone arrives at the office at 9:00AM and begins accessing the website.",
      "E. Use data pipelines to migrate your DynamoDB table to a new DynamoDB table with a primary key that is evenly distributed across your dataset. Update your web application to request data from the new table"
    ],
    correctAnswer: "Answer – E",
    explanation:
      "The AWS documentation provide the following information on the best performance for DynamoDB tables"
  },
  {
    topic: "SDLC Automation",
    question:
      "You have a web application composed of an Auto Scaling group of web servers behind a load balancer, and create a new AMI for each application version for deployment. You have a new version to release, and you want to use the A/B deployment technique to migrate users over in a controlled manner while the size of the fleet remains constant over a period of 12 hours, to ensure that the new version is performing well.\n\nWhat option should you choose to enable this technique while being able to roll back easily?",
    answers: [
      "A. Create an Auto scaling launch configuration with the new AMI. Configure the AutoScaling group with the new launch configuration. Use the Auto Scaling rolling updates feature to migrate to the new version.",
      "B. Create an Auto Scaling launch configuration with the new AMI. Create an Auto Scaling group configured to use the new launch configuration and to register instances with the same load balancer. Vary the desired capacity of each group to migrate.",
      "C. Create an Auto scaling launch configuration with the new AMI. Configure Auto Scaling to vary the proportion of instances launched from the two launch configurations.",
      "D. Create a load balancer. Create an Auto Scaling launch configuration with the new AMI to use the new launch configuration and to register instances with the new load balancer. Use Amazon Route53 weighted Round Robin to vary the proportion of requests sent to the load balancers.",
      "E. Launch new instances using the new AMI and attach them to the Auto Scaling group. Configure Elastic Load Balancing to vary the proportion of requests sent to instances running the two application versions."
    ],
    correctAnswer: "Answer - B",
    explanation:
      "Since you want to control the usage to the new application in a controlled manner while keeping the number of instances consistent, the best way is to use an Auto Scaling launch configuraiton. This way the desired capacity of the fleet can stay constant for the 12-hour period."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You have an I/O and network-intensive application running on multiple Amazon EC2 instances that cannot handle a large ongoing increase in traffic. The Amazon EC2 instances are using two Amazon EBS PIOPS volumes each, and each instance is identical.\n\nWhich of the following approaches should be taken in order to reduce load on the instances with the least disruption to the application?",
    answers: [
      "A. Create an AMI from each instance, and set up Auto Scaling groups with a larger instance type that has enhanced networking enabled and is Amazon EBS-optimized.",
      "B. Stop each instance and change each instance to a larger Amazon EC2 instance type that has enhanced networking enabled and is Amazon EBS-optimized. Ensure that RAID striping is also set up on each instance.",
      "C. Add an instance-store volume for each running Amazon EC2 instance and implement RAID striping to improve I/O performance.",
      "D. Add an Amazon EBS volume for each running Amazon EC2 instance and implement RAID striping to improve I/O performance.",
      "E. Create an AMI from an instance, and set up an Auto Scaling group with an instance type that has enhanced networking enabled and is Amazon EBS-optimized."
    ],
    correctAnswer: "Answer – E",
    explanation: "The AWS Documentation mentions the following on AMI’s"
  },
  {
    topic: "Monitoring and Logging",
    question:
      "Your application uses Amazon SQS and Auto Scaling to process background jobs. The Auto Scaling policy is based on the number of messages in the queue, with a maximum instance count of 100. Since the application was launched, the group has never scaled above 50. The Auto scaling group has now scaled to 100, the queue size is increasing and very few jobs are being completed. The number of messages being sent to the queue is at normal levels. What should you do to identity why the queue size is unusually high and to reduce it?",
    answers: [
      "A. Temporarily increase the AutoScaling group’s desired value to 200. When the queue size has been reduced,reduce it to 50.",
      "B. Analyze the application logs to identify possible reasons for message processing failure and resolve the cause for failures.",
      "C. Create additional Auto Scaling groups enabling the processing of the queue to be performed in parallel.",
      "D. Analyze CloudTrail logs for Amazon SQS to ensure that the instances Amazon EC2 role has permission to receive messages from the queue."
    ],
    correctAnswer: "Answer : B",
    explanation:
      "Here the best option is to look at the application logs and resolve the failure. You could be having a functionality issue in the application that is causing the messages to queue up and increase the fleet of instances in the Autoscaling group."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "Your public website uses a load balancer and an Auto Scaling group in a virtual private cloud. Your chief security officer has asked you to set up a monitoring system that quickly detects and alerts your team when a large sudden traffic increase occurs. How should you set this up?",
    answers: [
      "A. Set up an Amazon CloudWatch alarm for the Elastic Load Balancing NetworkIn metric and then use Amazon SNS to alert your team.",
      "B. Use an Amazon EMR job to run every thirty minutes, analyze the Elastic Load Balancing access logs in a batch manner to detect a sharp increase in traffic and then use the Amazon Simple Email Service to alert your team.",
      "C. Use an Amazon EMR job to run every thirty minutes analyze the CloudWatch logs from your application Amazon EC2 instances in a batch manner to detect a sharp increase in traffic and then use the Amazon SNS SMS notification to alert your team",
      "D. Set up an Amazon Cloudwatch alarm for the NetworkIn metric associated with the EC2 instances of the Auto Scaling group and then use Amazon SNS to alert your team.",
      "E. Set up a cron job to actively monitor the AWS CloudTrail logs for increased traffic and use Amazon SNS to alert your team."
    ],
    correctAnswer: "Answer - D",
    explanation:
      "The below snapshot from the AWS documentation gives details on the NetworkIn metric"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "As part of your deployment process, you are configuring your continuous integration (CI) system to build AMIs. You want to build them in an automated manner that is not time constrained and is also cost-efficient. Which method should you use?",
    answers: [
      "A. Attach an Amazon EBS volume to your CI instance, build the root file system of your image on the volume, and use the CreateImage API call to create an AMI out of this volume.",
      "B. Have the CI system launch a new instance, bootstrap the code and apps onto the instance and create an AMI out of it.",
      "C. Upload all contents of the image to Amazon S3 launch the base instance, download all of the contents from Amazon S3 and create the AMI.",
      "D. Have the CI system launch a new spot instance bootstrap the code and apps onto the instance and create an AMI out of it."
    ],
    correctAnswer: "Answer – D",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "SDLC Automation",
    question:
      "As part of your deployment pipeline, you want to enable automated testing of your AWS CloudFormation template. What testing should be performed to enable faster feedback while minimizing costs and risk? Select three answers from the options given below",
    answers: [
      "A. Use the AWS CloudFormation Validate Template to validate the syntax of the template",
      "B. Use the AWS CloudFormation Validate Template to validate the properties of resources defined in the template.",
      "C. Validate the template’s is syntax using a general JSON parser.",
      "D. Validate the AWS CloudFormation template against the official XSD scheme definition published by Amazon Web Services.",
      "E. In the testing environment create and update the stack with the template. If the template fails rollback will return the stack and its resources to exactly the same state.",
      "F. In the testing environment, while creating the stack, specify an Amazon SNS topic for subscription. Your testing environment runs tests when it receives notification when the stack is created or updated."
    ],
    correctAnswer: "Answer – A,E and F",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You set up a web application development environment by using a third party configuration management tool to create a Docker container that is run on local developer machines.\n\nYou need to deploy the docker container in the staging and production environments with minimal interference and administrative overhead. How can you accomplish this?",
    answers: [
      "A. Write a script using the AWS SDK or CLI to deploy the application code from version control to the local development environments staging and production using AWS OpsWorks.",
      "B. Define an AWS CloudFormation template to place your infrastructure into version control and use the same template to deploy the Docker container into Elastic Beanstalk for staging and production.",
      "C. Because the application is inside a Docker container, there are no infrastructure differences to be taken into account when moving from the local development environments to AWS for staging and production.",
      "D. Define an AWS CloudFormation template for each stage of the application deployment lifecycle –development, staging and production –and have tagging in each template to define the environment."
    ],
    correctAnswer: "Answer - B",
    explanation:
      "Elastic Beanstalk supports the deployment of web applications from Docker containers. With Docker containers, you can define your own runtime environment. You can choose your own platform, programming language, and any application dependencies (such as package managers or tools), that aren't supported by other platforms. Docker containers are self-contained and include all the configuration information and software your web application requires to run.\n\nBy using Docker with Elastic Beanstalk, you have an infrastructure that automatically handles the details of capacity provisioning, load balancing, scaling, and application health monitoring. \n\nThis seems to be more appropriate than Option D."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your team is developing an online bidding application which has used DynamoDB tables to store customers’ information. The application requires the fastest possible response time for reads in microseconds. However, it seems that the existing DynamoDB tables cannot provide such performance. Which approach is the best to improve the read performance greatly without modifying existing application logic?",
    answers: [
      "A. Configure an on-demand read/write capacity mode for the DynamoDB table.",
      "B. Create a Global Secondary Index for the DynamoDB table so that queries can be more efficient.",
      "C. Create a read replica table in another region (Global Table) to improve the read capacity.",
      "D. Configure a DynamoDB Accelerator (DAX) cluster for the application to use which can deliver up to a 10x performance improvement."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "DAX is a DynamoDB service to provide access to eventually consistent data with microsecond latency. Refer to https://docs.amazonaws.cn/en_us/amazondynamodb/latest/developerguide/DAX.html#DAX.use-cases"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have decided you need to change the instance type of your instances in production which are running as part of an Autoscaling Group. We have used CloudFormation Template to launch our architecture and have currently 4 instances in production. You cannot have any interruption in service and need to ensure 2 instances are always running during the update. Which of the options listed below can be chosen for this?",
    answers: [
      "A. AutoScalingRollingUpdate",
      "B. AutoScalingScheduledAction",
      "C. AutoScalingReplacingUpdate",
      "D. AutoScalingIntegrationUpdate"
    ],
    correctAnswer: "Answer – A",
    explanation:
      "The AWS::AutoScaling::AutoScalingGroup resource supports an UpdatePolicy attribute. This is used to define how an Auto Scaling group resource is updated when an update to the CloudFormation stack occurs. A common approach to updating an Auto Scaling group is to perform a rolling update, which is done by specifying the AutoScalingRollingUpdate policy. This retains the same Auto Scaling group and replaces old instances with new ones, according to the parameters specified."
  },
  {
    topic: "SDLC Automation",
    question:
      "Your application is currently running on Amazon EC2 instances behind a load balancer. Your management has decided to use a Blue/Green deployment strategy. How should you implement this for each deployment?",
    answers: [
      "A. Set up Amazon Route 53 health checks to fail over from any Amazon EC2 instance that is currently being deployed to.",
      "B. Using AWS CloudFormation, create a test stack for validating the code, and then deploy the code to each production Amazon EC2 instance.",
      "C. Create a new load balancer with new Amazon EC2 instances, carry out the deployment, and then switch DNS over to the new load balancer using Amazon Route 53 after testing.",
      "D. Launch more Amazon EC2 instances to ensure high availability, de-register each Amazon EC2 instance from the load balancer, upgrade it, and test it, and then register it again with the load balancer."
    ],
    correctAnswer: "Answer – C",
    explanation: "The below diagram shows how this can be done"
  }
];
