import React, { useState, useEffect, useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import "./PracticeSentences.css";
import SRS from "../../helpers/SRS";
import TestTemplate from "./TestTemplate";
import TestsPage from "../tests/TestsPage";
import tests from "../../dummy-data";
import CardYard from './CardYard';

let CardGroupModule = ({ group, studyNow, selectCards, openModal}) => {
  let [showSubGroups, toggleSubGroups] = useState(false);

  return (
    <div className="card-group-module">
      <h1>{group.name}</h1>
      <div className="select-button" onClick={() => studyNow(group)}>
        Study All Cards
      </div>
      {group.subGroups && group.subGroups.length ? (
        <div
          className="select-button"
          onClick={() => toggleSubGroups(!showSubGroups)}
        >
          Select a saved group of cards
        </div>
      ) : null}
      <div className="select-button" onClick={() => openModal(group)}>
        Choose Cards to Study
      </div>
      {showSubGroups ? (
        <div className="subgroup-container" style={{display: 'flex'}}>
          {group.subGroups.map((subGroup) => {
            return <CardGroupModule studyNow={studyNow} group={subGroup} />;
          })}
        </div>
      ) : null}
    </div>
  );
};
let createSentenceArray = (array) => {
  let sentences = [];

  array.forEach((session) => {
    if (sentences.length < 100) {
      session.cardsArray.forEach((card) => {
        if (sentences.length < 100 && card.sentences) {
          card.sentences.slice(0, 2).forEach((crd) => {
            sentences.push(crd);
          });
        }
      });
    }
  });
  return sentences;
};

let aminoAcidsGroup = {
  name: "Amino Acids",
  cards: [...tests.testOne],
  subGroups: [
    { name: "Cards 1-5", cards: [...tests.testOne.slice(0, 5)] },
    { name: "Cards 6-10", cards: [...tests.testOne.slice(5, 10)] },
    { name: "Cards 11-15", cards: [...tests.testOne.slice(10, 15)] },
    { name: "Cards 16-20", cards: [...tests.testOne.slice(15, 20)] },
  ],
};
const PracticeSentences = () => {
  let [state, setState] = useState({
    sentences: [],
    current: "",
    cardGroups: [],
    studyNowGroup: {},
  });
  let [cardGroups, setCardGroups] = useState([]);
  let [studyNowGroup, setStudyNow] = useState({});
  let sessionsArray = JSON.parse(localStorage.getItem("sessionsArray")) || [];
  let [showModal, setShowModal] = useState(false)
  let [modalCards, setModalCards] = useState([]);
  

  let srs = new SRS(3, createSentenceArray(sessionsArray));

  let openModal = (group) => {


    setModalCards(group.cards)
    setShowModal(true);

  }
  useEffect(() => {
    console.log(srs);

    let cardGroups = [aminoAcidsGroup];
    setState({
      ...state,
      sentences: createSentenceArray(sessionsArray),
      current: srs.currentCard,
      cardGroups,
    });
  }, []);

  return (
    <div className="dashboard-container">
      {studyNowGroup.name ? (
        <TestTemplate cards={studyNowGroup.cards} />
      ) : (
        <>
          {" "}
          <h1 style={{}}>Select A Group of Flashcards to Study!</h1>
          <div className="select-group-container">
            {state.cardGroups.length ? (
              state.cardGroups.map((group) => {
                return <CardGroupModule studyNow={setStudyNow} group={group} openModal={openModal} />;
              })
            ) : (
              <div>
                {" "}
                You have no cards available to study. Make a new card group now!{" "}
              </div>
            )}
          </div>
        </>
      )}
      {showModal ? <CardSelectorModule setShowModal={setShowModal} cards={modalCards}/> : null}
    </div>
  );
};

const CardSelectorModule = ({cards, setShowModal}) => {

return (
    <div className="pop-up-container" onClick={() => {}} style={{position: 'absolute', width: '100vw', height: '100vh', display: 'flex', justifyContent:'center', alignContent: "center" }}>
        <CardYard cards={cards} /> 
    </div>
)

}

export default PracticeSentences;
