import React, { useState, useEffect, useMemo } from "react";
import "./CardTemplate.css";

const AnswerBlock = ({ answers, showAnswers }) => {
  return (
    <div className={`answer-block ${!showAnswers ? "hide" : ""}`}>
      {answers && answers.map(answer => {
        return <div className="answer">{answer}</div>;
      })}
    </div>
  );
};

const CardTemplate = ({
  index,
  currentCard,
  wide,
  card,
  frontKey = "front",
  backKey = "",
  frontMultiData = [],
  backMultiData = [],
  fontSize
}) => {
  let [state, setState] = useState({
    flipped: false,
    card,
    currentCard,
    showAnswers: false
  });

  useEffect(() => {
    console.log("INDEX", index, "KEY",card, currentCard)
    setState({
      ...state,
      card,
      currentCard
    });
    window.document.addEventListener("keydown", e => {
      if (e.key === "ArrowLeft") {
       
      } else if (e.key === "ArrowRight") {
        
      } else if (e.key === "ArrowDown") {
        
      } else if (e.key === "ArrowUp") {
        state.flipped = !state.flipped
        console.log("STATE",state.flipped)
        setState({
          ...state,
          //flipped: state.flipped ? false : true
        })
       
        
      } else {
        console.log(e.key);
      }
    });
  }, []);

  let multiData = data => {
    return <div>{card[data]}</div>;
  };

  return (
    <div
    
      className={`card ${wide ? "wide" : ""} ${
        index === currentCard.key ? "" : "hidden"
      } 
                             ${state.flipped ? "flip" : ""} `}
      onClick={() => setState({ ...state, flipped: !state.flipped })}
    >
      <div className="front">
        <div style={{fontSize}}>
          {frontMultiData && frontMultiData.length
            ? frontMultiData.map(multiData)
            : card[frontKey]}
        </div>
        {/* This shows the order of the card for tracking purposes: index % 5 === 0 ? index : null */}
        {card.audio ? <audio controls src={`${card.audio}`}/> : ""}
        {card.answers && card.answers.length ? (
          <button
            onMouseOver={() =>
              setState({ ...state, showAnswers: true })
            }
            onMouseOut={() =>
              setState({ ...state, showAnswers: false })
            }
            className="see-choices"
          >
            See Choices
          </button>
        ) : null}
        <div style={{position: 'absolute', left: 10, bottom: 5, fontSize: 40}}><span>😈</span></div>
      </div>

      <div className="back">
        {card.img ? <img style={{transform: 'rotate(90deg)', width: '60%'}} alt="" src={`${process.env.PUBLIC_URL + card.img}`/* `../../images/cytosine-c.png` *//* card.img */} /> : null}
        {!card.img && backMultiData.length ? backMultiData.map(multiData) : card[backKey]}
        <div style={{position: 'absolute', right: 10, bottom: 5, fontSize: 40}}><span>🌹</span></div>
      </div>
      <AnswerBlock answers={card.answers} showAnswers={state.showAnswers} />
    </div>
  );
};

export default CardTemplate;
