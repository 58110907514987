import React from "react";
import "./Card.css";
import { Spring } from "react-spring";
import Icon from "antd/lib/icon";
import AWS from "aws-sdk";



// Initialize the Amazon Cognito credentials provider
AWS.config.region = "us-east-2"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "us-east-2:0f4c8b71-d03c-49f8-b2ec-a4d5616e3e45"
});

const polly = new AWS.Polly({ apiVersion: "2016-06-10" });

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: "mnemozen-audio" }
});

export default class Card extends React.Component {
  state = {
    flipped: false,
    audioLink: "",
    word: this.props.card.word,
    order: this.props.order,
    examples: this.props.card.examples,
    starred: this.props.card.starred,
    currentCardKey: this.props.currentCard.key
  };

  markStar = () => {
    this.props.toggleStarred(this.props.card);

    this.setState({
      starred: this.props.card.starred
    });
  };

  flipCard = () => {
    this.props.flipCard();
  };

  grabURL = url => {
    console.log(url);
    this.setState({
      audioLink: url
    });
  };

  /* // ORIGINAL FETCH AUDIO FUNCTION THAT BOTH UPLOADS THE TEXT AND GETS THE URL
          fetchAudio = async function() {
    let word = this.state.word;
    var speechParams = {
      OutputFormat: "mp3",
      SampleRate: "16000",
      Text: `<speak><prosody rate="slow">${word}</prosody></speak>`,
      TextType: "ssml",
      VoiceId: "Mia"
    };

    let order = this.state.order;
    try {
      let urlParams = {
        Key: `${word}.mp3`
      };
      let audioObject = await polly.synthesizeSpeech(speechParams, function(
        error,
        data
      ) {
        if (error) {
          console.log("syntherr", error);
        } else {
          console.log(data.AudioStream);
          var putParams = {
            Body: data.AudioStream,
            ContentType: "application/octet-stream",
            Key: `${word}.mp3` //
          };
          s3.upload(putParams, function(err, data) {
            if (err) console.log(err, err.stack);
            // an error occurred
            else {
              console.log("upload success");
              console.dir(data); // successful response
            }
          });
          let urlParams = {
            Key: `${word}.mp3`
          };
          let url = s3.getSignedUrl("getObject", urlParams);
          console.log(url);
          let audio = document.getElementById(`audioPlayback${order}`);
          audio.src = url;
        }
      });
    } catch (err) {
      console.log("synth", err);
    }
  }; */

  fetchAudio = async function() {
    let word = this.state.word;

    let order = this.state.order;
    try {
      let params = {
        Key: `${word}.mp3`
      };
      let audioObject = await s3.getObject(params, function(error, data) {
        if (error) {
          console.log("syntherr", error);
        } else {
          let url = s3.getSignedUrl("getObject", params);
          console.log(url);
          let audio = document.getElementById(`audioPlayback${order}`);
          audio.src = url;
        }
      });
    } catch (err) {
      console.log("synth", err);
    }
  };

  audioText = async function() {
    var speechParams = {
      OutputFormat: "mp3",
      SampleRate: "16000",
      Text: `<speak><prosody rate="slow">${this.state.word}</prosody></speak>`,
      TextType: "ssml",
      VoiceId: "Mia"
    };
    var polly1 = new AWS.Polly({
      apiVersion: "2016-06-10"
    });
  };

  fetchExampleAudio = function() {
    let order = this.props.order;

    this.state.examples.forEach((ex, index) => {
      async function synthAud() {
        let slicePoint = ex.native.length / 2;
        let params = {
          Key: `${ex.native.slice(0, slicePoint)}.mp3`
        };
        try {
          let newURL = await s3.getObject(params, function(error, data) {
            if (error) {
              console.log(error);
            } else {
              let url = s3.getSignedUrl("getObject", params);
              let audio = new Audio(`${url}`);
              console.log("getobject success");
              let example = document.querySelector(`.card${order}Ex${index}`);
              let playFunc = () => audio.play();
              example.onclick = playFunc;
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
      return synthAud();
    });
  };

  /* ORIGINAL FETCHEXAMPLE FUNCTION 
  fetchExampleAudio = function() {
    let order = this.props.order;

    this.state.examples.forEach((ex, index) => {
      async function synthAud() {
        var speechParams = {
          OutputFormat: "mp3",
          SampleRate: "16000",
          Text: `<speak><prosody rate="slow">${ex.native}</prosody></speak>`,
          TextType: "ssml",
          VoiceId: "Mia"
        };
       
        let slicePoint = ex.native.length / 2;
        try {
          let newURL = await polly.synthesizeSpeech(speechParams, function(
            error,
            data
          ) {
            if (error) {
              console.log(error);
            } else {
              var putParams = {
                Body: data.AudioStream,
                ContentType: "application/octet-stream",
                Key: `${ex.native.slice(0, slicePoint)}.mp3`
              };
              s3.upload(putParams, function(err, data) {
                if (err) console.log(err, err.stack);
                // an error occurred
                else {
                  console.log("upload success");
                  console.dir(data); // successful response
                }
              });
              let urlParams = {
                Key: `${ex.native.slice(0, slicePoint)}.mp3`
              };
              let url = s3.getSignedUrl("getObject", urlParams);

              let audio = new Audio(`${url}`);
              console.log(audio);
              let example = document.querySelector(`.card${order}Ex${index}`);
              let playFunc = () => audio.play();
              example.onclick = playFunc;
              console.log(example);
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
      return synthAud();
    });
  };
  */

  showTranslation = e => classname => {
    let translation = document.querySelector(`.${classname}2`);
    translation.classList.remove("hidden");
  };

  hideTranslation = e => classname => {
    let translation = document.querySelector(`.${classname}2`);
    translation.classList.add("hidden");
  };

  sortExamples = arr => {
    let array = arr;
    for (let i = 0; i < array.length; i++) {
      var index;
      let crnt = arr[i];
      let next = arr[i + 1];
      let before = arr[i - 1];
      console.log("ARRAYCHECK",array)
      if (
        arr[i].native &&
        arr[i + 1] &&
        { ...arr[i] }.native.length < { ...arr[i + 1] }.native.length
      ) {
        // console.log('ran1a Arr1:', arr[i], 'Arr2:', arr[i+1])

        index = arr[i + 1];
        arr[i + 1] = crnt;
        arr[i] = index;
        
        if (
          arr[i].native &&
          arr[i - 1] &&
          arr[i - 1]["native"].length < arr[i]["native"].length
        ) {
          index = arr[i - 1];
          before = arr[i];
          index = arr[i];
          before = arr[i - 1];
        }
      }
    }
    return arr;
  };

  playExampleAudio = () => {
    let audioExample = document.getElementById(
      `audioPlayback${this.props.order}`
    );

    if (this.props.order === this.props.currentCard.key) {
      audioExample.play();
    }
  };

  componentDidMount() {
    let array = this.props.card.sentences || [];
    let examplesArr = this.sortExamples(array);
    console.log("CARD", this.props.card)
    this.setState({
      examples: examplesArr
    });

    //this.fetchAudio();
    //this.fetchExampleAudio();
    /* if (this.props.order === 0) {
      this.fetchAudio();
      this.fetchExampleAudio();
    } */

    window.document.addEventListener("keydown", e => {
      if (e.key === "0") {
        this.playExampleAudio();
      } else if (
        (e.key === "ArrowLeft" || e.key === "ArrowRight") &&
        this.props.order % 3 === 0
      ) {
        //this.fetchAudio();
        //this.fetchExampleAudio();
      }
    });
  }

  render() {
    return (
      <div
        className="cardHolder"
        style={
          this.props.order === this.props.currentCard.key &&
          this.props.showSideBar
            ? { filter: "blur(3px)" }
            : null
        }
      >
        <div
          className={`card 
                             ${
                               this.props.order === this.props.currentCard.key
                                 ? ""
                                 : "hidden"
                             } 
                             ${this.props.flipped ? "flip" : ""}`}
          onClick={this.props.flipCard}
        >
          <div className="front">
            <div>{this.props.currentCard.word}</div>
          </div>

          <div className="back">{this.props.currentCard.translation}</div>
        </div>

        <div
          className={`mkStarCont ${
            this.props.order === this.props.currentCard.key ? "" : "hidden"
          }`}
        >
          <div className="markerStar" onClick={this.markStar}>
            <Icon
              type="star"
              className={this.props.card.starred ? "starSelect" : ""}
            />
          </div>
        </div>

        <audio
          id={`audioPlayback${this.props.order}`}
          className={`wordPlayer ${
            this.props.order === this.props.currentCard.key ? "" : "hidden"
          }`}
          src={`${this.state.url}`}
          controls
        />

        <div
          className={`btn-container  ${
            this.props.order === this.props.currentCard.key ? "" : "hidden"
          }`}
          style={this.props.showSideBar ? { filter: "blur(3px)" } : null}
        >
          <div
            className="cardBtn green"
            onClick={
              !this.props.threshold
                ? this.props.addRemembered
                : this.props.addAfterThreshold
            }
          >
            <Icon type="check" style={{ fill: "white" }} />
          </div>
          <div className="cardBtn red" onClick={this.props.forgotten}>
            <Icon type="stop" style={{ fill: "white" }} />
          </div>
        </div>
        {/* <div
          className={`audioExampleContainer ${
            this.props.order === this.props.currentCard.key ? "" : "hidden"
          }`}
        >
          {this.state.examples ? this.state.examples.map((ex, index) => (
            <div
              className={`audioExampleBox ${
                this.props.order === this.props.currentCard.key ? "" : "hidden"
              }`}
            >
              <p
                className={`audioExampleText card${this.state.order}Ex${index}`}
                onMouseOver={() =>
                  this.showTranslation()(`card${this.state.order}Ex${index}`)
                }
                onMouseOut={() =>
                  this.hideTranslation()(`card${this.state.order}Ex${index}`)
                }
              >
                {ex.native}
              </p>
              <Spring
                from={{ opacity: 0, top: -50 }}
                to={{ opacity: 1, top: 0 }}
              >
                {props => (
                  <p
                    className={`audioExampleText translation hidden card${
                      this.state.order
                    }Ex${index}2`}
                  >
                    {ex.translation}
                  </p>
                )}
              </Spring>
            </div>
          )) : null}
        </div> */}
      </div>
    );
  }
}
