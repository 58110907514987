export const testOne = [
{front: 'Glutamine >> Gln >> Q',back: 'hola', img: "/images/glutamine-gln-q.png"},       
{front: 'Lysine >> Lys >> K',back: 'hola', img: "/images/lysine-lys-k.png"},         
{front: 'Tyrosine >> Tyr >> Y',back: 'hola', img: "/images/tyrosine-tyr-y.png"},
{front: 'Phenylalanine >> Phe >> F',back: 'hola', img: "/images/Phenylalanine-phe-f.png"},  
{front: 'Aspartic Acid >> Asp >> D',back: 'hola', img: "/images/aspartic-acid-asp-d.png"},
{front: 'Asparagine >> Asn >> N',back: 'hola', img: "/images/asparagine-asn-n.png"},  
{front: 'Methionine >> Met >> M',back: 'hola', img: "/images/methionine-met-m.png"},     
{front: 'Tryptophan >> Trp >> W',back: 'hola', img: "/images/tryptophan-trp-w.png"},
{front: 'Glutamic Acid >> Glu >> E',back: 'hola', img: "/images/glutamic-acid-glu-e.png"},

{front: 'Cysteine >> Cys >> C',back: 'hola', img: "/images/cysteine-cys-C.jpg"},  
{front: 'Alanine >> Ala >> A',back: 'hola', img: "/images/alanine-ala-a.jpg"},   
{front: 'Glycine >> Gly >> G',back: 'hola', img: "/images/glycine-gly-g.jpg"},  
{front: 'Serine >> Ser >> S',back: 'hola', img: "/images/serine-ser-s.jpg"},         
{front: 'Valine >> Val >> V',back: 'hola', img: "/images/valine-val-v.jpg"},
{front: 'Leucine >> Leu >> L',back: 'hola', img: "/images/leucine-leu-l.jpg"},         
{front: 'Threonine >> Thr >> T',back: 'hola', img: "/images/threonine-thr-T.jpg"},
{front: 'Histidine >> His >> H', back: "", img: "/images/histidine-his-h.jpg"},
{front: 'Isoleucine >> Ile >> I',back: 'hola', img: "/images/isoleucine-Ile-i.jpg"},   


//{front: 'd-Glucose',back: 'hola', img: "/images/d-glucose.png"}, 
//{front: 'd-Ribose',back: 'hola', img: "/images/d-ribose.png"},              
{front: 'Proline >> Pro >> P',back: 'hola', img: "/images/proline-pro-p.png"},        
//{front: 'Cytosine >> C',back: 'hola', img: "/images/cytosine-c.png"},
//{front: 'Guanine >> G',back: 'hola', img: "/images/guanine-g.png"},  
//{front: 'cholesterol',back: 'hola', img: "/images/cholesterol.png"}, 
//{front: 'Thymine >> T',back: 'hola', img: "/images/thymine-t.png"}, 
//{front: 'Adenine >> A',back: 'hola', img: "/images/adenine-a.png"},
{front: 'Arginine >> Arg >> R',back: 'hola', img: "/images/arginine-arg-r.png"},    

//{front: 'Cytosine Atom Numbers',back: 'hola', img: "/images/cytosine-atom-numbers.png"},  
//{front: 'Guanine Atom Numbers',back: 'hola', img: "/images/guanine-atom-numbers.png"}, 
//{front: 'Adenine Atom Numbers',back: 'hola', img: "/images/adenine-atom-numbers.png"},  
//{front: 'U-A Base Pair',back: 'hola', img: "/images/u-a-base-pair.png"},
//{front: 'Peptide Bond',back: 'hola', img: "/images/peptide-bond.png"},   
//{front: 'Uracil Atom Numbers',back: 'hola', img: "/images/uracil-atom-numbers.png"},
//{front: 'L-a-Amino Acid',back: 'hola', img: "/images/l-a-amino-acit.png"},  
//{front: 'C-G Base Pair',back: 'hola', img: "/images/c-g-base-pair.png"}, 
]

