import React, { useState, useEffect } from "react";
import Icon from "antd/es/icon";
import "./SelectionCard.css";

const typeColor = {
  verb: "red",
  adjective: "orange",
  adverb: "green"
};
export default ({ wordData, changeState, forgotten = false, selectWord }) => {
  const [wordState, setWordState] = useState(false);
  
  let type = `${wordData.type ? wordData.type : 'noun'}s`;
  
  return (
    <div
      className={`select-card ${forgotten ? "forgotten-card" : null}`}
      style={{
        border: `3px solid ${wordData.type ? typeColor[wordData.type] : "blue"}`
      }}
    >
      <div
        className="select-card-corner"
        style={{
          background: `${wordData.type ? typeColor[wordData.type] : "blue"}`
        }}
      ></div>
      {/* <div className='select-card-corner right' style={{background: `${wordData.type ? typeColor[wordData.type] : 'blue'}`}}></div> */}
      <div className="select-card-word-row">
        <span className={`select-translation ${wordState ? "" : "hide"}`}>
          {wordData.translation ? wordData.translation.split(";")[0] : null}
        </span>
        <span
          className="select-word"
          onMouseOver={() => setWordState(true)}
          onMouseOut={() => setWordState(false)}
        >
          {wordData.word}
        </span>

        <div
          className={`delete crd ${forgotten ? "hidden" : ""}`}
          onClick={() => changeState(wordData)}
        >
          <Icon type="redo" />
        </div>
      </div>
      <div className="select-card-add-row">
        <div onClick={()=>selectWord(type, wordData)}><Icon type="plus-circle" /></div>
      </div>
    </div>
  );
};
