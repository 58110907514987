import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import nouns from "../base-data/nouns";
import verbsObject from "../base-data/verbs.json";
import adverbs from "../base-data/adverbs.js";
import adjectives from "../base-data/adjectives.js";
import "./CardSelection.css";
import Icon from "antd/es/icon";
import SelectionCard from "./SelectionCard";
import SelectedCard from './SelectedCard';
import WordTypeSelection from "./WordTypeSelection";
import ForgottenCards from "./ForgottenCards";

let nounsUnsorted = [...nouns];
let verbs = [];
let wordTypesArrays = {
  nouns: [],
  adjectives: [],
  verbs: [],
  adverbs: [],
  custom: []
};
Object.keys(verbsObject).forEach(verbObj => {
  nouns.push(verbsObject[verbObj]);
  verbs.push(verbsObject[verbObj]);
});


// :::NEW SORTING LOGIC:::
const sortWordArray = (array = []) => {
  let userContext = JSON.parse(localStorage.getItem('userContext')) || {cardDictionary: {} }
  let returnedArray = array.sort((a, b) => {
    if (a.word.toLowerCase() < b.word.toLowerCase()) {
      return -1;
    }
    if (a.word.toLowerCase() > b.word.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  return returnedArray.filter(wordData => !userContext.cardDictionary[wordData.word] )

};

wordTypesArrays["nouns"] = sortWordArray(nounsUnsorted);
wordTypesArrays["adjectives"] = sortWordArray(adjectives);
wordTypesArrays["verbs"] = sortWordArray(verbs);
wordTypesArrays["adverbs"] = sortWordArray(adverbs);
wordTypesArrays["custom"] = sortWordArray();

//:::END:::

adverbs.forEach(adverb => {
  if (adverb.word) {
    nouns.push(adverb);
  }
});

adjectives.forEach(adjective => {
  if (adjective.word) {
    nouns.push(adjective);
  }
});

let nounzz = nouns.sort((a, b) => {
  if (a.word.toLowerCase() < b.word.toLowerCase()) {
    return -1;
  }
  if (a.word.toLowerCase() > b.word.toLowerCase()) {
    return 1;
  }
  return 0;
});

const CardSelection = props => {
  const [state, setState] = useState({
    allNounsArray: [],
    lastLetter: "",
    totalCount: 0,
    dictionary: {},
    forgottenCards: [],
    wordTypesArrays,
    selectedType: "",
    selectedCards: []
  });
  let history = useHistory();

  useEffect(() => {
    //Check if a session is in progress and the user tried to redirect to the page
    let session = JSON.parse(localStorage.getItem("session"));

    console.log("3", state);
    if (session && session.inProgress) {
      history.push({
        pathname: "/dashboard/learn",
        state: { cards: session.cardsArray }
      });
    }

    /* setState({...state, allNounsArray, dictionary, lastLetter})  */
  });

  useMemo(() => {
    //Create the allNounsArray that will be set to state
    let allNounsArray = [];
    let lastLetter = "";
    let totalCount = 0;
    let currentLetter = "";
    let currentCount = 0;
    let currentLetterArray = [];
    let dictionary = {};
    let nounz = nounzz;
    let userContext = JSON.parse(localStorage.getItem("userContext")) || {
      cardDictionary: {}
    };
    console.log("USERCXT", userContext);
    nounz.forEach(obj => {
      let letter = obj.word.toLowerCase().split("")[0];

      if (currentLetter !== letter) {
        //if the current word begins with a new letter

        currentLetter = letter;
        dictionary[letter] = [];
        if (totalCount < 15 && currentLetterArray.length < 3) {
          if (userContext && !userContext.cardDictionary[obj.word]) {
            console.log("1", obj.word);
            currentLetterArray.push(obj);
            totalCount++;
          }
        }
      } else {
        if (currentLetterArray.length >= 3) {
          // when the current word row is full, switch to a new first letter and row
          lastLetter = currentLetter;
          allNounsArray.push(currentLetterArray);
          currentLetterArray = [];
        }
        if (
          totalCount < 15 &&
          currentLetterArray.length < 3 &&
          lastLetter !== letter
        ) {
          if (userContext && !userContext.cardDictionary[obj.word]) {
            console.log("2", obj.word);
            currentLetterArray.push(obj);
            totalCount++;
          }
        } else {
          if (!userContext.cardDictionary[obj.word]) {
            dictionary[letter].push(obj);
          }
        }
      }
    });

    let sessionArray = JSON.parse(localStorage.getItem("sessionsArray")) || [];
    let forgottenSession = sessionArray.filter(
      session => session.sessionID === 0
    );

    let forgottenCards = [];
    console.log("useeffectstate", state);
    if (forgottenSession.length) {
      forgottenCards = forgottenSession[0].cardsArray;

      forgottenCards.forEach(card => {
        totalCount++;
      });
    }

    setState({
      ...state,
      allNounsArray,
      dictionary,
      lastLetter,
      forgottenCards,
      totalCount
    });
    console.log("USEMEMOHAPPENED", state, "totalcount", allNounsArray);
  }, []);

  let replaceArray = index => {
    let userContext = JSON.parse(localStorage.getItem("userContext")) || {
      cardDictionary: {}
    };
    let allNounsArray = state.allNounsArray;
    let allLetters = Object.keys(state.dictionary);
    let lastLetter = state.lastLetter;

    let letterIndex = allLetters.indexOf(lastLetter);
    let nextLetter = allLetters[letterIndex + 1];
    let dictionary = state.dictionary;
    dictionary[nextLetter] = dictionary[nextLetter].filter(
      obj => !userContext.cardDictionary[obj.word]
    );
    //Filter the words in the next letter to describe what is going on
    let replacementArray = dictionary[nextLetter].splice(0, 3);
    //dictionary[nextLetter] = dictionary[nextLetter].slice()

    allNounsArray[index] = replacementArray;
    lastLetter = nextLetter;

    setState({ ...state, allNounsArray, lastLetter });
  };
  let changeState = wordData => {
    let { word } = wordData;
    let userContext = JSON.parse(localStorage.getItem("userContext")) || {
      cardDictionary: {}
    };
    let allNounsArray = state.allNounsArray;
    let dictionary = state.dictionary;
    let letter = word.split("")[0].toLowerCase();

    state.dictionary[letter].push(wordData);
    let replacement = state.dictionary[letter].shift();

    let newArray = allNounsArray.map(array => {
      let index = array.findIndex(wordObject => {
        return wordObject.word === word;
      });

      if (index !== -1) {
        console.log(index);
        array[index] = replacement;
      }

      return array;
    });
    allNounsArray = newArray;

    setState({ ...state, allNounsArray });
  };

  let addArray = () => {
    let allLetters = Object.keys(state.dictionary);
    let dictionary = state.dictionary;
    let lastLetter = state.lastLetter;

    let letterIndex = allLetters.indexOf(state.lastLetter);
    let nextLetter = allLetters[letterIndex + 1];
    let newArray = dictionary[nextLetter].splice(0, 3);

    state.allNounsArray.push(newArray);
    lastLetter = nextLetter;
    state.totalCount = state.totalCount + newArray.length;
    setState({ ...state, lastLetter, dictionary });
  };

  let removeArray = index => {
    let letter = state.allNounsArray[index][0].word.split("")[0];
    let dictionary = state.dictionary;
    let lastLetter = state.lastLetter;
    if (state.totalCount > 15) {
      //add array back to its letter's dictionary list
      dictionary[letter] = [
        ...state.allNounsArray[index],
        ...dictionary[letter]
      ];

      if (letter === state.lastLetter) {
        let allLetters = Object.keys(dictionary);
        let letterIndex = allLetters.indexOf(lastLetter);
        lastLetter = allLetters[letterIndex - 1];
      }

      let [removedArray] = state.allNounsArray.splice(index, 1);
      state.totalCount = state.totalCount - removedArray.length;

      setState({ ...state, lastLetter, dictionary });
    }
  };
  let switchWordType = type => {
    if (state.selectedType === type) {
      type = "";
    }
    console.log("1", state);

    setState({
      ...state,
      selectedType: type
    });
  };

  let selectWord = (wordType, wordData) => {
    let selectedCards = [...state.selectedCards, { ...wordData }];
    let newWordTypeArray = [...state.wordTypesArrays[wordType]].filter(
      word => word.word !== wordData.word
    );

    setState({
      ...state,
      selectedCards,
      wordTypesArrays: {
        ...state.wordTypesArrays,
        [wordType]: newWordTypeArray
      }
    });
  };

  const removeWord = (wordData) => {
    let type = `${wordData.type ? wordData.type : 'noun'}s`
    //remove from selected
    let selectedCards = [...state.selectedCards].filter(word => word.word !== wordData.word)
    //add to wordType array
    let newWordTypeArray = sortWordArray([...state.wordTypesArrays[type], {...wordData}])

    setState({
      ...state,
      selectedCards,
      wordTypesArrays: {
        ...state.wordTypesArrays,
        [type]: newWordTypeArray
      }
    });
  }

  let startLearning = () => {
    //Check what the most recent sessionID is.
    let lastSessionID = localStorage.getItem("lastSessionID");
    let allNounsArray = state.allNounsArray;

    if (lastSessionID) {
      lastSessionID = lastSessionID++;
    } else {
      lastSessionID = 1;
      localStorage.setItem("lastSessionID", lastSessionID);
    }
    
    let cardsArray = state.selectedCards.map(obj => {
      obj = {
        ...obj,
        learned: false,
        rememberedStatus: 0,
        sessionId: lastSessionID
      };

      return obj

    });

    if (state.forgottenCards.length) {
      let forgottenCardsArray = [...state.forgottenCards].map(card => {
        card.sessionId = lastSessionID;
        return card;
      });
      cardsArray = [...cardsArray, ...forgottenCardsArray];
    }
    //set the session and push it to the sessions array + set it to local storage
    let sessionsArray = JSON.parse(localStorage.getItem("sessionsArray")) || [];

    sessionsArray = sessionsArray.map(session => {
      if (session.sessionID === 0) {
        session.cardsArray = [];
      }
      return session;
    });

    let startDate = Date.now();
    let nextTestDate = startDate + 60 * 60 * 12 * 1000;
    let session = {
      sessionID: lastSessionID,
      inProgress: true,
      cardsArray,
      startDate,
      nextTestDate,
      testCount: 0,
      nextTestDate: startDate + 60 * 60 * 12 * 1000
    };

    let totalLength = state.selectedCards.length + state.forgottenCards.length;

    if(totalLength >= 15){
      localStorage.setItem("sessionsArray", JSON.stringify([...sessionsArray]));
    localStorage.setItem("session", JSON.stringify(session));

    history.push({
      pathname: "/dashboard/learn",
      state: { cards: cardsArray }
    });
    }
    
  };

  return (
    <div className="card-select-container-fullpage">
      <div className="card-select-container">
        <div className="selected-cards">
          <h1>Selected Cards</h1>
          <div className="selected-cards-box">
            {state.forgottenCards.length ? (
              <ForgottenCards forgottenCards={state.forgottenCards} />
            ) : null}
           <div className="regular-selected-card-container">
           {state.selectedCards.length
              ? state.selectedCards.map(wordData => {
                  return <SelectedCard wordData={wordData} removeWord={removeWord} />;
                })
              : null}
           </div>
            
          </div>
        </div>

        <div className="card-select-header">
          {state.allNounsArray.length ? (
            <h1 className="forgotten-header">Select New Cards</h1>
          ) : null}
          <div className="card-select-tabs">
            <div
              className="card-select-tab"
              onClick={() => switchWordType("nouns")}
              style={{ border: `3px solid ${`blue`}` }}
            >
              Nouns
            </div>
            <div
              className="card-select-tab"
              onClick={() => switchWordType("verbs")}
              style={{ border: `3px solid ${`red`}` }}
            >
              Verbs
            </div>
            <div
              className="card-select-tab"
              onClick={() => switchWordType("adjectives")}
              style={{ border: `3px solid ${`orange`}` }}
            >
              Adjectives
            </div>
            <div
              className="card-select-tab"
              onClick={() => switchWordType("adverbs")}
              style={{ border: `3px solid ${`green`}` }}
            >
              Adverbs
            </div>
            <div
              className="card-select-tab"
              onClick={() => switchWordType("custom")}
              style={{ border: `3px solid ${`purple`}` }}
            >
              Custom
            </div>
          </div>
        </div>

        {state.selectedType ? (
          <WordTypeSelection
            changeState={changeState}
            array={state.wordTypesArrays[state.selectedType]}
            selectWord={selectWord}
          />
        ) : (
          state.allNounsArray.map((array, index) => {
            return (
              <div className="card-select-row">
                <div className="row-top">
                  <div className="delete-rows">
                    <div
                      className="deleted-d"
                      onClick={() => removeArray(index)}
                    >
                      {/* <Icon type="delete" /> */}x
                    </div>
                    <div className="swap" onClick={() => replaceArray(index)}>
                      <Icon type="swap" />
                    </div>
                  </div>
                </div>
                <div className="row-bottom">
                  {array.map(wordData => {
                    return (
                      <SelectionCard
                        state={state}
                        wordData={wordData}
                        changeState={changeState}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })
        )}
      </div>
      <button className="more-cards-btn" onClick={addArray}>
        +Add More Cards
      </button>
      <button className="learn-btn" onClick={startLearning}>
        Start Learning
      </button>
    </div>
  );
};

export default CardSelection;
