import React, { useState, useEffect, useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import "./PracticeSentences.css";
import SRS from "../../helpers/SRS";
import TestTemplate from './TestTemplate'
import TestsPage from "../tests/TestsPage";
import tests from "../../dummy-data";

let createSentenceArray = (array) => {
    let sentences = [];

    array.forEach((session)=>{
        
        if(sentences.length < 100){
            
            session.cardsArray.forEach(card => {
                
            if(sentences.length < 100 && card.sentences){
                card.sentences.slice(0,2).forEach(crd => {
                    sentences.push(crd)
                })
               
            }
        })}
        
    })
    return sentences
}

let aminoAcidsGroup = {name: "Amino Acids", terma: [...tests.testOne], subGroups: {}}
const PracticeSentences = () => {
    let [state, setState] = useState({
        sentences: [],
        current: '',
        cardGroups: []
    })
    let [cardGroups, setCardGroups] = useState([])
    let sessionsArray = JSON.parse(localStorage.getItem('sessionsArray')) || [];

    let srs = new SRS(3, createSentenceArray(sessionsArray))

    let remembered = () => {

    }
    useEffect(()=>{
        console.log(srs)

        let cardGroups = [aminoAcidsGroup];
        setState({
            ...state,
            sentences: createSentenceArray(sessionsArray),
            current: srs.currentCard,
            cardGroups
        })
    }, [])

    return (
        <div className="practice-container">
            <TestTemplate/>
        </div>
    )
}

export default PracticeSentences