import React, { PureComponent, useState, useEffect } from "react";
import "./NoteTaker.css";

/* class NoteTaker extends React.Component {
    state = {  }

    componentDidMount(){
        
        console.log(document.querySelector('.pad-container').scrollHeight % window.innerHeight / 10)
    }
    render() {
         
        return ( 
        <div className="note-taker">
            <div className="pad-container">
                <div className="pad-line" styles={{height: window.innerHeight / 10}}>

                </div>
            </div>
            
            
        </div> );
    }
} */

const NoteBlock = ({width}) => {
  return (
    <div className="note-block" style={{ width }}></div>
  );
};

const NoteTaker = () => {
  let [state, setState] = useState({ rowHeight: 5 });

  useEffect(() => {
    let windowHeight = window.innerHeight;
    let rowHeight = document.querySelector(".pad-container").scrollHeight / 10;
    let noteBlockWidth = document.querySelector(".pad-row").scrollWidth / 8;

    setState({
      ...state,
      rowHeight,
      noteBlockWidth,
    });
  }, []);
  return (
    <div className="note-taker">
      <div className="pad-container">
        {Array(10).fill(
          <div className="pad-row" style={{ height: state.rowHeight }}>
            {Array(8).fill(<NoteBlock width={state.noteBlockWidth} />)}
          </div>
        )}
      </div>
      {/*<input className="entry-1" type="text"/>*/}
    </div>
  );
};

export default NoteTaker;
