
export const createTestDataB = () => {
  const weekdays = {
    0: "Sun",
    1: "Mon",
    2: "Tues",
    3: "Wed",
    4: "Thurs",
    5: "Fri",
    6: "Sat"
  };

  let today = new Date(Date.now()).getDay();

  let sessions = JSON.parse(localStorage.getItem("sessionsArray")) || [];
  // check if nextTestDate is more than a week away
  const testIntervals = {
    /* This object maps the key (corresponding to the 'testCount'[i.e has it been tested once, twice, etc] 
            value of the session) to its appropriate interval of time. */
    0: 60 * 60 * 12 * 1000,
    1: 60 * 60 * 48 * 1000,
    2: 60 * 60 * 24 * 5 * 1000,
    3: 60 * 60 * 24 * 7 * 1000
  };

  let cardsPerDay = {
    0: { due: 0, overdue: 0 },
    1: { due: 0, overdue: 0 },
    2: { due: 0, overdue: 0 },
    3: { due: 0, overdue: 0 },
    4: { due: 0, overdue: 0 },
    5: { due: 0, overdue: 0 },
    6: { due: 0, overdue: 0 }
  };
  // Push any sessions that are due for testing to this array.
  let testsArray = [];

  console.log("sessions", sessions);
  // Look through sessions and update dashboard data accordingly
  sessions.forEach(session => {
    let currentDay = Date.now();
    let sixthDay = currentDay + 60 * 60 * 24 * 6 * 1000;

    // if the nextTestDate has already passed/is overdue, add them to the current day
    if (session.nextTestDate < Date.now() && session.sessionID !== 0) {
      let currentDayIndex = new Date(currentDay).getDay();
      if (currentDayIndex === "Sun") {
        console.log("currentday session", session);
      }
     
      cardsPerDay[currentDayIndex].overdue += session.cardsArray.length;
      cardsPerDay[currentDayIndex].due += session.cardsArray.length;

      testsArray.push(session);
    }

    // if the session's nextTestDate is within this week (not greater than the UTC time 8 days later)
    else if (session.nextTestDate < sixthDay && session.sessionID !== 0) {
      let sessionTestWeekday = new Date(session.nextTestDate).getDay();
      if (sessionTestWeekday === today) {
        console.log("currentday session \n", sessionTestWeekday, session);
      }

      cardsPerDay[sessionTestWeekday].due += session.cardsArray.length;

      if (sessionTestWeekday === today && session.nextTestDate < Date.now()) {
        testsArray.push(session);
      }
    }
  });

  return { testsArray, cardsPerDay };
};

export const createTestData = () => {
  let testLimit = 100;
  let today = new Date(Date.now()).getDay();
  let sessions = JSON.parse(localStorage.getItem("sessionsArray")) || [];

  let cardsPerDay = {
    0: { due: 0, overdue: 0 },
    1: { due: 0, overdue: 0 },
    2: { due: 0, overdue: 0 },
    3: { due: 0, overdue: 0 },
    4: { due: 0, overdue: 0 },
    5: { due: 0, overdue: 0 },
    6: { due: 0, overdue: 0 }
  };
 
  // Push any sessions that are due for testing to this array.
  let testsArray = [];

  /* 
  -
  -set a day marker = 0
  -push all overdue into an array
  -sort sessions by date
  -
  -for each session:
    --run a while loop that ends when a day is found that the session can be added to
    --check how many are due today
    --if the number + the sessions count is under testLimit, add to the test array.
    --if over the testLimit, increment the marker
  */
  let lastFullDay;
  
  sessions = sessions.sort((a, b) => {
    if (a.nextTestDate > b.nextTestDate) {
      return -1;
    }
    if (a.nextTestDate < b.nextTestDate) {
      return 1;
     }
    return 0;
  });

  // Look through sessions and update dashboard data accordingly
  sessions.forEach(session => {
    let currentDay = Date.now();
    let sixthDay = currentDay + 60 * 60 * 24 * 6 * 1000;

    // if the nextTestDate has already passed/is overdue, add them to the current day
    if (session.nextTestDate < Date.now() && session.sessionID !== 0) {
      let currentDayIndex = new Date(currentDay).getDay();
      lastFullDay = currentDayIndex;
      //check the limit
      //--if there is space, add it to today
      //--if there is not space, add it to the next available day. before adding it, update the sessions nextTestDate
  
      if(cardsPerDay[currentDayIndex].due + session.cardsArray.length < testLimit){
        cardsPerDay[currentDayIndex].due += session.cardsArray.length;
        cardsPerDay[currentDayIndex].overdue += session.cardsArray.length;
        testsArray.push(session);
      }

      else if(cardsPerDay[currentDayIndex].due + session.cardsArray.length > testLimit){
        for(let i = 0; i < 6; i++ ){
          let nextDay = lastFullDay+1 > 6 ? 0 : lastFullDay+1;
          if(cardsPerDay[nextDay].due + session.cardsArray.length < testLimit){
            cardsPerDay[nextDay].due += session.cardsArray.length;
            break
          }
          else if(cardsPerDay[nextDay].due + session.cardsArray.length > testLimit){
            lastFullDay = nextDay
          }
        }   
      }  
    }

    // if the session's nextTestDate is within this week (not greater than the UTC time 8 days later)
    else if (session.nextTestDate < sixthDay && session.sessionID !== 0) {
      let sessionTestWeekday = new Date(session.nextTestDate).getDay();

      cardsPerDay[sessionTestWeekday].due += session.cardsArray.length;

      if (sessionTestWeekday === today && session.nextTestDate < Date.now()) {
        testsArray.push(session);
      }
    }
  });

  
  

  console.log("TEST ARRAY",testsArray)

  return {testsArray, cardsPerDay}
    
  
  ;
};
