import React, { Component, useState } from "react";
import logo from "../imgs/logo.svg";
import "./MemoryQuizPage.css";
import Card from "./Card";
import Icon from "antd/lib/icon";
import StarredWord from "./StarredWord";
import SaveLessonModal from "./Modals/SaveLessonModal";
import { Spring, config, Transition } from "react-spring";
import QuizSentenceHolder from "./QuizSentenceHolder"
import LearnSentenceContainer from "./LearnSentenceContainer"
import { useHistory } from "react-router-dom";
import axios from "axios";
import nouns from "../base-data/nouns";

let nounz = nouns.sort((a, b) => {
  if (a.word.toLowerCase() < b.word.toLowerCase()) {
    return -1;
  }
  if (a.word.toLowerCase() > b.word.toLowerCase()) {
    return 1;
  }
  return 0;
});

const data = [
  {
    word: "la aparición",
    definition: "appearance; apparition(ghost)",
    examples: [
      {
        native:
          "'Yo soy el Espíritu de la Navidad Presente.', dijo la aparición. '¡Mírame!'",
        english:
          "'I am the Ghost of Christmas Present.', said the Spirit. 'Look upon me!'"
      },
      {
        native: "Nadie se dio cuenta de la aparición del oso",
        english: "No one noticed the bear's appearance."
      },
      {
        native: "Su repentina aparición nos sorprendió a todos.",
        english: "His sudden appearance surprised us all."
      }
    ]
  },
  {
    word: "la oración",
    definition: "sentence; prayer",
    examples: [
      { native: "Di tus oraciones.", english: "Say your prayers." },
      {
        native: "¡No traduzcas esta oración!",
        english: "Don't translate this sentence."
      },
      {
        native: "Corregid las siguientes oraciones.",
        english: "Correct the following sentences."
      }
    ]
  },
  {
    word: "la herramienta",
    definition: "tool",
    examples: [
      {
        native: "Necesitamos algunas herramientas.",
        english: "We need some tools."
      },
      {
        native: "Ojalá nadie tome mis herramientas.",
        english: "I hope no one takes my tools."
      },
      { native: "Voy a coger mis herramientas.", english: "I'll get my tools." }
    ]
  },
  {
    word: "la financiación",
    definition: "funding",
    examples: [
      {
        native: "La industria depende fuertemente de la financiación estatal.",
        english: "The industry is heavily dependent on government funding."
      },
      {
        native:
          "Necesitamos desesperadamente más dinero para ayudar a la financiación de nuestro proyecto.",
        english: "We desperately need more money to help fund our project."
      },
      {
        native: "No tenemos un mecanismo para sustentar la financiación.",
        english: "We don't have a mechanism for sustained funding."
      }
    ]
  },
  {
    word: "la ola",
    definition: "wave",
    examples: [
      {
        native: "Una gran ola volcó su canoa.",
        english: "A big wave turned over his canoe."
      },
      {
        native:
          "Nuestra ciudad está enfrentando una ola de protestas estudiantiles.",
        english: "Our town is facing a wave of student protest."
      },
      {
        native: "Estamos en la segunda semana de una implacable ola de calor.",
        english: "We're in the second week of an unrelenting heat wave."
      }
    ]
  },
  {
    word: "la tienda",
    definition: "shop",
    examples: [
      {
        native: "¿Recuerdas esa tienda?",
        english: "Do you remember that store?"
      },
      { native: "Cerraron la tienda.", english: "They closed the shop." },
      {
        native: "Enséñame alguna tienda barata.",
        english: "Do you know of any inexpensive stores?"
      }
    ]
  },
  {
    word: "la carcasa",
    definition: "case, casing; carcass",
    examples: [
      { native: "La carcasa del ordenador.", english: "The computer case" },
      {
        native: "Todo lo que sobró de la cebra fue la carcasa.",
        english: "All that was left over from the zebra was the carcass."
      }
    ]
  },
  {
    word: "el título",
    definition: "title",
    examples: [
      { native: "El título es engañoso.", english: "The title is misleading." },
      {
        native: "¿Cuál es el título del libro?",
        english: "What is the title of the book?"
      },
      {
        native: "Él tiene el título de abogado.",
        english: "He is qualified to be(has the title of) a lawyer."
      }
    ]
  },
  {
    word: "la confianza",
    definition: "trust; confidence",
    examples: [
      { native: "Ten confianza.", english: "Be confident." },
      { native: "Tom tenía confianza.", english: "Tom had confidence" },
      { native: "La confianza se gana.", english: "Trust is earned." }
    ]
  },
  {
    word: "el reloj",
    definition: "wristwatch",
    examples: [
      { native: "Perdí el reloj.", english: "I lost the watch." },
      { native: "El reloj atrasa.", english: "The watch is slow." },
      { native: "Enséñame otro reloj.", english: "Show me another watch." }
    ]
  },
  {
    word: "la ubicación",
    definition: "location",
    examples: [
      { native: "¿Cuál es tu ubicación?", english: "What is your location?" },
      {
        native: "Podís ubicarme en este número.",
        english: "You can reach me at this number."
      },
      {
        native: "Indíqueme su ubicación, por favor.",
        english: "Please tell me your location"
      }
    ]
  },
  {
    word: "el altavoz",
    definition: "speaker(orator); speaker(audio)",
    examples: [
      {
        native: "Marca es un buen altavoz",
        english: "Marca is a good speaker"
      },
      {
        native: "Ya no funciona el altavoz.",
        english: "The speaker does not work anymore."
      },
      {
        native: "La estaban llamando por el altavoz.",
        english: "They were calling her through the speaker."
      }
    ]
  },
  {
    word: "la solicitud",
    definition: "a request; application",
    examples: [
      {
        native: "Nuestra solicitud fue aprobada.",
        english: "Our request was approved."
      },
      {
        native: "Las solicitudes vencen el lunes.",
        english: "Applications are due by Monday."
      },
      {
        native: "Acabo de entregar mi solicitud.",
        english: "I just submitted my application."
      }
    ]
  },
  {
    word: "la apertura",
    definition: "opening",
    examples: [
      {
        native:
          "Estoy tan feliz de que me pidieras que diera el discurso de apertura esta noche.",
        english:
          "I'm so glad that you asked me to give the opening speech tonight."
      },
      {
        native: "La apertura de una libreta de ahorros en un banco.",
        english: "The opening of a savings account in a bank."
      },
      {
        native: "El presidente hizo la apertura a la reunión.",
        english: "The president opened (made the opening of) the meeting."
      }
    ]
  },
  {
    word: "el juguete",
    definition: "toy",
    examples: [
      { native: "Mi juguete está roto.", english: "My toy is broken." },
      {
        native: "Esa fábrica hace juguetes.",
        english: "That factory makes toys."
      },
      { native: "Le gustan los juguetes.", english: "He loves toys." }
    ]
  },
  {
    word: "la ganancia",
    definition: "gain; profits",
    examples: [
      {
        native: "Compartimos las ganancias.",
        english: "We share the profits."
      },
      {
        native: "Su pérdida supone nuestra ganancia.",
        english: "Their loss means our gain."
      },
      {
        native: "Las ganancias fueron muy altas.",
        english: "The profits were very high."
      }
    ]
  },
  {
    definition: "asset",
    word: "el activo",
    examples: [
      {
        native: "El trabajo de Scott es evaluar los activos de las compañías.",
        english: "Scott's job is to evaluate the assets of companies."
      }
    ]
  },
  {
    definition: "pool",
    word: "la piscina",
    examples: [
      { native: "¡A la piscina!", english: "To the swimming pool!" },
      { native: "La piscina está llena.", english: "The pool is full." },
      {
        native: "Todos saltaron a la piscina.",
        english: "Everyone jumped into the pool."
      }
    ]
  },
  {
    definition: "tendency; trend",
    word: "la tendencia",
    examples: [
      {
        native: "Tom tiene tendencia a exagerar.",
        english: "Tom has a tendency to exaggerate."
      },
      {
        native: "Sabe mucho de las últimas tendencias.",
        english: "She knows a lot about the latest trends."
      },
      {
        native: "Esa tendencia es marcada entre los americanos.",
        english: "That tendency is strong among Americans."
      }
    ]
  },
  {
    definition: "survey",
    word: "la encuesta",
    examples: [
      {
        native: "Él participó en una encuesta en línea.",
        english: "He participated in an online survey."
      },
      {
        native:
          "La encuesta reveló que la mayoría de las mujeres embarazadas prefieren niñas.",
        english: "The survey revealed that most pregnant women prefer girls."
      },
      {
        native: "Así es. Las encuestas lo comprueban.",
        english: "That's right. Surveys confirm it."
      }
    ]
  },
  {
    definition: "joy",
    word: "la alegría",
    examples: [
      { native: "¡Qué alegría!", english: "What joy!" },
      { native: "Salté de alegría.", english: "I jumped for joy." },
      { native: "Me alegra verte.", english: "I'm glad to see you." }
    ]
  },
  {
    definition: "delay",
    word: "el retraso",
    examples: [
      { native: "¡Feliz retraso!", english: "Happy belated!" },
      { native: "Disculpe mi retraso.", english: "Sorry I'm late" },
      { native: "Vamos con retraso.", english: "We're late." }
    ]
  }
];

class MemoryQuizPage extends Component {
  state = {
    cards: [],
    moduloIndex: "",
    currentCard: {},
    notRem: [],
    remOnce: [],
    remTwice: [],
    remOnceQueue: "",
    afterInsert: null,
    threshold: false,
    totalClicks: 0,
    afterThresholdCount: 0,
    remembered: [],
    forgotten: [],
    allRemembered: false,
    forgottenCard: "",
    starredArray: [{ definition: "cat", word: "el gato", starred: true }],
    exStarHover: false,
    showSideBar: false,
    flipped: false,
    totalRemembered: 0
  };

  inputHandler = e => {
    console.log(e.target);
  };

  forgotten = () => {
    let currentCard = this.state.currentCard;
    this.conditionFlip();

    if (this.state.threshold) {
      console.log("F:", "0");

      if (this.state.afterInsert) {
        console.log("F:", "0a");
        let newCurrentCard = this.state.afterInsert; //T:5

        this.setState({
          afterThresholdCount: this.state.afterThresholdCount + 1,
          forgotten: [...this.state.forgotten, currentCard],
          currentCard: newCurrentCard,
          afterInsert: null
        });
      } else if (
        this.state.afterThresholdCount % this.state.moduloIndex === 0 &&
        this.state.remOnce.length
      ) {
        console.log("F: 0b");
        let remTwice = [...this.state.remTwice]; //^^T: 1 equivalent, takes forgotten card when modulo has arrived and afterinsert is needed
        let newCurrentCard = remTwice.shift();
        let remOnce = [...this.state.remOnce];
        let nextCard = remOnce.shift();

        this.setState({
          remTwice: remTwice,
          forgotten: [...this.state.forgotten, currentCard],
          currentCard: newCurrentCard,
          afterThresholdCount: this.state.afterThresholdCount + 1,
          afterInsert: nextCard,
          remOnce
        });
      } else if (this.state.remembered && !this.state.remTwice.length) {
        console.log("F: 0c1");
        let remembered = [...this.state.remembered];
        let nextCurrentCard = remembered.shift();
        let currentCard = this.state.currentCard;

        this.setState({
          remembered,
          currentCard: nextCurrentCard,
          forgotten: [...this.state.forgotten, currentCard],
          allRemembered: true
        });
      } else {
        console.log("F:", "0c2");
        let currentCard = this.state.currentCard;

        if (!this.state.remOnce.length && this.state.remTwice) {
          console.log("F:", "0c1");
          let remTwiceCopy = [...this.state.remTwice];
          let newCurrentCard = remTwiceCopy.shift();

          this.setState({
            currentCard: newCurrentCard,
            forgotten: [...this.state.forgotten, currentCard],
            remTwice: remTwiceCopy,
            afterThresholdCount: this.state.afterThresholdCount + 1
          });
        } else {
          console.log("F:", "0c2");
          let remOnceCopy = [...this.state.remOnce];
          let newCurrentCard = remOnceCopy.shift();

          this.setState({
            currentCard: newCurrentCard,
            forgotten: [...this.state.forgotten, currentCard],
            remOnce: remOnceCopy,
            afterThresholdCount: this.state.afterThresholdCount + 1
          });
        }
      }
    } else if (this.state.afterInsert) {
      console.log("F:1"); // 3 equivalent
      let newCurrentCard = this.state.afterInsert;
      this.setState({
        forgotten: [...this.state.forgotten, currentCard],
        currentCard: newCurrentCard,
        afterInsert: null
      });
    } else {
      console.log("F: 2"); //UNFINISHED::meant to deal with card shifts when there are both remOnce and notRem queues.
      return null;
      /* let notRem = [...this.state.notRem]
      let afterInsertCard = notRem.shift(); 
      let remCard = [...this.state.remOnce].shift();
      let currentCard = this.state.currentCard
      
      this.setState({
        currentCard: this.state.remOnce.shift(), 
        remOnceQueue: remCard,
        remOnce: [...this.state.remOnce, currentCard],
        afterInsert: afterInsertCard,
        notRem
      }) */
    }
  };

  addAfterThreshold = () => {
    this.conditionFlip();
    //INSERT PROTOCOL
    if (
      this.state.currentCard.key + 1 === this.state.cards.length &&
      this.state.afterThresholdCount === 0
    ) {
      console.log("T");
      let currentCard = { ...this.state.currentCard, rememberedStatus: 1 };
      console.log("CURRENTCARD-REM1-Update", currentCard);
      let remOnce = [...this.state.remOnce, currentCard];
      let newCurrentCard = remOnce.shift();

      this.setState({
        currentCard: newCurrentCard,
        afterThresholdCount: this.state.afterThresholdCount + 1,
        remOnce
      });
    } else if (
      this.state.afterThresholdCount % this.state.moduloIndex === 0 &&
      this.state.remOnce.length
    ) {
      console.log("T: 1");
      let currentCard = { ...this.state.currentCard, rememberedStatus: 2 };
      let remTwice = [...this.state.remTwice, currentCard];
      let newCurrentCard = remTwice.shift();
      let remOnce = [...this.state.remOnce];
      let nextCard = remOnce.shift();

      this.setState({
        remTwice: remTwice,
        currentCard: newCurrentCard,
        afterThresholdCount: this.state.afterThresholdCount + 1,
        afterInsert: nextCard,
        remOnce
      });
    }

    /* else if(this.state.forgottenCard){ console.log('T:XXforgotten')
      let remembered = [...this.state.remembered]
      let currentCard = this.state.currentCard
      let nextCurrentCard = remembered.shift()
      let remTwice = [...this.state.remTwice]
      let afterInsertCard = this.state.afterInsert
      let forgotten = [...this.state.forgotten]
      let forgottenCard = forgotten.shift()
      let nextCurrentCard = this.state.forgottenCard
      
      
      this.setState({
        afterThresholdCount: this.state.afterThresholdCount + 1,
        currentCard: nextCurrentCard,
        forgotten: '',
        afterInsert: afterInsertCard,
        remembered: [...remembered, currentCard],
        remTwice
      })
    } */

    //ONCE REMONCE QUEUE IS EMPTY
    else if (
      this.state.afterThresholdCount % this.state.moduloIndex === 0 &&
      !this.state.remOnce.length &&
      this.state.afterInsert
    ) {
      console.log("T:", "2test");
      let remembered = [...this.state.remembered];
      let currentCard = { ...this.state.currentCard, rememberedStatus: 3 };

      let nextCurrentCard = remembered.shift();
      let remTwice = [...this.state.remTwice];
      let afterInsertCard = this.state.afterInsert;
      let forgotten = [...this.state.forgotten];
      let forgottenCard = forgotten.shift();

      if (this.state.forgotten.length) {
        console.log("T:2Testa");
        this.setState({
          afterThresholdCount: this.state.afterThresholdCount + 1,
          currentCard: nextCurrentCard,
          afterInsert: afterInsertCard,
          remembered: [...remembered, currentCard],
          forgotten,
          forgottenCard,
          remTwice
        });
      } else if (this.state.forgottenCard) {
        let nextCurrentCard = this.state.forgottenCard;
        this.setState({
          afterThresholdCount: this.state.afterThresholdCount + 1,
          currentCard: nextCurrentCard,
          forgottenCard: "",
          afterInsert: afterInsertCard,
          remembered: [...remembered, currentCard],
          remTwice
        });
      } else {
        console.log("T:2Testb");
        this.setState({
          afterThresholdCount: this.state.afterThresholdCount + 1,
          currentCard: nextCurrentCard,
          afterInsert: afterInsertCard,
          remembered: [...remembered, currentCard],
          remTwice
        });
      }
    } else if (
      this.state.afterThresholdCount % this.state.moduloIndex === 0 &&
      !this.state.remOnce.length &&
      this.state.remTwice.length
    ) {
      console.log("T:", "2");
      let remembered = [...this.state.remembered];
      let currentCard = { ...this.state.currentCard, rememberedStatus: 3 };
      let nextCurrentCard = remembered.shift();
      let remTwice = [...this.state.remTwice];
      let afterInsertCard = remTwice.shift();

      if (this.state.forgotten.length) {
        let forgotten = [...this.state.forgotten];
        let forgottenCard = forgotten.shift();

        this.setState({
          afterThresholdCount: this.state.afterThresholdCount + 1,
          currentCard: nextCurrentCard,
          afterInsert: afterInsertCard,
          forgottenCard,
          forgotten,
          remembered: [...remembered, currentCard],
          remTwice
        });
      } else {
        this.setState({
          afterThresholdCount: this.state.afterThresholdCount + 1,
          currentCard: nextCurrentCard,
          afterInsert: afterInsertCard,
          remembered: [...remembered, currentCard],
          remTwice
        });
      }
    }
    //HANDLES ONCE ALL CARDS ARE REMEMBERED
    else if (
      (this.state.currentCard.key + 1 === this.state.cards.length &&
        !this.state.remTwice.length) ||
      !this.state.remTwice.length
    ) {
      console.log("T: Final Card");
      let currentCard = { ...this.state.currentCard, rememberedStatus: 3 };
      let remembered = [...this.state.remembered];

      if (
        this.state.forgottenCard &&
        this.state.afterThresholdCount % 3 === 0
      ) {
        console.log("T: Final Card A");
        let forgottenCard = this.state.forgottenCard;

        this.setState({
          remembered: [...this.state.remembered, currentCard],
          currentCard: forgottenCard,
          forgottenCard: "",
          afterThresholdCount: this.state.afterThresholdCount + 1
        });
      } else if (!this.state.allRemembered) {
        console.log("T: Final Card B");
        let nextCurrentCard = this.state.afterInsert;

        this.setState({
          currentCard: nextCurrentCard,
          remembered: [...remembered, currentCard],
          allRemembered: true,
          afterThresholdCount: this.state.afterThresholdCount + 1
        });
      } else if (this.state.forgotten.length && !this.state.forgottenCard) {
        console.log("T: Final Card C");
        let forgotten = [...this.state.forgotten];
        let forgottenCard = forgotten.shift();
        let nextCurrentCard = remembered.shift();

        this.setState({
          currentCard: nextCurrentCard,
          remembered: [...remembered, currentCard],
          forgottenCard,
          forgotten,
          afterInsert: null,
          afterThresholdCount: this.state.afterThresholdCount + 1
        });
      } else if (this.state.afterInsert) {
        console.log("T: Final Card D");
        let nextCurrentCard = this.state.afterInsert;

        this.setState({
          currentCard: nextCurrentCard,
          remembered: [...remembered, currentCard],
          afterInsert: null,
          afterThresholdCount: this.state.afterThresholdCount + 1
        });
      } else if (
        this.state.afterThresholdCount % this.state.moduloIndex === 0 ||
        this.state.afterThresholdCount % 5 === 0
      ) {
        console.log("T: Final Card E", this.state.currentCard.word);
        let remembered = [...this.state.remembered];
        let nextCurrentCard = remembered.shift();
        let afterInsert = remembered.shift();
        let currentCard = this.state.currentCard;

        this.setState({
          remembered: [...remembered, currentCard],
          currentCard: nextCurrentCard,
          afterInsert,
          afterThresholdCount: this.state.afterThresholdCount + 1
        });
      } else {
        console.log("T: Final Card F");
        let nextCurrentCard = remembered.shift();
        this.setState({
          currentCard: nextCurrentCard,
          remembered: [...remembered, currentCard],
          afterThresholdCount: this.state.afterThresholdCount + 1
        });
      }
    } else if (this.state.allRemembered) {
      let currentCard = { ...this.state.currentCard, rememberedStatus: 3 };
      let remembered = [...this.state.remembered];
      let nextCurrentCard = remembered.shift();

      this.setState({
        currentCard: nextCurrentCard,
        remembered: [...remembered, currentCard]
      });
    }

    //END::HANDLES ONCE ALL CARDS ARE REMEMBERED
    else if (
      this.state.currentCard.key === this.state.cards.length - 1 &&
      !this.state.remOnce.length
    ) {
      console.log("T:", "3A"); //HANDLES LAST NUMBER IN CARD LIST
      if (!this.state.afterInsert) {
        console.log("T:", "3A1"); //this is the one that malfunctions
        let currentCard = this.state.currentCard;
        let remTwice = [...this.state.remTwice];
        let newCurrentCard = remTwice.shift();

        this.setState({
          currentCard: newCurrentCard,
          remTwice: [...remTwice, this.state.currentCard]
        });
      } else {
        console.log("T:", "3A2");
        let nextCurrentCard = this.state.afterInsert;
        let currentCard = this.state.currentCard;
        let remTwice = [...this.state.remTwice];

        this.setState({
          afterThresholdCount: this.state.afterThresholdCount + 1,
          currentCard: nextCurrentCard,
          remTwice: [...this.state.remTwice, currentCard],
          afterInsert: null
        });
      }
    }
    //START::ONCE REMONCE QUEUE IS EMPTY
    else if (
      this.state.currentCard.key + 1 !== this.state.cards.length &&
      !this.state.remOnce.length &&
      this.state.afterInsert
    ) {
      console.log("T:", "3");
      let nextCurrentCard = this.state.afterInsert;
      let currentCard = { ...this.state.currentCard, rememberedStatus: 3 };
      let remTwice = [...this.state.remTwice];
      let afterInsert = remTwice.shift();

      if (this.state.forgottenCard) {
        console.log("T:", "3a");
        let nextCurrentCard = this.state.forgottenCard;

        this.setState({
          afterThresholdCount: this.state.afterThresholdCount + 1,
          currentCard: nextCurrentCard,
          remembered: [...this.state.remembered, currentCard],
          forgottenCard: ""
        });
      } else {
        console.log("T:", "3b");
        this.setState({
          afterThresholdCount: this.state.afterThresholdCount + 1,
          currentCard: nextCurrentCard,
          remembered: [...this.state.remembered, currentCard],
          afterInsert,
          remTwice
        });
      }
    } else if (!this.state.remOnce.length && this.state.remTwice.length) {
      console.log("T:", "4");

      let remTwice = [...this.state.remTwice];
      let nextCurrentCard = remTwice.shift();
      let currentCard = { ...this.state.currentCard, rememberedStatus: 3 };

      this.setState({
        afterThresholdCount: this.state.afterThresholdCount + 1,
        currentCard: nextCurrentCard,
        remTwice: remTwice,
        remembered: [...this.state.remembered, currentCard]
      });
    }
    //END::ONCE REMONCE QUEUE IS EMPTY
    else if (this.state.afterInsert) {
      console.log("T:", "5");
      let newCurrentCard = this.state.afterInsert;
      let currentCard = this.state.currentCard;

      this.setState({
        afterThresholdCount: this.state.afterThresholdCount + 1,
        remembered: [...this.state.remembered, currentCard],
        currentCard: newCurrentCard,
        afterInsert: null
      });
    } else if (this.state.remOnce.length === 1) {
      console.log("T:", "5A");
      let remTwice = [...this.state.remTwice];
      let afterInsert = remTwice.shift();
      let currentCard = this.state.currentCard;
      let remOnce = [...this.state.remOnce];
      let newCurrentCard = remOnce.shift();

      this.setState({
        currentCard: newCurrentCard,
        afterInsert,
        remTwice: [...remTwice, currentCard],
        remOnce
      });
    } else {
      console.log("T:", "6");

      let remOnceCopy = [...this.state.remOnce];
      let newCurrentCard = remOnceCopy.shift();
      let currentCard = this.state.currentCard;

      this.setState({
        currentCard: newCurrentCard,
        remTwice: [...this.state.remTwice, currentCard],
        remOnce: remOnceCopy,
        afterThresholdCount: this.state.afterThresholdCount + 1
      });
    }
  };
  conditionFlip = () => {
    if (this.state.flipped === true) {
      this.flipCard();
    }
  };

  addRemembered = () => {
    let notRem = [...this.state.notRem];

    this.conditionFlip();

    if (this.state.currentCard.key === 0 && !this.state.afterInsert) {
      console.log("0");
      let firstCurrentCard = this.state.currentCard;
      let currentCard = notRem.shift();
      firstCurrentCard = { ...firstCurrentCard, rememberedStatus: 1 };
      console.log("CURRENTCARD-REM1-Update", firstCurrentCard);
      this.setState({
        totalClicks: this.state.totalClicks + 1,
        remOnce: [...this.state.remOnce, firstCurrentCard],
        notRem: notRem,
        currentCard: currentCard
      });
    } else if (this.state.forgottenCard) {
      let forgottenCard = this.state.forgottenCard;
      let forgotten = [...this.state.forgotten];
      let currentCard = { ...this.state.currentCard, rememberedStatus: 1 };
      console.log("CURRENTCARD-REM1-Update", currentCard);

      this.setState({
        remOnce: [...this.state.remOnce, currentCard],
        currentCard: forgottenCard,
        forgottenCard: "",
        forgotten
      });
    } else if (this.state.afterInsert >= this.state.cards.length) {
      console.log("1");
      let remCard = [...this.state.remOnce].shift();

      this.setState({
        totalClicks: this.state.totalClicks + 1,
        currentCard: remCard,
        afterInsert: null,
        threshold: !this.state.threshold
      });
    } else if (!this.state.notRem.length) {
      console.log("2");

      if (this.state.afterInsert) {
        let newCurrentCard = this.state.afterInsert;
        let currentCard = { ...this.state.currentCard, rememberedStatus: 2 };
        this.setState({
          totalClicks: this.state.totalClicks + 1,
          threshold: !this.state.threshold, //THRESHOLD SETTER
          remTwice: [...this.state.remTwice, currentCard],
          currentCard: newCurrentCard,
          afterInsert: null
        });
      } else {
        let remOnce = [...this.state.remOnce];
        let newCurrentCard = remOnce.shift();

        this.setState({
          totalClicks: this.state.totalClicks + 1,
          threshold: !this.state.threshold, //THRESHOLD SETTER
          remOnce: [...remOnce, this.state.currentCard],
          currentCard: newCurrentCard
        });
      }
    } else if (this.state.afterInsert) {
      console.log("3"); //If the currentCard is a review card, this will add the review card to the "Remembered Twice Queue" and change the next card in the original line-up to the current card.
      let newRemTwiceCard = { ...this.state.currentCard, rememberedStatus: 2 };

      let nextCard = this.state.afterInsert;
      if (this.state.forgotten.length) {
        let forgotten = [...this.state.forgotten];
        let forgottenCard = forgotten.shift();

        this.setState({
          totalClicks: this.state.totalClicks + 1,
          currentCard: nextCard,
          afterInsert: null,
          forgottenCard,
          forgotten,
          remTwice: [...this.state.remTwice, newRemTwiceCard]
        });
      } else {
        this.setState({
          totalClicks: this.state.totalClicks + 1,
          currentCard: nextCard,
          afterInsert: null,
          remTwice: [...this.state.remTwice, newRemTwiceCard]
        });
      }
    } else if (this.state.currentCard.key % this.state.moduloIndex !== 0) {
      console.log("4");
      let currentCard = { ...this.state.currentCard, rememberedStatus: 1 };
      console.log("CURRENTCARD-REM1-Update", currentCard);

      let newCurrentCard = notRem.shift();
      this.setState({
        totalClicks: this.state.totalClicks + 1,
        remOnce: [...this.state.remOnce, currentCard],
        notRem,
        currentCard: newCurrentCard
      });
    } else if (this.state.cards.length <= this.state.currentCard.key) {
      console.log("5");
      let currentCard = [...this.state.remOnce].shift();
      this.setState({
        totalClicks: this.state.totalClicks + 1,
        currentCard: currentCard,
        notRem
      });
    } else {
      console.log("6"); //When the set modulo interval has come, this will shift the first card in the "Remembered Once"Queue into the Current Card spot.
      let notRem = [...this.state.notRem];
      let afterInsertCard = notRem.shift(); //This stores the spot of the card that will be next in line after the inserted cards are passed.
      let remCard = [...this.state.remOnce].shift();
      let currentCard = { ...this.state.currentCard, rememberedStatus: 1 };
      console.log("CURRENTCARD-REM1-Update", this.state);

      this.setState({
        totalClicks: this.state.totalClicks + 1,
        currentCard: this.state.remOnce.shift(),
        remOnceQueue: remCard,
        remOnce: [...this.state.remOnce, currentCard],
        afterInsert: afterInsertCard,
        notRem
      });
    }
  };

  toggleStarred = selected => {
    let cardsCopy = [...this.state.cards];
    let newSelected = { ...selected };
    console.log("1");
    let newCardsArray = cardsCopy.map(card => {
      if (selected.key === card.key) {
        card.starred = !card.starred;
        return card;
      } else {
        return card;
      }
    });

    newSelected.starred = !newSelected.starred;

    let starredArrCopy = [...this.state.starredArray];
    function hasSelected() {
      let bool = false;
      for (let i = 0; i < starredArrCopy.length; i++) {
        if (newSelected.key === starredArrCopy[i].key) {
          bool = true;
        }
      }
      return bool;
    }
    let selectedExists = hasSelected();

    if (!starredArrCopy.length || !selectedExists) {
      console.log("2");
      starredArrCopy.push(newSelected);
    } else {
      console.log("3");

      for (let i = 0; i < starredArrCopy.length; i++) {
        if (
          starredArrCopy[i].key === selected.key &&
          newSelected.starred === false
        ) {
          starredArrCopy.splice(i, 1);
        }
      }
    }

    this.setState({
      cards: newCardsArray,
      starredArray: starredArrCopy
    });
  };

  toggleSideBar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar
    });
  };

  flipCard = () => {
    this.setState({
      flipped: !this.state.flipped
    });
  };

  saveSession = () => {
    let session = JSON.parse(localStorage.getItem("session"));
    let sessionsArray = JSON.parse(localStorage.getItem("sessionsArray")) || [];
    let userContext = JSON.parse(localStorage.getItem("userContext")) || {};
    let cardDictionary = userContext.cardDictionary || {};
    session.cardsArray.map(obj => {
      cardDictionary[obj.word] = 1;
      obj.learned = true;
      return obj;
    });
    let startDate = Date.now();
    let nextTestDate = startDate + 60 * 60 * 12 * 1000;
    session.startDate = startDate;
    session.nextTestDate = startDate + 60 * 60 * 12 * 1000;

    sessionsArray.push(session);

    localStorage.setItem("userContext", JSON.stringify({ cardDictionary }));
    localStorage.setItem("sessionsArray", JSON.stringify(sessionsArray));
    localStorage.setItem("lastSessionID", session.sessionID + 1);
    localStorage.removeItem("session");

    this.props.history.push({ pathname: "/dashboard" });
  };

  componentWillMount() {}

  componentDidMount() {
    console.log("appCards", this.props.location.state.cards);
    let cards = this.props.location.state.cards;

    let notRem = cards.map((number, index) => {
      number.key = index;
      number.starred = false;
      return number;
    });
    let currentCard = notRem.shift();

    if (this.state.cards.length >= 16) {
      this.setState({ cards, moduloIndex: 4, currentCard, notRem });
    } else {
      this.setState({ cards, moduloIndex: 3, currentCard, notRem });
    }

    window.document.addEventListener("keydown", e => {
      if (e.key === "ArrowLeft") {
        !this.state.threshold ? this.addRemembered() : this.addAfterThreshold();
      } else if (e.key === "ArrowRight") {
        this.forgotten();
      } else if (e.key === "ArrowDown") {
        this.toggleStarred(this.state.currentCard);
      } else if (e.key === "ArrowUp") {
        this.flipCard();
      } else {
        console.log(e.key);
      }
    });
  }
  render() {
    if (this.state.allRemembered) console.log("ALL REMEMBERED");

    let statBarWidth = 5;
    let remOnceWidth = `${
      !this.state.remOnce.length
        ? 1
        : (this.state.remOnce.length / this.state.cards.length) * 100
    }%`;
    let remTwiceWidth = `${
      !this.state.remTwice.length
        ? 1
        : (this.state.remTwice.length / this.state.cards.length) * 100
    }%`;
    let rememberedWidth = `${
      !this.state.remembered.length
        ? 1
        : (this.state.remembered.length / this.state.cards.length) * 100
    }%`;
    let forgottenWidth = `${
      !this.state.forgotten.length
        ? 1
        : (this.state.forgotten.length / this.state.cards.length) * 100
    }%`;

    return (
      <React.Fragment>
        <div className="page-container">
          <div className="sidePanels" />
          <div className="card-container">
            {/* STATISTICS BOX */}

            <div className="left-section">
              <div className="left-section-content1">
                <div
                  className="statsBox"
                  style={
                    this.state.showSideBar ? { filter: "blur(3px)" } : null
                  }
                >
                  <div className="statHolder">
                    <div
                      className={`statName ${
                        this.state.allRemembered ? "complete-stat-text" : ""
                      }`}
                    >
                      Remembered Once:
                    </div>

                    <div className="statBarHolder">
                      <div
                        className={`statBar ${
                          this.state.allRemembered ? "complete-statbar" : ""
                        }`}
                        style={{
                          background: this.state.allRemembered
                            ? "unset"
                            : "rgb(0, 197, 26)",
                          width: this.state.allRemembered
                            ? "100%"
                            : remOnceWidth
                        }}
                      />
                    </div>
                  </div>

                  <div className="statHolder">
                    <div
                      className={`statName ${
                        this.state.allRemembered ? "complete-stat-text" : ""
                      }`}
                    >
                      Remembered Twice:
                    </div>

                    <div className="statBarHolder">
                      <div
                        className={`statBar ${
                          this.state.allRemembered ? "complete-statbar" : ""
                        }`}
                        style={{
                          background: this.state.allRemembered
                            ? "unset"
                            : "rgb(255, 197, 6)",
                          width: this.state.allRemembered
                            ? "100%"
                            : remTwiceWidth
                        }}
                      />
                    </div>
                  </div>

                  <div className="statHolder">
                    <div className={`statName`}>Remembered:</div>

                    <div className="statBarHolder">
                      <div
                        className={`statBar`}
                        style={{
                          background: "rgb(0, 162, 255)",
                          width: this.state.allRemembered
                            ? "100%"
                            : rememberedWidth,
                          border: this.state.allRemembered
                            ? "3px solid white"
                            : "unset"
                        }}
                      />
                    </div>
                  </div>

                  <div className="statHolder">
                    <div
                      className={`statName ${
                        this.state.allRemembered ? "complete-stat-text" : ""
                      }`}
                    >
                      Forgotten:
                    </div>

                    <div className="statBarHolder">
                      <div
                        className={`statBar ${
                          this.state.allRemembered ? "complete-statbar" : ""
                        }`}
                        style={{
                          background: this.state.allRemembered
                            ? "unset"
                            : "rgb(255, 60, 60)",
                          width: this.state.allRemembered
                            ? "100%"
                            : forgottenWidth
                        }}
                      />
                    </div>
                  </div>
                </div>

                <button
                  onClick={this.saveSession}
                  style={{
                    display: this.state.allRemembered ? "flex" : "none"
                  }}
                  className={`save-quit modal-button ${
                    this.state.allRemembered ? "" : "hide"
                  }`}
                >
                  Save & Quit
                </button>
              </div>
            </div>

            {/* STATISTICS BOX::END*/}

            {/* SIDE BAR */}

            {this.state.showSideBar ? (
              <div className="sideBarBackground" onClick={this.toggleSideBar}>
                <Spring
                  from={{ right: "-50px", opacity: 1 }}
                  to={{ right: 0, opacity: "0.5" }}
                >
                  {props => (
                    <div style={props} className={`side-bar`}>
                      <div className="side-bar-header">
                        <div className="dbl-right">
                          <Icon
                            type="double-right"
                            onClick={this.toggleSideBar}
                          />
                        </div>
                        <h1>Starred Items</h1>
                      </div>

                      <div className="items-container">
                        {this.state.starredArray.map(card => (
                          <StarredWord
                            card={card}
                            toggleStarred={this.toggleStarred}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </Spring>
              </div>
            ) : null}

            {/* SIDE BAR END */}

            <div className="center-section">
              <div className="statusBarCont">
                <div className="statusBar">
                  <Icon
                    type="star"
                    className="statusBarStar"
                    onClick={this.toggleSideBar}
                  />
                </div>
              </div>
              {this.state.cards.length
                ? this.state.cards.map((card, index) => (
                    <Card
                      toggleSideBar={this.toggleSideBar}
                      card={card}
                      currentCard={this.state.currentCard}
                      order={index}
                      flipCard={this.flipCard}
                      flipped={this.state.flipped}
                      toggleStarred={this.toggleStarred}
                      showSideBar={this.state.showSideBar}
                      threshold={this.state.threshold}
                      addRemembered={this.addRemembered}
                      addAfterThreshold={this.addAfterThreshold}
                      forgotten={this.forgotten}
                    />
                  ))
                : null}
              <div className="instructionContainer">
                <div className="insTitle">Keyboard Hotkeys</div>
                <div className="arrowContainer">
                  <div className="instBox">
                    <Icon
                      type="arrow-up"
                      style={{ fill: "rgb(214, 214, 214)" }}
                      className="arrows"
                    />
                    <div className="instr">Flip Card</div>
                  </div>
                  <div className="instBox">
                    <Icon
                      type="arrow-down"
                      style={{ fill: "rgb(214, 214, 214)" }}
                      className="arrows"
                    />
                    <div className="instr">Mark As Starred</div>
                  </div>
                  <div className="instBox">
                    <Icon
                      type="arrow-left"
                      style={{ fill: "rgb(214, 214, 214)" }}
                      className="arrows"
                    />
                    <div className="instr">
                      <Icon
                        type="check"
                        style={{ fill: "rgb(182, 182, 182)" }}
                      />
                      Remembered
                    </div>
                  </div>
                  <div className="instBox">
                    <Icon
                      type="arrow-right"
                      style={{ fill: "rgb(214, 214, 214)" }}
                      className="arrows"
                    />
                    <div className="instr">
                      <Icon
                        type="stop"
                        style={{ fill: "rgb(182, 182, 182)" }}
                      />
                      Forgotten
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-section">
              <LearnSentenceContainer currentCard={this.state.currentCard} />
            </div>

            {/* <div
              className="btn-container desktop"
              style={this.state.showSideBar ? { filter: "blur(3px)" } : null}
            >
              <div
                className="cardBtn green"
                onClick={
                  !this.state.threshold
                    ? this.addRemembered
                    : this.addAfterThreshold
                }
              >
                <Icon type="check" style={{ fill: "white" }} />
              </div>
              <div className="cardBtn red" onClick={this.forgotten}>
                <Icon type="stop" style={{ fill: "white" }} />
              </div>
            </div> */}
          </div>

          <div className="sidePanels" />
        </div>

        {/* <div className="modal-bg">
        <SaveLessonModal/>
        </div> */}
      </React.Fragment>
    );
  }
}

export default MemoryQuizPage;
