export const testThree = [
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Your DevOps team maintains a SaaS platform. In the platform, whenever an existing user modifies its subscription information, an email will be sent to the user which includes some important information. The subscription data is stored in a DynamoDB table and its Stream is enabled to capture the changes to the table items. A Java application is monitoring the Stream data and sends the email accordingly. About the usage of DynamoDB Streams, which statement is right?",
    answers: [
      "A. The Java application can only view the data items as they appeared after they were modified.",
      "B. The data in DynamoDB Streams is also encrypted.",
      "C. DynamoDB Streams can only write a stream record when items in the table are created or updated.",
      "D. The information in the Stream log is stored forever."
    ],
    correctAnswer: "Correct Answer –B",
    explanation:
      "The features of DynamoDB Streams can be found in the document of"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "A DevOps engineer is helping the development team to create a Node.js application with AWS Elastic Beanstalk. During environment creation, he modified the maximum number of instances in Auto Scaling Group to 10 in AWS console. However, in the source bundle, there is a configuration file in the folder .ebextensions that sets the maximum number of instances to 5. Given that by default the maximum number is 4 for Elastic Beanstalk environment, what is the maximum number of ASG after the environment is created by the DevOps engineer in AWS console?",
    answers: [
      "A. The environment cannot be created successfully as there is a conflict for the configurations.",
      "B. The maximum number is 10.",
      "C. The maximum number is 5.",
      "D. The maximum number is 4."
    ],
    correctAnswer: "Correct Answer -  B",
    explanation:
      "There are several methods to modify the configuration settings in Elastic Beanstalk. Please refer to"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are an AWS DevOps engineer in a company and you created a job in Jenkins to  use a script to build an Elastic Beanstalk environment. And eb config command has been used to configure a Security Group for an Elastic Load Balancer. After some time, another colleague found that the Security Group should be changed to another one and he modified this configuration option with a config file in the .ebextensions folder. However, when the Jenkins job was rebuilt, the Security Group did not change to the new one. What is the reason and how would you fix this issue?      ",
    answers: [
      "A. Security Group name cannot be changed in .ebextensions config file. The eb config in the script needs to be modified to use the correct Security Group.",
      "B. The .ebextensions config file was processed first. And eb config ran next and overrided the .ebextensions config file. Modify the script in Jenkins so that the .ebextensions config file is processed after eb config.",
      "C. When there is a conflict for the configurations in .ebextensions and eb config, the default value of the option is used. The Security Group settings in eb config should be removed to avoid a conflict.",
      "D. The settings in .ebextensions folder cannot override that in EB CLI command. Remove the configuration settings for Security Group in eb config command."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "The key to this question is that there are different priorities when configurations in Elastic Beanstalk are applied. Settings specified by the AWS Management Console, EB CLI, AWS CLI, and SDKs belong to the ones applied directly to the environment and have the highest priority."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are from an AWS consulting company and you are helping a customer to build up applications using AWS Elastic Beanstalk service. One important application is going to be installed in Amazon Linux platform. Because several Shell commands are required to be executed before and after the application version file is extracted, you have created some config files in the .ebextensions folder. In the config files, which sections should you put the commands in so that they can run appropriately? (Select TWO.)",
    answers: [
      "A. commands section",
      "B. files section",
      "C. services section",
      "D. deploy section",
      "E. container_commands section"
    ],
    correctAnswer: "Correct Answer – A, E",
    explanation:
      "The Elastic Beanstalk application can be customized by config files in .ebextensions folder. For Linux machines, the below sections can be added according to"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You have an a video processing application hosted in AWS. The video’s are uploaded by users onto the site. You have a program that is custom built to process those videos. The program is able to recover incase there are any failures when processing the videos. Which of the following mechanisms can be used to deploy the instances for carrying out the video processing activities , ensuring that the cost is kept at a minimum.",
    answers: [
      "A. Create a launch configuration with Reserved Instances. Ensure the User Data section details the installation of the custom software. Create an Autoscaling group with the launch configuration.",
      "B. Create a launch configuration with Spot Instances. Ensure the User Data section details the installation of the custom software. Create an Autoscaling group with the launch configuration.",
      "C. Create a launch configuration with Dedicated Instances. Ensure the User Data section details the installation of the custom software. Create an Autoscaling group with the launch configuration.",
      "D. Create a launch configuration with On-Demand Instances. Ensure the User Data section details the installation of the custom software. Create an Autoscaling group with the launch configuration."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "Since the application can recover from failures and cost is the priority , then Spot instances are the best bet for this requirement. The launch configuration has the facility to request for Spot Instances."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You have a requirement to automate the creation of EBS Snapshots. Which of the following can be used to achieve this in the best way possible.",
    answers: [
      "A. Create a powershell script which uses the AWS CLI to get the volumes and then run the script as a cron job.",
      "B. Use the AWS Config service to create a snapshot of the AWS Volumes",
      "C. Use the AWS CodeDeploy service to create a snapshot of the AWS Volumes",
      "D. Use Cloudwatch Events to trigger the snapshots of EBS Volumes"
    ],
    correctAnswer: "Answer – D",
    explanation:
      "The best is to use the inbuilt service from Cloudwatch , as Cloudwatch Events to automate the creation of EBS Snapshots. With Option A , you would be restricted to running the powrshell script on Windows machines and maintaining the script itself. And then you have the overhead of having a separate instance just to run that script."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You currently have an Autoscaling group that has the following settings\n\nMin capacity - 2\n\nDesired capacity – 2\n\nMaximum capacity – 2\n\nYour launch configuration has AMI’s which are based on the t2.micro instance type. The application running on these instances are now experiencing issues and you have identified that the solution is to change the instance type of the instances running in the Autoscaling Group.\n\nWhich of the below solutions will meet this demand.",
    answers: [
      "A. Change the Instance type in the current launch configuration. Change the Desired value of the Autoscaling Group to 4. Ensure the new instances are launched.",
      "B. Delete the current Launch configuration. Create a new launch configuration with the new instance type and add it to the Autoscaling Group. This will then launch the new instances.",
      "C. Make a copy the Launch configuration. Change the instance type in the new launch configuration. Attach that to the Autoscaling Group. Change the maximum and Desired size of the Autoscaling Group to 4. Once the new instances are launched, change the Desired and maximum size back to 2.",
      "D. Change the desired and maximum size of the Autoscaling Group to 4. Make a copy the Launch configuration. Change the instance type in the new launch configuration. Attach that to the Autoscaling Group. Change the maximum and Desired size of the Autoscaling Group to 2"
    ],
    correctAnswer: "Answer – C",
    explanation:
      "You should make a copy of the launch configuration, add the new instance type. The change the Autoscaling Group to include the new instance type. Then change the Desired number of the Autoscaling Group to 4 so that instances of new instance type can be launched. Once launched , change the desired size back to 2 , so that Autoscaling will delete the instances with the older configuration. Note that the assumption here is that the current instances are equally distributed across multiple AZ’s because Autoscaling will first use the AZRebalance process to terminate instances."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You currently have an Autoscaling group that has the following settings\n\nMin capacity - 2\n\nDesired capacity – 2\n\nMaximum capacity – 4\n\nThe current number of instances running in the Autoscaling Group is 2. You have been notified that for a duration of an hour, you need to ensure that no new instances are launched by the Autoscaling Group What combination of actions would stop new instances from being launched?(Select two)",
    answers: [
      "A. Change the Maximum capacity to 2",
      "B. Change the Desired capacity to 4",
      "C. Suspend the Launch process of the Autoscaling Group",
      "D. Change the Minimum capacity to 2"
    ],
    correctAnswer: "Answer – A and C",
    explanation:
      "You can temporarily suspend the creation of new instances by either reducing the Maximum capacity to 2 , so that the current instances running which is 2 , matches the maximum limit."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Your company has a set of resources hosted in AWS. Your IT Supervisor is concerned with the costs being incurred by the resources running in AWS and wants to optimize on the costs as much as possible. Which of the following ways could help achieve this efficiently? Choose 2 answers from the options given below.",
    answers: [
      "A. Create Cloudwatch alarms to monitor underutilized resources and either shutdown or terminate resources which are not required.",
      "B. Use the Trusted Advisor to see underutilized resources",
      "C. Create a script which monitors all the running resources and calculates the costs accordingly. The analyze those resources accordingly and see which can be optimized.",
      "D. Create Cloudwatch logs to monitor underutilized resources and either shutdown or terminate resources which are not required."
    ],
    correctAnswer: "Answer – A and B",
    explanation:
      "You can use Cloudwatch alarms to see if resources are below a threshold for long periods of time. If so you can take the decision to either stop them or to terminate the resources."
  },
  {
    topic: "SDLC Automation",
    question:
      "You are helping a team to design a new CI/CD pipeline on AWS platform. The requirement is that the whole pipeline should be managed by an orchestration tool including source control, build and deploy. In the deployment stage, there are different workflow-driven behaviors to be considered so that a state machine engine is needed to properly handle these actions. The pipeline and state machine execution status should be visualized. What two services in combinations can implement this solution? (Select TWO.)",
    answers: [
      "A. Setup AWS CodeStar to manage the whole CI/CD services in AWS.",
      "B. Configure an AWS CodeDeploy application to process the continuous deployment tasks.",
      "C. Set up an AWS CodePipeline. In its deployment stage, call a Lambda function which is the trigger for the state machine.",
      "D. Configure AWS Step Functions State Machine to process the state transition. Return the running state when being asked.",
      "E. Create an AWS SWF workflow for the state machine execution. Use workflow decider to control the activity steps."
    ],
    correctAnswer: "Correct Answer – C, D",
    explanation:
      "When an orchestration tool is required in AWS, the best service to be considered should be CodePipeline as it controls source control, build and deployment in a visualized way."
  },
  {
    topic: "SDLC Automation",
    question:
      "Your company has a set of EC2 resources hosted on AWS. Your new IT procedures state that AWS EC2 Instances must be of a particular Instance type. Which of the following can be used to get the list of EC2 Instances which currently don’t match the instance type specified in the new IT procedures",
    answers: [
      "A. Use AWS Cloudwatch alarms to check which EC2 Instances don’t match the intended instance type.",
      "B. Use AWS Config to create a rule to check the EC2 Instance type",
      "C. Use TrustedAdvisor to check which EC2 Instances don’t match the intended instance type.",
      "D. Use VPC Flow Logs to check which EC2 Instances don’t match the intended instance type."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "In AWS Config, you can create a rule which can be used to check if EC2 Instances follow a particular instance type. Below is a snapshot of the output of a rule to check if EC2 instances matches the type of t2.micro."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Which of the following design strategies is ideal when designing loosely coupled systems. Choose 2 answers from the options given below",
    answers: [
      "A. Having the web and worker roles running on the same set of EC2 Instances",
      "B. Having the web and worker roles running on separate EC2 Instances",
      "C. Using SNS to establish communication between the web and worker roles",
      "D. Using SQS to establish communication between the web and worker roles"
    ],
    correctAnswer: "Answer – B and D",
    explanation:
      "The below diagram shows the ideal design which uses SQS and separate environments for web and worker processes. The SQS queue manages the communication between the web and worker roles."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Your company has the requirement to set up instances running as part of an Autoscaling Group. Part of the requirement is to use Lifecycle hooks to setup custom based software’s and do the necessary configuration on the instances. The time required for this setup might take more than an hour or might finish before the hour is up. How should you setup lifecycle hooks for the Autoscaling Group. Choose 2 ideal actions you would include as part of the lifecycle hook.",
    answers: [
      "A. Configure the lifecycle hook to record heartbeats. If the hour is up, restart the timeout period.",
      "B. Configure the lifecycle hook to record heartbeats. If the hour is up, choose to terminate the current instance and start a new one",
      "C. If the software installation and configuration is complete , then restart the time period.",
      "D. If the software installation and configuration is complete , then send a signal to complete the launch of the instance."
    ],
    correctAnswer: "Answer – A and D",
    explanation:
      "The AWS Documentation provides the following information on lifecycle hooks"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your company is planning to setup a wordpress application. The wordpress application will connect to a MySQL database. Part of the requirement is to ensure that the database environment is fault tolerant and highly available. Which of the following 2 options individually can help fulfil this requirement. ",
    answers: [
      "A. Create a MySQL RDS environment with Multi-AZ feature enabled",
      "B. Create a MySQL RDS environment and create a Read Replica",
      "C. Create multiple EC2 instances in the same AZ. Host MySQL and enable replication via scripts between the instances.",
      "D. Create multiple EC2 instances in separate AZ’s. Host MySQL and enable replication via scripts between the instances."
    ],
    correctAnswer: "Answer – A and D",
    explanation:
      "One way to ensure high availability and fault tolerant environments is to ensure Instances are located across multiple availability zones. Hence if you are hosting MySQL yourself , ensure you have instances spread across multiple AZ’s"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are currently using Elastic Beanstalk to host your production environment. You need to rollout updates to your application hosted on this environment. This is a critical application which is why there is a requirement that the rollback , if required , should be carried out with the least amount of downtime. Which of the following deployment strategies would ideally help achieve this purpose",
    answers: [
      "A. Create a Cloudformation template with the same resources as those in the Elastic beanstalk environment. If the deployment fails, deploy the Cloudformation template.",
      "B. Use Rolling updates in Elastic Beanstalk so that if the deployment fails , the rolling updates feature would roll back to the last deployment.",
      "C. Create another parallel environment in elastic beanstalk. Use the Swap URL feature.",
      "D. Create another parallel environment in elastic beanstalk. Create a new Route53 Domain name for the new environment and release that url to the users."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "Since the requirement is to have the least amount of downtime, the ideal way is to create a blue green deployment environment and then use the Swap URL feature to swap environments for the new deployment and then do the swap back , incase the deployment fails."
  },
  {
    topic: "SDLC Automation",
    question:
      "A DevOps engineer is building up a pipeline for a Java project which is under development. AWS CodePipeline service has been chosen as the CI/CD tool. For the service deployment phase, CloudFormation stacks are used. However, there are several situations to be considered. For example (i) stack should be created if a stack does not exist, (ii) Change Set should be created and inspected if a stack already exists. The team lead prefers to implement a visualized state machine for the deployment and each state executes a Lambda function. What is the best way to achieve this requirement?",
    answers: [
      "A. Establish several SQS queues to indicate running status. The Lambda function for each state gets the message from the queue and processes the deployment task accordingly. The final result is returned to CodePipeline by a dedicated Lambda.",
      "B. Use a Python script in EC2 to record the running status and achieve the state machine feature. It calls different Lambda function depending on the current status. The script returns the execution status back to CodePipeline.",
      "C. Use a Lambda Function to interface with an AWS Step Functions which implements the workflow-driven state machines for CloudFormation stack deployment. The Lambda Function returns the execution status back to CodePipeline.",
      "D. Use a shell script in EC2 to interface with AWS MQ service to achieve the function of state machine. Depending on the running status of AWS MQ service, the script returns the execution result back to CodePipeline."
    ],
    correctAnswer: "Correct Answer – C",
    explanation:
      "A visualized state machine is required in this question. AWS Step Functions is the best tool to achieve this. About how to integrate with AWS CodePipeline for AWS Step Functions, check this document in"
  },
  {
    topic: "SDLC Automation",
    question:
      "In a financial company, AWS CodePipeline has been used as the orchestration tool to manage pipelines. There are some other AWS services which are configured in the pipelines including CodeCommit, CodeBuild, CodeDeploy, etc. There is a security policy that all artifacts generated during pipeline execution should be managed in a highly available and durable place. Which statements are correct about how AWS CodePipeline manages artifacts? (Select TWO.)         ",
    answers: [
      "A. User can select a custom S3 bucket as the artifact store while creating a new pipeline.",
      "B. Artifact can be stored in S3 or EC2 EBS.",
      "C. User can configure a default S3 bucket as the artifact store in the same region and account as the pipeline.",
      "D. When artifacts are saved in a S3 bucket, the bucket can belong to a different region.",
      "E. When CodeBuild is used in the build stage, user can only configure input artifacts but not output artifacts."
    ],
    correctAnswer: "Correct Answer – A, C",
    explanation:
      "During pipeline creation, user can select a default S3 bucket or a custom S3 bucket as the place to store artifacts:"
  },
  {
    topic: "SDLC Automation",
    question:
      "You are using AWS CodePipeline service to build up a pipeline for a new web application. In Source stage, the source provider is GitHub and its output artifact is configured as WebApp. In Build stage, there are two build actions in parallel and both of them have used AWS CodeBuild as the service provider. For these two build actions, which configurations of input/output artifacts are correct?",
    answers: [
      "A. Build Action 1 (input artifact: WebApp, output artifact:WebAppBuild1); Build Action 2 (input artifact: WebApp2, output artifact:WebAppBuild2)",
      "B. Build Action 1 (input artifact: empty, output artifact:WebAppBuild1); Build Action 2 (input artifact: WebApp2, output artifact:WebAppBuild2)",
      "C. Build Action 1 (input artifact: WebApp, output artifact:WebAppBuild1); Build Action 2 (input artifact: WebApp, output artifact:WebAppBuild2)",
      "D. Build Action 1 (input artifact: WebApp, output artifact:WebAppBuild); Build Action 2 (input artifact: WebApp, output artifact:WebAppBuild)"
    ],
    correctAnswer: "Correct Answer – C",
    explanation: "Refer to"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "The company you work for has a huge amount of infrastructure built on AWS. However there has been some concerns recently about the security of this infrastructure, and an external auditor has been given the task of running a thorough check of all of your company's AWS assets. The auditor will be in the USA while your company's infrastructure resides in the Asia Pacific (Sydney) region on AWS. Initially, he needs to check all of your VPC assets, specifically, security groups and NACLs You have been assigned the task of providing the auditor with a login to be able to do this. Which of the following would be the best and most secure solution to provide the auditor with so he can begin his initial investigations? Choose the correct answer from the options below",
    answers: [
      "A. Create an IAM user tied to an administrator role. Also provide an additional level of security with MFA.",
      "B. Give him root access to your AWS Infrastructure, because he is an auditor he will need access to every service.",
      "C. Create an IAM user who will have read-only access to your AWS VPC infrastructure and provide the auditor with those credentials.",
      "D. Create an IAM user with full VPC access but set a condition that will not allow him to modify anything if the request is from any IP other than his own."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "Generally you should refrain from giving high level permissions and give only the required permissions. In this case option C fits well by just providing the relevant access which is required."
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You're building a mobile application game. The application needs permissions for each user to communicate and store data in DynamoDB tables. What is the best method for granting each mobile device that installs your application to access DynamoDB tables for storage when required? Choose the correct answer from the options below",
    answers: [
      "A. During the install and game configuration process, have each user create an IAM credential and assign the IAM user to a group with proper permissions to communicate with DynamoDB.",
      "B. Create an IAM group that only gives access to your application and to the DynamoDB tables. Then, when writing to DynamoDB, simply include the unique device ID to associate the data with that specific user.",
      "C. Create an IAM role with the proper permission policy to communicate with the DynamoDB table. Use web identity federation, which assumes the IAM role using AssumeRoleWithWebIdentity, when the user signs in, granting temporary security credentials using STS.",
      "D. Create an Active Directory server and an AD user for each mobile application user. When the user signs in to the AD sign-on, allow the AD server to federate using SAML 2.0 to IAM and assign a role to the AD user which is the assumed with AssumeRoleWithSAML."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "For access to any AWS service, the ideal approach for any application is to use Roles. This is the first preference."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are in charge of creating a Cloudformation template that will be used to spin our resources on demand for your DevOps team. The requirement is that this cloudformation template should be able to spin up resources in different regions. Which of the following aspects of Cloudformation templates can help you design the template to spin up resources based on the region?",
    answers: [
      "A. Use the mappings section in the Cloudformation template, so that based on the relevant region, the relevant resource can be spinned up.",
      "B. Use the outputs section in the Cloudformation template, so that based on the relevant region, the relevant resource can be spinned up.",
      "C. Use the parameters section in the Cloudformation template, so that based on the relevant region, the relevant resource can be spinned up.",
      "D. Use the metadata section in the Cloudformation template, so that based on the relevant region, the relevant resource can be spinned up."
    ],
    correctAnswer: "Answer – A",
    explanation: "The AWS Documentation mentions"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are the IT administrator for your company. You have the responsibility of creating development environments which would confirm to the LAMP development stack. The requirement is that the development team always gets the latest version of the LAMP stack each time a new instance is launched. Which of the following is an efficient and effective way to implement this requirement? Choose 2 answers from the options given below",
    answers: [
      "A. Create an AMI with all the artifacts of the LAMP stack and provide an instance to the development team based on the AMI.",
      "B. Create a cloudformation template and use the cloud-init directives to download and the install the LAMP stack packages.",
      "C. Use the User data section and use a custom script which will be used to download the necessary LAMP stack packages.",
      "D. Create an EBS Volume with the LAMP stack and attach it to an instance whenever it is required."
    ],
    correctAnswer: "Answer – B and C",
    explanation:
      "Using User data and cloud-init directives you can always ensure you download the latest version of the LAMP stack and give it to the development teams. With AMI’s you will always have the same version and will need to create an AMI everytime the version of the LAMP stack changes."
  },
  {
    topic: "SDLC Automation",
    question:
      "A company has developed a Ruby on Rails content management platform. Currently, OpsWorks with several stacks for dev, staging, and production is being used to deploy and manage the application. Now the company wants to start using Python instead of Ruby. How should the company manage the new deployment? Choose the correct answer from the options below",
    answers: [
      "A. Update the existing stack with Python application code and deploy the application using the deploy life-cycle action to implement the application code.",
      "B. Create a new stack that contains a new layer with the Python code. To cut over to the new stack the company should consider using Blue/Green deployment",
      "C. Create a new stack that contains the Python application code and manage separate deployments of the application via the secondary stack using the deploy lifecycle action to implement the application code.",
      "D. Create a new stack that contains the Python application code and manages separate deployments of the application via the secondary stack."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "Blue/green deployment is a technique for releasing applications by shifting traffic between two identical environments running different versions of the application. Blue/green deployments can mitigate common risks associated with deploying software, such as downtime and rollback capability"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You were assigned a task to migrate an on-premise application to AWS Elastic Beanstalk environment. The operating system is Amazon Linux and package managers yum and rubygems are required in order to install all necessary packages for the application. You have created config files in .ebextensions and in the config files you added a Package section to install software packages. Which statement is correct for the Package section in the configuration file?",
    answers: [
      "A. Within each package manager, package installation order isn't guaranteed.",
      "B. For Amazon Linux operating system, only the package manager yum is supported in the Package section. Elastic Beanstalk does not support rubygems.",
      "C. Only the latest supported version is installed. The version number cannot be specified in the configuration file.",
      "D. Elastic Beanstalk only supports one package manager per config file. So at least two config files are needed. One for yum and the other for rubygems."
    ],
    correctAnswer: "Correct Answer –A",
    explanation: "Refer to"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are working as an AWS Devops admins for your company. You are in-charge of building a nested infrastructure for the company's development teams using CloudFormation. The template will include building the VPC and networking components , installing a LAMP stack and securing the created resources. As per the AWS best practices what is the best way to design this template?",
    answers: [
      "A. Create a single cloudformation template to create all the resources since it would be easier from the maintenance perspective.",
      "B. Create multiple cloudformation templates based on the number of VPC’s in the environment.",
      "C. Create multiple cloudformation templates based on the number of development groups in the environment.",
      "D. Create multiple cloudformation templates for each set of logical resources , one for networking , the other for LAMP stack creation."
    ],
    correctAnswer: "Answer – D",
    explanation:
      "Creating multiple cloudformation templates is an example of using nested stacks. The advantage of using nested stacks is given below as per the AWS documentation"
  },
  {
    topic: "SDLC Automation",
    question:
      "Your security officer has told you that you need to tighten up the logging of all events that occur on your AWS account. He wants to be able to access all events that occur on the account across all regions quickly and in the simplest way possible. He also wants to make sure he is the only person that has access to these events in the most secure way possible. Which of the following would be the best solution to assure his requirements are met? Choose the correct answer from the options below",
    answers: [
      "A. Use CloudTrail to log all events to one S3 bucket. Make this S3 bucket only accessible by your security officer with a bucket policy that restricts access to his user only and also add MFA to the policy for a further level of security.",
      "B. Use CloudTrail to log all events to an Amazon Glacier Vault. Make sure the vault access policy only grants access to the security officer's IP address.",
      "C. Use CloudTrail to send all API calls to CloudWatch and send an email to the security officer every time an API call is made. Make sure the emails are encrypted.",
      "D. Use CloudTrail to log all events to a separate S3 bucket in each region as CloudTrail cannot write to a bucket in a different region. Use MFA and bucket policies on all the different buckets."
    ],
    correctAnswer: "Answer – A",
    explanation:
      "AWS CloudTrail is a service that enables governance, compliance, operational auditing, and risk auditing of your AWS account. With CloudTrail, you can log, continuously monitor, and retain events related to API calls across your AWS infrastructure. CloudTrail provides a history of AWS API calls for your account, including API calls made through the AWS Management Console, AWS SDKs, command line tools, and other AWS services. This history simplifies security analysis, resource change tracking, and troubleshooting."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are in charge of designing Cloudformation templates for your company. One of the key requirements is to ensure that if a Cloudformation stack is deleted, a snapshot of the relational database is created which is part of the stack. How can you achieve this in the best possible way?",
    answers: [
      "A. Create a snapshot of the relational database beforehand so that when the cloudformation stack is deleted , the snapshot of the database will be present.",
      "B. Use the Update policy of the cloudformation template to ensure a snapshot is created of the relational database.",
      "C. Use the Deletion policy of the cloudformation template to ensure a snapshot is created of the relational database.",
      "D. Create a new cloudformation template to create a snapshot of the relational database."
    ],
    correctAnswer: "Answer – C",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Your team is using AWS API Gateway and Lambda function to maintain an API endpoint. Recently, there is a change in Lambda function which adds some new data in the response body. The Lambda function is upgraded and a new API is added to support the change. The team wants to keep both version1 and version2 of the API to be available simultaneously to your API consumers. How should you modify the version1 API in API Gateway to make it behave as the V1 consumers expect?",
    answers: [
      "A. Add a mapping template in the Method Response of version1 API to remove the new data in the response to keep the same behaviors as before.",
      "B. In the Integration Request of version1 API, add a mapping to inform the backend Lambda to remove the new data in the relevant response.",
      "C. In the Integration Response of version1 API, add a new Header Mapping to remove the new data which comes from the backend.",
      "D. Add a mapping template in the Integration Response of version1 API to remove the new data so that the response is transformed to the original format."
    ],
    correctAnswer: "Correct Answer – D",
    explanation: "Refer to"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your company is getting ready to do a major public announcement of a social media site on AWS. The website is running on EC2 instances deployed across multiple Availability Zones with a Multi-AZ RDS MySQL Extra Large DB Instance. The site performs a high number of small reads and writes per second and relies on an eventual consistency model. After comprehensive tests you discover that there is read contention on RDS MySQL. Which are the best approaches to meet these requirements? Choose 2 answers from the options below",
    answers: [
      "A. Deploy ElasticCache in-memory cache running in each availability zone",
      "B. Implement sharding to distribute load to multiple RDS MySQL instances",
      "C. Increase the RDS MySQL Instance size and Implement provisioned IOPS",
      "D. Add an RDS MySQL read replica in each availability zone"
    ],
    correctAnswer: "Answer – A and D",
    explanation: "Implement Read Replicas and Elastic Cache"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your company is planning to develop an application in which the front end is in .Net and the backend is in DynamoDB. There is an expectation of a high load on the application. How could you ensure the scalability of the application to reduce the load on the DynamoDB database? Choose an answer from the options below.",
    answers: [
      "A. Add more DynamoDB databases to handle the load.",
      "B. Increase write capacity of Dynamo DB to meet the peak loads",
      "C. Use SQS to assist and let the application pull messages and then perform the relevant operation in DynamoDB.",
      "D. Launch DynamoDB in Multi-AZ configuration with a global index to balance writes"
    ],
    correctAnswer: "Answer – C",
    explanation:
      "When the idea comes for scalability and decoupling then SQS is the best option. "
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "You are having a web and worker role infrastructure defined in AWS using Amazon EC2 resources. You are using SQS to manage the jobs being sent by the web role. Which of the following is the right way to ensure the worker processes are adequately setup to handle the number of jobs send by the web role",
    answers: [
      "A. Use Cloudwatch monitoring to check the size of the queue and then scale out SQS to ensure that it can handle the right number of jobs",
      "B. Use ELB to ensure that the load is evenly distributed to the set of web and worker instances",
      "C. Use Route53 to ensure that the load is evenly distributed to the set of web and worker instances",
      "D. Use Cloudwatch monitoring to check the size of the queue and then scale out using Autoscaling to ensure that it can handle the right number of jobs"
    ],
    correctAnswer: "Answer - D",
    explanation:
      "The below diagram shows how SQS can be used to manage the communication between the Web and worker roles. The number of messages in the SQS queue can be used to determine the number of instances that should be there in the AutoScaling Group."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "A team has implemented a service with AWS API Gateway and Lambda. The service endpoint can save input JSON data to internal database and S3. After the service has run for several years, some data in the JSON body are no longer used and should not be saved anymore. The backend Lambda has been upgraded to support only the valid JSON values. The original API in API Gateway is still used for some time before all users migrate to the new API. How should the original API be configured so that the old request is still supported by the Lambda in backend?  ",
    answers: [
      "A. In the original API, add a stage for canary deployment to understand how many users are still using the old JSON format before the original API service is completely removed.",
      "B. Configure a mapping template in Integration Request to remove the obsolete data so that the original API requests are transformed to be supported by the new Lambda.",
      "C. In the original API, use a new Lambda as an authorizer so that only the requests with valid JSON data can proceed to hit the backend.",
      "D. In Integration Response of the API, add a mapping template to remove the obsolete data for the backend Lambda to support the old requests."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "In this question, the backend Lambda is already upgraded and the original API is kept for some time. However the Lambda only supports the new requests without obsolete data in the JSON body, which means that a mapping template is needed to map the original requests to the new ones in Integration Request."
  },
  {
    topic: "Incident and Event Response",
    question:
      "You are responsible to maintain several AWS CloudFormation templates. Last month, there were two incidents that resources in existing CloudFormation stacks have been changed by someone without any alerts or notifications. Because of that, the potential changes may have negative impacts to the stacks and the changes may be lost if the stacks are re-deployed. This is not compliant with company policy. Your team lead asked you to warn the team whenever a drift in CloudFormation stack appears. What is the best way to achieve that?",
    answers: [
      "A. Use a Lambda function to check the drift status in each CloudFormation stack every 10 minutes. If there is a drift, send the team an email via AWS SES service.",
      "B. Create a rule in AWS Config to evaluate if the stack is considered to have drifted for its resources. If the rule is NON_COMPLIANT, notify the team via a SNS notification.",
      "C. Create a CloudWatch event rule for CloudFormation. If any event happens for CloudFormation, trigger an email notification by SNS.",
      "D. Enable CloudTrail. Use a Lambda function to analyze the CloudTrail logs. Send an email if it is found that the resources in CloudFormation stacks are modified."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "In AWS Config, there is an AWS managed rule (cloudformation-stack-drift-detection-check"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are using lifecycle hooks in your AutoScaling Group. Because there is a lifecycle hook, the instance is put in the Pending:Wait state, which means that it is not available to handle traffic yet. When the instance enters the wait state, other scaling actions are suspended. After some time, the instance state is changed to Pending:Proceed, and finally InService where the instances that are part of the Autoscaling Group can start serving up traffic. But you notice that the bootstrapping process on the instances finish much earlier, long before the state is changed to Pending:Proceed. \n\nWhat can you do to ensure the instances are placed in the right state after the bootstrapping process is complete?",
    answers: [
      "A. Use the complete-lifecycle-action call to complete the lifecycle action. Run this command from another EC2 Instance.",
      "B. Use the complete-lifecycle-action call to complete the lifecycle action. Run this command from the  Command line interface.",
      "C. Use the complete-lifecycle-action call to complete the lifecycle action. Run this command from the  Simple Notification service.",
      "D. Use the complete-lifecycle-action call to complete the lifecycle action. Run this command from a SQS queue"
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "SDLC Automation",
    question:
      "There is a requirement for an application hosted on a VPC to access the On-premise LDAP server. The VPC and the On-premise location are connected via an IPSec VPN. Which of the below are the right options for the application to authenticate each user. Choose 2 answers from the options below",
    answers: [
      "A. Develop an identity broker that authenticates against IAM security Token service to assume a IAM role in order to get temporary AWS security credentials The application calls the identity broker to get AWS temporary security credentials.",
      "B. The application authenticates against LDAP and retrieves the name of an IAM role associated with the user.  The application then calls the IAM Security Token Service to assume that IAM role. The application can use the temporary credentials to access any AWS resources.",
      "C. Develop an identity broker that authenticates against LDAP and then calls IAM Security Token Service to get IAM federated user credentials. The application calls the identity broker to get IAM federated user credentials with access to the appropriate AWS service.",
      "D. The application authenticates against LDAP the application then calls the AWS identity and Access Management (IAM) Security service to log in to IAM using the LDAP credentials the application can use the IAM temporary credentials to access the appropriate AWS service."
    ],
    correctAnswer: "Answer – B and C",
    explanation:
      "When you have the need for an in-premise environment to work with a cloud environment, you would normally have 2 artefacts for authentication purposes"
  },
  {
    topic: "Incident and Event Response",
    question:
      "A company has strict security policies. For its AWS services, special attention is required to ensure that there is no security vulnerability. You are asked to set up a rule in AWS Config to inspect if the AWS resources are always as expected. The rule is very complicated and there is no existing AWS managed rule that can meet the company’s needs. Which actions in combinations can achieve this requirement? (Select TWO.)",
    answers: [
      "A. Create a t2.micro EC2 instance to implement the custom policies to check if AWS resources are not exposed to security issues. The instance also listens to a SQS queue and starts processing whenever there is a new message in the queue.",
      "B. Create an AWS Lambda function which contains the logic of the custom rule to evaluate whether the AWS resources are compliant.",
      "C. Create an SNS topic and subscribe to the topic with an email notification to the team member in case there is a security issue in AWS resources.",
      "D. In AWS Config, add a custom rule that runs every hour and sends a message to a SQS queue.",
      "E. In AWS Config, add a custom rule and specify the ARN of an AWS Lambda function that checks AWS resources."
    ],
    correctAnswer: "Correct Answer – B, E",
    explanation:
      "In AWS config, there are AWS managed rules or custom rules. For the introduction of custom rules, please check the documentation in"
  },
  {
    topic: "SDLC Automation",
    question:
      "A company has recently started using Docker cloud. This is a SaaS solution for managing docker containers on the AWS cloud and the solution provider is also on the same cloud platform. There is a requirement for the SaaS solution to access AWS resources. Which of the following would meet the requirement for enabling the SaaS solution to work with AWS resources in the most secured manner?",
    answers: [
      "A. From the AWS Management Console, navigate to the Security Credentials page and retrieve the access and secret key for your account.",
      "B. Create an IAM user within the enterprise account assign a user policy to the IAM user that allows only the actions required by the SaaS application. Create a new access and secret key for the user and provide these credentials to the SaaS provider.",
      "C. Create an IAM role for cross-account access allows the SaaS provider’s account to assume the role and assign it a policy that allows only the actions required by the SaaS application.",
      "D. Create an IAM role for EC2 instances, assign it a policy mat allows only the actions required tor the Saas application to work, provide the role ARM to the SaaS provider to use when launching their application instances."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "Many SaaS platforms can access aws resources via a Cross account access created in aws. If you go to Roles in your identity management, you will see the ability to add a cross account role."
  },
  {
    topic: "SDLC Automation",
    question:
      "Which of the following will you need to consider so you can set up a solution that incorporates single sign-on from your corporate AD or LDAP directory and restricts access for each user to a designated user folder in a bucket? Choose 3 Answers from the options below",
    answers: [
      "A. Setting up a federation proxy or identity provider",
      "B. Using AWS Security Token Service to generate temporary tokens",
      "C. Tagging each folder in the bucket",
      "D. Configuring IAM role",
      "E. Setting up a matching IAM user for every user in your corporate directory that needs access to a folder in the bucket"
    ],
    correctAnswer: "Answer – A,B and D",
    explanation:
      "The below diagram showcases how authentication is carried out when having an identity broker. This is an example of a SAML connection , but the same concept holds true for getting access to an AWS resource."
  },
  {
    topic: "Incident and Event Response",
    question:
      'A DevOps engineer has created the below AWS CloudWatch Event rule in order to capture the CloudFormation API call:\n\n{\n\n  "source": [\n\n    "aws.cloudformation"\n\n  ],\n\n  "detail-type": [\n\n    "AWS API Call via CloudTrail"\n\n  ],\n\n  "detail": {\n\n    "eventSource": [\n\n      "cloudformation.amazonaws.com"\n\n    ]\n\n  }\n\n}\n\nThe Event rule has a Lambda function as a target to provide notifications in a Slack channel. However, when a CloudFormation stack was updated, there was no notification. Which one may be the cause and how would you fix it?',
    answers: [
      "A. AWS API Call via CloudTrail cannot be used in a CloudWatch Event rule.",
      "B. CloudTrail can only trace the add and delete for a CloudFormation stack. No API call is recorded for the stack update so no notification was received.",
      'C. The rule should be changed as the following snippet:\n\n{\n\n  "source": [\n\n    "aws.cloudtrail"\n\n  ],\n\n  "detail": {\n\n    "eventSource": [\n\n      "cloudformation.amazonaws.com"\n\n    ]\n\n  }\n\n}',
      "D. Check if CloudTrail logging is turned on in this region. If it is turned off, no AWS API action events can be received."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      'When a CloudWatch Event rule is created, the event type can be selected as "AWS API Call via CloudTrail". For its details, check the documentation in'
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You are an AWS consultant working in a startup IT company. The company wanted to increase visibility into its user and resource activity in AWS so CloudTrail was turned on. As most resources were used in region ap-south-1, the trail was only configured to collect the activities in that region. However, customer is worried that if some disaster in region ap-south-1 happens, the data in the CloudTrail may get lost. Which actions should you take to address the concern?",
    answers: [
      "A. Enable the encryption with SSE-KMS for the S3 bucket of the CloudTrail.",
      "B. In CloudTrail console, add another S3 bucket in a different region as the target for the trail.",
      "C. Enable Cross-Region Replication for the trail S3 bucket which automatically copies objects in different AWS Region.",
      "D. Create a Lambda Function which can read files in the trail S3 bucket and copy over the log files to a S3 bucket in another region."
    ],
    correctAnswer: "Correct Answer – C",
    explanation: "According to"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "Your IT company is currently hosting a production environment in Elastic beanstalk. You understand that the Elastic beanstalk service provides a facility known as Managed updates which are minor and patch version updates which are periodically required for your system. Your IT supervisor is worried about the impact that these updates would have on the system. What can you tell about the Elastic beanstalk service with regards to managed updates",
    answers: [
      "A. Package updates can be configurable weekly maintenance window",
      "B. Elastic Beanstalk applies managed updates with no downtime",
      "C. Elastic Beanstalk applies managed updates with no reduction in capacity",
      "D. All of the above"
    ],
    correctAnswer: "Answer - D",
    explanation:
      "The AWS Documentation mentions the following on package updates for the Elastic beanstalk environment"
  },
  {
    topic: "SDLC Automation",
    question:
      "You are using AWS CodeBuild service to handle the build task in a CI/CD pipeline. In the pre-build phase of buildspec.yml, there is a docker login command such as “docker login –u $USER_NAME –p $LOGIN_PASSWORD”. And its user name and password are provided as variables in the env phase in the same buildspec.yml file.\n\nYou find that there is a security issue as credentials may be exposed. What is the best way to fix the issue?",
    answers: [
      "A. In the env phase of buildspec.yml file, use parameter-store to specify the user name and password. The values are stored in Systems Manager parameter store.",
      "B. Store the buildspec.yml file in AWS CodeCommit rather than GitHub as IAM rules can be configured in CodeCommit to ensure the security.",
      "C. Add a strong IAM rule in AWS CodeBuild to make sure that only limited users can access the buildspec.yml file.",
      "D. Store the credentials in a file and put the file in a S3 bucket. Encrypt the S3 bucket via SSE-S3. Modify the buildspec.yml file to use the encrypted file in the S3 bucket."
    ],
    correctAnswer: "Correct Answer – A",
    explanation:
      "The security issue for this case is that the credentials are exposed in the buildspec.yml file. Approaches should be taken to prevent this. Check on"
  },
  {
    topic: "SDLC Automation",
    question:
      "A developer asks you to help on setting up AWS CodeBuild for a new Java project. The project is built by Maven. In its buildspec.yml file,  there are several phases such as install, pre_build and build. There is a requirement that if a command in a phase has failed, CodeBuild still has a chance to run some shell commands to do custom operations such as log collection. How should you configure the CodeBuild project to meet the need?",
    answers: [
      "A. Put the custom operation commands in the post_build phase so that they can still run when the build fails.",
      "B. Add a finally block after the commands block in each phase. Put the custom shell commands in the finally block.",
      "C. Create a CloudWatch Events rule for any CodeBuild failure event. Add a Lambda Function as the target to do custom operations.",
      "D. Trigger a SNS notification if a build fails in CodeBuild project. Use a Lambda Function to subscribe to the SNS topic and handle the required custom operations."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "To deal with the situation mentioned in the question, buildspec.yml file has provided the optional finally block to execute commands even if a command in the commands block fails. The reference is in"
  },
  {
    topic: "Incident and Event Response",
    question:
      "In a large corporation, an AWS organization is established with several OUs (organizational units). Because of several new security requirements, the security operator needs to create several CloudWatch Events rules. For one rule, the requirement is that if certain AWS services have not been used by an OU for 6 months but are suddenly used recently, an SNS notification will be triggered. How would you quickly get the service-last-accessed information to identify the potential services to add into the CloudWatch Event rule?",
    answers: [
      "A. In AWS Organization console, click each OU and view the service-last-accessed information.",
      "B. Send an AWS CLI command to AWS Config service to get the service-last-accessed information for each organizational unit.",
      "C. In AWS Resource Groups service, add a resource group for the candidate AWS services. View the last-accessed information for the group.",
      "D. In IAM access advisor, view the service-last-accessed information for each OU to help identify which services should be added in the CloudWatch Event rule."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "The service-last-accessed information can be viewed in IAM access advisor. Check the introduction in"
  },
  {
    topic: "SDLC Automation",
    question:
      "You are working in a large corporation and your team is in charge of the new CI/CD pipeline for a migration project from on-premises to AWS. The product used a license which limited the number of vCPUs and it is decided that the same license will be used on AWS platform. The software is baked on Amazon AMI and several teams including QA, DEV and Production frequently launch new EC2 instances using the Jenkins pipeline. Your team manager is worried that the number of servers used is getting too large and asked you to add a step in Jenkins to get the current license consumption status. What is the best way for you to implement that?",
    answers: [
      "A. Use a shell script to count the total number of EC2 instances in all teams and compare the number with the limit that the license allows.",
      "B. Create a Lambda to keep counting the number of servers and compare with the license limit.",
      "C. Use a DynamoDB table to record the usage status of vCPU. Add a Jenkins step to read and write the table to manage the license consumption status.",
      "D. Create a License Configuration for this license in AWS License Manager. Call the CLI list-usage-for-license-configuration to get the license consumption status."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "This question is related to the license usage. The first service to consider would be AWS License Manager, which streamlines the process of bringing software licenses to AWS. Check the link on"
  },
  {
    topic: "SDLC Automation",
    question:
      "You just joined an IT company as an AWS DevOps engineer. The company is creating an application and needs you to decide which AWS services to use. For this application, customers can upload new photos through mobile App to a S3 bucket. When there is a new object uploaded, a Lambda function is triggered. Later on there are some steps to process the object. For example, a Lambda function invokes the Rekognition API and Amazon Rekognition service is called. Another Lambda function extracts metadata including size and format. In the end, the relevant data is saved into a DynamoDB. Which AWS service would you use to coordinate the tasks for the image analyse?",
    answers: [
      "A. Use AWS Step Functions to manage the steps and tasks into a serverless workflow.",
      "B. Use a CloudFormation template to create several SQS queues and Lambda functions to manage the workflow for this application.",
      "C. Create an AWS Batch Job to manage and coordinate the tasks for this application.",
      "D. Use a Jenkins pipeline to add several steps which manage the various tasks for this application."
    ],
    correctAnswer: "Correct Answer – A",
    explanation:
      "In this question, there are several steps which need an AWS service to coordinate. The best tool to recommend is AWS Step Functions. Check its introductions in"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You were just hired as a DevOps Engineer for a startup. Your startup uses AWS for 100% of their infrastructure. They currently have no automation at all for deployment, and they have had many failures while trying to deploy to production.The company has told you deployment process risk mitigation is the most important thing now, and you have a lot of budget for tools and AWS resources.\n\nTheir stack includes a 2-tier API with data stored in DynamoDB or S3, depending on type. The Compute layer is EC2 in Auto Scaling Groups. They use Route53 for DNS pointing to an ELB. An ELB balances load across the EC2 instances. The scaling group properly varies between 4 and 12 EC2 servers. Which of the following approaches, given this company’s stack and their priorities, best meets the company’s needs?",
    answers: [
      "A. Model the stack in AWS Elastic Beanstalk as a single Application with multiple Environments. Use Elastic Beanstalk’s Rolling Deploy option to progressively roll out application code changes when promoting across environments.",
      "B. Model the stack in three CloudFormation templates: Data layer, compute layer, and networking layer. Write stack deployment and integration testing automation following Blue-Green methodologies.",
      "C. Model the stack in AWS OpsWorks as a single Stack, with 1 compute layer and its associated ELB. Use Chef and App Deployments to automate Rolling Deployment.",
      "D. Model the stack in 1 CloudFormation template, to ensure consistency and dependency graph resolution. Write deployment and integration testing automation following Rolling Deployment methodologies."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "Here you are using 2 of the best practices for deployment , one is Blue Green Deployments and the other is using Nested Cloudformation stacks."
  },
  {
    topic: "SDLC Automation",
    question:
      "You work for a company that automatically tags photographs using artificial neural networks (ANNs), which run on GPUs using C++. You receive millions of images at a time, but only 3 times per day on average. These images are loaded into an AWS S3 bucket you control for you in a batch, and then the customer publishes a JSON-formatted manifest into another S3 bucket you control as well. Each image takes 10 milliseconds to process using a full GPU. Your neural network software requires 5 minutes to bootstrap. Image tags are JSON objects, and you must publish them to an S3 bucket. Which of these is the best system architectures for this system?",
    answers: [
      "A. Create an OpsWorks Stack with two Layers. The first contains lifecycle scripts for launching and bootstrapping an HTTP API on G2 instances for image processing, and the second has an always-on instance which monitors the S3 manifest bucket for new files. When a new file is detected, request instances to boot on the artificial neural network layer. When the instances are booted and the HTTP APIs are up, submit processing requests to individual instances.",
      "B. Make an S3 notification configuration which publishes to AWS Lambda on the manifest bucket. Make the Lambda create a CloudFormation Stack which contains the logic to construct an autoscaling worker tier of EC2 G2 instances with the artificial neural network code on each instance. Handle the CloudFormation stacks creation success or failure using another Lambda Function. Create an SQS queue of the images in the manifest. Tear the stack down when the queue is empty.",
      "C. Deploy your artificial neural network code to AWS Lambda as a bundled binary for the C++ extension. Make an S3 notification configuration on the manifest, which publishes to another AWS Lambda running controller code. This controller code publishes all the images in the manifest to AWS Kinesis. Your ANN code Lambda Function uses the Kinesis as an Event Source. The system automatically scales when the stream contains image events.",
      "D. Create an Auto Scaling, Load Balanced Elastic Beanstalk worker tier Application and Environment. Deploy the artificial neural network code to G2 instances in this tier. Set the desired capacity to 1. Make the code periodically check S3 for new manifests. When a new manifest is detected, push all of the images in the manifest into the SQS queue associated with the Elastic Beanstalk worker tier."
    ],
    correctAnswer: "Answer – B",
    explanation:
      "The best way to get notified when the images get sent to the bucket is the S3 Events. Here you don’t need to provision infrastructure beforehand , and also since the source of S3 provides Event Handling , this should be used."
  },
  {
    topic: "SDLC Automation",
    question:
      "Your team was assigned a task to create a Jenkins job to do the backup for a very complicated system. The Jenkins job will trigger the backup task every week. For the backup itself, there are several steps involved including running preparation scripts, launching DB snapshots, checking DB snapshots status, running post scripts, etc. A Lambda function can implement each step. Besides, the solution architect expects the running status for each step can be visualized and managed by an AWS owned service. Which service should you use to achieve this requirement?",
    answers: [
      "A. AWS CodePipeline",
      "B. AWS Step Functions",
      "C. AWS Simple Notification Service",
      "D. AWS EventBridge"
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "In this question, it is essential that each step for the backup is visualized in an AWS service. Step Functions can meet the need as it has provided a UI to help understand the execution status for each step in a state machine. For example:"
  },
  {
    topic: "SDLC Automation",
    question:
      "A team is building up microservices using several Lambda functions. The team consulted you how to build a pipeline for the project. The requirement is that the pipeline uses AWS CodePipeline which is able to continuously build the Lambda code and deploy the applications in AWS whenever there is a new Git commit by a team member. In AWS CodePipeline, which combinations of services are valid for this scenario? (Select TWO.)",
    answers: [
      "A. Source stage (CodeCommit), Build stage (CodeBuild), Deployment stage (CloudFormation)",
      "B. Source stage (GitHub), Build stage (Jenkins), Deployment stage (Elastic Beanstalk)",
      "C. Source stage (Bitbucket), Build stage (CodeBuild), Deployment stage (Amazon ECS)",
      "D. Source stage (Github), Build stage (Jenkins), Deployment stage (CodeDeploy)",
      "E. Source stage (CodeCommit), Build stage (Travis CI), Deployment stage (CloudFormation)"
    ],
    correctAnswer: "Correct Answer – A, D",
    explanation:
      "In CodePipeline, various services can be integrated in different stages which is very flexible for users. However for this particular case, not every service is suitable although it may be a valid one for other scenarios. Refer to"
  },
  {
    topic: "Incident and Event Response",
    question:
      "You are currently using SQS to pass messages to EC2 Instances. You need to pass messages which are greater than 5 MB in size. Which of the following can help you accomplish this.",
    answers: [
      "A. Use Kinesis as a buffer stream for message bodies. Store the checkpoint id for the placement in the Kinesis Stream in SQS.",
      "B. Use the Amazon SQS Extended Client Library for Java and Amazon S3 as a storage mechanism for message bodies.",
      "C. Use SQS’s support for message partitioning and multi-part uploads on Amazon S3.",
      "D. Use AWS EFS as a shared pool storage medium. Store filesystem pointers to the files on disk in the SQS message bodies."
    ],
    correctAnswer: "Answer – B",
    explanation: "The AWS documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "When thinking of AWS Elastic Beanstalk, the ‘Swap Environment URLs’ feature most directly aids in what?\n\n ",
    answers: [
      "A. Immutable Rolling Deployments",
      "B. Mutable Rolling Deployments",
      "C. Canary Deployments",
      "D. Blue-Green Deployments"
    ],
    correctAnswer: "Answer – D",
    explanation: "The AWS Documentation mentions the following"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "A user is using Cloudformation to launch an EC2 instance and then configure an application after the instance is launched. The user wants the stack creation of ELB and AutoScaling to wait until the EC2 instance is launched and configured properly. How can the user configure this?",
    answers: [
      "A. It is not possible that the stack creation will wait until one service is created and launched",
      "B. The user can use the HoldCondition resource to wait for the creation of the other dependent resources",
      "C. The user can use the DependentCondition resource to hold the creation of the other dependent resources",
      "D. The user can use the WaitCondition resource to hold the creation of the other dependent resources"
    ],
    correctAnswer: "Answer – D",
    explanation:
      "You can use a wait condition for situations like the following:"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "A gaming company adopted AWS Cloud Formation to automate load-testing of their games. They have created an AWS Cloud Formation template for each gaming environment and one for the load-testing stack. The load-testing stack creates an Amazon Relational Database Service (RDS) Postgres database and two web servers running on Amazon Elastic Compute Cloud (EC2) that send HTTP requests, measure response times, and write the results into the database. A test run usually takes between 15 and 30 minutes. Once the tests are done, the AWS Cloud Formation stacks are torn down immediately. The test results written to the Amazon RDS database must remain accessible for visualization and analysis.\n\nSelect possible solutions that allow access to the test results after the AWS Cloud Formation load -testing stack is deleted.\n\nChoose 2 answers.",
    answers: [
      "A. Define an Amazon RDS Read-Replica in the load-testing AWS Cloud Formation stack and define a dependency relation between master and replica via the Depends On attribute.",
      "B. Define an update policy to prevent deletion of the Amazon RDS database after the AWS Cloud Formation stack is deleted.",
      "C. Define a deletion policy of type Retain for the Amazon RDS resource to assure that the RDS database is not deleted with the AWS Cloud Formation stack.",
      "D. Define a deletion policy of type Snapshot for the Amazon RDS resource to assure that the RDS database can be restored after the AWS Cloud Formation stack is deleted.",
      "E. Define automated backups with a backup retention period of 30 days for the Amazon RDS database and perform point-in-time recovery of the database after the AWS CloudFormation stack is deleted."
    ],
    correctAnswer: "Answer – C and D",
    explanation:
      "With the DeletionPolicy attribute you can preserve or (in some cases) backup a resource when its stack is deleted. You specify a DeletionPolicy attribute for each resource that you want to control. If a resource has no DeletionPolicy attribute, AWS CloudFormation deletes the resource by default."
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are writing an AWS CloudFormation template and you want to assign values to properties that will not be available until runtime. You know that you can use intrinsic functions to do this but are unsure as to which part of the template they can be used in. Which of the following is correct in describing how you can currently use intrinsic functions in an AWS CloudFormation template?",
    answers: [
      "A. You can use intrinsic functions in any part of a template.",
      "B. You can use intrinsic functions only in specific parts of a template. Currently, you can use intrinsic functions in resource properties, outputs, metadata attributes, and update policy attributes.",
      "C. You can use intrinsic functions only in the resource properties part of a template.",
      "D. You can use intrinsic functions in any part of a template, except AWSTemplateFormatVersion and Description."
    ],
    correctAnswer: "Answer – B",
    explanation: "This is clearly given in the aws documentation."
  },
  {
    topic: "SDLC Automation",
    question:
      "Your team has used AWS CodePipeline as the continuous integration and deployment tool for a Lambda function. In its deployment stage, the deployment provider is CloudFormation and the Action Mode is configured as “Create or update a stack”. This pipeline is working fine for several months however recently there were cases that the CloudFormation stack was updated inappropriately because of some mistake in the template. You were told to design a way so that the CodePipeline does not directly update the stack. Instead, there is a way to preview the stack changes before the deployment is completed. How should you work on this task?",
    answers: [
      "A. Change the code review process so that any issues on CloudFormation stacks are avoided.",
      "B. Modify the deployment provider to CodeDeploy to avoid any issues on CloudFormation stack update.",
      "C. Change the Action Mode to “Delete a stack” so that the environment is clean. Then add another stage to deploy the CloudFormation stack using the new template.",
      "D. Modify the Action Mode to “Create or replace a change set” for users to review the change. Then add another deployment stage to execute the change set if the change set is approved."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "This case asks for a way to preview the change for CloudFormation stack. The Change set is a good choice. Also refer to"
  },
  {
    topic: "SDLC Automation",
    question:
      "You have created an AWS CodePipeline which builds and deploys an AWS Step Function. The AWS Step Function is used to implement a state machine to query a large amount of records in DynamoDB. Now your lead asked you to add another test stage in the existing pipeline to do testing on the Step Function after a new version is deployed. In the new stage, which service should you use to trigger the Step Function for the test?",
    answers: [
      "A. Add a CloudWatch Event rule as the action provider in the new stage. Configure the Step Function as the target of the Event rule.",
      "B. Use a Lambda function to trigger the Step Function in order to do the testing.",
      "C. Add a Jenkins job to invoke the Step Function to perform any testing needed.",
      "D. Configure an API endpoint in API Gateway. The API will call StartExecution to start the state machine of the Step Function."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "In CodePipeline, a custom stage can be added with an action group. Then the user can configure the action group depending on the needs."
  },
  {
    topic: "Monitoring and Logging",
    question:
      "Your company has an e-commerce application accessed by users all over the world. You have EC2 instances deployed in multiple regions. You want to monitor the performance of these EC2 instances. How can you use CloudWatch to monitor these instances?",
    answers: [
      "A. Create separate dashboards in every region.",
      "B. Register instances running in different regions to CloudWatch.",
      "C. Have one single dashboard to report metrics to CloudWatch from different regions.",
      "D. This is not possible"
    ],
    correctAnswer: "Answer – C",
    explanation:
      "You can monitor AWS resources in multiple regions using a single CloudWatch dashboard."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your company is getting ready to do a major public announcement of a social media site on AWS. The website is running on EC2 instances deployed across multiple Availability Zones with a Multi-AZ RDS MySQL Extra Large DB Instance. The site performs a high number of small reads and writes per second and relies on an eventual consistency model. After comprehensive tests you discover that there is read contention on RDS MySQL. Which are the best approaches to meet these requirements?\n\nChoose 2 answers from the options below",
    answers: [
      "A. Deploy ElasticCache in-memory cache running in each availability zone",
      "B. Implement sharding to distribute load to multiple RDS MySQL instances",
      "C. Increase the RDS MySQL Instance size and Implement provisioned IOPS",
      "D. Add an RDS MySQL read replica in each availability zone"
    ],
    correctAnswer: "Answer – A and D",
    explanation: "Implement Read Replicas and Elastic Cache"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "A web-startup runs its very successful social news application on Amazon EC2 with an Elastic Load Balancer, an Auto-Scaling group of Java/Tomcat application-servers, and DynamoDB as data store. The main web application best runs on m2 x large instances since it is highly memory- bound. Each new deployment requires semi-automated creation and testing of a new AMI for the application servers which takes quite a while and is therefore only done once per week. Recently, a new chat feature has been implemented in nodejs and waits to be integrated in the architecture.\n\nFirst tests show that the new component is CPU bound because the company has some experience with using Chef. They decided to streamline the deployment process and move both the standard application and the new chat feature to OpsWorks because it can be used as an application life cycle tool to simplify management of the application and reduce the deployment cycles. What configuration in AWS Ops Works is necessary to integrate the new chat module in the most cost-efficient and flexible way?",
    answers: [
      "A. Create one AWS Ops Works stack, create one AWS Ops Works layer, create one custom recipe",
      "B. Create one AWS Ops Works stack create two AWS Ops Works layers create one custom recipe",
      "C. Create two AWS Ops Works stacks create two AWS Ops Works layers create one custom recipe",
      "D. Create two AWS Ops Works stacks create two AWS Ops Works layers create two custom recipe"
    ],
    correctAnswer: "Answer - B",
    explanation:
      "You can just have one Opswork stack and multiple layers, one for Node.js and the other for the standard application."
  },
  {
    topic: "SDLC Automation",
    question:
      "You are using an AWS CodePipeline to create a CloudFormation stack. The stack is used to deploy resources for a human approval project that allows the execution of a state machine to pause during a task. The workflow continues to the next state once the user has approved the task. In the CloudFormation template, there are already resources including Lambda functions that send email links for approval, API Gateway endpoint that triggers Lambda functions and an SNS topic that sends an email for approval. What is another key resource that needs to be created in the template?",
    answers: [
      "A. A state machine resource created in the type of AWS::StepFunctions::Activity.",
      "B. A SWF state machine resource configured in AWS::SWFFunctions::StateMachine.",
      "C. A Step Function resource created in the type AWS::StepFunctions::StateMachine.",
      "D. A SWF resource in the CloudFormation type AWS::SWFFunctions::Activity."
    ],
    correctAnswer: "Correct Answer – C",
    explanation:
      "In this question, the workflow progresses to the next state after the user has approved the task. It can be implemented with a state machine. For an example of creating a human approval project, check the document in"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "As part of a new project, you just created a regional Aurora MySQL cluster in ap-southeast-2 from AWS console. There are several Aurora database replicas which ensure that the Aurora cluster is highly available and durable. About the Aurora database replicas, which statement is correct? (Select TWO.)",
    answers: [
      "A. The replicas can share both the read and write traffic.",
      "B. The replicas are located in different availability zones in the same region as the primary database which is ap-southeast-2.",
      "C. By default, there is only one replica generated in each availability zone.",
      "D. Aurora automatically fails over to an Aurora Replica if the primary DB instance becomes unavailable.",
      "E. Replicas are configured with smaller storage volume than the primary DB instance to save cost."
    ],
    correctAnswer: "Correct Answer – B, D",
    explanation:
      "An Amazon Aurora DB cluster consists of primary DB instance and Aurora Replicas. Check the details in"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You are helping development team to create an Aurora MySQL cluster for a new web application. The cluster was created with a Dev/Test template since it was unclear about the details of requirements. There was only one writer in the DB cluster and there was no read replica. Recently, the Aurora database proved to be successful and you were told to configure the DB cluster to make it highly available. How should you set up the existing Aurora cluster?",
    answers: [
      "A. In RDS console, add Aurora Replicas to the DB cluster by actions -> add reader.",
      "B. Since an active Aurora cluster cannot configure read replicas, delete and recreate the Aurora cluster with a Production template.",
      "C. Use AWS CLI to configure cross region replications for the DB cluster to make it highly available.",
      "D. Delete the cluster and create a new Aurora cluster with serverless feature to make it highly available."
    ],
    correctAnswer: "Correct Answer – A",
    explanation:
      "Aurora read replicas can spread over multiple Availability Zones to improve the availability of the DB cluster. About how to add replicas, refer to"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You need to store a large volume of data. The data needs to be readily accessible for a short period, but then needs to be archived indefinitely after that. What is a cost-effective solution?",
    answers: [
      "A. Store all the data in S3 so that it can be more cost-effective",
      "B. Store your data in Amazon S3, and use lifecycle policies to archive to Amazon Glacier",
      "C. Store your data in an EBS volume, and use lifecycle policies to archive to AmazonGlacier.",
      "D. Store your data in Amazon S3, and use lifecycle policies to archive toS3-Infrequently Access"
    ],
    correctAnswer: "Answer – B",
    explanation:
      "The AWS documentation mentions the following on Lifecycle policies"
  },
  {
    topic: "SDLC Automation",
    question:
      "You work at a company that makes use of AWS resources. One of the key security policies is to ensure that all data is encrypted both at rest and in transit. Which of the following is the right implementation which aligns to this policy?",
    answers: [
      "A. Use S3 Server-side encryption  for data encryption at rest and SSL termination on ELB for data encryption in transit",
      "B. Use Server-side encryption for data encryption in transit and SSL termination on ELB for data encryption at rest",
      "C. Enabling Proxy Protocol",
      "D. Enabling sticky sessions on your load balancer"
    ],
    correctAnswer: "Answer - A",
    explanation:
      "\n\tOption Ais correct. Enabling S3 SSE encryption will encrypt data at rest for EBS volumes and  SSL termination allows encrypted traffic between the client and ELB.\n"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Your company is using an Autoscaling Group to scale out and scale in instances. There is an expectation of a peak in traffic every Monday at 8am. The traffic is then expected to come down before the weekend on Friday 5pm. How should you configure Autoscaling in this?",
    answers: [
      "A. Create dynamic scaling policies to scale up on Monday and scale down on Friday",
      "B. Create a scheduled policy to scale up on Friday and scale down on Monday",
      "C. Create a scheduled policy to scale out on Monday and scale in on Friday",
      "D. Manually add instances to the Autoscaling Group on Monday and remove them on Friday"
    ],
    correctAnswer: "Answer - C",
    explanation:
      "The AWS Documentation mentions the following for Scheduled scaling"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You are working for a company has an on-premise infrastructure. There is now a decision to move to AWS. The plan is to move the development environment first. There are a lot of custom based applications that need to be deployed for the development community. Which of the following can help to implement the application for the development team?\n\nChoose 2 answers from the options below.",
    answers: [
      "A. Create docker containers for the custom application components and deploy them using ECS",
      "B. Use OpsWorks to deploy the docker containers.",
      "C. Use Elastic beanstalk to deploy the docker containers.",
      "D. Use Cloudformation to deploy the docker containers."
    ],
    correctAnswer: "Answer - A and C",
    explanation:
      "The AWS documentation states the following for docker containers on Elastic Beanstalk"
  },
  {
    topic: "Policies and Standards Automation",
    question:
      "Your firm has uploaded a large amount of aerial image data to S3. In the past, in your on-premises\nenvironment, you used a dedicated group of servers to process this data and used Rabbit MQ – An open source messaging system to get job information to the servers. Once processed the data would go to tape and be shipped offsite. Your manager told you to stay with the current design, and leverage AWS archival storage and messaging services to minimize cost. Which is correct?",
    answers: [
      "A. Use SQS for passing job messages. Use Cloud Watch alarms to terminate EC2 worker instances when they become idle. Once data is processed, change the storage class of the S3 objects to Reduced Redundancy Storage.",
      "B. Setup Auto-Scaled workers triggered by queue depth that use spot instances to process messages in SQS. Once data is processed, Setup lifecycle policy to move object from S3 to Glacier.",
      "C. Change the storage class of the S3 objects to Reduced Redundancy Storage. Setup Auto-Scaled workers triggered by queue depth that use spot instances to process messages in SQS. Once data is processed, change the storage class of the S3 objects to Glacier.",
      "D. Use SNS to pass job messages use Cloud Watch alarms to terminate spot worker instances when they become idle. Once data is processed, change the storage class of the S3 object to Glacier."
    ],
    correctAnswer: "Answer - B",
    explanation:
      "The best option for reduces costs is Glacier, since anyway in the on-premise location everything was stored on tape. Hence option A is out."
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "Your company has recently extended its datacenter into a VPC on AWS. There is a requirement for on-premise users manage AWS resources from the AWS console. You don’t want to create IAM users for them again. Which of the below options will fit your needs for authentication?",
    answers: [
      "A. Use Auth 2.0 to retrieve temporary AWS security credentials to enable your members to sign in to the AWS Management Console.",
      "B. Use web Identity Federation to retrieve AWS temporary security credentials to enable your members to sign in to the AWS Management Console.",
      "C. Use your on-premises SAML 2 O-compliant identity provider (IDP) to grant the members federated access to the AWS Management Console via the AWS single-sign-on (SSO) endpoint.",
      "D. Use your on-premises SAML2.0-compliant identity provider (IDP) to retrieve temporary security credentials to enable members to sign in to the AWS management console."
    ],
    correctAnswer: "Answer – C",
    explanation:
      "You can use a role to configure your SAML 2.0-compliant IdP and AWS to permit your federated users to access the AWS Management Console. The role grants the user permissions to carry out tasks in the console."
  },
  {
    topic: "Monitoring and Logging",
    question:
      'Explain what the following resource in a CloudFormation template does?\n\nChoose the best possible answer.\n\n"SNSTopic" : {\n\n        "Type" : "AWS::SNS::Topic",\n\n        "Properties" : {\n\n               "Subscription" : [{\n\n                        "Protocol" : "sqs",\n\n                        "Endpoint" : { "Fn::GetAtt" : [ "SQSQueue", "Arn" ] }\n\n                 }]\n\n    }',
    answers: [
      "A. Creates an SNS topic which allows SQS subscription endpoints to be added as a parameter on the template",
      "B. Creates an SNS topic that allows SQS subscription endpoints",
      "C. Creates an SNS topic and then invokes the call to create an SQS queue with a logical resource name of SQSQueue",
      "D. Creates an SNS topic and adds a subscription ARN endpoint for the SQS resource created under the logical name SQSQueue"
    ],
    correctAnswer: "Answer – D",
    explanation:
      "The intrinsic function Fn::GetAtt returns the value of an attribute from a resource in the template. This has nothing to do with adding parameters (Option A is wrong) or allowing endpoints (Option B is wrong) or invoking relevant calls (Option C is wrong)"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "You are currently planning on using Autoscaling to launch instances which have an application installed. Which of the following methods will help ensure the instances are up and running in the shortest span of time to take in traffic from the users?",
    answers: [
      "A. Log into each instance and install the software.",
      "B. Use UserData to launch scripts to install the software.",
      "C. Use Docker containers to launch the software.",
      "D. Use AMI's which already have the software installed."
    ],
    correctAnswer: "Answer - D",
    explanation:
      "The AMI will be the fatest because it will already have the software installed. You can customize the instance that you launch from a public AMI and then save that configuration as a custom AMI for your own use. Instances that you launch from your AMI use all the customizations that you've made."
  },
  {
    topic: "SDLC Automation",
    question:
      "A company just started to use AWS ECS/ECR for its dockerized applications. They are looking for a pipeline service with a source stage and which can deploy a container application to the ECS cluster using a blue/green deployment. At the moment, all docker images are already stored in ECR service which will be used in the pipelines as artifactories. What is the quickest way to implement this pipeline?",
    answers: [
      "A. Create a Jenkins server in EC2 instance. In the Jenkins job, add a source stage for ECR docker image and a deployment stage for the ECS cluster using AWS CLI commands.",
      "B. In AWS CodePipeline, configure a source stage for ECR, a build stage with CodeBuild for the docker image and a deployment stage using CloudFormation to deploy ECS cluster.",
      "C. In AWS CodeDeploy, create a CodeDeploy application and a deployment group to configure the blue/green deployment to ECS cluster using the image in ECR repository.",
      "D. In AWS CodePipeline, add a source stage for ECR docker image and a deployment stage for ECS where the deployment runs with a CodeDeploy application and deployment group."
    ],
    correctAnswer: "Correct Answer – D",
    explanation:
      "The question asks for the quickest way so it is possible that multiple answers may work. However we should find out the answer which is the most fit in this scenario without unnecessary efforts."
  },
  {
    topic: "SDLC Automation",
    question:
      "A DevOps engineer is building a pipeline in AWS CodePipeline in order to build a docker image and push it to AWS ECR. In the CodePipeline, the source stage is configured with AWS CodeCommit where the source files are stored. In the build stage, the Action Provider is AWS CodeBuild. For its buildspec.yml file, there are three phases which are pre_build, build and post_build. The build stage in the pipeline is responsible for creating the image and pushing it to ECR repository. Which command should be put in the pre_build stage in the buildspec.yml file?",
    answers: [
      "A. docker build -t $REPOSITORY_URI:latest .",
      "B. aws ecr get-login --region $AWS_DEFAULT_REGION",
      "C. docker tag $REPOSITORY_URI:latest",
      "D. docker push $REPOSITORY_URI:latest"
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "The buildspec.yml file includes a collection of build commands and related settings for AWS CodeBuild to run the build. Refer to"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "For a new application, the DevOps team is implementing a blue/green deployment strategy. For each new release, the application can be deployed via a CloudFormation stack including resources such as Auto Scaling Group, Launch configuration, and Classic Load Balancer. A weighted routing policy is configured in Route53. With Route53, a small percentage of traffic firstly go to the green environment and the weights increase until the green environment carries the full production traffic. However, which disadvantage may this approach have?",
    answers: [
      "A. Weighted routing policy is complicated to implement in a pipeline.",
      "B. DNS TTL decides how long clients cache query results. Certain sessions may still be tied to the previous environment so that it may impact the speed to deploy a new environment or rollback.",
      "C. Route53 weighted routing policy is not suitable if a rollback is required.",
      "D. Only Classic load balancer is suitable for this approach. Network load balancer or application load balancer cannot be used together with Route53 weighted routing policy."
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "To implement blue/green deployments in AWS, there are several methods. For the details, check the document in"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "An IT company has owned several applications which have end users around the world. Route53 is being used to route traffic. Depending on different features of applications, various deployment strategies are implemented. For example, some sensitive applications use Canary deployment while others use Blue/Green deployment. Several applications also use Route53 to implement disaster recovery plan. Which routing policy in Route53 should be configured to achieve these strategies?",
    answers: [
      "A. Latency for Blue/Green. Simple for Canary. Weighted for Disaster recovery.",
      "B. Simple for Blue/Green. Weighted for Canary. Latency for Disaster recovery.",
      "C. Weighted for Blue/Green. Weighted for Canary. Failover for Disaster recovery.",
      "D. Weighted for Blue/Green. Latency for Canary. Failover for Disaster recovery."
    ],
    correctAnswer: "Correct Answer – C",
    explanation: "Check"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "You have a number of Cloudformation stacks in your IT organization. Which of the following command will help you to see all the cloudformation stacks that have been deleted three months earlier ?\n\n ",
    answers: [
      "A. describe-stacks",
      "B. list-stacks",
      "C. stacks-complete",
      "D. list-templates"
    ],
    correctAnswer: "Answer - B",
    explanation: "The following is the description of the list-stacks command"
  },
  {
    topic: "Configuration Management and Infrastructure as Code",
    question:
      "If you’re trying to configure an AWS Elastic Beanstalk worker tier for easy debugging if there are problems finishing queue jobs, what should you configure?",
    answers: [
      "A. Configure Rolling Deployments.",
      "B. Configure Enhanced Health Reporting.",
      "C. Configure Blue-Green Deployments.",
      "D. Configure a Dead Letter Queue."
    ],
    correctAnswer: "Answer – D",
    explanation:
      "The AWS documentation mentions the following on dead-letter queues"
  },
  {
    topic: "SDLC Automation",
    question:
      "Your CTO is very worried about the security of your AWS account. How best can you prevent hackers from completely hijacking your account?",
    answers: [
      "A. Use short but complex password on the root account and any administrators.",
      "B. Use AWS IAM Geo-Lock and disallow anyone from logging in except for in your city.",
      "C. Use MFA on all users and accounts, especially on the root account.",
      "D. Don’t write down or remember the root account password after creating the AWS account."
    ],
    correctAnswer: "Answer – C",
    explanation: "The AWS documentation mentions the following on MFA"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "A large corporation has owned several on-premise servers deployed in Europe. Recently, in order to save costs and add cloud servers for disaster recovery, it has a new strategy to migrate certain existing servers to AWS. To configure AWS servers smoothly, the DevOps team plans to use AWS Server Migration Service (SMS). Which scenario is NOT suitable to use SMS?",
    answers: [
      "A. An Ubuntu16.04 linux server running in VirtualBox.",
      "B. A Java application running in a virtual server in an vSphere Enterprise machine.",
      "C. A Windows10 Pro server running in Microsoft Hyper-V.",
      "D. A VM running in Microsoft System Center Virtual Machine Manager."
    ],
    correctAnswer: "Correct Answer – A",
    explanation:
      "This question asks for the answer which is NOT suitable for AWS SMS. Please check the documentation"
  },
  {
    topic: "High Availability, Fault Tolerance, and Disaster Recovery",
    question:
      "A big retail company has owned lots of on-premise servers. In order to design a disaster recovery plan in the Cloud, the company plans to migrate its major servers to AWS ap-southeast-2 region. The servers are deployed in Windows virtual machines managed by Microsoft Hyper-V. Your team lead wants to use an AWS tool to simplify the migration process to generate AMIs periodically. Also the tool can schedule replications and track the progress. Which tool should you use to meet the need?",
    answers: [
      "A. AWS Migration Hub",
      "B. AWS Server Migration Service",
      "C. AWS DataSync",
      "D. AWS Database Migration Service"
    ],
    correctAnswer: "Correct Answer – B",
    explanation:
      "For on-premise virtual machines in Microsoft Hyper-V/SCVMM, AWS Server Migration Service is appropriate to automate the migration to AWS by creating AMI ready for deployment on Amazon EC2."
  }
];
