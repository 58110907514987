import React, { useState, useEffect } from "react";
import Icon from "antd/es/icon";
import "./SelectedCard.css";

const typeColor = {
  verb: "red",
  adjective: "orange",
  adverb: "green"
};

export default ({ wordData, forgotten = false, removeWord }) => {
  const [state, setState] = useState({
    showDelete: false
  });

  const toggleDelete = () => {

    if (!forgotten) {
      setState({
        showDelete: !state.showDelete
      });
    }
  };
  return (
    <div
      onMouseOver={toggleDelete}
      onMouseOut={toggleDelete}
      className={`selected-card ${
        forgotten ? "forgotten-selected-card" : null
      }`}
      style={{
        border: `2px solid ${
          wordData.type ? typeColor[wordData.type] : "blue"
        }`,
        borderRightWidth: "5px",
        borderLeftWidth: "5px"
      }}
    >
      <div className="selected-word-wrapper">{wordData.word}</div>
      <div
        onClick={() => removeWord(wordData)}
        className={`${state.showDelete ? "" : "hidden"} remove-selected`}
      >
        x
      </div>
    </div>
  );
};
