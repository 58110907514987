export default class SRS{
    constructor(arrayCount, studyContent, moduloBase = 4){
      this.arrayCount = arrayCount;
      this.contentLength = studyContent.length
      this.studyContent = studyContent.map(item => {
        item.reps = 0;
        return item
      });
      this.arrays = {0: [...this.studyContent], forgotten: []};
      this.currentCard = this.arrays[0].shift()
      this.baseArray = 0
      this.turn = 0
      this.moduloBase = moduloBase
      this.insertAfterForgotten = false
      this.forgottenCountdown = 0
      this.justForgotten = false
      this.forgottenModuloBase = 5
      this.sessionId = Date.now()
    }
    initializeArrays(){
      for(let i = 1; i < this.arrayCount + 1; i++){
        this.arrays[i] = []
        console.log(i)
      }   
    }
  
    printState(){
      console.log(this)
    }
  
    checkBaseArray(){
  
    }
  
    remembered(){
      console.log(`Remembered ${this.currentCard.name}!`)
  

     if(this.currentCard.reps === 0){
      //alert("First time card was called", this.currentCard.front)
     }
      /* I. PROCESS CURRENT CARD */
      /* 
      i) if card isn't in the 'peak'/highest array (i.e. remembered),assign it to the next tier via the reps property. 
      */
      if(!(this.currentCard.reps >= this.arrayCount)){
        this.currentCard.reps++
      }
      //If the card destination is in an array that is lower than the current baseArray, make the base array that number
      if(this.currentCard.reps < this.baseArray){
        this.baseArray = this.currentCard.reps
      }
      
      //Place card in it's appropriate destination
      let nextArray = this.arrays[this.currentCard.reps];
      let currentCard = {...this.currentCard}
      nextArray.push(currentCard);
  
      /*II. UPDATE NEW CURRENT CARD */
  
     /*  
     A) REVIEW PROTOCOL
      
      */
      /* Check and modify base array */
      if(!this.arrays[this.baseArray].length && this.baseArray < this.arrayCount){
        this.baseArray++
      }
  
      let tier = this.baseArray;
      if(this.forgottenCountdown > 0){
        this.forgottenCountdown--
      }
  
      if(!this.justForgotten && this.forgottenCountdown % this.forgottenModuloBase === 0 && this.arrays['forgotten'].length){
        tier = 'forgotten'
        if(this.turn % this.moduloBase === 0){
          this.insertAfterForgotten = true
        }
        console.log("FORGOTTEN", `${this.forgottenCountdown} % ${this.forgottenModuloBase}`)
        
      }
      else if(this.turn % this.moduloBase === 0 && this.turn !== 0 && this.baseArray !== this.arrayCount){
        //Grab the card from the next tier (one that has been reviewed already) or the forgotten tier and make it the currentCard
        tier = this.baseArray < this.arrayCount ? this.baseArray + 1 : this.baseArray ;          
        
      }
      else if(this.insertAfterForgotten){
        tier = this.baseArray < this.arrayCount ? this.baseArray + 1 : this.baseArray ;  
        this.insertAfterForgotten = false;
      }
      
        /* Check if the base array is empty */
      console.log("Tier", tier)
      this.currentCard = this.arrays[tier].shift();
      this.turn++;
      
      this.justForgotten = false
      
      
      this.printArrays()
      
    }
  
    forgotten(){
      // Check if card has been reviewed yet
      console.log(this.arrays, "Base Array", this.baseArray)
      this.forgottenCountdown = this.forgottenCountdown + this.forgottenModuloBase;
  
      if(this.currentCard.reps > 0){
        let currentCard = {...this.currentCard}
        this.arrays['forgotten'].push(currentCard);
        if(this.arrays['forgotten'].length === 1){
          this.justForgotten = true
        }
        
      
      if(!this.arrays[this.baseArray].length){
        this.baseArray++
      }
      let tier = this.baseArray; 
  
      if(!this.justForgotten && this.forgottenCountdown % this.forgottenModuloBase === 0){
        tier = 'forgotten'
        if(this.turn % this.moduloBase === 0){
          this.insertAfterForgotten = true
        }
        
      }
  
      else if(this.turn % this.moduloBase === 0 && this.turn !== 0 && this.baseArray !== this.arrayCount){
        //Grab the card from the next tier (one that has been reviewed already) and make it the currentCard
        tier = tier < this.arrayCount ? tier + 1 : tier
        
      }
      else if(this.insertAfterForgotten){
        tier = tier < this.arrayCount ? tier + 1 : tier;  
        this.insertAfterForgotten = false;
      }
      console.log("FUCKED TIER", tier)
      /* Check if the base array is empty */
      this.currentCard = this.arrays[tier].shift();
      this.turn++;
      if(!this.justForgotten && this.forgottenCountdown > 0){
        this.forgottenCountdown--
      }
      this.justForgotten = false
      
      }
      else{
        console.log(`You haven't seen this card yet!`)
      }
      
    }
  
    printArrays(){
      console.log(`Arrays:`, this.arrays)
    }
  
  }