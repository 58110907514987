import React, { useState, useEffect, useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import TestComponent from './TestComponent'
import "./TestBox.css";

const TestBox = ({session, addAndDeleteSessions}) =>{
    let [testBoxState, setTestBoxState] = useState({
        clicked: false,
        session: session
    })
   

    let flipClicked = () => {
        setTestBoxState({
            ...testBoxState,
            clicked: !testBoxState.clicked
        })
        addAndDeleteSessions(!testBoxState.clicked, testBoxState.session)
    }
    return (
        <div className="test-container">
            {`Study Session ${session.sessionID}`}
            <div className={`test-word-count ${testBoxState.clicked ? "clicked" : ''}`} onClick={flipClicked}>
            {session.cardsArray.length}
            </div>
            <button className="test-button">Begin</button>
        </div>
    )
}

export default TestBox;