import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import "./TestTemplate.css";
import SRS from "../../helpers/SRS";
import createSession from "../../data-templates/sessionTemplate";
import CardTemplate from "./CardTemplate";
import TestButtons from "./TestButtons";
import Icon from "antd/lib/icon";
import tests from "../../dummy-data";
import modifyUser from "../../helpers/user-helpers/modifyUser";

 export const CardYard = ({cards}) => { 

  let cardStyles = {}
  
  return (<div className="card-yard-container" style={{}}>
    
    {cards.map(card => {
      return <BasicCard card={card} />
    })}
  
  </div>);


}

const BasicCard = ({card, cardStyles = {}}) => {
  let [state, setState] = useState({
    flipped: false,
    card,
  });

  let sideStyles = {position: 'relative', width: '100%'}
  return ( <div
    
    className={`basic-card`}
    style={{height: 200,minWidth: 200 }}                   
    onClick={() => setState({ ...state, flipped: !state.flipped })}
  >
    {
      !state.flipped ? <div style={sideStyles}>
      <div style={{fontSize: 14}}>
        {card.front}
      </div>
    </div>
      :

<div style={sideStyles}>
      {card.img ? <img style={{transform: 'rotate(90deg)', width: '60%'}} alt="" src={`${process.env.PUBLIC_URL + card.img}`/* `../../images/cytosine-c.png` *//* card.img */} /> : null}
      {!card.img && card.back}
      
    </div>
    }

    
    
  </div>)

}

const testIntervals = {
  /* This object maps the key (corresponding to the 'testCount'[i.e has it been tested once, twice, etc] 
        value of the session) to its appropriate interval of time. */
  0: 60 * 60 * 12 * 1000,
  1: 60 * 60 * 48 * 1000,
  2: 60 * 60 * 24 * 5 * 1000,
  3: 60 * 60 * 24 * 7 * 1000,
};

let SrsContext = createContext();
let objcopy = {
  topic: "Configuration Management and Infrastructure as Code",
  question:
    "You are in charge of designing a number of Cloudformation templates for your organization. You need to ensure that no one can accidentally update the production based resources on the stack during a stack update. How can this be achieved in the most efficient way?",
  answers: [
    "A. Create tags for the resources and then create IAM policies to protect the resources.",
    "B. Use a Stack based policy to protect the production based resources.",
    "C. Use S3 bucket policies to protect the resources.",
    "D. Use MFA to protect the resources",
  ],
  correctAnswer: "Answer – B",
  explanation: "",
};

const paramBasedTest = (param, testArray ) => {
  console.log(param, testArray)
  switch(param){
    case "/page1":
      return testArray.slice(0,9)
    case "/page2":
      return testArray.slice(9,18)
    case "/page3":
      return testArray.slice(18,27)
    case "/page4":
      return testArray.slice(27,35)
    default:
      return testArray
  }
  

}

const TestTemplate = ({ allCards, cards }) => {
  let [state, setState] = useState({
    currentCard: "",
    testArray: [],
    cardsArray: [],
    remembered: [],
    forgotten: [],
    rememberedHighlights: [],
    allCards: [],
  });

  let [viewAll, setViewAll] = useState(false);

  const history = useHistory();
 let showReview = history.location.search === '?showReview' ? true : false
   

  var user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {

    /* if(history.location.pathname && state.testArray){
      console.log("2",history.location.pathname, state.testArray)
      state.testsArray = paramBasedTest(history.location.pathname, state.testArray)

      setState({
        ...state
      })
    } */
  }, [history.location.pathname]);
  useMemo(() => {
    console.log("HIST", history)
    let testsArray = cards //tests.testOne;

    let deckName = "test";

    
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)),
          temp = array[i];
        [array[i], array[j]] = [array[j], array[i]];
        array[i].key = i;
        array[j].key = j;
      }
      return array;
    };

    if(history.location.pathname && testsArray){
      console.log("2",history.location.pathname, state.testArray)
      testsArray = paramBasedTest(history.location.pathname, testsArray)

    }

    
    let test =
      testsArray.length > 9
        ? shuffleArray(testsArray)//.slice(0, 10)
        : shuffleArray(testsArray);
    let srs = new SRS(3, test, testsArray.length > 7 ? 4: 2);

    srs.initializeArrays();

    let cardsArray = [...test];
    

    let newSession = createSession({ cardsArray, sessionId: 1, deckName });

    setState({
      ...state,
      testArray: test,
      cardsArray,
      currentCard: srs.currentCard,
      srs: srs,
    });

    //modifyUser("currentSession",`customDecks.${deckName}`, newSession);
  }, [history.location.pathname]);

  let rotateCards = (status) => {
    let clickedCard = { ...state.currentCard };
    // let nextCard = state.testArray.pop();
    //console.log('nextCard', nextCard);
    //state[status].push(clickedCard);
    //state.currentCard = state.testArray.pop();
    //console.log(state.srs.currentCard)

    if (status === "remembered") {
      if (state.rememberedHighlights.length === 5) {
        state.rememberedHighlights.shift();
      }

      state.rememberedHighlights.push(state.currentCard);

      state.srs.remembered();
    }
    if (status === "forgotten") {
      state.srs.forgotten();
    }
    state.currentCard = state.srs.currentCard;

    setState({
      ...state,
    });
    console.log(
      "STATE",
      state.srs.arrays[3],
      state.cardsArray.length,
      state.srs.baseArray
    );
  };

  let saveQuit = () => {
    if (!user.decks.customDecks[state.currentSession.deckName].sessions.list) {
      user.decks.customDecks[state.currentSession.deckName].sessions.list = [];
    }
    user.decks.customDecks[state.currentSession.deckName].sessions.list.push(
      state.currentSession
    );
    //modifyUser(`decks.customDecks.${deckName}.sessions.array`, pushItem())
    user.currentSession = "";
    console.log("Saved USER", user);
    localStorage.setItem("user", JSON.stringify(user));
    history.push("/dashboard/custom-decks");
  };
  useEffect(() => {
    console.log("SRS.arrays[1]", state.srs.arrays[1]);
    if (state.srs.arrays[1]) {
      setState({ ...state, remembered: state.srs.arrays[1] });
    }
  }, [state.currentCard]);

  let srsInstance = useContext(SrsContext);

  let boxStyles = { width: 90, height: 90, boxShadow: '1px 1px 1px rgba(0,0,0,.5)' };

  return (
    <><div className="main-test-section">
      <div
      className="view-all"
            style={{
             
              borderRadius: 10,
              position: "absolute",
              left: "5%",
              top: '15%',
              height: 100, 
              width: 120,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 5, 
              cursor: 'pointer',
              zIndex: 10
            }}
            onClick={() => setViewAll(!viewAll)}
          >
           {!viewAll ? "View All Cards" :"Continue Practice"}
          </div>
      {viewAll ? (
        <CardYard cards={state.cardsArray} />
      ) : (
        <>
          
          {
            <div className="left-section">
              {showReview ? <div className="card-yard">
                {state.remembered.map((item) => {
                  return (
                    <div style={boxStyles}>
                      <img
                        alt=""
                        style={{ transform: "rotate(90deg)", width: 90 }}
                        src={`${process.env.PUBLIC_URL + item.img}`}
                      />
                    </div>
                  );
                })}
              </div>: null}
              
            </div>
          }
          <div className="middle-section">
            {state.currentCard ? (
              state.cardsArray.map((card, index) => {
                return (
                  <div className="test-card-container">
                    <CardTemplate
                      wide
                      index={index}
                      frontKey="front"
                      backKey="back"
                      currentCard={state.currentCard}
                      card={card}
                      frontMultiData={[]}
                      backMultiData={[]}
                      fontSize={"50px"}
                    />
                  </div>
                );
              })
            ) : (
              <div className="general-text">
                You've finished your test!
                <div className="save-quit-button" onClick={() => saveQuit()}>
                  Save & Quit
                </div>
              </div>
            )}
            <TestButtons
              rotateCards={rotateCards}
              currentCard={state.currentCard}
            />
            {state.srs &&
            state.srs.arrays[3].length === state.cardsArray.length - 1 ? (
              <button onClick={saveQuit}>END</button>
            ) : (
              ""
            )}
          </div>
          <div className="right-section">
            {
              showReview ? <div className="card-yard">
              {state.remembered.map((item) => {
                return <div style={boxStyles}>{item.front}</div>;
              })}
            </div> : null}
          </div>
        
      </>) }
      </div>
    </>
  );
};

export default TestTemplate;
