import React, { useState, useEffect, useMemo } from "react";
import "./WordTypeSelection.css";
import SelectionCard from "./SelectionCard";


export default ({array = [], addWord, changeState, selectWord}) => {
    
    return (
    <div className='word-type-selection-container'>
        {array.map(wordData => {
                  return (
                    <SelectionCard
                      wordData={wordData}
                      changeState={changeState}
                      selectWord={selectWord}
                    />
                  );
                })}
    </div>)
}