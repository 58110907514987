import React, { Component, useState } from "react";
//import logo from "./logo.svg";
import "./App.css";
import Card from "./components/Card";
import Icon from "antd/lib/icon";
import StarredWord from "./components/StarredWord";
import { Spring, config, Transition } from "react-spring";
import MemoryQuizPage from "./components/MemoryQuizPage";
import CardReview from "./components/CardReview";
import NoteTaker from "./components/NoteTaker";
import LiningPractice from "./components/LiningPractice";
import CardSelection from "./components/CardSelection";
import Dashboard from './components/practice/Dashboard';
import {Route, Link , Switch} from 'react-router-dom';
import TestsPage from './components/tests/TestsPage';
import AddWordsPage from './components/AddWordsPage';
import CustomDecks from './components/CustomDecks';
import PracticeSentences from './components/practice/PracticeSentences';
import axios from 'axios';
import nouns from './base-data/nouns'




class App extends Component {
  state = {
    cards: []
  }

  
  componentWillMount() {

  }

  componentDidMount() {
    //axios.get('localhost:8888/car')
    
  }

  render() {
    

    return (
      <div className="App">
        <div className="navBar">
          <nav>
            <Link style={{textDecoration: 'none'}}to="/dashboard">
            <div className="logo-txt" style={{textDecoration: 'unset',fontStyle: 'italic'}}>
              STUDY HARD!!
             {/*  MNEMO
              <span
                style={{
                  color: "black",
                  textShadow: "1px 1px 0px rgb(255, 60, 60)"
                }}
              >
                Z
              </span>
              <span
                style={{
                  color: "black",
                  textShadow: "1px 1px 0px rgb(255, 197, 6)"
                }}
              >
                E
              </span>
              <span
                style={{
                  color: "black",
                  textShadow: "1px 1px 0px rgb(0, 162, 255)"
                }}
              >
                N
              </span> */}
            </div>
            </Link>
              
            <div className="iconContainer" style={{fontSize: 10, display: 'flex', width: '30%', justifyContent: "space-between"}}>
              {/* <Icon type="user" className="user" />
              <Icon type="setting" className="settings" /> */}

              <Link onClick={()=>console.log("fack")} style={{margin: "0 5"}} to='/page1'>
                Page1
              </Link>
              <Link style={{margin: "0 5"}} to='/page2'>
                Page2
              </Link>
              <Link style={{margin: "0 5"}} to='/page3'>
                Page3
              </Link>
              <Link style={{margin: "0 5"}} to='/page4'>
                Page4
              </Link> 
            </div>
          </nav>
        </div>
        <Switch>
        <Route exact path="/" render={props =><Dashboard {...props}/>} />
        <Route exact path="/dashboard" render={props => <Dashboard {...props}/>} />
        <Route exact path="/:page" render={props => <PracticeSentences {...props}/>} />
        
        <Route path="/dashboard/learn" render={props => <MemoryQuizPage {...props}/>} />
        <Route path="/dashboard/review" render={props => <CardReview {...props}/>} />
        <Route path="/dashboard/entry" render={props => <NoteTaker {...props}/>} />
        <Route path="/dashboard/lining" render={props => <LiningPractice {...props}/>} />
        <Route path="/dashboard/select" render={props => <CardSelection setCards={this.setCards} {...props}/>} />
        <Route path="/dashboard/tests" render={props => <TestsPage {...props}/> } />
        <Route path="/dashboard/add-words" render={props => <AddWordsPage {...props}/> } />
        <Route path="/dashboard/custom-decks" render={props => <CustomDecks {...props}/> } />
        <Route path="/dashboard/practice/sentences" render={props => <PracticeSentences  {...props}/> } />
        <Route path="/deck-app" render={props => <></> } />
        </Switch>
      </div>
    );
  }
}

export default App;

