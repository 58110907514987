import React, { useState, useEffect, useMemo } from "react";
import "./ForgottenCards.css";
import SelectionCard from "./SelectionCard";
import SelectedCard from './SelectedCard';

const ForgottenCards = ({forgottenCards}) => {
  return (
    <div className="forgotten-card-container">
        <div className="forgotten-cards">
        {forgottenCards.map(card => {
          return <SelectedCard wordData={card} forgotten />;
        })}
        </div>
        
      
    </div>
  );
};

export default ForgottenCards
